import styled from 'styled-components';
import { white, primary, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
   background-color: ${white};
   width: 100vw;
   display: flex;
   align-items: top;
   justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1.5%;
  justify-content: space-between;
  align-items: center;

  h1{
    font-size: 28px;
    color: ${primary};
    margin-top: 5%;

    @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;

export const ContentFile = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
  
  h3{
    font-size: 20px;
    font-weight: bold;
    color: ${primary};
  }
`

export const Image = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;

    @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

    .description{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 55%;
        height: 100%;

        @media (max-width: 1000px) {
            width: 100%;
        }

        h3{
            font-size: 20px;
            font-weight: bold;
            color: ${primary};
            margin: 30px 0 25px 0;
        }

        p{
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            line-height: 35px;

            span{
                color: ${middleGray};
                font-weight: 600;
            }
        }
    }

    img{
        width: 40%;
    }
`