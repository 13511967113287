/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Container, LoadingArea, ButtonArea, Printable } from './styles';
import { loadingColor, logoDefault as myPassLogo } from 'styles/colorProvider';
import { Button } from 'components';
import { BsPrinter } from 'react-icons/bs';

function GeneralConditions({ history }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    history.push(`${process.env.PUBLIC_URL}/CondicoesGerais.pdf`);
  }, []);

  return (
    <>
      {!loading && (
        <ButtonArea>
          <Button height="30px" width="130px" onClick={() => window.print()}>
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <Container>
          <Printable>
            <div className="header">
              <div className="logoArea">
                <img src={myPassLogo} />
              </div>
              <div className="titleArea">
                <h1 className="name">Condições Gerais</h1>
                <p>Prezado(a) Segurado(a)</p>
                <p style={{ textAlign: 'justify' }}>
                  Estas Condições Gerais trarão informações importantes para que seja possível entender os termos técnicos,
                  coberturas e orientações sobre sua jornada como SEGURADO(A) junto ao nosso time. A interpretação será apenas e
                  tão somente as constantes nestas cláusulas, não cabendo a utilização de qualquer outra.
                </p>
              </div>
            </div>

            <section>
              <div>
                <div>
                  <h1>1. OBJETIVO DO SEGURO&nbsp;</h1>
                </div>
                <div>
                  <p>
                    O contrato de seguro tem por objetivo garantir a(o) SEGURADO(A) ou BENEFICI&Aacute;RIO(A), at&eacute; o LMI -
                    Limite m&aacute;ximo de indeniza&ccedil;&atilde;o contratado para cada cobertura, o pagamento de
                    indeniza&ccedil;&atilde;o em consequ&ecirc;ncia direta da ocorr&ecirc;ncia dos riscos cobertos e expressamente
                    convencionados nas coberturas e cl&aacute;usulas contratadas&nbsp;e estabelecidas neste documento, bem como as
                    despesas com socorro e salvamento do ve&iacute;culo segurado, respeitados os riscos exclu&iacute;dos do
                    contrato de seguro&nbsp;aqui descriminados.&nbsp;
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>2. COBERTURAS SEGUR&Aacute;VEIS&nbsp;</h1>
                </div>
                <div>
                  <p>
                    Estar&atilde;o cobertos os riscos referentes &agrave;s coberturas previstas&nbsp;nestas
                    condi&ccedil;&otilde;es gerais,&nbsp;contratadas pelo segurado e especificadas na ap&oacute;lice. O(A)
                    SEGURADO(A), mediante pagamento de pr&ecirc;mio, dever&aacute; contratar pelo menos uma das coberturas
                    b&aacute;sicas e poder&aacute; contratar coberturas adicionais (opcionais), de acordo com os crit&eacute;rios
                    de aceita&ccedil;&atilde;o da Seguradora:&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>2.1&nbsp;Coberturas B&aacute;sicas:&nbsp;</h2>
                </div>
              </div>
              <div>
                <div>
                  <ol start="1">
                    <li>
                      <p>Colis&atilde;o, Fen&ocirc;menos Naturais e Inc&ecirc;ndio;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>Roubo e Furto.&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>2.2&nbsp;Coberturas Adicionais:&nbsp;</h2>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>Assist&ecirc;ncia 24 (vinte e quatro) horas;&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="4">
                    <li>
                      <p>APP &ndash; Acidente pessoal por passageiro:&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>Carro reserva;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>
                        Cobertura para vidros: Far&oacute;is, lanternas e vidros (dianteiro, traseiro, laterais e lentes
                        retrovisores);&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="7">
                    <li>
                      <p>Danos a terceiros RCF &ndash; Danos Materiais (Carros);&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="8">
                    <li>
                      <p>Danos a terceiros RCF &ndash; Danos Materiais (Motos);&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="9">
                    <li>
                      <p>Franquia Reduzida.&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>
                    2.3&nbsp;Consideram-se coberturas contratadas aquelas expressamente convencionadas na ap&oacute;lice de
                    seguro, sendo obrigat&oacute;ria a contrata&ccedil;&atilde;o de uma das coberturas b&aacute;sicas, podendo
                    qualquer uma das coberturas b&aacute;sicas serem contratadas isoladamente, observado os termos das
                    Condi&ccedil;&otilde;es Gerais.&nbsp;
                  </h2>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>3. DEFINI&Ccedil;&Otilde;ES&nbsp;</h1>
                </div>
              </div>
              <div>
                <p className="definition">ACEITA&Ccedil;&Atilde;O&nbsp;</p>
              </div>
              <div>
                <p>
                  Aprova&ccedil;&atilde;o do risco, apresentado na proposta de seguro efetuada pelo(a) SEGURADO(A), para a
                  contrata&ccedil;&atilde;o do seguro que serve de base para a emiss&atilde;o da ap&oacute;lice.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">ACESS&Oacute;RIOS&nbsp;</p>
              </div>
              <div>
                <p>
                  S&atilde;o pe&ccedil;as fixadas em car&aacute;ter transit&oacute;rio no ve&iacute;culo segurado,
                  independentemente de ser ou n&atilde;o original de f&aacute;brica, referentes a som e imagem, tais como,
                  r&aacute;dios e toca-fitas, conjugados ou n&atilde;o, amplificadores, equalizadores, CD players, auto falantes,
                  televisores, telefones m&oacute;veis e aparelhos transmissores e ou receptores de r&aacute;dio de forma
                  exemplificativa. Al&eacute;m de itens como &ldquo;mata cachorro&rdquo;, bagageiros externos, suporte para
                  escadas, etc.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">ACIDENTE PESSOAL DE OCUPANTE&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; o evento s&uacute;bito, involunt&aacute;rio e violento, com data caracterizada, exclusivamente
                  provocado por acidente de tr&acirc;nsito com o ve&iacute;culo segurado, causador de les&atilde;o f&iacute;sica
                  que, por si s&oacute;, e independente de toda e qualquer outra causa, tenha como consequ&ecirc;ncia direta a
                  morte ou invalidez permanente total ou parcial dos ocupantes do ve&iacute;culo segurado.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">APROPRIA&Ccedil;&Atilde;O IND&Eacute;BITA&nbsp;</p>
              </div>
              <div>
                <p>
                  Ato il&iacute;cito, tipificado no artigo 168 do C&oacute;digo Penal Brasileiro, que consiste em apossar-se de
                  coisa alheia m&oacute;vel de quem tem a posse, propriedade ou deten&ccedil;&atilde;o sem consentimento do
                  propriet&aacute;rio. Ou seja, &eacute; o ato praticado por pessoa que recebe a posse do bem em confian&ccedil;a
                  e conhecimento do propriet&aacute;rio, mas que n&atilde;o procede &agrave; devolu&ccedil;&atilde;o do bem no
                  prazo acordado ou quando lhe foi solicitado pelo propriet&aacute;rio.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">AP&Oacute;LICE&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; o documento emitido pela SEGURADORA, para formaliza&ccedil;&atilde;o do contrato de seguro, ap&oacute;s
                  aceita&ccedil;&atilde;o do risco.&nbsp;Na ap&oacute;lice&nbsp;haver&aacute; a discrimina&ccedil;&atilde;o do bem
                  segurado, tipo de coberturas aplic&aacute;veis, os direitos e obriga&ccedil;&otilde;es do SEGURADO e da
                  SEGURADORA e estabelecimento dos riscos exclu&iacute;dos do &acirc;mbito de observ&acirc;ncia da
                  SEGURADORA.&nbsp;
                </p>
              </div>

              <div>
                <p className="definition">AVARIAS&nbsp;</p>
              </div>
              <div>
                <p>
                  S&atilde;o os danos existentes no ve&iacute;culo, anteriores &agrave; contrata&ccedil;&atilde;o do seguro ou
                  n&atilde;o. Quando existentes antes do contrato de seguro, o(a) SEGURADO(A) fica ciente de que n&atilde;o
                  ter&aacute; cobertura posterior, mesmo mediante o aviso de sinistro. Salvos os casos em que se vale
                  indeniza&ccedil;&atilde;o total do ve&iacute;culo e desde que tenha comprova&ccedil;&atilde;o do nexo causal com
                  o evento ocorrido e devidamente coberto por este seguro.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">AVISO DE SINISTRO&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; a comunica&ccedil;&atilde;o que deve ser feita &agrave; SEGURADORA, assim que tenha conhecimento dele e
                  cujas caracter&iacute;sticas est&atilde;o ligadas &agrave;s circunst&acirc;ncias previstas nestas
                  Condi&ccedil;&otilde;es Gerais, por telefone, Internet ou formul&aacute;rio pr&oacute;prio, pelo(a) SEGURADO(A),
                  REPRESENTANTE LEGAL ou CORRETOR(A) DE SEGUROS, cuja finalidade &eacute; dar &agrave; SEGURADORA conhecimento da
                  ocorr&ecirc;ncia de um sinistro.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">ATO I&Iacute;CITO&nbsp;</p>
              </div>
              <div>
                <p>
                  A&ccedil;&otilde;es ou omiss&otilde;es volunt&aacute;rias, que violem direito e causem dano a outrem, ainda que
                  exclusivamente moral&nbsp;
                </p>
              </div>

              <div>
                <p className="definition">BENEFICI&Aacute;RIO(A)&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; a pessoa f&iacute;sica ou jur&iacute;dica que, por for&ccedil;a de lei, tem direito &agrave;
                  indeniza&ccedil;&atilde;o. Quando n&atilde;o houver, na ap&oacute;lice, discrimina&ccedil;&atilde;o do(a)
                  benefici&aacute;rio(a), ser&aacute; observado o que disp&otilde;e o C&oacute;digo Civil Brasileiro.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">CANCELAMENTO&nbsp;</p>
              </div>
              <div>
                <p>
                  Ato volunt&aacute;rio de dissolu&ccedil;&atilde;o antecipada da ap&oacute;lice por parte do(A) SEGURADO(A) ou da
                  SEGURADORA.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">CARROCERIA&nbsp;</p>
              </div>
              <div>
                <p>Estrutura acoplada na parte traseira do ve&iacute;culo destinada ao transporte de carga.&nbsp;</p>
              </div>
              <div>
                <p className="definition">CONDI&Ccedil;&Otilde;ES GERAIS&nbsp;</p>
              </div>
              <div>
                <p>
                  Conjunto das cl&aacute;usulas comuns a todas as modalidades e/ou coberturas de um plano de seguro, que
                  estabelecem as obriga&ccedil;&otilde;es e os direitos das partes contratantes. Podendo eventualmente ser
                  alteradas pelas Condi&ccedil;&otilde;es Especiais e/ou Condi&ccedil;&otilde;es Particulares.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">CONDI&Ccedil;&Otilde;ES ESPECIAIS&nbsp;</p>
              </div>
              <div>
                <p>
                  Cl&aacute;usulas complementares &agrave;s Condi&ccedil;&otilde;es Gerais da Ap&oacute;lice, as quais estabelecem
                  determinadas coberturas espec&iacute;ficas ao Segurado. Podem tamb&eacute;m cancelar ou alterar
                  disposi&ccedil;&otilde;es j&aacute; existentes, ou ainda ampliar ou restringir coberturas.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">CONDI&Ccedil;&Otilde;ES PARTICULARES&nbsp;</p>
              </div>
              <div>
                <p>
                  Cl&aacute;usulas que alteram as Condi&ccedil;&otilde;es Gerais e/ou as Condi&ccedil;&otilde;es Especiais da
                  Ap&oacute;lice, com a finalidade de modificar, cancelar ou particularizar determinadas disposi&ccedil;&otilde;es
                  j&aacute; existentes ou, ainda, introduzindo novas disposi&ccedil;&otilde;es e eventualmente ampliando ou
                  restringindo a cobertura do seguro.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">CULPA&nbsp;</p>
              </div>
              <div>
                <p>
                  Conduta negligente, imprudente, imperita ou temer&aacute;ria sem prop&oacute;sito preconcebido de prejudicar,
                  mas do qual advenham danos, les&otilde;es ou preju&iacute;zos a terceiros.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">DANO CORPORAL&nbsp;</p>
              </div>
              <div>
                <p>
                  Les&atilde;o exclusivamente f&iacute;sica (morte, invalidez parcial ou total, les&atilde;o corporal) causada a
                  terceiro, em consequ&ecirc;ncia de acidente de tr&acirc;nsito envolvendo o ve&iacute;culo segurado.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">DANO A TERCEIRO (MATERIAL)&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; o tipo de dano causado exclusivamente &agrave; propriedade material de terceiro gerando perda ou
                  redu&ccedil;&atilde;o no padr&atilde;o de beleza, est&eacute;tica ou seguran&ccedil;a do objeto.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">DANO MORAL&nbsp;</p>
              </div>
              <div>
                <p>
                  Ofensa ou viola&ccedil;&atilde;o aos princ&iacute;pios e valores de ordem moral, tais como liberdade, honra,
                  sentimento, dignidade pessoal ou familiar. N&atilde;o &eacute; suscet&iacute;vel de valor econ&ocirc;mico e,
                  sendo assim, caber&aacute; ao poder judici&aacute;rio reconhecer a exist&ecirc;ncia de tal dano e fixar o valor
                  para sua repara&ccedil;&atilde;o.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">DOLO&nbsp;</p>
              </div>
              <div>
                <p>
                  A&ccedil;&atilde;o praticada com a inten&ccedil;&atilde;o de violar o direito de terceiro ou causar-lhe
                  dano.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">ENDOSSO&nbsp;</p>
              </div>
              <div>
                <p>
                  Aditivo &agrave; ap&oacute;lice emitida pela SEGURADORA durante a vig&ecirc;ncia do contrato de seguro pelo qual
                  as partes acordam em altera&ccedil;&otilde;es de dados, modifica&ccedil;&atilde;o de condi&ccedil;&otilde;es, de
                  objeto segurado ou ainda o transfere a outrem, retificando e atualizando o contrato atual.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">EQUIPAMENTOS&nbsp;</p>
              </div>
              <div>
                <p>
                  Pe&ccedil;as instaladas em car&aacute;ter permanente no ve&iacute;culo segurado, destinado a um fim
                  espec&iacute;fico, n&atilde;o relacionado &agrave; sua locomo&ccedil;&atilde;o, mas ao aformoseamento ou lazer
                  dos ocupantes do ve&iacute;culo.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">ESTELIONATO&nbsp;</p>
              </div>
              <div>
                <p>
                  Obter para si ou para outrem vantagem il&iacute;cita em preju&iacute;zo alheio, induzindo ou mantendo
                  algu&eacute;m em erro, mediante artif&iacute;cio ardil ou qualquer outro meio fraudulento.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">ESTIPULANTE&nbsp;</p>
              </div>
              <div>
                <p>
                  Pessoa que contrata o seguro por conta de terceiros, qualificando-se desta forma como representante do(a)
                  SEGURADO(A) perante&nbsp;&agrave;&nbsp;SEGURADORA.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">EVENTO&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; o fato ou acontecimento cuja ocorr&ecirc;ncia acarreta preju&iacute;zo ao Segurado ou a
                  Seguradora&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">FATOR DE AJUSTE&nbsp;</p>
              </div>
              <div>
                <p>
                  Percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contrata&ccedil;&atilde;o do
                  seguro, que ser&aacute; aplicado sobre o valor que constar na tabela de refer&ecirc;ncia de
                  cota&ccedil;&atilde;o para o ve&iacute;culo, para a estipula&ccedil;&atilde;o do valor da
                  indeniza&ccedil;&atilde;o integral do ve&iacute;culo segurado. A aplica&ccedil;&atilde;o do fator de ajuste pode
                  resultar em valor superior ou inferior &agrave;quele cotado na tabela de refer&ecirc;ncia estabelecida na
                  proposta, de acordo com as caracter&iacute;sticas do ve&iacute;culo e seu estado de
                  conserva&ccedil;&atilde;o.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">FRANQUIA&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; a participa&ccedil;&atilde;o obrigat&oacute;ria do SEGURADO(A), dedut&iacute;vel em cada sinistro
                  coberto pelo seguro, exceto nos casos de sinistros procedentes de raio e suas consequ&ecirc;ncias,
                  explos&atilde;o acidental, inc&ecirc;ndio, roubo e furto mediante aviso de sinistro aprovado e autorizado para
                  indeniza&ccedil;&atilde;o integral do ve&iacute;culo.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">FURTO&nbsp;</p>
              </div>
              <div>
                <p>Subtra&ccedil;&atilde;o de todo ou parte do bem sem amea&ccedil;a ou viol&ecirc;ncia &agrave; pessoa.&nbsp;</p>
              </div>
              <div>
                <p className="definition">FURTO QUALIFICADO&nbsp;</p>
              </div>
              <div>
                <p>
                  Subtra&ccedil;&atilde;o de todo ou parte do bem sem amea&ccedil;a ou viol&ecirc;ncia &agrave; pessoa, com
                  destrui&ccedil;&atilde;o ou rompimento de obst&aacute;culo &agrave; subtra&ccedil;&atilde;o da coisa. Mediante
                  concurso de duas ou mais pessoas, utilizando abuso de confian&ccedil;a, mediante fraude, escalada, destreza ou
                  com emprego de chave falsa.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">INC&Ecirc;NDIO&nbsp;</p>
              </div>
              <div>
                <p>
                  Evento n&atilde;o premeditado, com aus&ecirc;ncia materialidade comprovada por exame de corpo de delito e que
                  ocasione a destrui&ccedil;&atilde;o total ou parcial do bem por meio da a&ccedil;&atilde;o de fogo, n&atilde;o
                  compreendendo, eleva&ccedil;&atilde;o de temperatura, danos el&eacute;tricos ou&nbsp;curto circuito&nbsp;em que
                  n&atilde;o haja propaga&ccedil;&atilde;o de chamas e fogo.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">INDENIZA&Ccedil;&Atilde;O INTEGRAL&nbsp;</p>
              </div>
              <div>
                <p>
                  Ser&aacute; caracterizada a indeniza&ccedil;&atilde;o integral quando os preju&iacute;zos resultantes de um
                  mesmo sinistro, atingirem ou ultrapassarem 75% do valor contratado para cobrir o ve&iacute;culo segurado.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">INVALIDEZ PERMANENTE&nbsp;</p>
              </div>
              <div>
                <p>
                  Perda, redu&ccedil;&atilde;o ou impot&ecirc;ncia funcional definitiva, total ou parcial, de membro ou
                  &oacute;rg&atilde;o que implique na redu&ccedil;&atilde;o ou aboli&ccedil;&atilde;o da capacidade para o
                  exerc&iacute;cio pleno das atividades normais.&nbsp;
                </p>
              </div>

              <div>
                <p className="definition">LIMITE M&Aacute;XIMO DE INDENIZA&Ccedil;&Atilde;O (LMI)&nbsp;</p>
              </div>
              <div>
                <p>
                  Representa o valor m&aacute;ximo de indeniza&ccedil;&atilde;o que a Seguradora ir&aacute; suportar em um risco
                  coberto, respeitado o valor de mercado do ve&iacute;culo segurado na data do evento de acordo com o valor
                  especificado na tabela referenciada e fator de ajuste previsto na Ap&oacute;lice.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">LIQUIDA&Ccedil;&Atilde;O DE SINISTROS&nbsp;</p>
              </div>
              <div>
                <p>
                  Processo pelo qual a SEGURADORA paga, (a)o SEGURADO(A) ou BENEFICI&Aacute;RIO(A), os preju&iacute;zos que ele
                  sofreu em consequ&ecirc;ncia de um dos riscos cobertos, baseada no relat&oacute;rio de regula&ccedil;&atilde;o
                  de sinistro.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">M&Aacute;-F&Eacute;&nbsp;</p>
              </div>
              <div>
                <p>
                  Para efeitos deste contrato, ser&aacute; considerada m&aacute;-f&eacute; o fornecimento intencional de
                  informa&ccedil;&otilde;es inexatas, incompletas ou omissas, mesmo que parcialmente, pelo(a) SEGURADO(A), seu
                  representante ou seu corretor de seguros.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">OCUPANTE&nbsp;</p>
              </div>
              <div>
                <p>
                  Pessoa que se encontra no interior do ve&iacute;culo segurado, no momento do acidente, inclusive o
                  condutor.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">OFICINAS REFERENCIADAS&nbsp;</p>
              </div>
              <div>
                <p>
                  Rede de prestadores de servi&ccedil;os terceirizados que mediante acordo de servi&ccedil;o com a SEGURADORA, se
                  colocam &agrave; disposi&ccedil;&atilde;o dos segurados para realizar os atendimentos e reparos
                  necess&aacute;rios em caso de sinistros cobertos por esta ap&oacute;lice.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">PERDA PARCIAL&nbsp;</p>
              </div>
              <div>
                <p>
                  Caracteriza-se a perda parcial quando o custo da repara&ccedil;&atilde;o do bem segurado n&atilde;o atingir 75%
                  do valor contratado para o ve&iacute;culo e cujo valor n&atilde;o poder&aacute; ser superior ao Limite
                  M&aacute;ximo de Indeniza&ccedil;&atilde;o estabelecido nesta Ap&oacute;lice.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">PR&Ecirc;MIO&nbsp;</p>
              </div>
              <div>
                <p>
                  Valor pago pelo(a) SEGURADO(A) ou ESTIPULANTE &agrave; SEGURADORA, atribuindo a SEGURADORA o risco ao qual o(a)
                  SEGURADO(A) est&aacute; exposto(a) e nos limites do contrato ora firmado entre SEGURADO(A) E SEGURADORA.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">PRESCRI&Ccedil;&Atilde;O&nbsp;</p>
              </div>
              <div>
                <p>
                  Perda do direito de a&ccedil;&atilde;o para reclamar os direitos ou obriga&ccedil;&otilde;es em virtude do
                  decurso do tempo previsto em lei e/ou Condi&ccedil;&otilde;es Gerais da SEGURADORA.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">PRINCIPAL CONDUTOR(A)&nbsp;</p>
              </div>
              <div>
                <p>
                  Pessoa legalmente habilitada e que utiliza o ve&iacute;culo segurado no m&iacute;nimo 03 (tr&ecirc;s) dias da
                  semana. Havendo mais de um(a) condutor(a) nesta condi&ccedil;&atilde;o, dever&atilde;o ser utilizados os dados
                  da pessoa mais jovem entre eles.&nbsp;
                </p>
              </div>

              <div>
                <p className="definition">PROPONENTE&nbsp;</p>
              </div>
              <div>
                <p>Pessoa que pretende fazer um seguro e que j&aacute; firmou, para esse fim, a proposta de seguro.&nbsp;</p>
              </div>
              <div>
                <p className="definition">PROPOSTA DE SEGURO&nbsp;</p>
              </div>
              <div>
                <p>
                  Instrumento que formaliza o interesse do proponente em efetuar o seguro, na qual constam as
                  informa&ccedil;&otilde;es necess&aacute;rias para a precifica&ccedil;&atilde;o do risco e para a emiss&atilde;o
                  da ap&oacute;lice.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">QUESTION&Aacute;RIO DE AVALIA&Ccedil;&Atilde;O DO RISCO&nbsp;</p>
              </div>
              <div>
                <p>
                  Formul&aacute;rio de quest&otilde;es, parte integrante da proposta de seguro e que deve ser respondido pelo
                  Proponente, de modo preciso, sobre os condutores e as caracter&iacute;sticas do uso do ve&iacute;culo e demais
                  elementos constitutivos do risco a ser analisado pela seguradora. &Eacute; utilizado para o c&aacute;lculo do
                  pr&ecirc;mio do seguro e como par&acirc;metro para avalia&ccedil;&atilde;o da regularidade da cobertura em caso
                  de sinistro.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">REGULA&Ccedil;&Atilde;O DE SINISTRO&nbsp;</p>
              </div>
              <div>
                <p>
                  Exame das causas, circunst&acirc;ncias e consequ&ecirc;ncias do sinistro para se concluir sobre a cobertura
                  securit&aacute;ria.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">RESPONSABILIDADE CIVIL&nbsp;</p>
              </div>
              <div>
                <p>
                  Responsabilidade do(a) SEGURADO(A) por danos causados a terceiros, decorrentes de acidente com o ve&iacute;culo
                  segurado ou por sua carga transportada, decorrente de neglig&ecirc;ncia, imprud&ecirc;ncia ou
                  imper&iacute;cia.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">RESSARCIMENTO&nbsp;</p>
              </div>
              <div>
                <p>
                  Direito que a SEGURADORA possui de recuperar do terceiro respons&aacute;vel pelo sinistro, ou de sua SEGURADORA,
                  quando for o caso, o valor pago a t&iacute;tulo de indeniza&ccedil;&atilde;o.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">RISCO&nbsp;</p>
              </div>
              <div>
                <p>
                  Possibilidade de um acontecimento inesperado, causador de danos que independe da vontade das partes. As
                  caracter&iacute;sticas que definem o risco s&atilde;o: incerto, aleat&oacute;rio, poss&iacute;vel, concreto,
                  l&iacute;cito, fortuito e quantific&aacute;vel.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">ROUBO&nbsp;</p>
              </div>
              <div>
                <p>
                  Subtra&ccedil;&atilde;o do todo ou parte do bem com amea&ccedil;a grave ou viol&ecirc;ncia &agrave;
                  pessoa.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">SALVADO&nbsp;</p>
              </div>
              <div>
                <p>
                  Ve&iacute;culo ou acess&oacute;rio encontrado ap&oacute;s o pagamento da indeniza&ccedil;&atilde;o ao Segurado
                  pelo roubo ou furto total ou, ainda, o que restou de um ve&iacute;culo (acess&oacute;rios, pe&ccedil;as e
                  partes) ap&oacute;s um evento indenizado pela Seguradora.&nbsp;
                </p>
              </div>

              <div>
                <p className="definition">SEGURADO(A)&nbsp;</p>
              </div>
              <div>
                <p>
                  Pessoa f&iacute;sica ou jur&iacute;dica que manifesta interesse e contrata um seguro em seu benef&iacute;cio ou
                  de terceiros.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">SEGURADORA&nbsp;</p>
              </div>
              <div>
                <p>
                  Pessoa jur&iacute;dica legalmente constitu&iacute;da, autorizada pela SUSEP a funcionar no Brasil, e que
                  recebendo o pr&ecirc;mio, assume o risco de indenizar o(a) SEGURADO(A) ou BENEFICI&Aacute;RIO(A), em caso de
                  ocorr&ecirc;ncia de sinistro amparado pelo contrato de seguro.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">SINISTRO&nbsp;</p>
              </div>
              <div>
                <p>
                  Ocorr&ecirc;ncia de acontecimentos involunt&aacute;rios e casuais previsto no contrato de seguro, para o qual
                  foi contratada a cobertura.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">SUB-ROGA&Ccedil;&Atilde;O&nbsp;</p>
              </div>
              <div>
                <p>
                  Opera-se com a transfer&ecirc;ncia de direitos e obriga&ccedil;&otilde;es do(a) SEGURADO(A) para a SEGURADORA em
                  virtude do pagamento da indeniza&ccedil;&atilde;o.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">SUSEP&nbsp;</p>
              </div>
              <div>
                <p>
                  Superintend&ecirc;ncia de Seguros Privados. Autarquia Federal respons&aacute;vel pela regula&ccedil;&atilde;o e
                  fiscaliza&ccedil;&atilde;o do mercado de seguros.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">TABELA DE REFER&Ecirc;NCIA&nbsp;</p>
              </div>
              <div>
                <p>
                  Tabela de mercado identificada na proposta de seguro, especializada em pesquisa de valor de mercado de
                  ve&iacute;culos, utilizada na modalidade de seguros Valor de Mercado Referenciado.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">TABELA SUBSTITUTA&nbsp;</p>
              </div>
              <div>
                <p>
                  Tabela de mercado identificada na proposta de seguro que poder&aacute; ser utilizada em
                  substitui&ccedil;&atilde;o &agrave; Tabela de Refer&ecirc;ncia caso&nbsp;esta&nbsp;deixe de existir, ou caso o
                  ve&iacute;culo segurado deixe de constar nesta.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">TERCEIRO&nbsp;</p>
              </div>
              <div>
                <p>
                  Pessoa culpada ou prejudicada no acidente, exceto os ocupantes do ve&iacute;culo segurado, o(a)
                  pr&oacute;prio(a) SEGURADO(A) ou seus ascendentes, descendentes, c&ocirc;njuge, irm&atilde;os, pessoas que com
                  ele residam ou que dele(a) dependam economicamente, bem como os s&oacute;cios, diretores, administradores e
                  controladores da pessoa jur&iacute;dica.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">VALOR DE MERCADO REFERENCIADO&nbsp;</p>
              </div>
              <div>
                <p>
                  &Eacute; a modalidade que garante ao Segurado, no caso de Indeniza&ccedil;&atilde;o Integral, o pagamento de
                  quantia vari&aacute;vel, em moeda corrente nacional, determinada de acordo com a Tabela de Refer&ecirc;ncia,
                  previamente fixada na Proposta de Seguro, conjugada com o fator de ajuste, em percentual, a ser aplicado sobre o
                  valor de cota&ccedil;&atilde;o do ve&iacute;culo na Tabela de Refer&ecirc;ncia, na data da ocorr&ecirc;ncia do
                  sinistro.&nbsp;
                </p>
              </div>

              <div>
                <p className="definition">VMR - Valor de Mercado Referenciado&nbsp;</p>
              </div>
              <div>
                <p>
                  Esta modalidade de seguro garante, no caso de indenização integral, o pagamento de quantia variável, em moeda
                  corrente nacional, determinada de acordo com a tabela FIPE de referência de cotação para veículo, previamente
                  fixada na proposta do seguro e discriminada na apólice, conjugada com o fator de ajuste (percentual a ser
                  aplicado sobre a tabela no cálculo do valor da indenização), na data da ocorrência do sinistro, aplicado o fator
                  de ajuste contratado e definido na apólice..&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">VIG&Ecirc;NCIA&nbsp;</p>
              </div>
              <div>
                <p>
                  Prazo que determina o in&iacute;cio e o fim da validade das garantias contratadas entre SEGURADO(A) e
                  SEGURADORA, podendo ser renovado, se for de interesse das partes contratantes.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">VISTORIA PR&Eacute;VIA&nbsp;</p>
              </div>
              <div>
                <p>
                  Inspe&ccedil;&atilde;o realizada pela SEGURADORA, antes da aceita&ccedil;&atilde;o do risco, para
                  verifica&ccedil;&atilde;o das caracter&iacute;sticas e condi&ccedil;&otilde;es do ve&iacute;culo a ser
                  segurado.&nbsp;
                </p>
              </div>
              <div>
                <p className="definition">VISTORIA DE SINISTRO&nbsp;</p>
              </div>
              <div>
                <div>
                  <p>
                    Inspe&ccedil;&atilde;o a ser realizada pela Seguradora ou por terceiro por esta contratado, quando a
                    Seguradora julgar necess&aacute;rio, para verifica&ccedil;&atilde;o das caracter&iacute;sticas e estado de
                    conserva&ccedil;&atilde;o do ve&iacute;culo a ser segurado.&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>4. ABRANG&Ecirc;NCIA&nbsp;</h1>
                </div>
                <div>
                  <p>
                    A SEGURADORA tem como objeto atender ve&iacute;culos que estejam dentro do territ&oacute;rio nacional
                    brasileiro dos tipos: carros, motos e utilit&aacute;rios pequenos a partir de 30 (trinta) anos de
                    fabrica&ccedil;&atilde;o do ano vigente, de uso particular &uacute;nico motorista, familiar, comercial e
                    motorista de aplicativo.&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div>
                <div>
                  <h1>5. FORMA DE CONTRATA&Ccedil;&Atilde;O&nbsp;</h1>
                </div>
                <div>
                  <p>
                    Este seguro est&aacute; enquadrado na modalidade de Primeiro Risco Absoluto, ou seja, a Seguradora
                    responder&aacute; pelo pagamento dos preju&iacute;zos at&eacute; o valor estipulado para as coberturas
                    seguradas, sem aplica&ccedil;&atilde;o de proporcionalidade (rateio).&nbsp;
                  </p>
                </div>
                <div>
                  <p>
                    De comum acordo entre Segurado e Seguradora, as coberturas b&aacute;sicas de casco dever&atilde;o ser
                    contratadas em conformidade com as modalidades de seguro a seguir descrita:&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>5.1 VMR - Valor de Mercado Referenciado&nbsp;(VD)&nbsp;&nbsp;</h2>
                </div>
              </div>
              <div>
                <p>
                  Esta modalidade de seguro garante, no caso de indenização integral, o pagamento de quantia variável, em moeda
                  corrente nacional, determinada de acordo com a tabela FIPE de referência de cotação para veículo, previamente
                  fixada na proposta do seguro e discriminada na apólice, conjugada com o fator de ajuste (percentual a ser
                  aplicado sobre a tabela no cálculo do valor da indenização), na data da ocorrência do sinistro, aplicado o fator
                  de ajuste contratado e definido na apólice.&nbsp;
                </p>
              </div>
              <div>
                <p>
                  5.1.1&nbsp;O Segurado, a qualquer tempo, poder&aacute; subscrever nova Proposta de Seguro, para
                  altera&ccedil;&atilde;o do limite da garantia contratualmente previsto, ficando a crit&eacute;rio da Seguradora
                  sua aceita&ccedil;&atilde;o e altera&ccedil;&atilde;o do Pr&ecirc;mio, quando couber.&nbsp;&nbsp;
                </p>
              </div>
              <div>
                <p>
                  5.1.2&nbsp;Ser&aacute; considerado para fins de Indeniza&ccedil;&atilde;o Integral, os preju&iacute;zos,
                  resultantes de um mesmo sinistro, atingirem ou ultrapassarem 75% (Setenta e cinco por cento) sobre o valor do
                  limite m&aacute;ximo de indeniza&ccedil;&atilde;o definido na ap&oacute;lice.&nbsp;
                </p>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <h2>5.2 Valor De Mercado Referenciado&nbsp;(VMR)&nbsp;</h2>
              </div>
              <div>
                <div>
                  <p>
                    Esta modalidade de seguro garante, no caso de indeniza&ccedil;&atilde;o integral, o pagamento de quantia
                    vari&aacute;vel, em moeda corrente nacional, determinada de acordo com a tabela de refer&ecirc;ncia de
                    cota&ccedil;&atilde;o para ve&iacute;culo, previamente fixada na proposta do seguro e discriminada na
                    ap&oacute;lice, conjugada com o fator de ajuste (percentual a ser aplicado sobre a tabela no c&aacute;lculo do
                    valor da indeniza&ccedil;&atilde;o), na data da ocorr&ecirc;ncia do sinistro.&nbsp;
                  </p>
                </div>
                <div>
                  <p>
                    5.2.1&nbsp;Se a tabela de refer&ecirc;ncia de cota&ccedil;&atilde;o especificada na ap&oacute;lice for extinta
                    ou deixar de ser publicada, a indeniza&ccedil;&atilde;o integral ter&aacute; como base o valor que constar na
                    tabela substituta estabelecida quando da contrata&ccedil;&atilde;o do seguro, especificada na
                    ap&oacute;lice.&nbsp;
                  </p>
                </div>
                <div>
                  <p>
                    5.2.2&nbsp;Ser&aacute; considerado para fins de Indeniza&ccedil;&atilde;o Integral, os preju&iacute;zos,
                    resultantes de um mesmo sinistro, atingirem ou ultrapassarem 75% (setenta e cinco por cento) sobre o valor de
                    cota&ccedil;&atilde;o do ve&iacute;culo segurado, de acordo com a tabela de refer&ecirc;ncia contratualmente
                    estabelecida e em vigor na data da ocorr&ecirc;ncia do sinistro.&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>6. OBRIGA&Ccedil;&Otilde;ES DO ESTIPULANTE, DISTRIBUIDOR OU REPRESENTANTE&nbsp;</h1>
                </div>
              </div>
              <div>
                <div>
                  <p>S&atilde;o obriga&ccedil;&otilde;es do estipulante, distribuidor ou representante de seguro:&nbsp;</p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Fornecer &agrave; Seguradora todas as informa&ccedil;&otilde;es necess&aacute;rias para a an&aacute;lise e
                        aceita&ccedil;&atilde;o do risco, previamente estabelecidas por ela, inclusive as
                        informa&ccedil;&otilde;es cadastrais.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Manter a Seguradora informada a respeito dos dados cadastrais dos Segurados, altera&ccedil;&otilde;es na
                        natureza do risco coberto, bem como quaisquer eventos que possam, no futuro, resultar em sinistro, de
                        acordo com o definido contratualmente.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Fornecer&nbsp;ao Segurado, sempre que solicitado, quaisquer informa&ccedil;&otilde;es relativas ao
                        contrato de seguro.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        Discriminar o valor do Pr&ecirc;mio no instrumento de cobran&ccedil;a, quando este for de sua
                        responsabilidade.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="5">
                    <li>
                      <p>Repassar os pr&ecirc;mios &agrave; Seguradora, nos prazos estabelecidos contratualmente.&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>
                        Repassar aos Segurados todas as comunica&ccedil;&otilde;es ou avisos inerentes &agrave; Ap&oacute;lice,
                        quando for diretamente respons&aacute;vel pela sua administra&ccedil;&atilde;o.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="7">
                    <li>
                      <p>
                        Discriminar a raz&atilde;o social e, se for o caso, o nome fantasia da Seguradora respons&aacute;vel pelo
                        risco, nos documentos e comunica&ccedil;&otilde;es referentes ao seguro, emitidos para o Segurado.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="8">
                    <li>
                      <p>
                        Comunicar, de imediato, &agrave; Seguradora, a ocorr&ecirc;ncia de qualquer Sinistro, ou expectativa de
                        sinistro, referente ao grupo que representa, assim que deles tiver conhecimento, quando esta
                        comunica&ccedil;&atilde;o estiver sob sua responsabilidade.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="9">
                    <li>
                      <p>
                        Dar ci&ecirc;ncia aos Segurados dos procedimentos e prazos estipulados para a liquida&ccedil;&atilde;o de
                        sinistros.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="10">
                    <li>
                      <p>
                        Informar a raz&atilde;o social e, se for o caso, o nome fantasia da Seguradora, bem como o percentual de
                        participa&ccedil;&atilde;o no risco, no caso de cosseguro, em qualquer material de promo&ccedil;&atilde;o
                        ou propaganda do seguro, em car&aacute;ter tipogr&aacute;fico maior ou igual ao do estipulante.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="11">
                    <li>
                      <p>Dever&atilde;o ser estabelecidos, em contrato espec&iacute;fico firmado entre a Seguradora e o&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="12">
                    <li>
                      <p>
                        Estipulante, Representante ou Distribuidor, os deveres de cada parte em rela&ccedil;&atilde;o &agrave;
                        contrata&ccedil;&atilde;o do seguro, nos termos deste artigo.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <h2 className="subitem-text">
                    6.1&nbsp;Nos seguros contribut&aacute;rios, o n&atilde;o repasse dos pr&ecirc;mios &agrave; Seguradora, nos
                    prazos&nbsp;contratualmente estabelecidos, poder&aacute; acarretar a suspens&atilde;o ou o cancelamento da
                    cobertura, a crit&eacute;rio da Seguradora, e sujeita o Estipulante,
                    Representante,&nbsp;Distribuidor&nbsp;ou&nbsp;Subestipulante&nbsp;&agrave;s comina&ccedil;&otilde;es legais. A
                    esse respeito, a Seguradora &eacute; obrigada a informar ao Segurado a situa&ccedil;&atilde;o de
                    adimpl&ecirc;ncia do Estipulante ou&nbsp;Subestipulante, sempre que solicitada.&nbsp;
                  </h2>
                </div>
                <div>
                  <p>
                    6.1.1&nbsp;Nos seguros contribut&aacute;rios, &eacute; expressamente proibido ao
                    Estipulante/Subestipulante:&nbsp;
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        cobrar, dos segurados, quaisquer valores relativos ao seguro, al&eacute;m dos especificados pela
                        Seguradora;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        rescindir o contrato de seguro ou fazer qualquer modifica&ccedil;&atilde;o que implique em &ocirc;nus ou
                        dever para os Segurados sem anu&ecirc;ncia pr&eacute;via e expressa de um n&uacute;mero de Segurados que
                        represente, no m&iacute;nimo, tr&ecirc;s quartos do grupo segurado;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        efetuar propaganda e promo&ccedil;&atilde;o do seguro sem pr&eacute;via anu&ecirc;ncia da Seguradora, e
                        sem respeitar a fidedignidade das informa&ccedil;&otilde;es quanto ao seguro que ser&aacute;
                        contratado;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        vincular a contrata&ccedil;&atilde;o de seguros a qualquer de seus produtos, ressalvada a hip&oacute;tese
                        em que tal contrata&ccedil;&atilde;o sirva de garantia direta a estes produtos.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div>
                <div>
                  <h1>7. OBRIGA&Ccedil;&Otilde;ES DO SEGURADO&nbsp;</h1>
                </div>
                <div>
                  <p>O Segurado, independente de outras estipula&ccedil;&otilde;es deste seguro, obriga-se a:&nbsp;</p>
                </div>
                <div>
                  <h2>7.1&nbsp;Ocorr&ecirc;ncias de Sinistros&nbsp;</h2>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        comunicar imediatamente &agrave; Seguradora, pela via mais r&aacute;pida poss&iacute;vel, a
                        ocorr&ecirc;ncia de qualquer fato ou circunst&acirc;ncia que possa afetar ou alterar o risco,
                        principalmente mas n&atilde;o exclusivamente os casos em que o ve&iacute;culo tenha sido objeto de
                        acidentes com danos parciais ou totais n&atilde;o vinculados a ocorr&ecirc;ncia do roubo ou furto, bem
                        como qualquer evento que possa vir a se caracterizar como um sinistro, indeniz&aacute;vel ou n&atilde;o,
                        nos termos desta Ap&oacute;lice, encaminhando posteriormente documento por via formal e escrita;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        avisar a Seguradora sobre a localiza&ccedil;&atilde;o do ve&iacute;culo roubado ou furtado mesmo
                        ap&oacute;s o pagamento da indeniza&ccedil;&atilde;o;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="3">
                    <li>
                      <p>agir com boa-f&eacute;, prestando declara&ccedil;&otilde;es claras e precisas;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        tomar o mais depressa poss&iacute;vel, todas as provid&ecirc;ncias ao seu alcance para proteger o
                        ve&iacute;culo sinistrado e evitar a agrava&ccedil;&atilde;o dos preju&iacute;zos;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        dar imediato aviso &agrave;s autoridades policiais, em caso de desaparecimento, roubo ou furto, total ou
                        parcial do ve&iacute;culo segurado;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>aguardar a vistoria da Seguradora para iniciar as repara&ccedil;&otilde;es de quaisquer danos;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="7">
                    <li>
                      <p>
                        aguardar a autoriza&ccedil;&atilde;o formal da Seguradora para pagamento de qualquer acordo,
                        indeniza&ccedil;&atilde;o ou mesmo condena&ccedil;&atilde;o judicial relacionada a eventos cobertos por
                        esta ap&oacute;lice e especialmente nas coberturas de terceiros.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>7.2&nbsp;Contrata&ccedil;&atilde;o e altera&ccedil;&otilde;es no contrato&nbsp;</h2>
                </div>
                <div>
                  <p>
                    O Segurado &eacute; obrigado a comunicar a Seguradora, logo que saiba, todo fato ou incidente
                    suscept&iacute;vel de agravar o risco coberto, sob pena de perder o direito &agrave; garantia, especialmente
                    sobre:&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        comunicar imediatamente &agrave; Seguradora a transfer&ecirc;ncia do ve&iacute;culo de sua posse ou
                        propriedade;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        fornecer &agrave; Seguradora, no momento da contrata&ccedil;&atilde;o do seguro, seus dados completos e
                        respostas corretas aos question&aacute;rios de contrata&ccedil;&atilde;o, de forma a possibilitar seu
                        perfeito cadastro e determina&ccedil;&atilde;o de pr&ecirc;mio para a cobertura do seguro contratado, bem
                        como comunicar imediatamente &agrave; Seguradora qualquer altera&ccedil;&atilde;o do seu cadastro ou de
                        dados do question&aacute;rio de contrata&ccedil;&atilde;o, tal como altera&ccedil;&atilde;o de local de
                        circula&ccedil;&atilde;o do ve&iacute;culo (entre outros);&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="3">
                    <li>
                      <p>altera&ccedil;&otilde;es no pr&oacute;prio ve&iacute;culo ou no uso do mesmo;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        cumprir as disposi&ccedil;&otilde;es estabelecidas nestas Condi&ccedil;&otilde;es Gerais e
                        Especiais.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>7.3&nbsp;Conserva&ccedil;&atilde;o do Ve&iacute;culo&nbsp;</h2>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>manter o ve&iacute;culo segurado em bom estado de conserva&ccedil;&atilde;o e seguran&ccedil;a;&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        disponibilizar, a qualquer tempo, o ve&iacute;culo para vistoria requisitada pela Seguradora em virtude da
                        necessidade de constata&ccedil;&atilde;o de danos no ve&iacute;culo pela ocorr&ecirc;ncia de acidentes
                        vinculados ou n&atilde;o a eventos de roubo/furto.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>
                    A inobserv&acirc;ncia das obriga&ccedil;&otilde;es convencionadas nestas Condi&ccedil;&otilde;es Gerais e
                    Especiais, por parte do Segurado, isentar&aacute; a Seguradora da obriga&ccedil;&atilde;o de pagar qualquer
                    indeniza&ccedil;&atilde;o com base na presente Ap&oacute;lice.&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>8. CONCORR&Ecirc;NCIA DE AP&Oacute;LICES&nbsp;</h1>
                </div>
                <div>
                  <p>
                    O Segurado que, na vig&ecirc;ncia do contrato, pretender obter novo seguro sobre os mesmos bens e contra os
                    mesmos riscos, dever&aacute; comunicar sua inten&ccedil;&atilde;o, previamente, por escrito, a todas as
                    seguradoras envolvidas, sob pena de perda de direito.&nbsp;
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <h2 className="subitem-text">
                    8.1&nbsp;O preju&iacute;zo total relativo a qualquer sinistro ser&aacute; constitu&iacute;do pela soma das
                    seguintes parcelas:&nbsp;
                  </h2>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Despesas de salvamento comprovadamente efetuadas pelo Segurado durante e/ou ap&oacute;s a ocorr&ecirc;ncia
                        do sinistro;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        valor referente aos danos materiais comprovadamente causados pelo Segurado e/ou por terceiros na tentativa
                        de minorar o dano ou salvar a coisa;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>danos sofridos pelos bens segurados.&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <h2 className="subitem-text">
                    8.2&nbsp;A indeniza&ccedil;&atilde;o relativa a qualquer sinistro n&atilde;o poder&aacute; exceder, em
                    hip&oacute;tese alguma, o valor do preju&iacute;zo vinculado &agrave; cobertura considerada.&nbsp;
                  </h2>
                </div>
              </div>
              <div>
                <div>
                  <h2 className="subitem-text">
                    8.3&nbsp;Na ocorr&ecirc;ncia de sinistro contemplado por coberturas concorrentes, ou seja, que garantam os
                    mesmos interesses contra os mesmos riscos, em ap&oacute;lices distintas, a distribui&ccedil;&atilde;o de
                    responsabilidade entre as sociedades seguradoras envolvidas dever&aacute; obedecer &agrave;s seguintes
                    disposi&ccedil;&otilde;es:&nbsp;
                  </h2>
                </div>
                <div>
                  <p>
                    I. Ser&aacute; calculada a indeniza&ccedil;&atilde;o individual de cada cobertura como se o respectivo
                    contrato fosse o &uacute;nico vigente, considerando-se, quando for o caso, franquias,
                    participa&ccedil;&otilde;es obrigat&oacute;rias do Segurado, limite m&aacute;ximo de indeniza&ccedil;&atilde;o
                    da cobertura e cl&aacute;usulas de rateio;&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <p>
                    II. Ser&aacute; calculada a &ldquo;indeniza&ccedil;&atilde;o individual ajustada&rdquo; de cada cobertura, na
                    forma abaixo indicada:&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        se para uma determinada ap&oacute;lice for verificado que a soma das
                        indeniza&ccedil;&otilde;es&nbsp;correspondentes &agrave;s diversas coberturas abrangidas pelo sinistro
                        &eacute; maior que seu&nbsp;respectivo limite m&aacute;ximo de indeniza&ccedil;&atilde;o, a
                        indeniza&ccedil;&atilde;o individual de cada cobertura ser&aacute; recalculada, determinando-se, assim, a
                        respectiva indeniza&ccedil;&atilde;o individual ajustada. Para efeito deste rec&aacute;lculo, as
                        indeniza&ccedil;&otilde;es individuais ajustadas relativas &agrave;s coberturas que n&atilde;o apresentem
                        concorr&ecirc;ncia com outras ap&oacute;lices ser&atilde;o as maiores poss&iacute;veis, observados os
                        respectivos preju&iacute;zos e limites m&aacute;ximos de indeniza&ccedil;&atilde;o. O valor restante do
                        limite m&aacute;ximo de garantia da Ap&oacute;lice ser&aacute; distribu&iacute;do entre as coberturas
                        concorrentes observados os preju&iacute;zos e os limites m&aacute;ximos de indeniza&ccedil;&atilde;o
                        destas coberturas; e&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        caso contr&aacute;rio, a &ldquo;indeniza&ccedil;&atilde;o individual ajustada&rdquo; ser&aacute; a
                        indeniza&ccedil;&atilde;o individual, calculada de acordo com o inciso I deste item.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    III. Ser&aacute; definida a soma das indeniza&ccedil;&otilde;es individuais ajustadas das coberturas
                    concorrentes de diferentes ap&oacute;lices, relativas aos preju&iacute;zos comuns, calculadas de acordo com o
                    inciso II deste item;&nbsp;
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    IV. Se a quantia a que se refere o inciso III deste item for igual ou inferior ao preju&iacute;zo vinculado
                    &agrave; cobertura concorrente, cada sociedade seguradora envolvida participar&aacute; com a respectiva
                    indeniza&ccedil;&atilde;o individual ajustada, assumindo o Segurado a responsabilidade pela diferen&ccedil;a,
                    se houver; e&nbsp;
                  </p>
                </div>

                <div>
                  <p>
                    V. Se a quantia estabelecida no inciso III deste item for maior que o preju&iacute;zo vinculado &agrave;
                    cobertura concorrente, cada sociedade seguradora envolvida participar&aacute; com percentual do
                    preju&iacute;zo correspondente &agrave; raz&atilde;o entre a respectiva indeniza&ccedil;&atilde;o individual
                    ajustada e a quantia estabelecida naquele inciso.&nbsp;
                  </p>
                </div>
                <div>
                  <h2 className="subitem-text">
                    8.4&nbsp;A sub-roga&ccedil;&atilde;o relativa a salvados operar-se-&aacute; na mesma propor&ccedil;&atilde;o
                    da cota de participa&ccedil;&atilde;o&nbsp;de cada sociedade seguradora na indeniza&ccedil;&atilde;o
                    paga.&nbsp;
                  </h2>
                </div>
              </div>
              <div>
                <div>
                  <h2 className="subitem-text">
                    8.5&nbsp;Salvo disposi&ccedil;&atilde;o em contr&aacute;rio, a sociedade seguradora que tiver participado com
                    a maior parte da indeniza&ccedil;&atilde;o ficar&aacute; encarregada de negociar os salvados e repassar a
                    quota-parte, relativa ao produto desta negocia&ccedil;&atilde;o &agrave;s demais participantes.&nbsp;
                  </h2>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>9. FORMAS DE PAGAMENTO&nbsp;</h1>
                </div>
                <div>
                  <h2 className="subitem-text">
                    9.1&nbsp;O(A) SEGURADO(A) poder&aacute; efetuar o pagamento do pr&ecirc;mio &agrave; SEGURADORA por meio das
                    seguintes formas:&nbsp;&nbsp;
                  </h2>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>Boleto;&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>Cart&atilde;o de Cr&eacute;dito;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>Cart&atilde;o de D&eacute;bito;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>Cart&atilde;o de Cr&eacute;dito Recorrente;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>PIX.&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <h2 className="subitem-text">
                    9.2&nbsp;No caso de existir pedido ou endosso&nbsp;de cancelamento dentro de 7 (sete) dias corridos&nbsp;a
                    contar do in&iacute;cio de vig&ecirc;ncia da ap&oacute;lice,&nbsp;e desde que n&atilde;o tenha ocorrido nenhum
                    evento envolvendo as coberturas contratadas,&nbsp;ter&aacute;&nbsp;o SEGURADO (a) direito
                    &agrave;&nbsp;restitui&ccedil;&atilde;o integral do pagamento;&nbsp;
                  </h2>
                </div>
              </div>
              <div>
                <div>
                  <h2 className="subitem-text">
                    9.3&nbsp;No caso de existir pedido ou endosso de cancelamento&nbsp;ap&oacute;s&nbsp;7 (sete) dias corridos a
                    contar do in&iacute;cio de vig&ecirc;ncia da ap&oacute;lice, e desde que n&atilde;o tenha ocorrido nenhum
                    evento envolvendo as coberturas contratadas, a ap&oacute;lice ser&aacute; cancelada somente ap&oacute;s o
                    t&eacute;rmino de vig&ecirc;ncia atual, ou seja no momento da renova&ccedil;&atilde;o ou&nbsp;pr&oacute;xima
                    subscri&ccedil;&atilde;o.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    9.4&nbsp;Renova&ccedil;&atilde;o&nbsp;ser&aacute; sempre autom&aacute;tica e&nbsp;se d&aacute; por meio de
                    boleto mensal devidamente pago at&eacute; o limite m&aacute;ximo de 3 (tr&ecirc;s) dias corridos do vencimento
                    original,&nbsp;ou por indica&ccedil;&atilde;o do segurado de outro meio de pagamento de acordo com a
                    defini&ccedil;&atilde;o do item 9.1 destas condi&ccedil;&otilde;es.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    9.5&nbsp;A ap&oacute;lice &eacute; emitida&nbsp;no modelo
                    de&nbsp;re-subscri&ccedil;&atilde;o&nbsp;mensal&nbsp;autom&aacute;tica.&nbsp;
                  </h2>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>10. VIG&Ecirc;NCIA DA AP&Oacute;LICE, FORMA E PERIODICIDADE DO PAGAMENTO DOS PR&Ecirc;MIOS&nbsp;</h1>
                </div>
              </div>
              <div>
                <h2 className="subitem-text">
                  10.1&nbsp;As renova&ccedil;&otilde;es de ap&oacute;lices ser&atilde;o autom&aacute;ticas. A cada encerramento de
                  vig&ecirc;ncia, o(a) SEGURADO(A) receber&aacute; uma proposta para emiss&atilde;o de ap&oacute;lice com
                  in&iacute;cio de vig&ecirc;ncia imediato ao encerramento do per&iacute;odo anterior.&nbsp;
                </h2>
              </div>

              <div>
                <h2 className="subitem-text">
                  10.2&nbsp;Quando a data de vencimento do pr&ecirc;mio coincidir em dia em que n&atilde;o haja expediente
                  banc&aacute;rio, o pagamento poder&aacute; ser efetuado no primeiro dia &uacute;til subsequente.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  10.3&nbsp;Caso o(a) SEGURADO(A) n&atilde;o realize o pagamento do pr&ecirc;mio mensal em at&eacute; 3
                  (tr&ecirc;s) corridos do vencimento original, determina a suspens&atilde;o e cancelamento autom&aacute;tico da
                  cobertura de seguro, independentemente de qualquer interpela&ccedil;&atilde;o judicial ou extrajudicial. E para
                  todos os efeitos, n&atilde;o ter&aacute; direito a indeniza&ccedil;&atilde;o/repara&ccedil;&atilde;o total ou
                  parcial do ve&iacute;culo caso o evento ocorra ap&oacute;s os 3 dias corridos deste per&iacute;odo.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  10.4&nbsp;O pagamento ap&oacute;s os 3 dias corridos da data de vencimento e suspens&atilde;o do seguro,
                  n&atilde;o gera direito a nenhuma forma de cobertura por parte da SEGURADORA em eventual sinistro sofrido no
                  per&iacute;odo de inadimpl&ecirc;ncia do(a) SEGURADO(A).&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  10.5&nbsp;O(A) SEGURADO(A) que ficar inadimplente poder&aacute; ser cobrado(a) judicialmente ou ter
                  t&iacute;tulos protestados, cujas despesas para realiza&ccedil;&atilde;o de tais procedimentos ocorrer&atilde;o
                  inteiramente &agrave;s expensas do(a) SEGURADO(A).&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  10.6&nbsp;Realizado a quita&ccedil;&atilde;o dos d&eacute;bitos de pr&ecirc;mio e parcelas ajustadas, acrescidas
                  dos encargos previstos no contrato atual. &Eacute; necess&aacute;rio realiza&ccedil;&atilde;o de nova vistoria
                  para nova proposta de seguro e, mediante autoriza&ccedil;&atilde;o da SEGURADORA, emiss&atilde;o de nova
                  ap&oacute;lice.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  10.7&nbsp;Nos casos em que o pr&ecirc;mio tenha sido liquidado &agrave; vista, mediante financiamento
                  &agrave;(o) SEGURADO(A) por institui&ccedil;&atilde;o financeira, fica vedado o cancelamento do seguro, caso
                  ocorra inadimpl&ecirc;ncia do(a) SEGURADO(A) junto &agrave; institui&ccedil;&atilde;o financiadora.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  10.8&nbsp;Na ocorr&ecirc;ncia de sinistro indeniz&aacute;vel que implique no cancelamento do seguro, as parcelas
                  vincendas do pr&ecirc;mio dever&atilde;o ser deduzidas do valor a ser indenizado, com a devida exclus&atilde;o
                  dos juros embutidos na(s) parcela(s).&nbsp;
                </h2>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <div>
                  <h1>11. CANCELAMENTO DO SEGURO&nbsp;&nbsp;</h1>
                </div>
                <div>
                  <p>O seguro individual ser&aacute; cancelado nas seguintes situa&ccedil;&otilde;es:&nbsp;</p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>por solicita&ccedil;&atilde;o do Segurado;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        se o Segurado, seu(s) preposto(s) ou seu(s) representante(s) legal(is) agirem com dolo, praticarem ato
                        il&iacute;cito ou contr&aacute;rio &agrave; lei, cometerem fraude ou tentativa de fraude no ato da
                        contrata&ccedil;&atilde;o ou durante toda a vig&ecirc;ncia desta Ap&oacute;lice, simulando ou provocando
                        Sinistro ou ainda agravando as consequ&ecirc;ncias do mesmo para obter indeniza&ccedil;&atilde;o ou
                        dificultar a sua elucida&ccedil;&atilde;o;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        se o Segurado n&atilde;o fizer declara&ccedil;&otilde;es verdadeiras e completas, omitir
                        circunst&acirc;ncias do seu conhecimento que possam influir na aceita&ccedil;&atilde;o, na
                        taxa&ccedil;&atilde;o, ou no conhecimento exato e caracteriza&ccedil;&atilde;o do risco;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        na hip&oacute;tese de qualquer descumprimento das obriga&ccedil;&otilde;es convencionadas na presente
                        Ap&oacute;lice; ou&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        no caso de indeniza&ccedil;&atilde;o integral, desaparecimento, perda de valor monet&aacute;rio ou do
                        interesse segur&aacute;vel dos bens cobertos pela ap&oacute;lice.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>12. ACEITA&Ccedil;&Atilde;O DA PROPOSTA&nbsp;</h1>
                </div>
                <div>
                  <h2 className="subitem-text">
                    12.1&nbsp;A SEGURADORA ter&aacute; o prazo de 15 (quinze) dias corridos contados a partir do recebimento da
                    proposta de seguro para se manifestar sobre a aceita&ccedil;&atilde;o ou n&atilde;o do risco, seja em caso de
                    seguros novos, renova&ccedil;&otilde;es ou altera&ccedil;&otilde;es de seguro, independentemente da
                    exist&ecirc;ncia de sinistro por meio remoto, seja atrav&eacute;s da plataforma de acesso do SEGURADO(A)
                    (&aacute;rea de login),&nbsp;email&nbsp;ou intera&ccedil;&atilde;o voz/texto com o proponente, corretor ou
                    representante legal.&nbsp;&nbsp;
                  </h2>
                </div>
              </div>
              <div>
                <div>
                  <h2 className="subitem-text">
                    12.2&nbsp;A vistoria pr&eacute;via n&atilde;o caracteriza cobertura provis&oacute;ria para o ve&iacute;culo e
                    sim um instrumento para a SEGURADORA avaliar a aceita&ccedil;&atilde;o ou n&atilde;o do risco. O(A)
                    SEGURADRO(A) dever&aacute; apresentar o ve&iacute;culo, fotos ou documentos para realiza&ccedil;&atilde;o de
                    vistoria pr&eacute;via sempre que for solicitado pela SEGURADORA e de forma tempestiva, no prazo m&aacute;ximo
                    de 7 dias &uacute;teis.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    12.3&nbsp;Nas Propostas de Seguro submetidas por pessoas f&iacute;sicas, a Seguradora, dentro do prazo
                    estabelecido no item 12.1 desta Cl&aacute;usula, poder&aacute; solicitar documentos complementares para
                    an&aacute;lise e aceita&ccedil;&atilde;o do risco ou da altera&ccedil;&atilde;o da Proposta de Seguro.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    12.4&nbsp;A emiss&atilde;o da Ap&oacute;lice, certificado ou do endosso ser&aacute; feita em at&eacute; 15
                    (quinze) dias, a partir da data de aceita&ccedil;&atilde;o da proposta.&nbsp;
                  </h2>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>13. COBERTURAS B&Aacute;SICAS&nbsp;</h1>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Estar&atilde;o cobertos por este seguro os preju&iacute;zos devidamente comprovados&nbsp;e enquadrados nos
                    eventos descritos e coberto nesta ap&oacute;lice, observados os riscos exclu&iacute;dos&nbsp;e perda de
                    direitos&nbsp;decorrentes da(s) cobertura(s) expressamente contratada(s) na ap&oacute;lice&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>13.1&nbsp;Colis&atilde;o, Fen&ocirc;menos Naturais&nbsp;e&nbsp;Inc&ecirc;ndio&nbsp;Compreensiva</h2>
                </div>
                <div>
                  <p>
                    Contratando a cobertura de colis&atilde;o, fen&ocirc;menos naturais ou inc&ecirc;ndio, o(a) SEGURADO(A)
                    ter&aacute; direito a uma indeniza&ccedil;&atilde;o ou reparo direcionado &agrave; rede referenciada, em
                    virtude de preju&iacute;zos ocasionados ao ve&iacute;culo segurado provenientes de:&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>Colis&atilde;o ou capotagem acidental; &nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>Queda acidental em precip&iacute;cios ou de pontes;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Queda acidental de qualquer agente externo sobre o ve&iacute;culo segurado, desde que tal agente
                        n&atilde;o fa&ccedil;a parte integrante do ve&iacute;culo e n&atilde;o esteja nele afixado;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        Queda sobre o ve&iacute;culo segurado da carga por ele transportada, em decorr&ecirc;ncia de acidente de
                        tr&acirc;nsito e n&atilde;o por simples freada;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        Acidente durante seu transporte por meio apropriado, como, exemplificativamente, cegonha ou guincho
                        indicado pela SEGURADORA;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>
                        Atos danosos praticados por terceiros, exceto os constantes no item &ldquo;Preju&iacute;zos n&atilde;o
                        indeniz&aacute;veis para todas as coberturas&rdquo;;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="7">
                    <li>
                      <p>
                        Despesas necess&aacute;rias ao socorro e salvamento do ve&iacute;culo em consequ&ecirc;ncia de um dos
                        riscos cobertos de interven&ccedil;&atilde;o humana ou n&atilde;o (raios, enchentes, dentre
                        outros);&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="8">
                    <li>
                      <p>
                        Despesas referentes a danos materiais comprovadamente causados pelo(a) SEGURADO(A) e/ou por terceiros na
                        tentativa de evitar o sinistro, minorar o dano ou salvar a coisa;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="9">
                    <li>
                      <p>Inc&ecirc;ndio ou explos&atilde;o acidental n&atilde;o intencionada;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="10">
                    <li>
                      <p>raio e suas consequ&ecirc;ncias.&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div>
                <div>
                  <h2>13.2&nbsp;Roubo&nbsp;ou&nbsp;Furto&nbsp;Total e Parcial&nbsp;Compreensiva</h2>
                </div>
                <div>
                  <p>
                    Contratando a cobertura de roubo e furto&nbsp;total e parcial, o(a) SEGURADO(A) ter&aacute; direito a uma
                    indeniza&ccedil;&atilde;o/repara&ccedil;&atilde;o em virtude de preju&iacute;zos ocasionados ao ve&iacute;culo
                    segurado provenientes de:&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>Roubo ou furto total do ve&iacute;culo segurado;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Danos sofridos pelo ve&iacute;culo segurado durante o tempo em que, como consequ&ecirc;ncia de roubo ou
                        furto esteve em poder de terceiros, deduzida da indeniza&ccedil;&atilde;o a franquia estipulada na
                        ap&oacute;lice para o ve&iacute;culo quando for de repara&ccedil;&atilde;o parcial;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Despesas necess&aacute;rias ao socorro e salvamento do ve&iacute;culo em consequ&ecirc;ncia de um dos
                        riscos cobertos e decorrentes de interven&ccedil;&otilde;es humanas;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        Despesas referentes a danos materiais comprovadamente causados pelo(a) SEGURADO(A) e/ou por terceiros na
                        tentativa de evitar o sinistro, minorar o dano ou salvar a coisa;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        Roubo ou furto total exclusivo do r&aacute;dio, toca-fitas, toca&nbsp;cd&rsquo;s, desde que tais itens
                        fa&ccedil;am parte do modelo original do ve&iacute;culo, descontado do valor da indeniza&ccedil;&atilde;o
                        a franquia integral estipulada na ap&oacute;lice para o ve&iacute;culo quando for de
                        repara&ccedil;&atilde;o parcial.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <p>13.2.1&nbsp;Exclus&otilde;es Espec&iacute;ficas&nbsp;&nbsp;</p>
                </div>
                <div>
                  <p>
                    Al&eacute;m dos Riscos Exclu&iacute;dos, das Condi&ccedil;&otilde;es Gerais, est&atilde;o tamb&eacute;m
                    exclu&iacute;dos desta cobertura:&nbsp;&nbsp;
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    a) Roubo/Furto parcial, entendido como o roubo ou furto de quaisquer partes, pe&ccedil;as, equipamentos ou
                    acess&oacute;rios do ve&iacute;culo.&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>13.3 Exclusiva para Indenização Integral por Roubo ou Furto Total do Veículo Restrita</h2>
                </div>
                <div>
                  <p>
                    Contratando a cobertura de roubo e furto total restrita, o(a) SEGURADO(A) terá direito a uma indenização em
                    virtude da perda total do veículo segurado provenientes de:
                  </p>
                </div>
                <div>
                  <p>
                    a) Roubo ou furto total do veículo segurado, não localizado até a data de pagamento da indenização de
                    sinistro.
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Caso o veículo segurado seja localizado antes do pagamento do sinistro, mas por conta do roubo ou furto
                        total, ele contenha danos superiores a 75% (setenta e cinco por cento) do Limite Máximo de Indenização,
                        desde que atestado por empresa especializada, a Seguradora considerará que o veículo foi totalmente
                        danificado e pagará o Limite Máximo de Indenização previsto nesta Apólice.
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Caso o veículo segurado seja localizado antes do pagamento do Sinistro e não tenha danos ou tenha danos
                        inferiores a 75% (setenta e cinco por cento) do Limite Máximo de Indenização, o processo de sinistro será
                        cancelado sem indenização pelo evento ocorrido.
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>13.3.1&nbsp;Exclusões Específicas</p>
                </div>
                <div>
                  <p>Além dos Riscos Excluídos, das Condições Gerais, estão também excluídos desta cobertura:</p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Roubo/Furto parcial, entendido como o roubo ou furto exclusivo de quaisquer partes, peças, equipamentos ou
                        acessórios do veículo.
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Danos cujo valores de reparos sejam inferiores a 75% (setenta e cinco por cento) Limite Máximo de
                        Indenização contratado na apólice.
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Despesas de quaisquer naturezas, mesmo aquelas relacionadas a efetiva recuperação, liberação, vistoria,
                        documentação ou regularização do veiculo.
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <h2>13.4 Exclusiva para Indenização Integral por Colisão, Fenômenos Naturais ou Incêndio Restrita</h2>
                </div>
                <div>
                  <p>
                    Contratando a cobertura de Indenização Integral por Colisão, Fenômenos Naturais ou Incêndio Restrita, o(a)
                    SEGURADO(A) terá direito a uma indenização em virtude da perda total do veículo segurado provenientes de: 
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Colisões, Incêndio, explosões acidentais, quedas ou outros acidentes de causa externa, incluindo
                        alagamento, inundação, queda de raio, granizo, furacão e terremoto, que ocasionem danos ao veículo
                        segurado cujo valor para reparação seja superior a 75% (setenta e cinco por cento) do Limite Máximo de
                        Indenização contratado na apólice. 
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>13.4.1&nbsp;Exclusões Específicas</p>
                </div>
                <div>
                  <p>Além dos Riscos Excluídos, das Condições Gerais, estão também excluídos desta cobertura:</p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Roubo/Furto total ou parcial do veículo ou de suas partes, peças, equipamentos ou acessórios do veículo.
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Danos cujo valores de reparos sejam inferiores a 75% (setenta e cinco por cento) Limite Máximo de
                        Indenização contratado na apólice. Para efeito de cálculo do valor de reparo, não serão considerados os
                        custos de depreciação, perda, desvalorização, remarcação de chassis, emissão ou regularização de
                        documentos.
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Despesas de quaisquer naturezas, mesmo aquelas relacionadas a efetiva recuperação, liberação, vistoria,
                        documentação ou regularização do veículo.
                      </p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>14. COBERTURAS ADICIONAIS&nbsp;</h1>
                </div>
                <div>
                  <h2>14.1&nbsp;Assist&ecirc;ncia 24 Horas&nbsp;</h2>
                </div>
                <div>
                  <p>
                    A Assist&ecirc;ncia veicular &eacute; um servi&ccedil;o complementar ao seguro e sua presta&ccedil;&atilde;o
                    n&atilde;o implica, para qualquer efeito, no reconhecimento, pela SEGURADORA, de cobertura em
                    rela&ccedil;&atilde;o aos itens&nbsp;descritos na proposta de cota&ccedil;&atilde;o, termo de aceite ou na
                    ap&oacute;lice de seguro, que se rege por suas pr&oacute;prias condi&ccedil;&otilde;es contratuais entre
                    SEGURADO(A) e SEGURADORA&nbsp;considerando:&nbsp;
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Presta&ccedil;&atilde;o de servi&ccedil;o de assist&ecirc;ncia 24 (vinte e quatro) horas a(o) SEGURADO(A)
                        em car&aacute;ter emergencial mediante a impossibilidade de locomo&ccedil;&atilde;o do ve&iacute;culo em
                        raz&atilde;o de acidentes (quando cobertos pelo plano contratado), panes, roubos ou furtos e
                        fen&ocirc;menos naturais, devendo ser solicitado atrav&eacute;s dos meios oficiais de
                        comunica&ccedil;&atilde;o. N&atilde;o s&atilde;o considerados servi&ccedil;os emergenciais eventos que se
                        referem &agrave; manuten&ccedil;&atilde;o do ve&iacute;culo, que n&atilde;o esteja amparado no termo de
                        aceite, proposta de contrata&ccedil;&atilde;o do seguro, ap&oacute;lice de seguro ou casos em que o
                        cliente n&atilde;o realize o acionamento no mesmo dia/hora do fato ocorrido;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        A inadimpl&ecirc;ncia do(a) SEGURADO(A), de acordo com as regras destas Condi&ccedil;&otilde;es Gerais,
                        acarretar&aacute;&nbsp;na&nbsp;inativa&ccedil;&atilde;o da presta&ccedil;&atilde;o de servi&ccedil;os da
                        Assist&ecirc;ncia 24 horas;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Apenas em caso de necessidade e expl&iacute;cita autoriza&ccedil;&atilde;o por parte da SEGURADORA,
                        poder&aacute; a SEGURADORA ou empresa terceirizada, autorizar o acionamento particular por parte do(a)
                        SEGURADO(A), e ressarci-lo(a) do desembolso para tal fim, respeitando o procedimento de reembolso;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        N&atilde;o ser&atilde;o indenizados reboques que excedam a quilometragem constante no termo de aceite, na
                        ap&oacute;lice de seguro ou que desrespeitem as normas das Condi&ccedil;&otilde;es Gerais;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        O(A) SEGURADO(A) dever&aacute; contatar a central de assist&ecirc;ncia no momento do evento e fornecer as
                        informa&ccedil;&otilde;es solicitadas de forma clara e completa para a devida identifica&ccedil;&atilde;o
                        do usu&aacute;rio e do ve&iacute;culo assistido, bem como para a confirma&ccedil;&atilde;o de sua
                        inclus&atilde;o do cadastro de an&aacute;lise das condi&ccedil;&otilde;es da Assist&ecirc;ncia
                        contratada;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="6">
                    <li>
                      <p>
                        No caso de cancelamento do servi&ccedil;o ap&oacute;s a libera&ccedil;&atilde;o do prestador, seja qual
                        for o motivo do cancelamento, o servi&ccedil;o ser&aacute; considerado como concedido, prestado e
                        conclu&iacute;do, n&atilde;o tendo o cliente o direito a uma nova solicita&ccedil;&atilde;o para o mesmo
                        evento dentro do per&iacute;odo corrente de cobertura da ap&oacute;lice.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <br />
                  </div>
                  <table border="2" width="100%">
                    <thead>
                      <td width="33%">Serviços ao veículo</td>
                      <td width="33%">Automóvel</td>
                      <td width="33%">Moto</td>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Assistência ao veículo (Reboque) <sup>1</sup>
                        </td>
                        <td>Sim</td>
                        <td>Sim</td>
                      </tr>
                      <tr>
                        <td>Chaveiro</td>
                        <td>Sim</td>
                        <td>Não</td>
                      </tr>
                      <tr>
                        <td>Pane Seca</td>
                        <td>Sim</td>
                        <td>Sim</td>
                      </tr>
                      <tr>
                        <td>Troca de Pneu</td>
                        <td>Sim</td>
                        <td>Não</td>
                      </tr>
                      <tr>
                        <td>Recarga de Bateria </td>
                        <td>Sim</td>
                        <td>Sim</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <div>
                  <p>
                    14.1.1&nbsp;As garantias e os servi&ccedil;os referentes a essas cl&aacute;usulas devem ser solicitados
                    &agrave; Central de Assist&ecirc;ncia 24 horas. Somente os prestadores da rede referenciada poder&atilde;o
                    executar tais servi&ccedil;os. Em nenhuma hip&oacute;tese, ser&atilde;o reembolsados gastos relativos a
                    servi&ccedil;os executados por prestadores n&atilde;o referenciados. Para essas cl&aacute;usulas, n&atilde;o
                    h&aacute; limites de acionamentos.&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>14.2&nbsp;APP&nbsp;(Acidente Pessoal Por Passageiro)&nbsp;</h2>
                </div>
                <div>
                  <p>
                    Contratando a cobertura de Acidente Pessoal por Passageiro, o(a) SEGURADO(A) ter&aacute; direito a uma
                    indeniza&ccedil;&atilde;o em virtude de preju&iacute;zos ocasionados ao ve&iacute;culo segurado provenientes
                    de evento de sinistro comunicado e autorizado pela SEGURADORA.&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Cobertura no valor de at&eacute; R$ 10.000,00 (dez mil reais) por passageiro do ve&iacute;culo integrante
                        da rela&ccedil;&atilde;o com a SEGURADORA, em caso de morte;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        O APP &eacute; limitado ao valor de at&eacute; R$ 50.000,00 (cinquenta mil reais) por
                        ve&iacute;culo;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        O ressarcimento de despesas m&eacute;dicas/hospitalares &eacute; de at&eacute; R$ 2.000,00 (dois mil
                        reais) por passageiro, limitado ao valor de R$ 10.000,00 (dez mil reais) por ve&iacute;culo, exceto
                        passageiros de motocicleta tendo em vista que os mesmos n&atilde;o fazem jus a referida
                        cobertura;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        Os ressarcimentos ser&atilde;o avaliados mediante comprova&ccedil;&atilde;o documentada a ser
                        exigida&nbsp;no momento da comunica&ccedil;&atilde;o do sinistro.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>14.3 Carro Reserva&nbsp;</h2>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Contratando a cobertura de Carro Reserva, o(a) SEGURADO(A) ter&aacute; direito a uma utiliza&ccedil;&atilde;o
                    em virtude de preju&iacute;zos ocasionados ao ve&iacute;culo segurado provenientes de evento de sinistro
                    comunicado e autorizado pela SEGURADORA.&nbsp;
                  </p>
                </div>
                <div>
                  <p>
                    14.3.1&nbsp;Poder&aacute; ser solicitado respeitando a quantidade de dias devidamente contratados pelo(a)
                    SEGURADO(A) e expresso na ap&oacute;lice de seguro, em casos de roubo, furto, colis&atilde;o, inc&ecirc;ndio
                    ou fen&ocirc;menos naturais indenizados pela SEGURADORA, mediante aprova&ccedil;&atilde;o do sinistro e
                    apresenta&ccedil;&atilde;o de boletim de ocorr&ecirc;ncia, respeitando-se todas as regras determinadas pelas
                    locadoras indicadas, em conformidade com as condi&ccedil;&otilde;es gerais da SEGURADORA.&nbsp;
                  </p>
                </div>
                <div>
                  <p>14.3.2&nbsp;Sendo necess&aacute;rio ter no m&iacute;nimo:&nbsp;</p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>2 (dois) anos de habilita&ccedil;&atilde;o na categoria &ldquo;B&rdquo; ou acima;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>21 (vinte e um) anos de idade;&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Limite dispon&iacute;vel em cart&atilde;o de cr&eacute;dito no valor m&iacute;nimo de R$
                        1.000,00&nbsp;(hum mil reais).&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>
                    14.3.3&nbsp;Para todos os casos, qualquer outro crit&eacute;rio a ser exigido pela locadora dever&aacute; ser
                    respeitado pelo(a) SEGURADO(A), al&eacute;m disto, fica exclu&iacute;da qualquer responsabilidade por parte da
                    SEGURADORA para itens como: motorista adicional, pagamento de seguro do ve&iacute;culo locado, dentre
                    outros.&nbsp;
                  </p>
                </div>
                <div>
                  <p>
                    14.3.4&nbsp;O ve&iacute;culo ser&aacute; de categoria popular b&aacute;sico, contendo ar-condicionado e
                    dire&ccedil;&atilde;o hidr&aacute;ulica para o m&aacute;ximo de 5 ocupantes.&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2>14.4&nbsp;Danos&nbsp;a&nbsp;Terceiros&nbsp;</h2>
                </div>
              </div>
              <div>
                <p>
                  Servi&ccedil;o dispon&iacute;vel mediante contrata&ccedil;&atilde;o para cobertura material a terceiros
                  envolvidos em evento de sinistro, que decorram de evento comunicado e autorizado pela SEGURADORA.&nbsp;
                </p>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <p>
                  14.4.1&nbsp;&Eacute; limitado ao valor m&aacute;ximo de indeniza&ccedil;&atilde;o (LMI) de acordo com o
                  contratado pelo SEGURADO e expresso na ap&oacute;lice do SEGURADO.&nbsp;
                </p>
              </div>
              <div>
                <p>
                  14.4.2&nbsp;Esta cobertura adicional apenas se aplica em caso de aprova&ccedil;&atilde;o do sinistro que o(a)
                  SEGURADO(A) tenha dado causa, n&atilde;o sendo aplic&aacute;vel para casos em que o terceiro envolvido seja
                  causador ou que haja culpa concorrente ou exclusiva de terceiros.&nbsp;
                </p>
              </div>
              <div>
                <p>
                  14.4.3&nbsp;Referido valor diz respeito apenas para danos materiais, n&atilde;o sendo poss&iacute;vel
                  repara&ccedil;&atilde;o por danos morais, est&eacute;ticos, m&eacute;dicos, lucros cessantes ou quaisquer outras
                  finalidades, em conformidade com as condi&ccedil;&otilde;es gerais da SEGURADORA.&nbsp;
                </p>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <h2>14.5&nbsp;Cobertura Para Vidros: Far&oacute;is, Lanternas E Vidros&nbsp;</h2>
              </div>
              <div>
                <p>
                  Servi&ccedil;o dispon&iacute;vel mediante contrata&ccedil;&atilde;o para cobertura de vidros e espelhos
                  retrovisores em eventos ou acidentes que provoquem um sinistro e que decorram de evento coberto e devidamente
                  comunicado e autorizado pela SEGURADORA.&nbsp;
                </p>
              </div>
              <div>
                <p>
                  14.5.1&nbsp;Cobertura dos vidros laterais, traseiro, para-brisa, far&oacute;is, lanternas e vidros (lentes) dos
                  retrovisores, os limites m&aacute;ximos de indeniza&ccedil;&otilde;es constar&atilde;o na ap&oacute;lice.&nbsp;
                </p>
              </div>
              <div>
                <p>14.5.2&nbsp;Para esta cobertura o limite de acionamentos ser&aacute; de um evento por m&ecirc;s.&nbsp;</p>
              </div>
              <div>
                <div>
                  <p>
                    14.5.3&nbsp;N&atilde;o ser&atilde;o autorizadas trocas por m&aacute; uso/manuten&ccedil;&atilde;o ou decurso
                    de uso e conserva&ccedil;&atilde;o natural do ve&iacute;culo segurado.&nbsp;
                  </p>
                </div>
                <div>
                  <p>14.5.4&nbsp;Exclus&otilde;es Espec&iacute;ficas&nbsp;&nbsp;</p>
                </div>
                <div>
                  <p>
                    Al&eacute;m dos Riscos Exclu&iacute;dos, das Condi&ccedil;&otilde;es Gerais, est&atilde;o tamb&eacute;m
                    exclu&iacute;dos desta cobertura:&nbsp;&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>Roubo/Furto total ou parcial dos vidros, far&oacute;is, lanternas ou retrovisores;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Simples quebra, rachadura ou fissura que n&atilde;o sido decorrente de acidente ou evento externo,
                        s&uacute;bito e involunt&aacute;rio;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Danos a far&oacute;is de neblina, far&oacute;is de milha, luzes ou piscas de retrovisores, far&oacute;is
                        ou&nbsp;lanternas&nbsp;n&atilde;o originais de&nbsp;f&aacute;brica&nbsp;para o modelo de ve&iacute;culos
                        segurado.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>15. BENS N&Atilde;O COMPREENDIDOS NO SEGURO&nbsp;&nbsp;</h1>
                </div>
                <div>
                  <p>
                    Al&eacute;m dos Riscos Exclu&iacute;dos, das Condi&ccedil;&otilde;es Gerais e Especiais, est&atilde;o
                    tamb&eacute;m exclu&iacute;dos destas coberturas:&nbsp;&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        os r&aacute;dios, toca-fitas, disc-laser conjugados ou n&atilde;o, originais de f&aacute;brica ou
                        n&atilde;o, e demais pe&ccedil;as/aparelhos relacionado ao som, carro&ccedil;arias (inclusive custos com
                        blindagem) e equipamentos especiais;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        os equipamentos destinados a um fim espec&iacute;fico n&atilde;o relacionados com a
                        locomo&ccedil;&atilde;o ou movimento do ve&iacute;culo;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>danos causados a adesivos, plotagens e envelopamentos;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>blindagem;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        danos ocasionados ao kit g&aacute;s ou por ele provocados (salvo se contratada cobertura adicional na
                        ap&oacute;lice) e desde que esteja devidamente homologado no Detran e Inmetro, dentro do prazo de validade
                        dos equipamentos e constante no documento de licenciamento do ve&iacute;culo;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>
                        desvaloriza&ccedil;&atilde;o, deprecia&ccedil;&atilde;o, perda de valor do bem segurado, mesmo que em
                        virtude de evento coberto na ap&oacute;lice;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="7">
                    <li>
                      <p>lucros cessantes de qualquer natureza;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="8">
                    <li>
                      <p>
                        danos ou perda de objetos transportados pelo ve&iacute;culo segurado, mesmo que em virtude de evento
                        coberto na ap&oacute;lice.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>16. FRANQUIAS&nbsp;</h1>
                </div>
                <div>
                  <h2>16.1&nbsp;Franquia&nbsp;Padr&atilde;o&nbsp;</h2>
                </div>
              </div>
              <div>
                <p>
                  Ser&aacute; de 10% (dez por cento) do valor FIPE do ve&iacute;culo segurado, data base do levantamento do valor
                  FIPE ser&aacute; a data do evento:&nbsp;
                </p>
              </div>
              <div>
                <h2>
                  16.2&nbsp;A franquia poder&aacute; ser vari&aacute;vel de acordo com a cobertura adicional contratada conforme
                  abaixo:&nbsp;
                </h2>
              </div>
              <table border="2" width="100%">
                <thead>
                  <td width="50%">COBERTURAS</td>
                  <td width="50%">FRANQUIA PADRÃO</td>
                </thead>
                <tbody>
                  <tr>
                    <td>Colisão / Incêndio / Fenômenos Naturais</td>
                    <td>10% do valor FIPE do veículo segurado</td>
                  </tr>
                  <tr>
                    <td>Faróis, lanternas e vidros </td>
                    <td>20% por item com o mínimo de R$ 60,00</td>
                  </tr>
                  <tr>
                    <td>Roubo e Furto com reparo parcial </td>
                    <td>10% do valor FIPE do veículo segurado </td>
                  </tr>
                  <tr>
                    <td>Indenização total ou Roubo e Furto Total</td>
                    <td>10% do valor FIPE do veículo segurado</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <h2>16.3&nbsp;Franquia reduzida&nbsp;</h2>
              </div>
              <div>
                <p>
                  Caso o segurado contrate a cobertura adicional de franquia reduzida, ter&aacute; desconto sobre a franquia do
                  valor obrigat&oacute;rio que &eacute; de responsabilidade do(a) SEGURADO(A), para qualquer tipo de
                  indeniza&ccedil;&atilde;o parcial que for feita pela Seguradora. A depender do pacote contratado pelo(a)
                  SEGURADO(A) e disponibilidade por parte da SEGURADORA para determinados ve&iacute;culos, poder&aacute; ser 7%
                  (sete porcento) ou 4% (quatro porcento) do valor FIPE do ve&iacute;culo segurado, data base do levantamento do
                  valor FIPE ser&aacute; a data do evento&nbsp;de acordo com o pacote contratado e definido na ap&oacute;lice de
                  seguros.&nbsp;
                </p>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <table border="2" width="100%">
                <thead>
                  <td width="50%">COBERTURAS</td>
                  <td width="50%">FRANQUIA PADRÃO</td>
                </thead>
                <tbody>
                  <tr>
                    <td>Colisão / Incêndio / Fenômenos Naturais</td>
                    <td>7% ou 4% do valor FIPE do veículo</td>
                  </tr>
                  <tr>
                    <td>Faróis, lanternas e vidros </td>
                    <td>Não há opção de franquia reduzida</td>
                  </tr>
                  <tr>
                    <td>Roubo e Furto com reparo parcial</td>
                    <td>7% ou 4% do valor FIPE do veículo </td>
                  </tr>
                  <tr>
                    <td>Indenização total ou Roubo e Furto Total</td>
                    <td>10% do valor FIPE do veículo segurado</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <h1>17. PERDA DE DIREITO&nbsp;</h1>
              </div>
              <div>
                <p>
                  Al&eacute;m dos casos previstos em lei e nas demais cl&aacute;usulas das condi&ccedil;&otilde;es deste seguro, o
                  Segurado perder&aacute; o direito a qualquer indeniza&ccedil;&atilde;o, bem como ter&aacute; a ap&oacute;lice
                  cancelada, sem direito a restitui&ccedil;&atilde;o do pr&ecirc;mio j&aacute; pago, nos seguintes casos:&nbsp;
                </p>
              </div>
              <div>
                <ol start="1">
                  <li>
                    <p>O(A) SEGURADO(A) agravar intencionalmente o risco objeto do seguro;&nbsp;</p>
                  </li>
                </ol>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        O(A) SEGURADO(A) por qualquer meio procurar obter benef&iacute;cios il&iacute;citos do seguro a que se
                        refere esta ap&oacute;lice;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        O ve&iacute;culo e/ou seus documentos ou registros n&atilde;o forem verdadeiros ou tiverem sido por
                        qualquer forma adulterados;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        For verificada a venda do ve&iacute;culo segurado sem comunica&ccedil;&atilde;o &agrave; Seguradora;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        O(A) SEGURADO(A) deixar de comunicar quaisquer altera&ccedil;&otilde;es que possam influir no
                        enquadramento tarif&aacute;rio do seguro;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>
                        O(A) SEGURADO(A) deixar de avisar IMEDIATAMENTE em rela&ccedil;&atilde;o ao evento ap&oacute;s a
                        ocorr&ecirc;ncia do dano &agrave;s autoridades policiais ou n&atilde;o proceder ao registro do Boletim
                        de&nbsp;Ocorr&ecirc;ncia, em caso de desaparecimento, roubo, ou furto total ou parcial, do ve&iacute;culo
                        segurado;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="7">
                    <li>
                      <p>
                        O(A) SEGURADO(A) deixar de avisar o sinistro &agrave; SEGURADORA, pelo meio mais r&aacute;pido de que
                        dispuser;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="8">
                    <li>
                      <p>
                        O(A) SEGURADO(A) dirigir o ve&iacute;culo segurado sem habilita&ccedil;&atilde;o legal ou permitir que o
                        mesmo seja dirigido por pessoa n&atilde;o legalmente habilitada, considerada para tal fim a
                        habilita&ccedil;&atilde;o daquela categoria, equiparando-se neste caso as habilita&ccedil;&otilde;es
                        cassadas, suspensas ou vencidas;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="9">
                    <li>
                      <p>
                        O ve&iacute;culo estiver sendo conduzido por pessoa que esteja sob a a&ccedil;&atilde;o do &aacute;lcool,
                        de drogas ou entorpecentes de uso fortuito, ocasional ou habitual, quando da ocorr&ecirc;ncia do sinistro.
                        Ou ainda, estiver utilizando medicamento controlado ou n&atilde;o, que expressamente indique a n&atilde;o
                        condu&ccedil;&atilde;o de ve&iacute;culo em sua bula, prescri&ccedil;&atilde;o ou
                        recomenda&ccedil;&atilde;o&nbsp;medica;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="10">
                    <li>
                      <p>
                        O(A) SEGURADO(A) deixar de comunicar &agrave; SEGURADORA, logo que saiba, todo incidente suscet&iacute;vel
                        de agravar o risco coberto, se provado que o(a) SEGURADO(A) silenciou de m&aacute;-f&eacute;;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="11">
                    <li>
                      <p>
                        O(A) SEGURADO, benefici&aacute;rio(a), seu(a) representante legal ou seu(a) corretor(a) de seguros
                        provocar ou simular sinistro, ainda que na forma tentada e n&atilde;o consumada;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="12">
                    <li>
                      <p>
                        O ve&iacute;culo n&atilde;o tiver instalado um rastreador, bloqueador ou localizador e em funcionamento,
                        quando a exist&ecirc;ncia de tal equipamento tiver sido exigida para a aceita&ccedil;&atilde;o do risco,
                        bem como arcar com o valor da mensalidade do equipamento, quando for o caso;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="13">
                    <li>
                      <p>
                        O ve&iacute;culo estiver com suas caracter&iacute;sticas originais alteradas, exemplo: rebaixado,
                        turbinado,&nbsp;tunning&nbsp;(transforma&ccedil;&atilde;o ou otimiza&ccedil;&atilde;o das
                        caracter&iacute;sticas do ve&iacute;culo, utilizada como est&eacute;tica),&nbsp;etc;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="14">
                    <li>
                      <p>
                        Forem realizados consertos sem pr&eacute;via autoriza&ccedil;&atilde;o da SEGURADORA quando decorrente de
                        sinistro indeniz&aacute;vel;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="15">
                    <li>
                      <p>
                        O(A) SEGURADO(A) deixar de tomar as provid&ecirc;ncias imediatas para minorar as consequ&ecirc;ncias do
                        sinistro;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="16">
                    <li>
                      <p>
                        O(A) SEGURADO(A) deixar de manter o ve&iacute;culo em bom estado de conserva&ccedil;&atilde;o e
                        seguran&ccedil;a e apto a trafegar;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="17">
                    <li>
                      <p>
                        O(A) SEGURADO(A), seu(a) representante ou seu(a) corretor(a) de seguros fizer declara&ccedil;&otilde;es
                        inexatas ou omitir circunst&acirc;ncias que possam influir na proposta ou no valor do pr&ecirc;mio,
                        ficar&aacute; prejudicado o direito &agrave; indeniza&ccedil;&atilde;o, al&eacute;m de estar o(a)
                        SEGURADO(A) obrigado(a) ao pagamento do pr&ecirc;mio vencido;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="18">
                    <li>
                      <p>
                        O(A) SEGURADO(A), seu(a) representante ou seu(a) corretor(a) de seguros assumir responsabilidade ou
                        realizar acordos, ainda que informais com terceiros ou outros envolvidos em sinistro para que haja
                        eventual atendimento pela ap&oacute;lice contratada;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="19">
                    <li>
                      <p>
                        Qualquer ocorr&ecirc;ncia causada pela fuga do condutor do ve&iacute;culo segurado &agrave;
                        persegui&ccedil;&atilde;o ou a&ccedil;&atilde;o policial, fiscal ou militar;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="20">
                    <li>
                      <p>
                        Se o Segurado, seu representante legal ou seu corretor de seguros, fizer declara&ccedil;&otilde;es
                        inexatas ou omitir circunst&acirc;ncias que possam influir na aceita&ccedil;&atilde;o da Proposta de
                        Seguro ou no valor do pr&ecirc;mio, ficar&aacute; prejudicado o direito &agrave;
                        indeniza&ccedil;&atilde;o, al&eacute;m de estar o Segurado obrigado ao pagamento do pr&ecirc;mio vencido,
                        podendo a SEGURADORA cobrar a diferen&ccedil;a de pr&ecirc;mio cab&iacute;vel ao correto enquadramento do
                        risco segurado.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div>
                <div>
                  <h1>
                    18. PREJU&Iacute;ZOS N&Atilde;O INDENIZ&Aacute;VEIS (RISCOS EXCLU&Iacute;DOS) PARA TODAS AS COBERTURAS&nbsp;
                  </h1>
                </div>
                <div>
                  <p>A seguradora n&atilde;o indenizar&aacute; os preju&iacute;zos, as perdas e os danos decorrentes:&nbsp;</p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        De lucros cessantes em virtude da paralisa&ccedil;&atilde;o do ve&iacute;culo segurado mesmo quando
                        resultante de um dos riscos cobertos, exceto quando contratada cobertura espec&iacute;fica mediante
                        pagamento de pr&ecirc;mio adicional;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Da participa&ccedil;&atilde;o do ve&iacute;culo segurado em pr&aacute;ticas esportivas bem como em
                        competi&ccedil;&otilde;es, apostas e provas de velocidade, legalmente autorizadas ou n&atilde;o, exceto
                        para a cobertura de Acidentes Pessoais com Ocupantes do ve&iacute;culo segurado;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Da presta&ccedil;&atilde;o de servi&ccedil;os especializados de natureza t&eacute;cnico profissional a que
                        se destine o ve&iacute;culo;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="4">
                    <li>
                      <p>De opera&ccedil;&otilde;es de carga e descarga;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="5">
                    <li>
                      <p>
                        Da superlota&ccedil;&atilde;o do ve&iacute;culo, quer de pessoas ou da carga transportada;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>
                        Do travamento do motor por motivo de falta de &oacute;leo, &aacute;gua ou itens de
                        manuten&ccedil;&atilde;o preventiva do ve&iacute;culo (correia dentada,&nbsp;etc);&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="7">
                    <li>
                      <p>
                        De atos ou opera&ccedil;&otilde;es de guerra, declarada ou n&atilde;o, qu&iacute;mica ou
                        bacteriol&oacute;gica, civil ou guerrilha, hostilidades, revolu&ccedil;&atilde;o, agita&ccedil;&atilde;o,
                        motim, revolta, sedi&ccedil;&atilde;o, subleva&ccedil;&atilde;o ou outras perturba&ccedil;&otilde;es da
                        ordem p&uacute;blica e delas decorrentes;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="8">
                    <li>
                      <p>
                        De roubo e/ou furto exclusivo da parte remov&iacute;vel de toca-fitas ou similares com frente
                        remov&iacute;vel, como tamb&eacute;m do controle remoto e do DVD fixados ou n&atilde;o em car&aacute;ter
                        permanente no ve&iacute;culo, originais de f&aacute;brica ou n&atilde;o;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="9">
                    <li>
                      <p>
                        Atos de hostilidade ou de guerra declarada ou n&atilde;o, de treinamento
                        militar,&nbsp;opera&ccedil;&otilde;es b&eacute;licas, de revoltas populares, greves, como&ccedil;&atilde;o
                        social, tumultos,&nbsp;arrua&ccedil;as,&nbsp;lock-out,&nbsp;sabotagem, vandalismo, terrorismo,
                        sedi&ccedil;&atilde;o, rebeli&atilde;o,&nbsp;insurrei&ccedil;&atilde;o, revolu&ccedil;&atilde;o, motim,
                        confisco, nacionaliza&ccedil;&atilde;o, destrui&ccedil;&atilde;o ou
                        requisi&ccedil;&atilde;o&nbsp;decorrentes de qualquer ato de autoridade de fato ou de direito, civil ou
                        militar, e,&nbsp;em geral, todo ou qualquer ato ou consequ&ecirc;ncia dessas ocorr&ecirc;ncias, bem
                        como&nbsp;atos praticados por qualquer organiza&ccedil;&atilde;o cujas atividades visem a derrubar
                        pela&nbsp;for&ccedil;a o governo ou instigar a sua queda, pela perturba&ccedil;&atilde;o de ordem
                        pol&iacute;tica e social&nbsp;do pa&iacute;s, por meio de guerra revolucion&aacute;ria, subvers&atilde;o e
                        guerrilhas, e, ainda, atos&nbsp;terroristas, cabendo &agrave; Seguradora, neste caso, comprovar com
                        documenta&ccedil;&atilde;o&nbsp;h&aacute;bil, acompanhada de laudo circunstanciado que caracterize a
                        natureza do&nbsp;atentado, independentemente de seu prop&oacute;sito e desde que tenha
                        sido&nbsp;devidamente reconhecido como atentat&oacute;rio &agrave; ordem p&uacute;blica pela autoridade
                        p&uacute;blica&nbsp;competente;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="10">
                    <li>
                      <p>
                        De destrui&ccedil;&atilde;o, requisi&ccedil;&atilde;o ou apreens&atilde;o por autoridade de fato ou de
                        direito, civil ou militar;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="11">
                    <li>
                      <p>
                        De preju&iacute;zos decorrentes de quaisquer perturba&ccedil;&otilde;es de ordem p&uacute;blica, tais
                        como, exemplificativamente: tumultos, motins, greve de empregados e paralisa&ccedil;&atilde;o de atividade
                        provocada pelo empregador (Lockout);&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="12">
                    <li>
                      <p>Da submers&atilde;o total ou parcial do ve&iacute;culo em &aacute;gua salgada;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="13">
                    <li>
                      <p>
                        Do roubo, furto ou danos materiais praticados com dolo ou culpa grave equipar&aacute;vel ao dolo, cometido
                        por pessoas que dependam do(a) SEGURADO(A) ou do(a) condutor(a), assim como seus s&oacute;cios,
                        c&ocirc;njuge, ascendentes ou descendentes por consanguinidade, afinidade, ado&ccedil;&atilde;o, bem como
                        a quaisquer parentes ou pessoas que com ele(a) residam e/ou dependam economicamente;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="14">
                    <li>
                      <p>
                        De despesas que n&atilde;o sejam estritamente necess&aacute;rias para o reparo do ve&iacute;culo e seu
                        retorno &agrave;s condi&ccedil;&otilde;es de uso imediatamente anteriores ao sinistro;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="15">
                    <li>
                      <p>
                        De estelionato, apropria&ccedil;&atilde;o ind&eacute;bita, extors&atilde;o, furto mediante fraude ou
                        sumi&ccedil;o imotivado do ve&iacute;culo, que n&atilde;o configure roubo ou furto;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="16">
                    <li>
                      <p>
                        De danos decorrentes da aus&ecirc;ncia ou falha na manuten&ccedil;&atilde;o do ve&iacute;culo segurado ou
                        aqueles relacionados &agrave; aus&ecirc;ncia de conserva&ccedil;&atilde;o do bem;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="17">
                    <li>
                      <p>
                        Desvaloriza&ccedil;&atilde;o do valor do ve&iacute;culo segurado, em virtude da remarca&ccedil;&atilde;o
                        do chassi, bem como qualquer outra forma de deprecia&ccedil;&atilde;o que o mesmo venha a sofrer,
                        inclusive &agrave;quela decorrente de sinistro ou pelo uso do bem;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="18">
                    <li>
                      <p>
                        Da fuga do(a) condutor(a) do ve&iacute;culo segurado &agrave; a&ccedil;&atilde;o policial;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="19">
                    <li>
                      <p>
                        De multas, composi&ccedil;&otilde;es civis, transa&ccedil;&otilde;es penais, fian&ccedil;as impostas a(o)
                        SEGURADO(A) e as despesas de qualquer natureza relativas a a&ccedil;&otilde;es e processos
                        criminais;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="20">
                    <li>
                      <p>
                        De despesas efetuadas com custas judiciais relativas a processo criminal, bem como com honor&aacute;rios
                        de advogados decorrentes dessas a&ccedil;&otilde;es.&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="21">
                    <li>
                      <p>
                        Da utiliza&ccedil;&atilde;o ou do manuseio, pelo(a) condutor(a), de telefone celular, smartphone, ou
                        qualquer outro aparelho eletr&ocirc;nico, bem como, se estiver utilizando fones de ouvido, desde que
                        caracterizado nexo causal com a ocorr&ecirc;ncia do sinistro e/ou com o evento que provocou os
                        danos.&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="22">
                    <li>
                      <p>
                        Aos pneus e c&acirc;maras de ar, exceto em casos de inc&ecirc;ndio ou indeniza&ccedil;&atilde;o integral
                        do ve&iacute;culo ou ainda em sinistro coberto e indeniz&aacute;vel de perda parcial do ve&iacute;culo que
                        os atinja;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="23">
                    <li>
                      <p>
                        Aos itens n&atilde;o originais de f&aacute;brica: toca&nbsp;cd&rsquo;s, r&aacute;dios, toca-fitas, kit
                        g&aacute;s, tac&oacute;grafo, cabine suplementar carro&ccedil;arias, equipamentos, quando n&atilde;o for
                        contratada cobertura ou n&atilde;o fizer parte do modelo original do ve&iacute;culo;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="24">
                    <li>
                      <p>Ao ve&iacute;culo segurado pelo congelamento da &aacute;gua do motor;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="25">
                    <li>
                      <p>
                        w) Aos acess&oacute;rios ou equipamentos remov&iacute;veis, n&atilde;o fixados em car&aacute;ter
                        permanente. Exemplo: toca-fitas remov&iacute;veis (gaveta);&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="26">
                    <li>
                      <p>
                        Ao dispositivo antifurto ou antirroubo, DVD, Kit viva-voz, micro system ou similares,
                        radiocomunica&ccedil;&atilde;o ou similares, v&iacute;deo cassete e televisor (conjugados ou n&atilde;o
                        com toca-fitas ou similares);&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="27">
                    <li>
                      <p>&Agrave; carga objeto de transporte;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="28">
                    <li>
                      <p>Exclusivamente ao tac&oacute;grafo, tax&iacute;metro e luminoso;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="29">
                    <li>
                      <p>
                        Por fen&ocirc;menos/convuls&otilde;es da natureza, exceto aquelas previstas na Cobertura B&aacute;sica da
                        ap&oacute;lice;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="30">
                    <li>
                      <p>
                        Ao ve&iacute;culo segurado por desgastes, deprecia&ccedil;&atilde;o decorrente de sinistro ou pelo uso,
                        falhas de material, defeitos mec&acirc;nicos ou da instala&ccedil;&atilde;o el&eacute;trica do
                        ve&iacute;culo segurado, bem como perdas ou danos decorrentes ou originados por falta de
                        manuten&ccedil;&atilde;o ou falhas e/ou erros de fabrica&ccedil;&atilde;o e/ou projeto;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="31">
                    <li>
                      <p>
                        Quando o ve&iacute;culo segurado&nbsp;estiver&nbsp;em tr&acirc;nsito por estradas ou caminhos impedidos,
                        n&atilde;o abertos ao tr&aacute;fego ou de areias fofas ou movedi&ccedil;as, bem como por praias e
                        regi&otilde;es ribeirinhas com ou sem autoriza&ccedil;&atilde;o de tr&aacute;fego pelo &oacute;rg&atilde;o
                        competente;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="32">
                    <li>
                      <p>
                        Pela carga objeto de transporte do ve&iacute;culo segurado, que contamine ou polua o meio ambiente bem
                        como pela carga do ve&iacute;culo do terceiro eventualmente envolvido em acidente com o ve&iacute;culo
                        segurado, exceto quando contratada cobertura espec&iacute;fica de contamina&ccedil;&atilde;o ou
                        polui&ccedil;&atilde;o causada ao meio ambiente pela carga do ve&iacute;culo segurado;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="33">
                    <li>
                      <p>
                        Pela contamina&ccedil;&atilde;o ou radia&ccedil;&atilde;o de qualquer natureza e processos provocados por
                        combust&iacute;veis e materiais de armas nucleares e ainda qualquer processo de fiss&atilde;o nuclear;
                        causados ao meio ambiente, tanto pelo ve&iacute;culo segurado quanto pelo ve&iacute;culo do terceiro
                        eventualmente envolvido no acidente;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="34">
                    <li>
                      <p>
                        Pelo reboque ou transporte do ve&iacute;culo segurado por ve&iacute;culo n&atilde;o apropriado a esse
                        fim;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="35">
                    <li>
                      <p>
                        Ao ve&iacute;culo segurado, pela queda, deslizamento ou vazamento dos objetos/carga por ele transportados,
                        salvo quando em consequ&ecirc;ncia de um dos riscos cobertos pela ap&oacute;lice, em decorr&ecirc;ncia de
                        acidente de tr&acirc;nsito e n&atilde;o da simples freada;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="36">
                    <li>
                      <p>Por danos morais ou est&eacute;ticos;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="37">
                    <li>
                      <p>
                        Por atos il&iacute;citos culposos ou dolosos, praticados por empregados do(a) SEGURADO(A), ou ainda, por
                        pessoas a ele(a) assemelhadas, como seus s&oacute;cios controladores, aos seus dirigentes e
                        administradores legais, aos benefici&aacute;rios e aos seus respectivos&nbsp;representantes;
                        exceto&nbsp;para a cobertura de Responsabilidade Civil;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="38">
                    <li>
                      <p>
                        Por atos il&iacute;citos dolosos ou por culpa grave equipar&aacute;vel ao dolo praticados pelo(a)
                        SEGURADO(A), por seu Benefici&aacute;rio ou pelo Representante, de um ou de outro, se o Segurado for
                        pessoa f&iacute;sica;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="39">
                    <li>
                      <p>
                        Por atos il&iacute;citos dolosos ou por culpa grave equipar&aacute;vel ao dolo, praticados pelos
                        s&oacute;cios controladores, dirigentes, administradores legais, benefici&aacute;rios e respectivos
                        representantes, se o(a) SEGURADO(A) for pessoa jur&iacute;dica;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="40">
                    <li>
                      <p>
                        Ao ve&iacute;culo segurado, por animais de propriedade do(a) SEGURADO(A) principal condutor ou de seus
                        ascendentes, descendentes ou c&ocirc;njuge;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="41">
                    <li>
                      <p>
                        Por acidentes decorrentes da inobserv&acirc;ncia a disposi&ccedil;&otilde;es legais causados por
                        exemplificativamente, lota&ccedil;&atilde;o de passageiros, peso, acondicionamento ou transporte da carga
                        ou objeto transportado e demais situa&ccedil;&otilde;es semelhantes;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="42">
                    <li>
                      <p>
                        &Agrave;s pessoas transportadas em locais n&atilde;o especificamente destinados e apropriados a esse
                        fim;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="43">
                    <li>
                      <p>
                        A pacientes transportados por ambul&acirc;ncias seguradas, mesmo quando contratada a cobertura de
                        acidentes pessoais passageiros;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="44">
                    <li>
                      <p>
                        A bens de terceiros, m&oacute;veis ou im&oacute;veis, em poder do(a) SEGURADO(A) para guarda,
                        cust&oacute;dia, transporte, uso, manipula&ccedil;&atilde;o ou execu&ccedil;&atilde;o de quaisquer
                        trabalhos;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="45">
                    <li>
                      <p>
                        Aos empregados e prepostos do(a) SEGURADO(A) quando a seu servi&ccedil;o; aos descendentes, ascendentes,
                        c&ocirc;njuge e irm&atilde;os de um ou de outro, bem como quaisquer parentes ou pessoas que residam ou que
                        dependam economicamente destes;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="46">
                    <li>
                      <p>
                        Por polui&ccedil;&atilde;o ou contamina&ccedil;&atilde;o ao meio ambiente, bem como quaisquer despesas
                        incorridas para limpeza e/ou descontamina&ccedil;&atilde;o do meio ambiente;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="47">
                    <li>
                      <p>
                        Por responsabilidades assumidas pelo(a) SEGURADO(A) por meio de contratos, conven&ccedil;&otilde;es ou
                        acordos sem a pr&eacute;via concord&acirc;ncia da SEGURADORA;&nbsp;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="48">
                    <li>
                      <p>Pela carga ou descarga das mercadorias do ve&iacute;culo segurado;&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="49">
                    <li>
                      <p>Pela opera&ccedil;&atilde;o de basculamento do ve&iacute;culo segurado;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="50">
                    <li>
                      <p>Ao envelopamento do ve&iacute;culo segurado;&nbsp;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="51">
                    <li>
                      <p>O presente seguro n&atilde;o cobrir&aacute; a blindagem do Ve&iacute;culo;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="52">
                    <li>
                      <p>Sinistro reclamado cuja garantia n&atilde;o foi contratada;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="53">
                    <li>
                      <p>Consertos efetuados sem a autoriza&ccedil;&atilde;o pr&eacute;via da Seguradora;&nbsp;e&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="54">
                    <li>
                      <p>
                        Despesas com reparo de avarias previamente constatadas e relacionadas na vistoria pr&eacute;via.&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>19. PROCEDIMENTOS PARA LIQUIDA&Ccedil;&Atilde;O DE SINISTROS&nbsp;</h1>
                </div>

                <div>
                  <h2 className="subitem-text">
                    19.1&nbsp;O Segurado dever&aacute; comunicar a SEGURADORA atrav&eacute;s dos meios oficiais de atendimento ou
                    acessando o canal do segurado (web) ou ainda no site www.splitrisk.com.br, qualquer&nbsp;ocorr&ecirc;ncia
                    sinistra ocorrido, t&atilde;o logo tenha dele conhecimento e encaminhar a
                    documenta&ccedil;&atilde;o&nbsp;necess&aacute;ria no&nbsp;prazo m&aacute;ximo de 48 horas a contar da
                    ci&ecirc;ncia da SEGURADORA do fato ocorrido.&nbsp;&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    19.2&nbsp;A SEGURADORA pagar&aacute; a indeniza&ccedil;&atilde;o no prazo m&aacute;ximo de 30 (trinta) dias
                    corridos ap&oacute;s a entrega de todos os documentos necess&aacute;rios e solicitados a(o) SEGURADO(A),
                    benefici&aacute;rio(a) ou seu(a) representante legal.&nbsp;
                  </h2>
                </div>
              </div>
              <div>
                <h2 className="subitem-text">
                  19.3&nbsp;No caso de d&uacute;vida fundada e justific&aacute;vel, &eacute; facultada &agrave; SEGURADORA a
                  solicita&ccedil;&atilde;o de outros documentos complementares. Neste caso, a contagem do prazo ser&aacute;
                  suspensa a partir do momento em que forem solicitados os novos documentos voltando a correr a partir do dia
                  &uacute;til posterior &agrave;quele em que forem entregues os respectivos documentos.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  19.4&nbsp;No caso de solicita&ccedil;&atilde;o de documenta&ccedil;&atilde;o e/ou informa&ccedil;&atilde;o
                  complementar na forma prevista no caput deste artigo, o prazo de que trata o par&aacute;grafo anterior
                  ser&aacute; suspenso, reiniciando sua contagem a partir do dia &uacute;til subsequente &agrave;quele em que
                  forem completamente atendidas as exig&ecirc;ncias.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  19.5&nbsp;O&nbsp;n&atilde;o pagamento da indeniza&ccedil;&atilde;o no prazo previsto no item 9.A deste artigo,
                  implicar&aacute; aplica&ccedil;&atilde;o de juros de 2% a.m. e mora de 1% a.m.&nbsp;&aacute;&nbsp;partir desta
                  data, sem preju&iacute;zo de sua atualiza&ccedil;&atilde;o, nos termos da legisla&ccedil;&atilde;o
                  espec&iacute;fica; na cl&aacute;usula correspondente &agrave; liquida&ccedil;&atilde;o de sinistros, o contrato
                  de seguro poder&aacute; admitir, para fins de indeniza&ccedil;&atilde;o, mediante acordo entre as partes, as
                  hip&oacute;teses de pagamento em dinheiro, (a vista ou parcelado), reposi&ccedil;&atilde;o ou reparo da
                  coisa.&nbsp;
                </h2>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <div>
                  <h1>20. COMPROVA&Ccedil;&Atilde;O DO SINISTRO&nbsp;</h1>
                </div>
                <div>
                  <p>
                    Qualquer pagamento de indeniza&ccedil;&atilde;o ou direito &agrave; indeniza&ccedil;&atilde;o com base na
                    Ap&oacute;lice ser&aacute;&nbsp;concretizado somente ap&oacute;s terem sido adequadamente relatadas pelo
                    Segurado as caracter&iacute;sticas da ocorr&ecirc;ncia do Sinistro, apuradas suas causas, natureza e
                    extens&atilde;o e comprovados os valores a indenizar e o direito de receb&ecirc;-los, cabendo ao
                    pr&oacute;prio Segurado prestar toda a assist&ecirc;ncia para que tais requisitos sejam plenamente
                    satisfeitos.&nbsp;
                  </p>
                </div>
                <div>
                  <h2 className="subitem-text">
                    20.1&nbsp;As despesas efetuadas com a comprova&ccedil;&atilde;o do Sinistro e com os documentos de
                    habilita&ccedil;&atilde;o efetivamente necess&aacute;rios a esta comprova&ccedil;&atilde;o correr&atilde;o por
                    conta do Segurado, salvo se diretamente realizadas pela Seguradora e/ou por ela expressamente
                    autorizadas.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    20.2&nbsp;Para a regula&ccedil;&atilde;o de sinistro, dever&atilde;o ser apresentados os documentos
                    estabelecidos nas&nbsp;Condi&ccedil;&otilde;es Gerais e Especiais das coberturas efetivamente contratadas e
                    especificadas na ap&oacute;lice.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    20.3&nbsp;O&nbsp;pagamento da indeniza&ccedil;&atilde;o do sinistro ocorrer&aacute; em at&eacute; 30 (trinta)
                    dias, a contar da data da apresenta&ccedil;&atilde;o &agrave; Seguradora da documenta&ccedil;&atilde;o
                    relacionada nas Condi&ccedil;&otilde;es Especiais.&nbsp;
                  </h2>
                </div>
              </div>
              <div>
                <p>
                  20.3.1&nbsp;Esta contagem ser&aacute; suspensa a partir do momento em que for solicitada
                  documenta&ccedil;&atilde;o&nbsp;complementar, com base em d&uacute;vida fundada e justificada, voltando a correr
                  a partir do dia &uacute;til subsequente &agrave;quele em que forem entregues os respectivos documentos.&nbsp;
                </p>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.4&nbsp;A Seguradora poder&aacute; exigir atestados ou certid&otilde;es de autoridades competentes, bem como o
                  resultado de inqu&eacute;ritos ou processos instaurados em virtude do fato que produziu o Sinistro, sem
                  preju&iacute;zo do pagamento da indeniza&ccedil;&atilde;o no prazo devido. Alternativamente, poder&aacute;
                  solicitar c&oacute;pia da certid&atilde;o de abertura de inqu&eacute;rito, que porventura tiver sido
                  instaurado.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.5&nbsp;A Seguradora definir&aacute; o meio pelo qual ser&aacute; indenizado o montante dos preju&iacute;zos
                  regularmente apurados, podendo realizar-se atrav&eacute;s de pagamento em dinheiro (moeda corrente nacional),
                  reposi&ccedil;&atilde;o ou reparo do bem. Na impossibilidade de reposi&ccedil;&atilde;o do bem &agrave;
                  &eacute;poca da liquida&ccedil;&atilde;o, a indeniza&ccedil;&atilde;o dever&aacute; ser paga em dinheiro.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.6&nbsp;Qualquer indeniza&ccedil;&atilde;o somente ser&aacute; paga mediante apresenta&ccedil;&atilde;o dos
                  documentos solicitados pela Seguradora, entre eles, que comprovem os direitos de propriedade, livre e
                  desembara&ccedil;ada de qualquer &ocirc;nus do propriet&aacute;rio/Segurado sobre o ve&iacute;culo e, no caso de
                  ve&iacute;culos importados, a prova da libera&ccedil;&atilde;o alfandeg&aacute;ria definitiva. Estar&atilde;o
                  sob responsabilidade desta Seguradora os valores das despesas de socorro e salvamento porventura existentes,
                  respeitando-se o limite m&aacute;ximo de indeniza&ccedil;&atilde;o contratado para a(s) cobertura(s) que
                  garanta(m) os respectivos preju&iacute;zos indeniz&aacute;veis.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.7&nbsp;Na hip&oacute;tese de n&atilde;o cumprimento do prazo para pagamento da indeniza&ccedil;&atilde;o,
                  descrito no subitem considerar-se-&atilde;o as disposi&ccedil;&otilde;es da cl&aacute;usula
                  &ldquo;Atualiza&ccedil;&atilde;o de Valores&rdquo;.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.8&nbsp;Nos casos de Indeniza&ccedil;&atilde;o Integral ou roubo e furto total, o ve&iacute;culo dever&aacute;
                  estar totalmente livre de restri&ccedil;&otilde;es e d&eacute;bitos. Com isso, as multas de tr&acirc;nsito,
                  quaisquer custos ou taxas referentes a licenciamento do ve&iacute;culo e demais encargos para a
                  regulariza&ccedil;&atilde;o da documenta&ccedil;&atilde;o do ve&iacute;culo sinistrado ser&atilde;o de
                  responsabilidade do Segurado.&nbsp;&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.9&nbsp;A indeniza&ccedil;&atilde;o integral ou roubo e furto total, ser&aacute; devida quando o
                  ve&iacute;culo estiver livre de penhoras, gravames ou &ocirc;nus de qualquer natureza e sua
                  documenta&ccedil;&atilde;o estiver devidamente regularizada.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.10&nbsp;O pagamento de qualquer indeniza&ccedil;&atilde;o prevista na ap&oacute;lice, ser&aacute; feito ao
                  propriet&aacute;rio legal do ve&iacute;culo, mesmo que a ap&oacute;lice esteja em nome de outra pessoa.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.11&nbsp;Se momento da liquida&ccedil;&atilde;o do sinistro, for constatado que o ve&iacute;culo&nbsp;objeto
                  do segurado,&nbsp;tenham sido objeto de leil&otilde;es (seja de qualquer tipo, natureza ou categoria)&nbsp;a
                  indeniza&ccedil;&atilde;o a que fizer jus ser&aacute;&nbsp;limitada&nbsp;a 70% (setenta por cento) do
                  valor&nbsp;de LMI definido na ap&oacute;lice contratada, e desde que tenha sido informada previamente esta
                  condi&ccedil;&atilde;o a SEGURADORA no momento da&nbsp;cota&ccedil;&atilde;o e an&aacute;lise da
                  aceita&ccedil;&atilde;o do risco.&nbsp;
                </h2>
              </div>
              <div>
                <p>
                  20.11.1&nbsp;Caso a condi&ccedil;&atilde;o de leil&atilde;o, n&atilde;o tenha sido informada a SEGURADORA no
                  momento da contrata&ccedil;&atilde;o do seguro, considerar-se-&aacute; recusado o direito a
                  indeniza&ccedil;&atilde;o do segurado, mesmo que esta omiss&atilde;o tenha sido praticada pelo corretor de
                  seguros, representante, distribuidor ou estipulante.&nbsp;
                </p>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.12&nbsp;Em caso de leasing, o pagamento da indeniza&ccedil;&atilde;o ser&aacute; efetuado integralmente
                  &agrave; empresa de leasing. O Segurado obriga-se a pagar as parcelas pendentes do seguro, caso existam.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.13&nbsp;Se o ve&iacute;culo for localizado oficialmente antes da efetiva&ccedil;&atilde;o do pagamento da
                  indeniza&ccedil;&atilde;o e independente da entrega da documenta&ccedil;&atilde;o para a Seguradora, o pagamento
                  ser&aacute; suspenso para a retomada do processo de liquida&ccedil;&atilde;o do sinistro. Ap&oacute;s a
                  avalia&ccedil;&atilde;o dos danos sofridos pelo ve&iacute;culo a Seguradora informar&aacute; ao Segurado quanto
                  &agrave; libera&ccedil;&atilde;o dos reparos no caso de se tratar de indeniza&ccedil;&atilde;o parcial, ou pela
                  indeniza&ccedil;&atilde;o integral, conforme os crit&eacute;rios definidos nestas Condi&ccedil;&otilde;es
                  Gerais. Ainda que a indeniza&ccedil;&atilde;o j&aacute; tenha sigo paga, o Segurado dever&aacute; informar
                  imediatamente a Seguradora as informa&ccedil;&otilde;es sobre a localiza&ccedil;&atilde;o do
                  ve&iacute;culo.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  20.14&nbsp;Os atos e provid&ecirc;ncias praticados pela Seguradora ap&oacute;s a ocorr&ecirc;ncia do sinistro
                  n&atilde;o&nbsp;importar&atilde;o, por si s&oacute;, no reconhecimento da obriga&ccedil;&atilde;o de pagar a
                  indeniza&ccedil;&atilde;o reclamada.&nbsp;
                </h2>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <div>
                  <h1>21. RECUSA DE SINISTRO&nbsp;</h1>
                </div>
                <div>
                  <p>
                    Quando a Seguradora recusar um Sinistro, comunicar&aacute; seus motivos ao Segurado por escrito, dentro do
                    prazo m&aacute;ximo de 30 (trinta) dias contados da entrega da &uacute;ltima documenta&ccedil;&atilde;o
                    solicitada.&nbsp;
                  </p>
                </div>
                <div>
                  <h2 className="subitem-text">
                    21.1&nbsp;Se, ap&oacute;s o pagamento da indeniza&ccedil;&atilde;o, a Seguradora tomar conhecimento de
                    qualquer fato que descaracterize o direito ao seu recebimento, esta poder&aacute; requerer do Segurado ou seus
                    herdeiros legais os valores pagos indevidamente e demais gastos incorridos no sinistro.&nbsp;
                  </h2>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>
                    22. DOCUMENTA&Ccedil;&Atilde;O NECESS&Aacute;RIA PARA O RECEBIMENTO DA INDENIZA&Ccedil;&Atilde;O PARA CADA
                    COBERTURA CONTRATADA&nbsp;
                  </h1>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Documentos m&iacute;nimos exigidos a fim de que seja dado continuidade ao processo de an&aacute;lise do reparo
                    parcial e/ou indeniza&ccedil;&atilde;o total para os ve&iacute;culos segurados ou cobertos por estas
                    condi&ccedil;&otilde;es, dentro dos crit&eacute;rios de cobertura emitidos na ap&oacute;lice de seguro
                    (terceiro envolvido por exemplo). Quaisquer outros documentos complementares poder&atilde;o ser solicitados
                    a(o) SEGURADO(A) mediante a necessidade para cada tipo de evento a ser analisado. A saber, os documentos
                    iniciais ser&atilde;o:&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>Aviso de Sinistro (realizado junto &agrave; SEGURADORA);&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        Boletim de Ocorr&ecirc;ncia com informa&ccedil;&otilde;es exatas e detalhadas de todo o ocorrido e
                        envolvidos;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Boletim de Atendimento M&eacute;dico (quando houver atendimento no local ou posterior decorrente do
                        sinistro);&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="4">
                    <li>
                      <p>
                        Fotos do evento (local, danos nos ve&iacute;culos com identifica&ccedil;&atilde;o das placas, estado dos
                        pneus, hod&ocirc;metro,&nbsp;etc);&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="5">
                    <li>
                      <p>Laudo pericial;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="6">
                    <li>
                      <p>C&oacute;pia CNH;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="7">
                    <li>
                      <p>C&oacute;pia do CRLV/DUT;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="8">
                    <li>
                      <p>DUT original (aplic&aacute;vel nos casos de indeniza&ccedil;&atilde;o);&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="9">
                    <li>
                      <p>Chave do ve&iacute;culo (aplic&aacute;vel nos casos de indeniza&ccedil;&atilde;o);&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="10">
                    <li>
                      <p>C&oacute;pia do CPF ou CNPJ;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="11">
                    <li>
                      <p>
                        Procura&ccedil;&atilde;o de f&eacute;&nbsp;p&uacute;blica&nbsp;assinada e com firma reconhecida em
                        cart&oacute;rio por autenticidade (aplic&aacute;vel nos casos de indeniza&ccedil;&atilde;o);&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="12">
                    <li>
                      <p>
                        Baixa de aliena&ccedil;&atilde;o com firma reconhecida (aplic&aacute;vel nos casos de
                        indeniza&ccedil;&atilde;o para ve&iacute;culos perda total n&atilde;o pass&iacute;veis de
                        recupera&ccedil;&atilde;o);&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="13">
                    <li>
                      <p>
                        Declara&ccedil;&atilde;o de responsabilidade pelas multas at&eacute; a data de transfer&ecirc;ncia do
                        ve&iacute;culo (aplic&aacute;vel nos casos de indeniza&ccedil;&atilde;o);&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="14">
                    <li>
                      <p>Ap&oacute;lice ou endosso quitados.&nbsp;</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <p>&nbsp;&nbsp;</p>
                </div>
                <div>
                  <h1>
                    23. PRAZO M&Aacute;XIMO PARA PAGAMENTO DA INDENIZA&Ccedil;&Atilde;O PELA SOCIEDADE SEGURADORA PARTICIPANTE DO
                    SANDBOX REGULAT&Oacute;RIO&nbsp;
                  </h1>
                </div>
                <div>
                  <p>
                    A liquida&ccedil;&atilde;o de qualquer sinistro coberto por esta ap&oacute;lice processar-se-&aacute;
                    consoante &agrave;s seguintes regras:&nbsp;
                  </p>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        Tratando-se de roubo total ou furto total do ve&iacute;culo segurado, decorridos 30 (trinta) dias corridos
                        do aviso &agrave;s autoridades policiais, n&atilde;o tendo sido o mesmo apreendido nem localizado
                        oficialmente, mediante certid&atilde;o comprobat&oacute;ria de furto atualizada e os documentos de
                        comprova&ccedil;&atilde;o solicitados pelo departamento de sinistro, respeitando&nbsp;o&nbsp;prazo
                        m&aacute;ximo de 30 (trinta) dias corridos ap&oacute;s a entrega de todos os documentos necess&aacute;rios
                        e solicitados a(o) SEGURADO(A), benefici&aacute;rio(a) ou seu(a) representante legal; e para fins de
                        indeniza&ccedil;&atilde;o, mediante acordo entre as partes, as hip&oacute;teses de pagamento em dinheiro,
                        (a vista ou parcelado), reposi&ccedil;&atilde;o ou reparo da coisa;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>
                        No caso de indeniza&ccedil;&atilde;o integral, inclusive no caso de roubo total ou furto total, sem
                        preju&iacute;zo das demais obriga&ccedil;&otilde;es estipuladas nesta ap&oacute;lice, qualquer
                        indeniza&ccedil;&atilde;o somente ser&aacute; paga mediante apresenta&ccedil;&atilde;o dos documentos que
                        comprovem os direitos de propriedade, livre e desembara&ccedil;ada de qualquer &ocirc;nus, do(a)
                        SEGURADO(A) sobre o ve&iacute;culo sinistrado e, no caso de ve&iacute;culos importados, a prova de
                        libera&ccedil;&atilde;o alfandeg&aacute;ria definitiva;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <ol start="3">
                    <li>
                      <p>
                        Ocorrendo a indeniza&ccedil;&atilde;o integral do ve&iacute;culo sinistrado, o pagamento da
                        indeniza&ccedil;&atilde;o ser&aacute; em moeda corrente nacional, e observar&aacute; os termos da forma de
                        contrata&ccedil;&atilde;o do seguro, bem como o acordo entre as partes;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h2 className="subitem-text">
                    23.1&nbsp;N&atilde;o obstante o disposto, para os ve&iacute;culos novos &ldquo;zero quil&ocirc;metro&rdquo;,
                    ocorrendo a indeniza&ccedil;&atilde;o integral, a quantia a ser paga corresponder&aacute; ao valor de
                    ve&iacute;culo novo &ldquo;zero quil&ocirc;metro&rdquo; de id&ecirc;nticas caracter&iacute;sticas, na data da
                    liquida&ccedil;&atilde;o do sinistro, apurada pela tabela de refer&ecirc;ncia do ve&iacute;culo e desde que
                    satisfa&ccedil;a todas as seguintes condi&ccedil;&otilde;es:&nbsp;
                  </h2>
                </div>
                <div>
                  <ol start="1">
                    <li>
                      <p>
                        A cobertura do seguro tenha se iniciado no prazo m&aacute;ximo de 72 (setenta e duas) horas contadas da
                        data da fatura/Nota Fiscal de compra do ve&iacute;culo;&nbsp;
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <ol start="2">
                    <li>
                      <p>Seja o primeiro sinistro com o ve&iacute;culo segurado;&nbsp;</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <h2 className="subitem-text">
                    23.2&nbsp;Na impossibilidade de apura&ccedil;&atilde;o do valor do ve&iacute;culo, dever&aacute; ser utilizado
                    para fins de cota&ccedil;&atilde;o um ve&iacute;culo de caracter&iacute;sticas e valor semelhante ao indicado
                    na ap&oacute;lice;&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    23.3&nbsp;Em caso de ocorr&ecirc;ncia da extin&ccedil;&atilde;o ou interrup&ccedil;&atilde;o da
                    publica&ccedil;&atilde;o da tabela adotada &agrave; &eacute;poca da contrata&ccedil;&atilde;o do seguro,
                    ser&aacute; utilizada uma segunda tabela de refer&ecirc;ncia, estabelecida na proposta de seguro, denominada
                    tabela substituta;&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    23.4&nbsp;No caso de aliena&ccedil;&atilde;o fiduci&aacute;ria, o documento de transfer&ecirc;ncia de
                    propriedade do ve&iacute;culo dever&aacute; ser devidamente preenchido com os dados de seu propriet&aacute;rio
                    e da SEGURADORA. A SEGURADORA pagar&aacute; diretamente &agrave; Institui&ccedil;&atilde;o Financeira o valor
                    do saldo devedor. Havendo valor remanescente apurado entre o valor da indeniza&ccedil;&atilde;o e o valor
                    quitado, este ser&aacute; pago ao propriet&aacute;rio do ve&iacute;culo. Para os casos em que o valor a ser
                    indenizado n&atilde;o cubra&nbsp;&agrave;s&nbsp;custas da quita&ccedil;&atilde;o total do ve&iacute;culo, a
                    indeniza&ccedil;&atilde;o ficar&aacute; suspensa at&eacute; efetivo pagamento do SEGURADO do valor da
                    diferen&ccedil;a &agrave; SEGURADORA.&nbsp;
                  </h2>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>
                    24. &Iacute;NDICE DE ATUALIZA&Ccedil;&Atilde;O DE VALORES, JUROS E MORAS PARA LIQUIDA&Ccedil;&Atilde;O DO
                    SINISTRO&nbsp;
                  </h1>
                </div>
              </div>
              <div>
                <h2 className="subitem-text">
                  24.1&nbsp;O pagamento dos valores relativos &agrave; atualiza&ccedil;&atilde;o monet&aacute;ria e juros
                  morat&oacute;rios ser&aacute; feito independentemente de notifica&ccedil;&atilde;o ou interpela&ccedil;&atilde;o
                  judicial, de uma s&oacute; vez, juntamente com os demais valores do contrato.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  24.2&nbsp;O &iacute;ndice pactuado para a atualiza&ccedil;&atilde;o dos valores &eacute; o IPCA/IBGE -
                  &Iacute;ndice de Pre&ccedil;os ao Consumidor Amplo da Funda&ccedil;&atilde;o Instituto Brasileiro de Geografia e
                  Estat&iacute;stica e no caso da sua extin&ccedil;&atilde;o, o &iacute;ndice que vier a
                  substitu&iacute;-lo.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  24.3&nbsp;A atualiza&ccedil;&atilde;o ser&aacute; efetuada com base na varia&ccedil;&atilde;o positiva apurada
                  entre o &uacute;ltimo &iacute;ndice publicado antes da data de exigibilidade da obriga&ccedil;&atilde;o
                  pecuni&aacute;ria e aquele publicado imediatamente anterior &agrave; data da sua efetiva
                  liquida&ccedil;&atilde;o.&nbsp;
                </h2>
              </div>
              <div>
                <h2 className="subitem-text">
                  24.4&nbsp;Em caso de extin&ccedil;&atilde;o do &iacute;ndice definido no item anterior, ser&aacute; utilizado o
                  &iacute;ndice que vier a ser autorizado pela Superintend&ecirc;ncia de Seguros Privados (SUSEP), ficando a
                  SEGURADORA respons&aacute;vel por dar ci&ecirc;ncia a(os) SEGURADOS(AS) desta altera&ccedil;&atilde;o.&nbsp;
                </h2>
              </div>
              <div>
                <p>&nbsp;</p>
              </div>
              <div>
                <div>
                  <h1>25. CONDI&Ccedil;&Otilde;ES GERAIS&nbsp;</h1>
                </div>
                <div>
                  <h2 className="subitem-text">
                    25.1&nbsp;A aceita&ccedil;&atilde;o do seguro estar&aacute; sujeita &agrave; an&aacute;lise do risco.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    25.2&nbsp;O registro deste plano na SUSEP n&atilde;o implica, por parte da Autarquia, incentivo ou
                    recomenda&ccedil;&atilde;o a sua comercializa&ccedil;&atilde;o.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    25.3&nbsp;O(A) SEGURADO(A) poder&aacute; consultar a situa&ccedil;&atilde;o cadastral de seu corretor de
                    seguros no s&iacute;tio www.susep.gov.br, por meio do n&uacute;mero de seu registro na SUSEP, nome completo,
                    CNPJ ou&nbsp;CPF.&nbsp;
                  </h2>
                </div>
                <div>
                  <h2 className="subitem-text">
                    25.4&nbsp;O pagamento do pr&ecirc;mio ser&aacute; mensal. Em se tratando de ap&oacute;lice de seguro com
                    vig&ecirc;ncia intermitente, o pagamento ser&aacute; &agrave; vista.&nbsp;
                  </h2>
                </div>
              </div>
              <div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>26. FORO&nbsp;</h1>
                </div>
                <div>
                  <p>
                    O Foro competente para as a&ccedil;&otilde;es derivadas do presente contrato ser&aacute; o da comarca de
                    domic&iacute;lio do(a) SEGURADO(A).&nbsp;
                  </p>
                </div>
                <div>
                  <p>&nbsp;</p>
                </div>
                <div>
                  <h1>
                    27. N&Uacute;MERO DO(S) PROCESSO(S) ADMINISTRATIVO(S) DE REGISTRO JUNTO &Agrave; SUSEP DO(S) PLANO(S) DE
                    SEGURO&nbsp;
                  </h1>
                </div>
              </div>
              <div>
                <p>Este produto encontra-se registrado na SUSEP pelo N&ordm; Processo SUSEP 15414.618377/2020-87.&nbsp;</p>
              </div>
            </section>

            <footer>
              <div className="logoArea">
                <img src={myPassLogo} />
              </div>
              <div className="barArea"></div>
            </footer>
          </Printable>
        </Container>
      )}
    </>
  );
}

export default GeneralConditions;
