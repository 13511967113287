import React from 'react';
import 'moment/locale/pt-br';
import { FaUserShield } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container, Div, Title, Subtitle, Icon } from './styles';
import { error, success } from 'styles/colorProvider';
import ReactLoading from 'react-loading';

function ThirdPartyItem({ item, deleteItem, claimData, loading }) {
  return (
    <>
      <Container>
        <div className="description">
          <div className="iconCar">
            <FaUserShield size={25} color={success} />
          </div>

          <Div>
            <Title>Nome do terceiro:</Title>
            <Subtitle>{item?.name}</Subtitle>
          </Div>

          <Div>
            <Title>Contato:</Title>
            <Subtitle>{item?.phone}</Subtitle>
          </Div>

          <Div>
            <Title>Placa</Title>
            <Subtitle>{item.plate === null || item.plate === '' ? 'Terceiro sem placa' : item.plate}</Subtitle>
          </Div>

          <Icon>
            {claimData?.status === 'created' && (
              <button>
                {loading ? (
                  <ReactLoading color={error} height={20} width={20} type="spin" />
                ) : (
                  <DeleteIcon style={{ cursor: 'pointer', color: error }} onClick={deleteItem} />
                )}
              </button>
            )}
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default ThirdPartyItem;
