/* eslint-disable react-hooks/exhaustive-deps */
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import api from 'api';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Sidebar } from '../style';
import { Button } from 'components';
import { primary } from 'styles/colorProvider';
import { formatCurrency, formatZipCode } from 'react-data-formatter';
import { orderArray } from 'utils/array';
import { toast } from 'react-toastify';

const AsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ mediaQuerieMaxWidth }) => mediaQuerieMaxWidth}) {
    flex: 1 1 auto;
  }
`;

export const SideBar = ({
  type,
  confirmButtonLabel = 'Enviar proposta',
  confirmButtonCollor = '',
  confirmButtonHandleClick = () => {},
  haveVehicleData = true,
  hasChange,
  setHasChange,
  mediaQuerieMaxWidth = '824px',
  quotationData,
  quotationResponse,
  dataCoverage,
  confirmButtonIsDisable,
  proposalResponse,
  selectedProducts,
  nextStep,
  sliderHandle,
  percentCommission,
  percentDiscount,
  grievance_percent,
  discount_percent,
}) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [commission, setCommision] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [coverages, setCoverages] = useState([]);
  const [loadingQuotation, setLoadingQuotation] = useState(false);
  const hasBasicCoverage = Boolean(quotationResponse?.basicCoverage);

  const handleCalculateQuotation = async (type) => {
    setLoadingQuotation(true);
    if (!dataCoverage.id_basic_coverage) return;
    const { optional_coverages, ...rest } = dataCoverage;
    const optionals = optional_coverages ? Object?.values(optional_coverages) : [];
    const optionalsCoverages = optionals?.filter((value) => value !== undefined);
    for (const item in rest) {
      if (rest[item] === undefined) {
        delete rest[item];
      }
    }
    const data = {
      cep: quotationData.address.zip_code,
      value: quotationData.vehicle.value,
      year_model: Number(quotationData.vehicle.year_model),
      year_manufacture: Number(quotationData.vehicle.build_year),
      id_use: quotationData.vehicle.id_vehicle_use,
      brand: quotationData.vehicle.id_brand,
      fipe_code: quotationData.vehicle.fipe_code,
      from_auction: quotationData.vehicle.auction,
      ...rest,
      has_gnv: quotationData.vehicle.gnv,
      zero_km: quotationData.vehicle.zero_km,
      eletric_hybridValue: false,
      some_driver_18_25_years_old: quotationData.quotationProposalData.under25,
      optional_coverages: optionalsCoverages,
      grievance_percent: percentCommission > 0 ? (percentCommission * grievance_percent) / 100 : 0,
      discount_percent: percentDiscount > 0 ? (percentDiscount * discount_percent) / 100 : 0,
    };

    try {
      const id = quotationData.quotationProposalData.quotationProposalId;
      const resp = await api({
        method: 'post',
        url: 'quotation/new-quotation',
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      const respData = resp.data;
      setValueCoverage(respData.valueCoverage);
      setDiscount(respData.discountValue);
      setCommision(respData.commissionValue);
      const coverages = [];
      if (respData.basicCoverage?.id) {
        coverages.push(respData.basicCoverage);
      }
      if (respData.suportCoverage?.id) {
        coverages.push(respData.suportCoverage);
      }
      if (respData.replacementCarCoverage?.id) {
        coverages.push(respData.replacementCarCoverage);
      }
      if (respData.glassCoverage?.id) {
        coverages.push(respData.glassCoverage);
      }
      if (respData.optionalCoverages.length > 0) {
        coverages.push(...respData.optionalCoverages);
      }

      setCoverages(coverages);
    } catch (error) {
      const title = error?.response?.data?.error || 'Erro ao criar cotação';
      return toast.error(title, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setLoadingQuotation(false);
    return;
  };

  const getOldQuotation = async () => {
    setLoadingQuotation(true);
    try {
      const id = quotationData.quotationProposalData.quotationProposalId;
      const resp = await api({
        method: 'get',
        url: `quotation/new-quotation-proposal/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      const respData = resp.data;
      if (!respData?.valueCoverage) return;
      setValueCoverage(respData.valueCoverage);
      setDiscount(respData.discountValue);
      setCommision(respData.commissionValue);
      const coverages = [];
      if (respData.basicCoverage?.id) {
        coverages.push(respData.basicCoverage);
      }
      if (respData.suportCoverage?.id) {
        coverages.push(respData.suportCoverage);
      }
      if (respData.replacementCarCoverage?.id) {
        coverages.push(respData.replacementCarCoverage);
      }
      if (respData.glassCoverage?.id) {
        coverages.push(respData.glassCoverage);
      }
      if (respData.optionalCoverages.length > 0) {
        coverages.push(...respData.optionalCoverages);
      }

      setCoverages(coverages);
    } catch (error) {
      const title = error?.response?.data?.error || 'Erro ao criar cotação';
      return toast.error(title, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setLoadingQuotation(false);
  };

  const finalValue =
    coverages?.length > 0 ? coverages?.reduce((acumulador, objeto) => Number(acumulador) + Number(objeto?.finalPrice), 0) : 0;

  const handleSaveQuotation = async () => {
    setLoadingQuotation(true);
    if (!dataCoverage.id_basic_coverage)
      return toast.error('Selecione uma cobertura basica', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    const { optional_coverages, ...rest } = dataCoverage;
    const optionals = optional_coverages ? Object?.values(optional_coverages) : [];
    const optionalsCoverages = optionals?.filter((value) => value !== undefined);
    for (const item in rest) {
      if (rest[item] === undefined) {
        delete rest[item];
      }
    }

    const data = {
      cep: quotationData.address.zip_code,
      value: quotationData.vehicle.value,
      year_model: Number(quotationData.vehicle.year_model),
      year_manufacture: Number(quotationData.vehicle.build_year),
      id_use: quotationData.vehicle.id_vehicle_use,
      brand: quotationData.vehicle.id_brand,
      fipe_code: quotationData.vehicle.fipe_code,
      from_auction: quotationData.vehicle.auction,
      ...rest,
      has_gnv: quotationData.vehicle.gnv,
      zero_km: quotationData.vehicle.zero_km,
      eletric_hybridValue: false,
      some_driver_18_25_years_old: quotationData.quotationProposalData.under25,
      optional_coverages: optionalsCoverages,
      grievance_percent: percentCommission > 0 ? (percentCommission * grievance_percent) / 100 : 0,
      discount_percent: percentDiscount > 0 ? (percentDiscount * discount_percent) / 100 : 0,
    };
    try {
      const id = quotationData.quotationProposalData.quotationProposalId;

      const quotation = await api({
        method: 'put',
        data,
        url: `/quotation/proposal-products/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      setHasChange(false);
    } catch (error) {
      const title = error?.response?.data?.error || 'Erro ao criar cotação';
      return toast.error(title, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setLoadingQuotation(false);
  };

  const saveAndPrintQuotation = async () => {
    if (hasChange) {
      await handleSaveQuotation();
    }
    const id = quotationData.quotationProposalData.quotationProposalId;
    window.open(`/imprimir-cotacao/${id}`, '_blank');
  };

  const handleSubmit = async () => {
    try {
      setLoadingQuotation(true);

      if (hasChange) {
        await handleSaveQuotation();
        setHasChange(false);
      }

      nextStep();
    } catch (error) {
      throw error;
    } finally {
      setLoadingQuotation(false);
    }
  };

  useEffect(() => {
    handleCalculateQuotation();
  }, [dataCoverage, percentCommission, percentDiscount]);
  useEffect(() => {
    if (!hasChange) {
      getOldQuotation();
    }
  }, [hasChange]);

  return (
    <AsideContainer mediaQuerieMaxWidth={mediaQuerieMaxWidth}>
      {/* <h1>{`${hasChange}`}</h1> */}
      <Sidebar>
        {loadingQuotation && (
          <>
            <h4>Valor total</h4>
            <div style={{ width: '100%', boxSizing: 'border-box', padding: 20 }}>
              <ReactLoading color={primary} height={24} width={24} type="spin" />
            </div>
          </>
        )}

        {!loadingQuotation && coverages.length > 0 && (
          <>
            <h4>Valor total</h4>
            <p className="value">
              {formatCurrency(finalValue || 0)} <span className="value-label">/ mês</span>
            </p>
            {sliderHandle && (
              <div>
                {commission > 0 && (
                  <p>
                    {formatCurrency(commission || 0)} <span className="value-label">/ Comissão</span>
                  </p>
                )}
                {discount > 0 && (
                  <p>
                    {formatCurrency(discount || 0)} <span className="value-label">/ Desconto</span>
                  </p>
                )}
              </div>
            )}
          </>
        )}

        <h4 style={{ marginTop: 20 }}>Dados do veículo</h4>

        {type === 'quotation' && (
          <>
            <p>Modelo: {quotationData?.vehicle?.model}</p>
            <p>
              Ano modelo: {quotationData?.vehicle?.brand} {quotationData?.vehicle?.model?.split(' ')[0]}{' '}
              {quotationData?.vehicle?.year_model}
            </p>
            <p>Placa: {quotationData?.vehicle?.plate?.toUpperCase() || 'Não emplacado'}</p>
          </>
        )}
        <p>Valor FIPE: {formatCurrency(quotationData?.vehicle?.value || 0)}</p>
        <p>
          LMI{' '}
          {(
            (quotationData?.vehicle?.auction || quotationData?.vehicle?.remarked ? valueCoverage * 70 : valueCoverage * 100) /
            quotationData?.vehicle?.value
          )?.toFixed(0)}
          %:{' '}
          {quotationData?.vehicle?.auction || quotationData?.vehicle?.remarked
            ? formatCurrency(valueCoverage * 0.7 || 0)
            : formatCurrency(valueCoverage || 0)}{' '}
        </p>
        <p>Leilão: {quotationData?.vehicle?.auction ? 'Sim' : 'Não'}</p>
        <p>Chassis Remarcado/Veiculo Recuperado de Sinistro: {quotationData?.vehicle?.remarked ? 'Sim' : 'Não'}</p>
        <p>GNV: {quotationData?.vehicle?.gnv ? 'Sim' : 'Não'}</p>
        <p>Condutor entre 18 e 25 anos: {quotationData?.quotationProposalData?.under25 ? 'Sim' : 'Não'}</p>

        <h4 style={{ marginTop: 20 }}>Coberturas</h4>
        {coverages?.length <= 0 && (
          <small>
            <em>Nenhuma cobertura selecionada</em>
          </small>
        )}
        {coverages?.length > 0 &&
          coverages?.map((item) => (
            <div className="coverage-item">
              <p>{item?.name}</p>
              <p>{formatCurrency(item?.finalPrice || 0)}</p>
            </div>
          ))}

        {type === 'proposal' && (
          <>
            <h4>Endereço</h4>
            <p>CEP: {formatZipCode(quotationData?.address?.zip_code)}</p>
            <p>
              Endereço: {quotationData?.address?.street}{' '}
              {quotationData?.address?.number && <>, {quotationData?.address?.number}</>}
            </p>
            {quotationData?.address?.complement && <p>Complemento: {quotationData?.address?.complement}</p>}
            <p>
              Cidade/UF: {quotationData?.address?.city} {quotationData?.address?.state && <>/{quotationData?.address?.state}</>}
            </p>
          </>
        )}

        {type === 'quotation' && (
          <div className="button-area">
            <Button
              disabled={loadingSave || loadingQuotation}
              height="35px"
              onClick={handleSaveQuotation}
              buttonColor={confirmButtonCollor}
              buttonBoxShadowColor="transparent"
              borderRadius="10px"
            >
              {loadingSave ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Salvar'}
            </Button>

            <Button
              disabled={loadingSave || loadingQuotation}
              height="35px"
              onClick={saveAndPrintQuotation}
              buttonColor={confirmButtonCollor}
              buttonBoxShadowColor="transparent"
              borderRadius="10px"
            >
              {loadingSave ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Imprimir'}
            </Button>
            <Button
              disabled={proposalResponse?.response?.id || confirmButtonIsDisable || loadingQuotation}
              height="35px"
              width="260px"
              // onClick={confirmButtonHandleClick}
              onClick={handleSubmit}
              buttonColor={confirmButtonCollor}
              buttonBoxShadowColor="transparent"
              borderRadius="10px"
            >
              {confirmButtonLabel}
            </Button>
          </div>
        )}
        {!hasBasicCoverage && type !== 'proposal' && (
          <small style={{ marginTop: 15 }}>Por favor, selecione pelo menos uma cobertura básica para continuar.</small>
        )}
        {proposalResponse?.response?.id && <small style={{ marginTop: 15 }}>Proposta já emitida</small>}
      </Sidebar>
    </AsideContainer>
  );
};

export default SideBar;
