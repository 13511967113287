import styled from 'styled-components';

export const CardsArea = styled.div`
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 45%;
  margin: 0px 0;

  @media (max-width: 1000px) {
    width: 100%;
    background-color: transparent;
    margin: 0 0 80px 0px;
  }
`;
export const ButtonModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;

  @media (max-width: 1000px) {
    margin: 0px 0 0px 0;
  }
`;