import styled from 'styled-components';
import { primary, white, black, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }
`;

export const Historic = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 35px;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${black};
  margin: 0;
  margin-top: 2%;

  span {
    color: ${middleGray};
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
    font-size: 12px;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const Logo = styled.div`
  margin-top: 60px;
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: center;
  align-items: center;

  .image {
    width: 200px;
    flex-direction: row;
  }

  @media (max-width: 1000px) {
    width: 80%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 30px;
    margin-left: 0%;
  }
`;

export const Description = styled.h1`
  font-size: 22px;
  color: ${middleGray};
  justify-content: column;

  div {
    margin-top: 10px;
  }

  span {
    cursor: pointer;
    color: ${primary};
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const AddComplaint = styled.div`
  display: flex;
  width: 100%;
  height: 2%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 0px;

  .add {
    font-size: 30px;
    color: ${middleGray};
    cursor: pointer;
  }
`;
