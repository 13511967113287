import React from 'react';
import Avatar from '@mui/material/Avatar';
import 'moment/locale/pt-br';
import { Container, Div, Title, Subtitle, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { RiTeamLine } from 'react-icons/ri';

function KeyAccountItem({ team, isCommercial }) {
    const history = useHistory();

    const navigate = async (e) => {
        e.preventDefault();

        history.push(`/backoffice/key-account/${team.id}`);
    };

    return (
        <>
            <Container onClick={navigate}>
                <div className="description">
                    <div className="iconProfile">
                        <RiTeamLine size={20} />
                    </div>

                    <Div style={{ width: '15%' }}>
                        <Title>KeyAccount</Title>
                        <Subtitle>{team?.name}</Subtitle>
                    </Div>

                    {/* <Div style={{ width: '25%' }}>
                        <Title>Quantidade de equipes</Title>
                        <Subtitle>{team?.members}</Subtitle>
                    </Div> */}

                    <IconMobile>
                        <Avatar sx={{ width: 50, height: 50 }} style={{ marginRight: 9, cursor: 'pointer' }} />
                    </IconMobile>
                </div>
            </Container>
        </>
    );
}

export default KeyAccountItem;
