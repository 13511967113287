import React from 'react';

export default function Contract() {
  return (
    <div>
      <p>
        Estas Condições Gerais trarão algumas informações importantes para que seja possível entender os termos técnicos,
        coberturas e orientações sobre sua jornada como SEGURADO(A) junto ao nosso time. A interpretação será apenas e tão somente
        as constantes nestas cláusulas, não cabendo a utilização de qualquer outra.
      </p>
      <h2>1 . Glossário</h2>
      <p>
        ACEITAÇÃO: Aprovação do risco, apresentado na proposta de seguro efetuada pelo(a) SEGURADO(A), para a contratação do
        seguro que serve de base para a emissão da apólice.
      </p>
      <p>
        ACESSÓRIOS: São peças fixadas em caráter transitório no veículo segurado, independentemente de ser ou não original de
        fábrica, referentes a som e imagem, tais como, rádios e toca-fitas, conjugados ou não, amplificadores, equalizadores, CD
        players, auto falantes, televisores, telefones móveis e aparelhos transmissores e/ou receptores de rádio de forma
        exemplificativa. Além de itens como “mata cachorro”, bagageiros externos, suporte para escadas, etc.
      </p>
      <p>
        ACIDENTE PESSOAL DE OCUPANTE: É o evento súbito, involuntário e violento, com data caracterizada, exclusivamente provocado
        por acidente de trânsito com o veículo segurado, causador de lesão física que, por si só, e independente de toda e
        qualquer outra causa, tenha como consequência direta a morte ou invalidez permanente total ou parcial dos ocupantes do
        veículo segurado.
      </p>
      <p>
        APROPRIAÇÃO INDÉBITA: Ato ilícito que consiste em apossar-se de coisa alheia móvel de quem tem a posse, propriedade ou
        detenção sem consentimento do proprietário.
      </p>
      <p>
        APÓLICE: É o documento emitido pela SEGURADORA que discrimina o bem segurado, assim como coberturas, direitos e deveres
        contratadas pelo(a) SEGURADO(A).
      </p>
      <p>
        AVARIAS: São os danos existentes no veículo, anteriores à contratação do seguro ou não. Quando existentes antes do
        contrato de seguro, o(a) SEGURADO(A) fica ciente de que não terá cobertura posterior, mesmo mediante o aviso de sinistro.
        Salvos os casos em que se vale indenização total do veículo.
      </p>
      <p>
        AVISO DE SINISTRO: É a comunicação que deve ser feita à SEGURADORA, por telefone, Internet ou formulário próprio, pelo(a)
        SEGURADO(A), REPRESENTANTE LEGAL ou CORRETOR(A) DE SEGUROS, cuja finalidade é dar à SEGURADORA conhecimento da ocorrência
        de um sinistro.
      </p>
      <p>
        BENEFICIÁRIO(A): É a pessoa física ou jurídica que, por força de lei, tem direito à indenização. Quando não houver, na
        apólice, discriminação do(a) beneficiário(a), será observado o que dispõe o Código Civil Brasileiro
      </p>
      <p>CANCELAMENTO: Ato voluntário de dissolução antecipada da apólice por parte do(A) SEGURADO(A) ou da SEGURADORA.</p>
      <p>CARROCERIA: Estrutura acoplada na parte traseira do veículo destinada ao transporte de carga.</p>
      <p>
        CONDIÇÕES GERAIS: Conjunto das cláusulas comuns a todas as modalidades e/ou coberturas de um plano de seguro, que
        estabelecem as obrigações e os direitos das partes contratantes.
      </p>
      <p>
        CULPA: Conduta negligente, imprudente, imperita ou temerária sem propósito preconcebido de prejudicar, mas do qual advenha
        danos, lesões ou prejuízos a terceiros.
      </p>
      <p>
        DANO CORPORAL: Lesão exclusivamente física (morte, invalidez parcial ou total, lesão corporal) causada a terceiro, em
        consequência de acidente de trânsito envolvendo o veículo segurado.
      </p>
      <p>
        DANO A TERCEIRO (MATERIAL): É o tipo de dano causado exclusivamente à propriedade material de terceiro gerando perda ou
        redução no padrão de beleza, estética ou segurança do objeto.
      </p>
      <p>
        DANO MORAL: Ofensa ou violação aos princípios e valores de ordem moral, tais como liberdade, honra, sentimento, dignidade
        pessoal ou familiar. Não é suscetível de valor econômico e, sendo assim, caberá ao poder judiciário reconhecer a
        existência de tal dano e fixar o valor para sua reparação.
      </p>
      <p>DOLO: Ação praticada com a intenção de violar o direito de terceiro ou causar-lhe dano.</p>
      <p>
        ENDOSSO: Aditivo à apólice emitida pela SEGURADORA durante a vigência do contrato de seguro pelo qual as partes acordam em
        alterações de dados, modificação de condições, de objeto segurado ou ainda o transfere a outrem, retificando e atualizando
        o contrato atual.
      </p>
      <p>
        EQUIPAMENTOS: Peças instaladas em caráter permanente no veículo segurado, destinado a um fim específico, não relacionado à
        sua locomoção, mas ao aformoseamento ou lazer dos ocupantes do veículo.
      </p>
      <p>
        ESTELIONATO: Obter para si ou para outrem vantagem ilícita em prejuízo alheio, induzindo ou mantendo alguém em erro,
        mediante artifício ardil ou qualquer outro meio fraudulento.
      </p>
      <p>
        ESTIPULANTE: Pessoa que contrata o seguro por conta de terceiros, qualificando-se desta forma como representante do(a)
        SEGURADO(A) perante à SEGURADORA.
      </p>
      <p>
        FATOR DE AJUSTE: Percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contratação do seguro, que
        será aplicado sobre o valor que constar na tabela de referência de cotação para o veículo, para a estipulação do valor da
        indenização integral do veículo segurado. A aplicação do fator de ajuste pode resultar em valor superior ou inferior
        àquele cotado na tabela de referência estabelecida na proposta, de acordo com as características do veículo e seu estado
        de conservação.
      </p>
      <p>
        FRANQUIA: É a participação obrigatória do SEGURADO(A), dedutível em cada sinistro coberto pelo seguro, exceto nos casos de
        sinistros procedentes de raio e suas consequências, explosão acidental, incêndio, roubo e furto mediante aviso de sinistro
        aprovado e autorizado para indenização integral do veículo.
      </p>
      <p>FURTO: Subtração de todo ou parte do bem sem ameaça ou violência à pessoa.</p>
      <p>
        FURTO QUALIFICADO: Subtração de todo ou parte do bem sem ameaça ou violência à pessoa, com destruição ou rompimento de
        obstáculo à subtração da coisa. Mediante concurso de duas ou mais pessoas, utilizando abuso de confiança, mediante fraude,
        escalada, destreza ou com emprego de chave falsa.
      </p>
      <p>
        INCÊNDIO: Evento não premeditado que ocasione a destruição total ou parcial do bem por meio da ação de fogo. INDENIZAÇÃO
        INTEGRAL: Será caracterizada a indenização integral quando os prejuízos resultantes de um mesmo sinistro, atingirem ou
        ultrapassarem 75% do valor contratado para cobrir o veículo segurado.
      </p>
      <p>
        INVALIDEZ PERMANENTE: Perda, redução ou impotência funcional definitiva, total ou parcial, de membro ou órgão que implique
        na redução ou abolição da capacidade para o exercício pleno das atividades normais.
      </p>
      <p>
        LIMITE MÁXIMO DE INDENIZAÇÃO (LMI): Valor máximo a ser pago pela SEGURADORA, contratada para cada cobertura, escolhida
        pelo SEGURADO(A) e expresso na apólice.
      </p>
      <p>
        LIQUIDAÇÃO DE SINISTROS: Processo pelo qual a SEGURADORA paga, (a)o SEGURADO(A) ou BENEFICIÁRIO(A), os prejuízos que ele
        sofreu em consequência de um dos riscos cobertos, baseada no relatório de regulação de sinistro.
      </p>
      <p>
        MÁ-FÉ: Para efeitos deste contrato, será considerada má-fé o fornecimento intencional de informações inexatas, incompletas
        ou omissas, mesmo que parcialmente, pelo(a) SEGURADO(A), seu representante ou seu corretor de seguros.
      </p>
      <p>OCUPANTE: Pessoa que se encontra no interior do veículo segurado, no momento do acidente, inclusive o condutor.</p>
      <p>
        OFICINAS REFERENCIADAS: Oficinas particulares e Concessionárias que por meio de contrato prestam serviços à SEGURADORA.
      </p>
      <p>
        PERDA PARCIAL: Caracteriza-se a perda parcial quando o custo da reparação do bem segurado não atingir 75% do valor
        contratado para o veículo.
      </p>
      <p>
        PRÊMIO: Valor pago pelo(a) SEGURADO(A) ou ESTIPULANTE à SEGURADORA, atribuindo a SEGURADORA o risco ao qual o(a)
        SEGURADO(A) está exposto(a) e nos limites do contrato ora firmado entre SEGURADO(A) E SEGURADORA. PRESCRIÇÃO: Perda do
        direito de ação para reclamar os direitos ou obrigações em virtude do decurso do tempo previsto em lei e/ou Condições
        Gerais da SEGURADORA.
      </p>
      <p>
        PRINCIPAL CONDUTOR(A): Pessoa legalmente habilitada e que utiliza o veículo segurado no mínimo 03 (três) dias da semana.
        Havendo mais de um(a) condutor(a) nesta condição, deverão ser utilizados os dados da pessoa mais jovem entre eles.
      </p>
      <p>
        PROPONENTE: Pessoa que pretende fazer um seguro e que já firmou, para esse fim, a proposta de seguro. PROPOSTA DE SEGURO:
        Instrumento que formaliza o interesse do proponente em efetuar o seguro, na qual constam as informações necessárias para a
        precificação do risco e para a emissão da apólice.
      </p>
      <p>
        QUESTIONÁRIO DE AVALIAÇÃO DO RISCO: Conjunto de perguntas sobre o(s) condutor(es) e seus hábitos com relação à utilização
        do veículo, que constam da proposta de seguro, com objetivo de precificar adequadamente o risco, motivo pelo qual deve ser
        respondido pelo(a) SEGURADO(A), de modo claro e preciso.
      </p>
      <p>
        REGULAÇÃO DE SINISTRO: Exame das causas, circunstâncias e consequências do sinistro para se concluir sobre a cobertura
        securitária.
      </p>
      <p>
        RESPONSABILIDADE CIVIL: Responsabilidade do(a) SEGURADO(A) por danos causados a terceiros, decorrentes de acidente com o
        veículo segurado ou por sua carga transportada, decorrente de negligência, imprudência ou imperícia.
      </p>
      <p>
        RESSARCIMENTO: Direito que a SEGURADORA possui de recuperar do terceiro responsável pelo sinistro, ou de sua SEGURADORA,
        quando for o caso, o valor pago a título de indenização.
      </p>
      <p>
        RISCO: Evento incerto e aleatório (data incerta), possível, concreto, lícito e fortuito (que independe da vontade das
        partes contratantes) e contra o qual é feito o seguro.
      </p>
      <p>
        ROUBO: Subtração do todo ou parte do bem com ameaça grave ou violência à pessoa. SALVADO: Objetos que se consegue resgatar
        de um sinistro e que ainda possui valor econômico.
      </p>
      <p>SEGURADO(A): Pessoa física ou jurídica que manifesta interesse e contrata um seguro em seu benefício ou de terceiros.</p>
      <p>
        SEGURADORA: Pessoa jurídica legalmente constituída, autorizada pela SUSEP a funcionar no Brasil, e que recebendo o prêmio,
        assume o risco de indenizar o(a) SEGURADO(A) ou BENEFICIÁRIO(A), em caso de ocorrência de sinistro amparado pelo contrato
        de seguro.
      </p>
      <p>
        SINISTRO: Ocorrência de acontecimentos involuntários e casuais previsto no contrato de seguro, para o qual foi contratada
        a cobertura.
      </p>
      <p>
        SUB-ROGAÇÃO: Opera-se com a transferência de direitos e obrigações do(a) SEGURADO(A) para a SEGURADORA em virtude do
        pagamento da indenização.
      </p>
      <p>
        SUSEP: Superintendência de Seguros Privados. Autarquia Federal responsável pela regulação e fiscalização do mercado de
        seguros.
      </p>
      <p>
        TERCEIRO: Pessoa culpada ou prejudicada no acidente, exceto os ocupantes do veículo segurado, o(a) próprio(a) SEGURADO(A)
        ou seus ascendentes, descendentes, cônjuge, irmãos, pessoas que com ele residam ou que dele(a) dependam economicamente,
        bem como os sócios, diretores, administradores e controladores da pessoa jurídica.
      </p>
      <p>
        VIGÊNCIA: Prazo que determina o início e o fim da validade das garantias contratadas entre SEGURADO(A) e SEGURADORA,
        podendo ser renovada, se for de interesse das partes contratantes.
      </p>
      <p>
        VISTORIA PRÉVIA: Inspeção realizada pela SEGURADORA, antes da aceitação do risco, para verificação das características e
        condições do veículo a ser segurado.
      </p>
      <p>
        VISTORIA DE SINISTRO: Inspeção efetuada pela SEGURADORA, em caso de sinistro no veículo segurado, para verificar os danos
        ou prejuízos por ele sofridos, podendo ser online ou presencial.
      </p>
      <h2>2 . Objetivo do seguro</h2>
      <p>
        O contrato de seguro tem por objetivo garantir a(o) SEGURADO(A) ou BENEFICIÁRIO(A), até o LMI - Limite máximo de
        indenização contratado para cada cobertura, o pagamento de indenização em consequência direta da ocorrência dos riscos
        cobertos e expressamente convencionados nas coberturas e cláusulas contratadas, bem como as despesas com socorro e
        salvamento do veículo segurado, respeitados os riscos excluídos do contrato de seguro.
      </p>
      <h2>3 . Abrangência</h2>
      <p>
        A SEGURADORA tem como objeto atender veículos que estejam dentro do território nacional brasileiro dos tipos: carros,
        motos e utilitários pequenos a partir de 30 (trinta) anos de fabricação do ano vigente, de uso particular único motorista,
        familiar, comercial e motorista de aplicativo.
      </p>
      <h2>4 . Riscos cobertos e excluídos</h2>
      <p>
        As coberturas contratadas são aquelas discriminadas na apólice de seguro, respeitadas as regras estabelecidas nestas
        condições gerais e nas especificações da apólice. O(A) SEGURADO(A), mediante pagamento de prêmio, deverá contratar pelo
        menos uma das coberturas básicas e poderá contratar coberturas adicionais (opcionais).
      </p>
      <h4>Coberturas Básicas</h4>
      <p>•Colisão, Fenômenos Naturais e Incêndio; •Roubo e Furto.</p>
      <h4>Coberturas Adicionais</h4>
      <p>• Assistência 24 (vinte e quatro) horas;</p>
      <p>• APP – Acidente pessoal por passageiro:</p>
      <p>• APP – Acidente pessoal por passageiro:</p>
      <p>• Carro reserva;</p>
      <p>
        • Cobertura para vidros: Faróis, lanternas e vidros (dianteiro, traseiro, laterais e lentes retrovisores); • Danos a
        terceiros RCF – Danos Materiais (Carros);
      </p>
      <p>• Danos a terceiros RCF – Danos Materiais (Motos);</p>
      <p>• Franquia Reduzida.</p>
      <h3>4 . 1 Coberturas Básicas</h3>
      <h4>4 . 1 . 1 . Colisão, Fenômenos Naturais e Incêndio</h4>
      <p>
        Contratando a cobertura de colisão, fenômenos naturais ou incêndio, o(a) SEGURADO(A) terá direito a uma indenização ou
        reparo direcionado à rede referenciada, em virtude de prejuízos ocasionados ao veículo segurado provenientes de:
      </p>
      <p>a) Colisão ou capotagem acidental;</p>
      <p>b) Queda acidental em precipícios ou de pontes;</p>
      <p>
        c) Queda acidental de qualquer agente externo sobre o veículo segurado, desde que tal agente não faça parte integrante do
        veículo e não esteja nele afixado;
      </p>
      <p>
        d) Queda sobre o veículo segurado da carga por ele transportada, em decorrência de acidente de trânsito e não por simples
        freada;
      </p>
      <p>
        e) Acidente durante seu transporte por meio apropriado, como, exemplificativamente, cegonha ou guincho indicado pela
        SEGURADORA;
      </p>
      <p>
        f) Atos danosos praticados por terceiros, exceto os constantes no item “Prejuízos não indenizáveis para todas as
        coberturas”;
      </p>
      <p>
        g) Despesas necessárias ao socorro e salvamento do veículo em consequência de um dos riscos cobertos de intervenção humana
        ou não (raios, enchentes, dentre outros);
      </p>
      <p>
        h) Despesas referentes a danos materiais comprovadamente causados pelo(a) SEGURADO(A) e/ou por terceiros na tentativa de
        evitar o sinistro, minorar o dano ou salvar a coisa;
      </p>
      <p>i) Incêndio ou explosão acidental não intencionada.</p>
      <h4>4.1.2. Roubo ou Furto</h4>
      <p>
        Contratando a cobertura de roubo e furto, o(a) SEGURADO(A) terá direito a uma indenização/reparação em virtude de
        prejuízos ocasionados ao veículo segurado provenientes de:
      </p>
      <p>a) Roubo ou furto total do veículo segurado;</p>
      <p>
        b) Danos sofridos pelo veículo segurado durante o tempo em que, como consequência de roubo ou furto esteve em poder de
        terceiros, deduzida da indenização a franquia estipulada na apólice para o veículo quando for de reparação parcial;
      </p>
      <p>
        c) Despesas necessárias ao socorro e salvamento do veículo em consequência de um dos riscos cobertos e decorrentes de
        intervenções humanas;
      </p>
      <p>
        d) Despesas referentes a danos materiais comprovadamente causados pelo(a) SEGURADO(A) e/ou por terceiros na tentativa de
        evitar o sinistro, minorar o dano ou salvar a coisa;
      </p>
      <p>
        e) Roubo ou furto total exclusivo do rádio, toca-fitas, toca cd’s, tacógrafo e kit gás, desde que tais itens façam parte
        do modelo original do veículo, descontado do valor da indenização a franquia estipulada na apólice para o veículo quando
        for de reparação parcial.
      </p>
      <h3>4 . 2 Coberturas Adicionais</h3>
      <h4>4 . 2 . 1 . Assistência 24 horas</h4>
      <p>
        A Assistência veicular é um serviço complementar ao seguro e sua prestação não implica, para qualquer efeito, no
        reconhecimento, pela SEGURADORA, de cobertura em relação aos itens descritos na proposta de cotação, termo de aceite ou na
        apólice de seguro, que se rege por suas próprias condições contratuais entre SEGURADO(A) e SEGURADORA.
      </p>
      <p>
        a) Prestação de serviço de assistência 24 (vinte e quatro) horas a(o) SEGURADO(A) em caráter emergencial mediante a
        impossibilidade de locomoção do veículo em razão de acidentes (quando cobertos pelo plano contratado), panes, roubos ou
        furtos e fenômenos naturais, devendo ser solicitado através dos meios oficiais de comunicação. Não são considerados
        serviços emergenciais eventos que se referem à manutenção do veículo, que não esteja amparado no termo de aceite, proposta
        de contratação do seguro, apólice de seguro ou casos em que o cliente não realize o acionamento no mesmo dia/hora do fato
        ocorrido;
      </p>
      <p>
        b) A inadimplência do(a) SEGURADO(A), de acordo com as regras destas Condições Gerais, acarretará na inativação da
        prestação de serviços da Assistência 24 horas;
      </p>
      <p>
        c) Apenas em caso de necessidade e explícita autorização por parte da SEGURADORA, poderá a SEGURADORA ou empresa
        terceirizada, autorizar o acionamento particular por parte do(a) SEGURADO(A), e ressarci-lo(a) do desembolso para tal fim,
        respeitando o procedimento de reembolso;
      </p>
      <p>
        d) Não serão indenizados reboques que excedam a quilometragem constante no termo de aceite, na apólice de seguro ou que
        desrespeitem as normas das Condições Gerais;
      </p>
      <p>
        e) O(A) SEGURADO(A) deverá contatar a central de assistência no momento do evento e fornecer as informações solicitadas de
        forma clara e completa para a devida identificação do usuário e do veículo assistido, bem como para a confirmação de sua
        inclusão do cadastro de análise das condições da Assistência contratada;
      </p>
      <p>
        f) No caso de cancelamento do serviço após a liberação do prestador, seja qual for o motivo do cancelamento, o serviço
        será considerado como concedido, prestado e concluído, não tendo o cliente o direito a uma nova solicitação para o mesmo
        evento dentro do período corrente de cobertura da apólice.
      </p>
      SERVIÇOS AO VEÍCULO AutomóAvuetlomóvel Moto Assistência ao veículo (Reboque) 1 Sim Sim Chaveiro Sim Não Pane Seca Sim Sim
      Troca de Pneu Sim Não Recarga de Bateria Sim Sim
      <p>
        As garantias e os serviços referentes a essas cláusulas devem ser solicitados à Central de Assistência 24 horas. Somente
        os prestadores da rede referenciada poderão executar tais serviços. Em nenhuma hipótese, serão reembolsados gastos
        relativos a serviços executados por prestadores não referenciados. Para essas cláusulas, não há limites de acionamentos.
      </p>
      <h4>4 . 2 . 2 . APP (Acidente Pessoal Por Passageiro)</h4>
      <p>
        Contratando a cobertura de Acidente Pessoal por Passageiro, o(a) SEGURADO(A) terá direito a uma indenização em virtude de
        prejuízos ocasionados ao veículo segurado provenientes de evento de sinistro comunicado e autorizado pela SEGURADORA.
      </p>
      <p>
        a) Cobertura no valor de até R$ 10.000,00 (dez mil reais) por passageiro do veículo integrante da relação com a
        SEGURADORA, em caso de morte;
      </p>
      <p>b) O APP é limitado ao valor de até R$ 50.000,00 (cinquenta mil reais) por veículo;</p>
      <p>
        c) O ressarcimento de despesas médicas/hospitalares é de até R$ 2.000,00 (dois mil reais) por passageiro, limitado ao
        valor de R$ 10.000,00 (dez mil reais) por veículo, exceto passageiros de motocicleta tendo em vista que os mesmos não
        fazem jus a referida cobertura;
      </p>
      <p>d) Os ressarcimentos serão avaliados mediante comprovação documentada a ser exigida.</p>
      <p>
        1 Assistência ao veículo (Reboque): O limite de quilometragem constará na apólice, poderá ser acionada mediante
        contratação e cobertura para os casos de Colisão, Fenômenos Naturais, Incêndio, Roubo, Furto e pane mecânica/elétrica em
        que o veículo não esteja em condições de rodagem.
      </p>
      <h3>4.2.3. Carro Reserva</h3>
      <p>
        Contratando a cobertura de Carro Reserva, o(a) SEGURADO(A) terá direito a uma utilização em virtude de prejuízos
        ocasionados ao veículo segurado provenientes de evento de sinistro comunicado e autorizado pela SEGURADORA.
      </p>
      <p>
        a) Poderá ser solicitado respeitando a quantidade de dias devidamente contratados pelo(a) SEGURADO(A) e expresso na
        apólice de seguro, em casos de roubo, furto, colisão, incêndio ou fenômenos naturais indenizados pela SEGURADORA, mediante
        aprovação do sinistro e apresentação de boletim de ocorrência, respeitando-se todas as regras determinadas pelas locadoras
        indicadas, em conformidade com as condições gerais da SEGURADORA.
      </p>
      <p>b) Sendo necessário ter no mínimo:</p>
      <p>• 2 (dois) anos de habilitação na categoria “B” ou acima;</p>
      <p>• 21 (vinte e um) anos de idade;</p>
      <p>• Limite disponível em cartão de crédito no valor mínimo de R$ 1.000,00.</p>
      <p>
        Para todos os casos, qualquer outro critério a ser exigido pela locadora deverá ser respeitado pelo(a) SEGURADO(A), além
        disto, fica excluída qualquer responsabilidade por parte da SEGURADORA para itens como: motorista adicional, pagamento de
        seguro do veículo locado, dentre outros.
      </p>
      <p>
        c) O veículo será de categoria popular básico, contendo ar-condicionado e direção hidráulica para o máximo de 5 ocupantes.
      </p>
      <h3>4.2.4. Danos a Terceiros</h3>
      <p>
        Serviço disponível mediante contratação para cobertura material a terceiros envolvidos em evento de sinistro, que decorram
        de evento comunicado e autorizado pela SEGURADORA.
      </p>
      <p>
        a) É limitado ao valor máximo de indenização (LMI) de acordo com o contratado pelo SEGURADO e expresso na apólice do
        SEGURADO;
      </p>
      <p>
        b) Esta cobertura adicional apenas se aplica em caso de aprovação do sinistro que o(a) SEGURADO(A) tenha dado causa, não
        sendo aplicável para casos em que o terceiro envolvido seja causador.
      </p>
      <p>
        c) Referido valor diz respeito apenas para danos materiais, não sendo possível reparação por danos morais, estéticos,
        médicos, lucros cessantes ou quaisquer outras finalidades, em conformidade com as condições gerais da SEGURADORA.
      </p>
      <h3>4 . 2 . 5 . Cobertura para Vidros: faróis, lanternas e vidros</h3>
      <p>
        Serviço disponível mediante contratação para cobertura de vidros e espelhos retrovisores em evento de sinistro que
        decorram de evento comunicado e autorizado pela SEGURADORA.
      </p>
      <p>
        a) Cobertura dos vidros laterais, traseiro, para-brisa, faróis, lanternas e vidros (lentes) dos retrovisores, os limites
        máximos de indenizações constarão na apólice;
      </p>
      <p>b) Para esta cobertura o limite de acionamentos será de um evento por mês;</p>
      <p>c) Não serão autorizadas trocas por má uso/manutenção ou decurso de uso e conservação natural do veículo segurado.</p>
      <h2>5 . Coberturas Adicionais</h2>
      <h3>5.1 O(A) SEGURADO(A) poderá efetuar o pagamento do prêmio à SEGURADORA por meio das seguintes formas:</h3>
      <p>• Boleto;</p>
      <p>• Cartão de Crédito;</p>
      <p>• Cartão de Débito;</p>
      <p>• Cartão de Crédito Recorrente; • PIX.</p>
      <h3>
        5 . 2 Endosso e cancelamento dentro de 7 (sete) dias corridos, terá restituição integral do pagamento. 5 . 3 Endosso e
        cancelamento após 7 (sete) dias corridos, terá sua validade após a próxima subscrição.
      </h3>
      <h3>
        5 . 4 Renovação se dá por meio de boleto mensal devidamente pago até o limite máximo de 3 (três) dias corridos do
        vencimento original, no modelo de re-subscrição automática.
      </h3>
      <h2>6 . Vigência da apólice, forma e periodicidade do pagamento dos prêmios</h2>
      <h3>
        6.1 As renovações de apólices serão automáticas. A cada encerramento de vigência, o(a) SEGURADO(A) receberá uma proposta
        para emissão de apólice com início de vigência imediato ao encerramento do período anterior.
      </h3>
      <h3>
        6.2 Quando a data de vencimento do prêmio coincidir em dia em que não haja expediente bancário, o pagamento poderá ser
        efetuado no primeiro dia útil subsequente.
      </h3>
      <h3>
        6 . 3 Caso o(a) SEGURADO(A) não realize o pagamento do prêmio mensal em até 3 (três) corridos do vencimento original,
        determina a suspensão e cancelamento automático da cobertura de seguro, independentemente de qualquer interpelação
        judicial ou extrajudicial. E para todos os efeitos, não terá direito a indenização/reparação total ou parcial do veículo
        caso o evento ocorra após os 3 dias corridos deste período.
      </h3>
      <h3>
        6 . 4 O pagamento após os 3 dias corridos da data de vencimento e suspensão do seguro, não gera direito a nenhuma forma de
        cobertura por parte da SEGURADORA em eventual sinistro sofrido no período de inadimplência do(a) SEGURADO(A).
      </h3>
      <h3>
        6 . 5 O(A) SEGURADO(A) que ficar inadimplente poderá ser cobrado(a) judicialmente ou ter títulos protestados, cujas
        despesas para realização de tais procedimentos ocorrerão inteiramente às expensas do(a) SEGURADO(A).+
      </h3>
      <h3>
        6 . 6 Realizado a quitação dos débitos de prêmio e parcelas ajustadas, acrescidas dos encargos previstos no contrato
        atual. É necessário realização de nova vistoria para nova proposta de seguro e, mediante autorização da SEGURADORA,
        emissão de nova apólice.
      </h3>
      <h3>
        6 . 7 Nos casos em que o prêmio tenha sido liquidado à vista, mediante financiamento à(o) SEGURADO(A) por instituição
        financeira, fica vedado o cancelamento do seguro, caso ocorra inadimplência do(a) SEGURADO(A) junto à instituição
        financiadora.
      </h3>
      <h3>
        6 . 8 Na ocorrência de sinistro indenizável que implique no cancelamento do seguro, as parcelas vincendas do prêmio
        deverão ser deduzidas do valor a ser indenizado, com a devida exclusão dos juros embutidos na(s) parcela(s).
      </h3>
      <h2>
        7 . Número do(s) processo(s) administrativo(s) de registro junto à SUSEP do(s) plano(s) de seguro Este produto encontra-se
        registrado na SUSEP pelo No Processo SUSEP 15414.618377/2020-87.
      </h2>
      <h2>8 . Franquias</h2>
      <h3>
        8.1 Franquia padrão: Será de 10% (dez por cento) do valor FIPE do veículo segurado, data base do levantamento do valor
        FIPE será a data do evento:
      </h3>
      <p>COBERTURAS Franquia</p>
      <p>Indenização total Isento do pagamento de franquia</p>
      <h3>
        8 . 2 Franquia reduzida: Terá desconto sobre a franquia do valor obrigatório que é de responsabilidade do(a) SEGURADO(A),
        para qualquer tipo de indenização parcial que for feita pela Seguradora. A depender do pacote contratado pelo(a)
        SEGURADO(A) e disponibilidade por parte da SEGURADORA para determinados veículos, poderá ser 7% (sete porcento) ou 4%
        (quatro porcento) do valor FIPE do veículo segurado, data base do levantamento do valor FIPE será a data do evento:
      </h3>
      <p>COBERTURAS Franquia</p>
      <p>Indenização total Isento do pagamento de franquia</p>
      <h2>9 . Procedimentos para liquidação de sinistros</h2>
      <h3>
        9.1 O Segurado deverá comunicar a SEGURADORA através dosmeios oficiais de atendimento 08009423290, ou e-mail:
        sinistro@splitrisk.com.br o sinistro ocorrido, tão logo tenha dele conhecimento e encaminhar a documentação indicada no
        item 11, no prazo máximo de 48 horas a contar da ciência da SEGURADORA do fato ocorrido. A SEGURADORA pagará a indenização
        no prazo máximo de 30 (trinta) dias corridos após a entrega de todos os documentos necessários e solicitados a(o)
        SEGURADO(A), beneficiário(a) ou seu(a) representante legal.
      </h3>
      <h3>
        9.2 Nocasodedúvidafundadaejustificável,éfacultadaàSEGURADORAasolicitaçãodeoutrosdocumentos complementares. Neste caso, a
        contagem do prazo será suspensa a partir do momento em que forem solicitados os novos documentos voltando a correr a
        partir do dia útil posterior àquele em que forem entregues os respectivos documentos.
      </h3>
      <h3>
        9 . 3 No caso de solicitação de documentação e/ou informação complementar na forma prevista no caput deste artigo, o prazo
        de que trata o parágrafo anterior será suspenso, reiniciando sua contagem a partir do dia útil subsequente àquele em que
        forem completamente atendidas as exigências.
      </h3>
      <h3>
        9.4 Onãopagamentodaindenizaçãonoprazoprevistonoitem9.Adesteartigo,implicaráaplicaçãodejuros de 2% a.m. e mora de 1% a.m. á
        partir desta data, sem prejuízo de sua atualização, nos termos da legislação específica; na cláusula correspondente à
        liquidação de sinistros, o contrato de seguro poderá admitir, para fins de indenização, mediante acordo entre as partes,
        as hipóteses de pagamento em dinheiro, (a vista ou parcelado), reposição ou reparo da coisa.
      </h3>
      <h2>1 0 . Critérios de suspensão das coberturas, se previstos nas condições gerais</h2>
      <h3>1 0 . 1 O(A) SEGURADO(A) agravar intencionalmente o risco objeto do seguro.</h3>
      <h3>
        1 0 . 2 O(A) SEGURADO(A) por qualquer meio procurar obter benefícios ilícitos do seguro a que se refere esta apólice.
      </h3>
      <h3>
        10.3 O veículo e/ou seus documentos ou registros não forem verdadeiros ou tiverem sido por qualquer forma adulterados.
      </h3>
      <h3>1 0 . 4 For verificada a venda do veículo segurado sem comunicação à Seguradora.</h3>
      <h3>
        10.5 O(A) SEGURADO(A) deixar de comunicar quaisquer alterações que possam influir no enquadramento tarifário do seguro.
      </h3>
      <h3>
        1 0 . 6 O(A) SEGURADO(A) deixar de avisar às autoridades policiais e não proceder ao registro do Boletim de Ocorrência, em
        caso de desaparecimento, roubo, ou furto total ou parcial, do veículo segurado.
      </h3>
      <h3>
        1 0 . 7 O(A) SEGURADO(A) deixar de avisar o sinistro à SEGURADORA, pelo meio mais rápido de que dispuser. 10.8 O(A)
        SEGURADO(A) dirigir o veículo segurado sem habilitação legal ou permitir que o mesmo seja dirigido por pessoa não
        legalmente habilitada, considerada para tal fim a habilitação daquela categoria.
      </h3>
      <h3>
        10.9 O veículo estiver sendo conduzido por pessoa que esteja sob a ação do álcool, de drogas ou entorpecentes de uso
        fortuito, ocasional ou habitual, quando da ocorrência do sinistro.
      </h3>
      <h3>
        1 0 . 1 0 O(A) SEGURADO(A) deixar de comunicar à SEGURADORA, logo que saiba, todo incidente suscetível de agravar o risco
        coberto, se provado que o(a) SEGURADO(A) silenciou de má-fé.
      </h3>
      <h3>
        1 0 . 1 1 O(A) SEGURADO, beneficiário(a), seu(a) representante legal ou seu(a) corretor(a) de seguros provocar ou simular
        sinistro.
      </h3>
      <h3>
        10.12 O veículo não tiver instalado um rastreador, bloqueador ou localizador e em funcionamento, quando a existência de
        tal equipamento tiver sido exigida para a aceitação do risco, bem como arcar com o valor da mensalidade do equipamento,
        quando for o caso.
      </h3>
      <h3>
        1 0 . 1 3 O veículo estiver com suas características originais alteradas, exemplo: rebaixado, turbinado, tunning
        (transformação ou otimização das características do veículo, utilizada como estética), etc.
      </h3>
      <h3>10.14 ForemrealizadosconsertossempréviaautorizaçãodaSEGURADORAquandodecorrentedesinistro indenizável.</h3>
      <h3>10.15 O(A) SEGURADO(A) deixar de tomar as providências imediatas para minorar as consequências do sinistro.</h3>
      <h3>1 0 . 1 6 O(A) SEGURADO(A) deixar de manter o veículo em bom estado de conservação e segurança e apto a trafegar.</h3>
      <h3>
        10.17 O(A)SEGURADO(A),seu(a)representanteouseu(a)corretor(a)desegurosfizerdeclaraçõesinexatas ou omitir circunstâncias que
        possam influir na proposta ou no valor do prêmio, ficará prejudicado o direito à indenização, além de estar o(a)
        SEGURADO(A) obrigado(a) ao pagamento do prêmio vencido.
      </h3>
      <h2>
        1 1 . Documentação necessária para o recebimento da indenização para cada cobertura contratada Documentos mínimos exigidos
        a fim de que seja dado continuidade ao processo de análise do reparo parcial e/ou indenização total para os veículos
        segurados ou cobertos por estas condições, dentro dos critérios de cobertura emitidos na apólice de seguro (terceiro
        envolvido por exemplo). Quaisquer outros documentos complementares poderão ser solicitados a(o) SEGURADO(A) mediante a
        necessidade para cada tipo de evento a ser analisado. A saber, os documentos iniciais serão:
      </h2>
      <p>• Aviso de Sinistro (realizado junto à SEGURADORA);</p>
      <p>• Boletim de Ocorrência com informações exatas e detalhadas de todo o ocorrido e envolvidos;</p>
      <p>• Boletim de Atendimento Médico (quando houver atendimento no local ou posterior decorrente do sinistro);</p>
      <p>• Fotos do evento (local, danos nos veículos com identificação das placas, estado dos pneus, hodômetro, etc);</p>
      <p>• Laudo pericial (aplicável aos casos de média e grande monta);</p>
      <p>• Cópia CNH;</p>
      <p>• Cópia do CRLV/DUT;</p>
      <p>• DUT original (aplicável nos casos de indenização);</p>
      <p>• Chave do veículo (aplicável nos casos de indenização);</p>
      <p>• Cópia do CPF ou CNPJ;</p>
      <p>
        • Procuração de fé publica assinada e com firma reconhecida em cartório por autenticidade (aplicável nos casos de
        indenização);
      </p>
      <p>
        • Baixa de alienação com firma reconhecida (aplicável nos casos de indenização para veículos perda total não passíveis de
        recuperação);
      </p>
      <p>
        • Declaração de responsabilidade pelas multas até a data de transferência do veículo (aplicável nos casos de indenização);
      </p>
      <p>• Apólice ou endosso quitados.</p>
      <h2>1 2 . Prazo máximo para pagamento da indenização pela sociedade seguradora participante do Sandbox Regulatório</h2>
      <p>A liquidação de qualquer sinistro coberto por esta apólice processar-se-á consoante às seguintes regras:</p>
      <p>
        a) Tratando-se de roubo total ou furto total do veículo segurado, decorridos 30 (trinta) dias corridos do aviso às
        autoridades policiais, não tendo sido o mesmo apreendido nem localizado oficialmente, mediante certidão comprobatória de
        furto atualizada e os documentos de comprovação solicitados pelo departamento de sinistro, respeitando o item 9. A deste
        artigo: A SEGURADORA pagará a indenização no prazo máximo de 30 (trinta) dias corridos após a entrega de todos os
        documentos necessários e solicitados a(o) SEGURADO(A), beneficiário(a) ou seu(a) representante legal; e 9.D: [...] para
        fins de indenização, mediante acordo entre as partes, as hipóteses de pagamento em dinheiro, (a vista ou parcelado),
        reposição ou reparo da coisa;
      </p>
      <p>
        b) No caso de indenização integral, inclusive no caso de roubo total ou furto total, sem prejuízo das demais obrigações
        estipuladas nesta apólice, qualquer indenização somente será paga mediante apresentação dos documentos que comprovem os
        direitos de propriedade, livre e desembaraçada de qualquer ônus, do(a) SEGURADO(A) sobre o veículo sinistrado e, no caso
        de veículos importados, a prova de liberação alfandegária definitiva;
      </p>
      <p>
        c) Ocorrendo a indenização integral do veículo sinistrado, o pagamento da indenização será em moeda corrente nacional, e
        observará os termos da forma de contratação do seguro, bem como o acordo entre as partes;
      </p>
      <p>
        d) Não obstante o disposto, para os veículos novos “zero quilômetro”, ocorrendo a indenização integral, a quantia a ser
        paga corresponderá ao valor de veículo novo “zero quilômetro” de idênticas características, na data da liquidação do
        sinistro, apurada pela tabela de referência do veículo e desde que satisfaça todas as seguintes condições:
      </p>
      <p>
        d.I. A cobertura do seguro tenha se iniciado no prazo máximo de 72 (setenta e duas) horas contadas da data da fatura/Nota
        Fiscal de compra do veículo;
      </p>
      <p>d.II. Seja o primeiro sinistro com o veículo segurado;</p>
      <p>
        d.III. Na impossibilidade de apuração do valor do veículo, deverá ser utilizado para fins de cotação um veículo de
        características e valor semelhante ao indicado na apólice;
      </p>
      <p>
        d.IV. Em caso de ocorrência da extinção ou interrupção da publicação da tabela adotada à época da contratação do seguro,
        será utilizada uma segunda tabela de referência, estabelecida na proposta de seguro, denominada tabela substituta;
      </p>
      <p>
        d.V. No caso de alienação fiduciária, o documento de transferência de propriedade do veículo deverá ser devidamente
        preenchido com os dados de seu proprietário e da SEGURADORA. A SEGURADORA pagará diretamente à Instituição Financeira o
        valor do saldo devedor. Havendo valor remanescente apurado entre o valor da indenização e o valor quitado, este será pago
        ao proprietário do veículo. Para os casos em que o valor a ser indenizado não cubra as custas da quitação total do
        veículo, a indenização ficará suspensa até efetivo pagamento do SEGURADO do valor da diferença à SEGURADORA.
      </p>
      <h2>1 3 . Prejuízos não indenizáveis (riscos excluídos) para todas as coberturas</h2>
      <p>A seguradora não indenizará os prejuízos, as perdas e os danos decorrentes:</p>
      <p>
        a) De lucros cessantes em virtude da paralisação do veículo segurado mesmo quando resultante de um dos riscos cobertos,
        exceto quando contratada cobertura específica mediante pagamento de prêmio adicional;
      </p>
      <p>
        b) Da participação do veículo segurado em práticas esportivas bem como em competições, apostas e provas de velocidade,
        legalmente autorizadas ou não, exceto para a cobertura de Acidentes Pessoais com Ocupantes do veículo segurado;
      </p>
      <p>c) Da prestação de serviços especializados de natureza técnico profissional a que se destine o veículo;</p>
      <p>d) De operações de carga e descarga;</p>
      <p>e) Da superlotação do veículo, quer de pessoas ou da carga transportada;</p>
      <p>
        f) Do travamento do motor por motivo de falta de óleo, água ou itens de manutenção preventiva do veículo (correia dentada,
        etc);
      </p>
      <p>
        g) De atos ou operações de guerra, declarada ou não, química ou bacteriológica, civil ou guerrilha, hostilidades,
        revolução, agitação, motim, revolta, sedição, sublevação ou outras perturbações da ordem pública e delas decorrentes;
      </p>
      <p>
        h) De roubo e/ou furto exclusivo da parte removível de toca-fitas ou similares com frente removível, como também do
        controle remoto e do DVD fixados ou não em caráter permanente no veículo, originais de fábrica ou não;
      </p>
      <p>i) De atos de hostilidade ou de guerra, rebelião, insurreição, revolução, confisco ou nacionalização;</p>
      <p>j) De destruição, requisição ou apreensão por autoridade de fato ou de direito, civil ou militar;</p>
      <p>
        k) De prejuízos decorrentes de quaisquer perturbações de ordem pública, tais como, exemplificativamente: tumultos, motins,
        greve de empregados e paralisação de atividade provocada pelo empregador (Lockout);
      </p>
      <p>l) Da submersão total ou parcial do veículo em água salgada;</p>
      <p>
        m) Do roubo, furto ou danos materiais praticados com dolo ou culpa grave equiparável ao dolo, cometido por pessoas que
        dependam do(a) SEGURADO(A) ou do(a) condutor(a), assim como seus sócios, cônjuge, ascendentes ou descendentes por
        consanguinidade, afinidade, adoção, bem como a quaisquer parentes ou pessoas que com ele(a) residam e/ou dependam
        economicamente;
      </p>
      <p>
        n) De despesas que não sejam estritamente necessárias para o reparo do veículo e seu retorno às condições de uso
        imediatamente anteriores ao sinistro;
      </p>
      <p>
        o) De estelionato, apropriação indébita, extorsão, furto mediante fraude ou sumiço imotivado do veículo, que não configure
        roubo ou furto;
      </p>
      <p>
        p) De danos decorrentes da ausência ou falha na manutenção do veículo segurado ou aqueles relacionados à ausência de
        conservação do bem;
      </p>
      <p>
        q) Desvalorização do valor do veículo segurado, em virtude da remarcação do chassi, bem como qualquer outra forma de
        depreciação que o mesmo venha a sofrer, inclusive àquela decorrente de sinistro ou pelo uso do bem; r) Da fuga do(a)
        condutor(a) do veículo segurado à ação policial;
      </p>
      <p>
        s) De multas, composições civis, transações penais, fianças impostas a(o) SEGURADO(A) e as despesas de qualquer natureza
        relativas a ações e processos criminais;
      </p>
      <p>
        t) De despesas efetuadas com custas judiciais relativas a processo criminal, bem como com honorários de advogados
        decorrentes dessas ações.
      </p>
      <p>
        u) Da utilização ou do manuseio, pelo(a) condutor(a), de telefone celular, smartphone, ou qualquer outro aparelho
        eletrônico, bem como, se estiver utilizando fones de ouvido, desde que caracterizado nexo causal com a ocorrência do
        sinistro e/ou com o evento que provocou os danos.
      </p>
      <p>
        v) Aos pneus e câmaras de ar, exceto em casos de incêndio ou indenização integral do veículo ou ainda em sinistro coberto
        e indenizável de perda parcial do veículo que os atinja;
      </p>
      <p>
        x) Aos itens não originais de fábrica: toca cd’s, rádios, toca-fitas, kit gás, tacógrafo, cabine suplementar carroçarias,
        equipamentos, quando não for contratada cobertura ou não fizer parte do modelo original do veículo;
      </p>
      <p>y) Ao veículo segurado pelo congelamento da água do motor;</p>
      <p>
        w) Aos acessórios ou equipamentos removíveis, não fixados em caráter permanente. Exemplo: toca-fitas removíveis (gaveta);
      </p>
      <p>
        z) Ao dispositivo antifurto ou antirroubo, DVD, Kit viva-voz, micro system ou similares, radiocomunicação ou similares,
        vídeo cassete e televisor (conjugados ou não com toca-fitas ou similares);
      </p>
      <p>aa) À carga objeto de transporte;</p>
      <p>bb) Exclusivamente ao tacógrafo, taxímetro e luminoso;</p>
      <p>cc) Por fenômenos/convulsões da natureza, exceto aquelas previstas na Cobertura Básica da apólice;</p>
      <p>
        dd) Ao veículo segurado por desgastes, depreciação decorrente de sinistro ou pelo uso, falhas de material, defeitos
        mecânicos ou da instalação elétrica do veículo segurado, bem como perdas ou danos decorrentes ou originados por falta de
        manutenção ou falhas e/ou erros de fabricação e/ou projeto;
      </p>
      <p>
        ee) Quando o veículo segurado estiver em trânsito por estradas ou caminhos impedidos, não abertos ao tráfego ou de areias
        fofas ou movediças, bem como por praias e regiões ribeirinhas com ou sem autorização de tráfego pelo órgão competente;
      </p>
      <p>
        ff) Pela carga objeto de transporte do veículo segurado, que contamine ou polua o meio ambiente bem como pela carga do
        veículo do terceiro eventualmente envolvido em acidente com o veículo segurado, exceto quando contratada cobertura
        específica de contaminação ou poluição causada ao meio ambiente pela carga do veículo segurado;
      </p>
      <p>
        gg) Pela contaminação ou radiação de qualquer natureza e processos provocados por combustíveis e materiais de armas
        nucleares e ainda qualquer processo de fissão nuclear; causados ao meio ambiente, tanto pelo veículo segurado quanto pelo
        veículo do terceiro eventualmente envolvido no acidente;
      </p>
      <p>hh) Pelo reboque ou transporte do veículo segurado por veículo não apropriado a esse fim;</p>
      <p>
        ii) Ao veículo segurado, pela queda, deslizamento ou vazamento dos objetos/carga por ele transportados, salvo quando em
        consequência de um dos riscos cobertos pela apólice, em decorrência de acidente de trânsito e não da simples freada;
      </p>
      <p>jj) Por danos morais ou estéticos;</p>
      <p>
        kk) Por atos ilícitos culposos ou dolosos, praticados por empregados do(a) SEGURADO(A), ou ainda, por pessoas a ele(a)
        assemelhadas, exceto para a cobertura de Responsabilidade Civil;
      </p>
      <p>
        ll) Por atos ilícitos dolosos ou por culpa grave equiparável ao dolo praticados pelo(a) SEGURADO(A), por seu Beneficiário
        ou pelo Representante, de um ou de outro, se o Segurado for pessoa física;
      </p>
      <p>
        mm) Por atos ilícitos dolosos ou por culpa grave equiparável ao dolo, praticados pelos sócios controladores, dirigentes,
        administradores legais, beneficiários e respectivos representantes, se o(a) SEGURADO(A) for pessoa jurídica;
      </p>
      <p>
        nn) Ao veículo segurado, por animais de propriedade do(a) SEGURADO(A) principal condutor ou de seus ascendentes,
        descendentes ou cônjuge;
      </p>
      <p>
        oo) Por acidentes decorrentes da inobservância a disposições legais causados por exemplificativamente, lotação de
        passageiros, peso, acondicionamento ou transporte da carga ou objeto transportado e demais situações semelhantes;
      </p>
      <p>pp) Às pessoas transportadas em locais não especificamente destinados e apropriados a esse fim;</p>
      <p>
        qq) A pacientes transportados por ambulâncias seguradas, mesmo quando contratada a cobertura de acidentes pessoais
        passageiros;
      </p>
      <p>
        rr) A bens de terceiros, móveis ou imóveis, em poder do(a) SEGURADO(A) para guarda, custódia, transporte, uso, manipulação
        ou execução de quaisquer trabalhos;
      </p>
      <p>
        ss) Aos empregados e prepostos do(a) SEGURADO(A) quando a seu serviço; aos descendentes, ascendentes, cônjuge e irmãos de
        um ou de outro, bem como quaisquer parentes ou pessoas que residam ou que dependam economicamente destes;
      </p>
      <p>
        tt) Por poluição ou contaminação ao meio ambiente, bem como quaisquer despesas incorridas para limpeza e/ou
        descontaminação do meio ambiente;
      </p>
      <p>
        uu) Por responsabilidades assumidas pelo(a) SEGURADO(A) por meio de contratos, convenções ou acordos sem a prévia
        concordância da SEGURADORA;
      </p>
      <p>vv) Pela carga ou descarga das mercadorias do veículo segurado;</p>
      <p>xx) Pela operação de basculamento do veículo segurado;</p>
      <p>yy) Ao envelopamento do veículo segurado; e</p>
      <p>
        ww) Não haverá cobertura para veículos que sejam advindos de leilão (qualquer natureza), que tenham chassi remarcado ou
        ilegível, veículos sinistrados com média ou grande monta, que tenham qualquer alteração no motor ou em sua estrutura
        original (rebaixados, alteração de potência, etc), veículos com blindagem, restrições administrativas (RENAJUD, etc),
        placa clonada ou qualquer outro impedimento relativo ao veículo que possa causar embaraço à SEGURADORA. É de
        responsabilidade do(a) proponente, SEGURADO(A), seu representante legal ou corretor(a) a veracidade das informações e a
        formalização da condição do veículo, respeitando os pontos anteriormente indicados, não sendo a SEGURADORA obrigada a
        constatar tal condição.
      </p>
      <h2>14. Foro</h2>
      <p>O Foro competente para as ações derivadas do presente contrato será o da comarca de domicílio do(a) SEGURADO(A).</p>
      <h2>1 5 . Índice da atualização de valores, juros e moras para liquidação do sinistro</h2>
      <p>O Foro competente para as ações derivadas do presente contrato será o da comarca de domicílio do(a) SEGURADO(A).</p>
      <h3>
        1 5 . 1 O pagamento dos valores relativos à atualização monetária e juros moratórios será feito independentemente de
        notificação ou interpelação judicial, de uma só vez, juntamente com os demais valores do contrato.
      </h3>
      <h3>
        1 5 . 2 O índice pactuado para a atualização dos valores é o IPCA/IBGE - Índice de Preços ao Consumidor Amplo da Fundação
        Instituto Brasileiro de Geografia e Estatística e no caso da sua extinção, o índice que vier a substituí-lo.
      </h3>
      <h3>
        15.3 A atualização será efetuada com base na variação positiva apurada entre o último índice publicado antes da data de
        exigibilidade da obrigação pecuniária e aquele publicado imediatamente anterior à data da sua efetiva liquidação.
      </h3>
      <h3>
        1 5 . 4 Em caso de extinção do índice definido no item anterior, será utilizado o índice que vier a ser autorizado pela
        Superintendência de Seguros Privados (SUSEP), ficando a SEGURADORA responsável por dar ciência a(os) SEGURADOS(AS) desta
        alteração.
      </h3>
      <h2>1 6 . Aceitação da proposta</h2>
      <h3>
        16.1 A SEGURADORA terá o prazo de 15 (quinze) dias corridos contados a partir do recebimento da proposta de seguro para se
        manifestar sobre a aceitação ou não do risco, seja em caso de seguros novos, renovações ou alterações de seguro,
        independentemente da existência de sinistro por meio remoto, seja através da plataforma de acesso do SEGURADO(A) (área de
        login), email ou interação voz/texto com o proponente, corretor ou representante legal.
      </h3>
      <h3>
        16.2 A vistoria prévia não caracteriza cobertura provisória para o veículo e sim um instrumento para a SEGURADORA avaliar
        a aceitação ou não do risco. O(A) SEGURADRO(A) deverá apresentar o veículo, fotos ou documentos para realização de
        vistoria prévia sempre que for solicitado pela SEGURADORA e de forma tempestiva, no prazo máximo de 7 dias úteis.
      </h3>
      <h2>1 7 . Condições Gerais</h2>
      <h3>1 7 . 1 A aceitação do seguro estará sujeita à análise do risco.</h3>
      <h3>
        1 7 . 2 O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua
        comercialização.
      </h3>
      <h3>
        17.3 O(A) SEGURADO(A) poderá consultar a situação cadastral de seu corretor de seguros no sítio www.susep.gov.br, por meio
        do número de seu registro na SUSEP, nome completo, CNPJ ou CPF.
      </h3>
      <h3>
        1 7 . 4 O pagamento do prêmio será mensal. Em se tratando de apólice de seguro com vigência intermitente, o pagamento será
        à vista.
      </h3>
    </div>
  );
}
