import styled from 'styled-components';
import { white, primary, secondary, darkGray, lighterPrimary, lightGray, success } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100vh !important;
  overflow-y: hidden !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  h4 {
    margin: 0px;
    width: 100%;
    text-align: left;
  }

  .divider {
    margin: 10px 0px;
    border-bottom: 1px solid ${lightGray};
    padding-bottom: 10px;
  }

  .search-area {
    width: 260px;
  }

  .result-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0px;
  }

  .result-item {
    background-color: #fafafa;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    box-sizing: border-box;
    padding: 10px 15px;
    margin: 0px;
    margin-bottom: 15px;

    p {
      font-size: 13px;
      margin: 0px;
    }

    small {
      margin: 0px;
      font-size: 10px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .result-item-selected {
    border: 1px solid ${success};
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 90%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 10px;
  padding-left: 90px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const Subtitle = styled.p`
  font-size: 18px;
  color: ${darkGray};
  margin: 0px;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .containerTitle {
    display: flex;
    width: 38%;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1000px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const ToolBar = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${lighterPrimary};
  padding: 5px 10px;
  border-radius: 15px;
  margin-bottom: 10px;
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;
  /* box-shadow: rgba(17, 12, 46, 0.03) 0px 48px 100px 0px; */

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${secondary};
    border-radius: 10px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;

export const CountArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 12px;

  p {
    margin: 0px;
  }
`;

export const Button = styled.button`
  width: 135px;
  height: 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: ${primary};
  background: ${secondary};
  margin-right: 20px;

  :hover {
    opacity: 0.8;
    color: ${darkGray};
  }

  @media (max-width: 1000px) {
    margin: 0 0 15px 0;
  }
`;

export const ModalButton = styled.div`
  display: flex;
  margin-top: 3%;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  }
`;
export const SelectLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0px;
  width: 40%;

  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
