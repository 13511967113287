import { RiCarLine } from 'react-icons/ri';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { formatPolicyStatus } from 'utils/policyStatus';
import { formatPlate } from 'react-data-formatter';

import { Container, ContainerText, BrandAndModel, Plate, Status } from './styles';
import { lightGray } from 'styles/colorProvider';

function ObjectPolicy({ category, data, clickable = true }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(category === 'proposal' ? `/detalhes-da-proposta/${data?.id}` : `/detalhes-da-apolice/${data?.id}`);
  };

  return (
    <Container clickable={clickable} onClick={clickable && navigate}>
      <div className="description">
        <RiCarLine color={formatPolicyStatus(data?.status)?.color} size={28} />
        <ContainerText>
          <BrandAndModel>{`${data?.vehicle?.brand} ${data?.vehicle?.model.split(' ')[0]}`}</BrandAndModel>
          <Plate>{formatPlate(data?.vehicle?.plate)}</Plate>
          <Status color={formatPolicyStatus(data?.status)?.color}>{formatPolicyStatus(data?.status)?.condition}</Status>
        </ContainerText>
      </div>
      {clickable && <HiOutlineArrowNarrowRight color={lightGray} size={25} className="iconArrow" />}
    </Container>
  );
}

export default ObjectPolicy;
