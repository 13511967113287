//////////////////////////////DEFAULTERS//////////////////////////////
import React from 'react';
import { Header, SideMenu } from 'components';
import { Container, ContentArea, Title, Div } from './styles';

function Defaulters() {
    return (
        <>
            <Header />
            <Container>
                <SideMenu />
                <ContentArea>
                    <Div>
                        <Title>INADIMPLENTES</Title>
                    </Div>
                </ContentArea>
            </Container>
        </>
    );
}

export default Defaulters;