/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';
import api from 'api';
import Cookies from 'js-cookie';
import { Header, ObjectPolicy, BrokerMenu, SideMenuBroker } from 'components';
import { Container, ContentArea, Title, ListPolicy, LoadingArea, EmptyArea } from './styles';
import { useStoreActions, useStoreState } from 'easy-peasy';
import emptyAnimation from 'assets/empty.json';
import { loadingColor } from 'styles/colorProvider';

function BrokerArea({ history }) {
  //SE NAO TIVER PERMISSAO, REDIRECIONAR
  const [loading, setLoading] = useState(false);
  const [policies, setPolicies] = useState([]);

  const [user, setUser] = [useStoreState(({ user }) => user.user), useStoreActions(({ user }) => user.setUser)];

  const loadUser = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/me`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setUser(response.data);
    setLoading(false);
  };

  useEffect(() => !user && loadUser(), [user]);

  return (
    <>
      <Header />
      <Container>
        <SideMenuBroker />
        <ContentArea>
          <>
            <Title>Portal comercial</Title>
            <BrokerMenu />
          </>
        </ContentArea>
      </Container>
    </>
  );
}

export default BrokerArea;
