import styled from 'styled-components';
import { primary, lightGray } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .areaLogo {
    display: flex;
    justify-content: center;

    h2 {
      font-weight: bold;
      font-size: 28px;
      text-align: center;
      color: ${primary};
      margin-top: 0;
      margin-bottom: 12%;
    }
  }

  .closeArea {
    display: flex;
    justify-content: flex-end;
    width: 720px;
    margin-top: -15px;

    @media (max-width: 1000px) {
      width: 110%;
      margin-bottom: 5%;
    }
  }

  .closeAreaPaymentData {
    display: flex;
    justify-content: flex-end;
    width: 720px;
    margin-top: -15px;

    @media (max-width: 1000px) {
      margin-top: 0;
      width: 102%;
      margin-bottom: 2%;
    }
  }

  .paymentMethods {
    width: 269px;
  }

  .cardDataContainer {
    border: 2px solid ${lightGray};
    border-radius: 20px;
    padding: 3% 5%;
    margin-top: -3%;
    margin-bottom: 3%;

    @media (max-width: 1000px) {
      border: none;
      padding: 0;
      margin-bottom: 8%;
    }
  }

  .twoInputArea {
    display: flex;
    justify-content: space-between;
  }
`;
