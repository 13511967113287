import styled from 'styled-components';
import { primary, white } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${primary};
    margin: 0px;
    margin-top: 40px;
    font-size: 30px;
  }

  h3 {
    font-weight: normal;
    margin: 10px 0px;
    margin-bottom: 50px;
  }
`;
