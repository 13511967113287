import React from 'react';
import 'moment/locale/pt-br';
import { BsChatSquareText } from 'react-icons/bs';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { formatCPF } from 'react-data-formatter';
import { success, error } from '../../styles/colorProvider';
import moment from 'moment';

function ComplaintItem({ complaint }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(`/backoffice/detalhes-reclamacao/${complaint?.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconCar">
            {complaint?.susep_status?.name === 'ABERTA' && <BsChatSquareText size={23} color={success} />}
            {complaint?.susep_status?.name !== 'ABERTA' && <BsChatSquareText size={23} color={error} />}
          </div>

          <Div>
            <Title>{complaint?.user?.name}</Title>
            <Subtitle>{formatCPF(complaint?.user?.cpf)}</Subtitle>
          </Div>

          <Div>
            <Title>Tipo</Title>
            <Subtitle>{complaint?.susep_type?.name}</Subtitle>
          </Div>

          <Div>
            <Title>Status</Title>
            <Subtitle>{complaint?.susep_status?.name}</Subtitle>
          </Div>

          <Div>
            <Title>Aberta em</Title>
            <Subtitle>{moment(complaint?.created_at).format('DD/MM/YYYY')}</Subtitle>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>

          {complaint?.susep_status?.name === 'ABERTA' && (
            <IconMobile>
              <BsChatSquareText size={40} color={success} />
            </IconMobile>
          )}
          {complaint?.susep_status?.name !== 'ABERTA' && (
            <IconMobile>
              <BsChatSquareText size={40} color={error} />
            </IconMobile>
          )}
        </div>
      </Container>
    </>
  );
}

export default ComplaintItem;
