import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { FaUserShield } from 'react-icons/fa';
import { success } from 'styles/colorProvider';

function ThirdPartyFiles({ data }) {
  const history = useHistory();

  return (
    <Container onClick={() => history.push(`/backoffice/terceiro-arquivo/${data.id}`)}>
      <div className="icon-container">
        <FaUserShield size={'85%'} color={success} />
      </div>
      <div className="info-container">
        <h3>{data.name}</h3>
        <p>Telefone: {data.phone}</p>
        <p>Placa: {data.plate === null || data.plate === '' ? 'Terceiro sem placa' : data.plate}</p>
      </div>
    </Container>
  );
}

export default ThirdPartyFiles;
