/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';
import { Header, Input, Button } from 'components';
import { Container, ContentArea, Title, LoadingArea, Subtitle, Form, FormPermissions, ButtonLine } from './styles';
import { useHistory } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { formatRoleCategory } from 'utils/userCategory';
import { success, white } from 'styles/colorProvider';
import { toast } from 'react-toastify';

function AccessGroupsNew({ location }) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [accessGroupData, setAccessGroupData] = useState({});
  const [featureData, setFeatureData] = useState();
  const [features, setFeatures] = useState([]);

  const loadData = async () => {
    setLoading(true);

    const features = await api({
      method: 'GET',
      url: `/list-features`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });

    setFeatureData(features.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChangeFeature = (feature) => {
    const isChecked = features?.findIndex((i) => i === feature) > -1;
    const curretFeaturesList = [...features];

    if (isChecked) {
      curretFeaturesList.splice(
        curretFeaturesList.findIndex((listItem) => listItem === feature),
        1,
      );
      setFeatures(curretFeaturesList);
      setAccessGroupData({ ...accessGroupData, features: curretFeaturesList });
    } else {
      curretFeaturesList.push(feature);
      setFeatures(curretFeaturesList);
      setAccessGroupData({ ...accessGroupData, features: curretFeaturesList });
    }
  };

  const handleCreateAccessGroup = async () => {
    setLoadingCreate(true);
    try {
      await api({
        method: 'POST',
        url: `/access-group`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          name: accessGroupData.name,
          description: accessGroupData.description,
          features,
        },
        json: true,
      });
      setLoadingCreate(false);
      toast.success('Grupo de acesso criado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      history.push('/backoffice/grupos-de-acesso');
    } catch (error) {
      setLoadingCreate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const categories = [...new Set(featureData?.map((feature) => feature.category))].filter((i) => i !== 'user').sort();

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={success} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <Title>
              <IconButton size="small" style={{ marginRight: 15 }} onClick={() => history.push('/backoffice/grupos-de-acesso')}>
                <MdChevronLeft size={24} />
              </IconButton>
              DETALHES DO GRUPO DE ACESSO
            </Title>

            <Form>
              <div class="grid-item">
                <Input
                  height="35px"
                  label="Nome"
                  placeholder="Nome"
                  value={accessGroupData?.name}
                  onChange={(e) => setAccessGroupData({ ...accessGroupData, name: e.target.value })}
                />
                <Input
                  height="35px"
                  label="Descrição"
                  placeholder="Descrição"
                  value={accessGroupData?.description}
                  onChange={(e) => setAccessGroupData({ ...accessGroupData, description: e.target.value })}
                />
              </div>
            </Form>

            <Subtitle>Selecionar permissões:</Subtitle>

            <FormPermissions style={{ paddingTop: 10 }}>
              {categories
                ?.filter((cat) => cat !== 'public-api')
                ?.map((category) => (
                  <div class="permission-section">
                    <h3>{formatRoleCategory(category)}</h3>
                    <div className="items-list">
                      {featureData
                        ?.filter((f) => f.category === category)
                        ?.map((feature) => (
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id={feature?.id}
                              name={feature?.id}
                              checked={features?.findIndex((i) => i === feature.id) > -1}
                              onChange={() => handleChangeFeature(feature?.id)}
                            />
                            <label for={feature?.id}>{feature?.name}</label>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </FormPermissions>

            <ButtonLine style={{ marginTop: 20 }}>
              <Button
                height="35px"
                disabled={loadingCreate || !accessGroupData.name || features?.length < 1}
                onClick={handleCreateAccessGroup}
              >
                {loadingCreate ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Criar gupo de acesso'}
              </Button>
            </ButtonLine>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default AccessGroupsNew;
