import { useEffect, useState } from 'react';
import { Container, Text5, Button, Div } from './style';
import { Payer } from './components/Payer';

// api
import api from 'api/index';

import { Bank } from './components/Bank';

export const Billet = () => {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const updateUserData = async () => {
      const { data } = await api.post('/create-billet', {
        due_at: '2022-10-30',
        value: 10.0,
        instructions: 'teste Bia',
        customer: {
          name: 'Bia',
          cpf: '14458884670',
          city: 'Uberlandia',
          UF: 'MG',
          CEP: '38413177',
          street: 'rua do carpinteiro',
          number: '824',
        },
      });
      setUserData(data);
    };
    updateUserData();
  }, []);

  return (
    <Container>
      <Div>
        <Text5 fontWeight="bold"> Instruções de Impressão</Text5>
        <Text5>
          Imprimir em impressora jato de tinta (ink jet) ou laser em qualidade normal. (Não use modo econômico). Utilize folha A4
          (210 x 297 mm) - Corte na linha indicada
        </Text5>
        <Button onClick={() => window.print()}>Clique aqui para imprimir</Button>
      </Div>
      <Payer userData={userData} />
      <Bank userData={userData} />
    </Container>
  );
};
// width: 1, height: 15, backgroundColor: '#000'
