import { StyledButton } from './styles';
import ReactLoading from 'react-loading';
import { white } from 'styles/colorProvider';

function Button({ loading, children, color, outlined, backgroundColor, ...props }) {
  return (
    <StyledButton backgroundColor={backgroundColor} outlined={outlined} disabled={loading || props.disabled} {...props}>
      {loading ? <ReactLoading color={white} height={24} width={24} type="spin" /> : children}
    </StyledButton>
  );
}

export default Button;
