import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { BsShieldCheck, BsShieldX } from 'react-icons/bs';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { formatPolicyStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';

function PolicyItem({ policy, commercial }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    commercial
      ? history.push(`/comercial/detalhes-da-apolice/${policy?.id}`)
      : history.push(`/backoffice/detalhes-da-apolice/${policy?.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconCar">
            {policy?.status === 'canceled' ? (
              <BsShieldX size={23} color={formatPolicyStatus(policy?.status)?.color} />
            ) : (
              <BsShieldCheck size={23} color={formatPolicyStatus(policy?.status)?.color} />
            )}
          </div>

          <Div>
            <Title>{policy?.user?.name}</Title>
            <Subtitle>{formatCPF(policy?.user.cpf)}</Subtitle>
          </Div>

          <Div>
            <Title color={formatPolicyStatus(policy?.status)?.color}>
              {policy?.policy_number} ({formatPolicyStatus(policy?.status)?.label})
            </Title>
            <Subtitle>
              Placa: {policy?.vehicle?.plate !== null || undefined ? formatPlate(policy?.vehicle?.plate) : 'Sem placa'}
            </Subtitle>
          </Div>

          <Div>
            <Subtitle>Emitida por {policy?.author?.name}</Subtitle>
            <Subtitle>Emitida em {moment(policy?.created_at).format('DD/MM/YYYY HH:mm')}</Subtitle>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>

          <IconMobile>
            <BsShieldCheck size={40} color={formatPolicyStatus(policy?.status)?.color} />
          </IconMobile>
        </div>
      </Container>
    </>
  );
}

export default PolicyItem;
