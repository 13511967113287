import React from 'react';
import 'moment/locale/pt-br';
import moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Container, ContainerArea } from './styles';
import { formatHistoricStatus } from 'utils/policyStatus';
import { formatCurrency } from 'react-data-formatter';

function HistoryItem({ data, setAttendanceAction, setAttendanceData }) {
  const openLink = (link) => {
    window.open(link, '_blank');
  };

  const openAttendance = (attendance) => {
    setAttendanceAction('view');
    setAttendanceData(attendance);
  };

  return (
    <>
      <Container>
        <ContainerArea>
          <Timeline style={{ width: '100%', marginLeft: -150 }}>
            {data?.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent style={{ fontSize: 12 }}>
                  {moment(item?.created_at)?.format('DD/MM/YYYY')} <br />
                  <p style={{ margin: 0, fontSize: 12, color: '#868686' }}>{moment(item?.created_at)?.format('HH:mm')}</p>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    style={{ backgroundColor: formatHistoricStatus(item?.action)?.color, cursor: 'pointer' }}
                    onClick={() => {
                      item.action === 'attendance' && openAttendance(item);
                      item.action === 'new_proposal' && openLink(`/backoffice/detalhes-da-proposta/${item?.proposal?.id}`);
                      // item.action === 'inspection' && openLink(`${item?.inspection?.inspection_code}`);
                      item.action === 'new_policy' && openLink(`/backoffice/detalhes-da-apolice/${item?.policy?.id}`);
                      item.action === 'new_charge' && openLink(`/backoffice/faturas/${item?.id_policy}`);
                      item.action === 'new_claim' && openLink(`/backoffice/detalhes-do-sinistro/${item?.metadata}`);
                      item.action === 'charge_paid' && openLink(`/backoffice/faturas/${item?.id_policy}`);
                      item.action === 'charge_failed' && openLink(`/backoffice/faturas/${item?.id_policy}`);
                      item.action === 'new_cancelation' && openLink(`/backoffice/cancelamentos`);
                      item.action === 'cancelation_updated' && openLink(`/backoffice/cancelamentos`);
                      item.action === 'new_complaint' &&
                        openLink(`//backoffice/detalhes-reclamacao/${item?.complaint?.id_complaint}`);
                      item.action === 'complaint_updated' &&
                        openLink(`//backoffice/detalhes-reclamacao/${item?.complaint?.id_complaint}`);
                      item.action === 'complaint_finished' &&
                        openLink(`//backoffice/detalhes-reclamacao/${item?.complaint?.id_complaint}`);
                    }}
                  >
                    {formatHistoricStatus(item?.action)?.icon}
                  </TimelineDot>
                  {index !== data.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent style={{ fontSize: 14 }}>
                  {item.action === 'attendance' ? 'Atendimento registrado' : item?.description} <br />
                  <p style={{ margin: 0, fontSize: 12, color: '#868686', maxWidth: '60%', textAlign: 'justify' }}>
                    {item.action === 'new_proposal' && `Protocolo: ${item?.proposal?.protocol}`}
                    {item.action === 'inspection' && `Vistoria: ${item?.inspection?.inspection_code}`}
                    {item.action === 'new_policy' && `Apólice: ${item?.policy?.policy_number}`}
                    {item.action === 'attendance' && `${item?.description}`}
                    {item.action === 'new_charge' && formatCurrency(item?.charge?.value || 0)}
                    {item.action === 'charge_paid' && formatCurrency(item?.charge?.value || 0)}
                    {item.action === 'charge_failed' &&
                      `${item?.transaction?.TB_CONSTANT_PAY_METHODS?.name} - ${formatCurrency(item?.transaction?.value) || 0}`}
                    {item.action === 'new_cancelation' && `Motivo: ${item?.cancelation?.TB_CONSTANT_CANCELLATION_REASON?.name}`}
                    {item.action === 'cancelation_updated' && `Observação: ${item?.cancelation?.description}`}
                    {item.action === 'new_complaint' && `Motivo: ${item?.complaint?.TB_COMPLAINT?.TB_SUSEP_TYPE_COMPLAINT?.name}`}
                    {item.action === 'complaint_updated' &&
                      `Motivo: ${item?.complaint?.TB_COMPLAINT?.TB_SUSEP_TYPE_COMPLAINT?.name}`}
                    {item.action === 'complaint_finished' &&
                      `Status: ${item?.complaint?.TB_COMPLAINT?.TB_SUSEP_STATUS_COMPLAINT?.name}`}
                  </p>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </ContainerArea>
      </Container>
    </>
  );
}

export default HistoryItem;
