import styled from 'styled-components';
import { primary } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    margin: none;
    outline: none;
    border: 1px dashed ${primary};
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
    border-radius: 50px;
    color: ${primary};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 200px;

    &:hover {
      opacity: 0.6;
    }
  }
`;
