/* eslint-disable react-hooks/exhaustive-deps */

import { cpf, cnpj } from 'cpf-cnpj-validator';
import { Input, Button } from 'components';
import { isBirthDateValid } from 'utils/birthDate';
import ReactLoading from 'react-loading';
import { Container } from '../../style';
import { toast } from 'react-toastify';
import { ClientDataContainer, Content, Radio } from './style';
import api from 'api/index';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { primary } from 'styles/colorProvider';

const ClientStep = ({ quotationData, setQuotationData, nextStep }) => {
  const [hasChange, setHasChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState({});
  
  useEffect(() => {
    if (quotationData?.client) {
      setClient({ ...quotationData.client });
    }
  }, []);
  const handleSubmit = async () => {
    setLoading(true)
    try {

      const createdLead = await api({
        method: 'post',
        url: '/quotation/lead-quotation',
        data: {
          name: client?.name,
          phone: client?.phone?.replace(/[^\d]/g, '') || "",
          document: client?.document?.replace(/[^\d]/g, '') || "",
          email: client?.email || "",
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      const quotationProposal = await api({
        method: 'post',
        url: '/quotation/proposal',
        data: {
          id_lead: createdLead.data.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      setQuotationData({
        ...quotationData,
        client,
        hasQuotation:true,
        quotationProposalData: { quotationProposalId: quotationProposal.data.id, leadId: createdLead.data.id },
      });
      nextStep();
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };
  const handleUpdate = async () => {
    if (!hasChange) {
      return nextStep();
    }
    setLoading(true)
    try {

      const createdLead = await api({
        method: 'post',
        url: '/quotation/lead-quotation',
        data: {
          name: client?.name,
          phone: client?.phone?.replace(/[^\d]/g, '') || "",
          document: client?.document?.replace(/[^\d]/g, '') || "",
          email: client?.email || "",
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      await api({
        method: 'put',
        url: `/quotation/proposal/${quotationData.quotationProposalData.quotationProposalId}`,
        data: {
          id_lead: createdLead.data.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      setQuotationData({
        ...quotationData,
        client,
        quotationProposalData: { ...quotationData.quotationProposalData, leadId: createdLead.data.id },
      });
      nextStep();
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };
  return (
    <Container>
      <Content style={{ width: '100%' }}>
        <ClientDataContainer>
          <div className="proposal-section">
            <h3>Dados do cliente</h3>
            <div className="form">
              <Input
                height="30px"
                width="230px"
                style={{ marginRight: 50, marginTop: 20 }}
                label="Nome completo"
                required
                value={client?.name}
                // value={quotationData?.client?.name}
                onChange={(event) => {
                  setClient((prev) => ({ ...prev, name: event.target.value }));
                  // setQuotationData({ ...quotationData, client: { ...quotationData?.client, name: event.target.value } });
                  setHasChange(true);
                }}
                success={client?.name?.length > 5}
              />
              {client?.document?.length > 14 ? (
                <Input
                  height="30px"
                  width="230px"
                  style={{ marginRight: 50, marginTop: 20 }}
                  label="CNPJ"
                  mask="99.999.999/9999-99"
                  value={client?.document}
                  onChange={(event) => {
                    // setQuotationData({ ...quotationData, client: { ...quotationData?.client, document: event.target.value } });
                    setClient((prev) => ({ ...prev, document: event.target.value }));
                    setHasChange(true);
                  }}
                  success={cnpj.isValid(client?.document)}
                  error={client?.document && !cnpj.isValid(client?.document)}
                />
              ) : (
                <Input
                  height="30px"
                  width="230px"
                  style={{ marginRight: 50, marginTop: 20 }}
                  label="CPF / CNPJ"
                  mask="999.999.999-999"
                  value={client?.document}
                  onChange={(event) => {
                    // setQuotationData({ ...quotationData, client: { ...quotationData?.client, document: event.target.value } });
                    setClient((prev) => ({ ...prev, document: event.target.value }));
                    setHasChange(true);
                  }}
                  success={cpf.isValid(client?.document)}
                  error={client?.document && !cpf.isValid(client?.document)}
                />
              )}

              <Input
                height="30px"
                width="230px"
                label="Telefone"
                style={{ marginRight: 50, marginTop: 20 }}
                mask="(99) 99999-9999"
                value={client?.phone}
                onChange={(event) => {
                  // setQuotationData({ ...quotationData, client: { ...quotationData?.client, phone: event.target.value } });
                  setClient((prev) => ({ ...prev, phone: event.target.value }));
                  setHasChange(true);
                }}
                success={client?.phone?.length === 15}
              />

              <Input
                height="30px"
                width="230px"
                label="E-mail"
                style={{ marginRight: 50, marginTop: 20 }}
                value={client?.email}
                onChange={(event) => {
                  // setQuotationData({ ...quotationData, client: { ...quotationData?.client, email: event.target.value } });
                  setClient((prev) => ({ ...prev, email: event.target.value }));
                  setHasChange(true);
                }}
                success={/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(client?.email)}
                error={client?.email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(client?.email)}
              />
            </div>
          </div>
          <Button
            disabled={!client?.name}
            height="35px"
            style={{ margin: 0, marginTop: 30 }}
            onClick={() => (quotationData?.hasQuotation ? handleUpdate() : handleSubmit())}
          >
            {!loading && "Continuar"}
            {loading && <ReactLoading color={primary} height={24} width={24} type="spin" />}
          </Button>
        </ClientDataContainer>
      </Content>
    </Container>
  );
};

export default ClientStep;
