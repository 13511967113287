import styled from 'styled-components';
import logoDefault from '../../assets/logo-default.png';
import { primary } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .areaLogo {
    display: flex;
    justify-content: center;

    h2 {
      font-weight: bold;
      font-size: 28px;
      text-align: center;
      color: ${primary};
      margin-top: 0;
    }
  }

  .logo {
    height: 36px;
    width: 209px;
    background-image: url(${logoDefault});
    background-size: cover;
  }

  .buttonArea {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .closeArea {
    display: flex;
    justify-content: flex-end;
    width: 720px;
    margin-top: -15px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .inputs {
    @media (max-width: 1000px) {
      margin-top: -5%;
    }
  }
`;
