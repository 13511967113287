import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import api from 'api';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { BiLocationPlus } from 'react-icons/bi';
import { Container, SectionTitle } from '../../style';
import { AddressDataContainer, AddressDataInputContainer } from './style';
import { loadingColor, primary } from 'styles/colorProvider';
import { Input, Button } from 'components';
import { toast } from 'react-toastify';
export const AddressStep = ({ quotationData, setQuotationData, nextStep }) => {
  const [address, setAddress] = useState(
    quotationData?.address || {
      zip_code: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
    }
  );

  const [hasChange, setHasChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const createdAddress = await api({
        method: 'post',
        url: '/quotation/new-address',
        data: {
          zip_code: address.zip_code.replace(/[^\d]/g, ''),
          city: address.city,
          street: address.street,
          number: address.number,
          complement: address.complement,
          district: address.district,
          state: address.state,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
      await api({
        method: 'put',
        url: `/quotation/proposal/${quotationData.quotationProposalData.quotationProposalId}`,
        data: {
          id_address: createdAddress.data.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
      setQuotationData({
        ...quotationData,
        address: {
          zip_code: createdAddress.data.zip_code,
          street: createdAddress.data.street,
          number: createdAddress.data.number,
          complement: createdAddress.data.complement,
          district: createdAddress.data.district,
          city: createdAddress.data.city,
          state: createdAddress.data.state,
        },
        quotationProposalData: { ...quotationData.quotationProposalData, addressId: createdAddress.data.id },
      });
      nextStep();
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  const handleChangeCep = async (e) => {
    setAddressError(true);
    const stringCep = e.target.value.replaceAll('-', '').replaceAll('.', '');

    setLoading(true);
    setAddress((prev) => ({ ...prev, zip_code: e.target.value }));
    if (stringCep.length === 8) {
      try {
        const data = await axios.get(`https://viacep.com.br/ws/${stringCep}/json/`);
        const currentAddress = {
          zip_code: stringCep,
          street: data?.data?.logradouro,
          // number:
          district: data?.data?.bairro,
          city: data?.data?.localidade,
          state: data?.data?.uf,
        };
        // const response = await api({
        //   method: 'POST',
        //   url: `/address-accepted`,
        //   data: { address: currentAddress },
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: Cookies.get('splitriskwl-authtoken'),
        //   },
        // });
        setHasChange(true);
        setAddress((prev) => ({ ...prev, ...currentAddress }));
        setAddressError(false);

        // setAddressError(!response.data);
      } catch (err) {}
    }
    setLoading(false);
  };

  return (
    <Container>
      <SectionTitle>Endereço do proprietário</SectionTitle>
      {/* {addressError && (
        <Alert style={{ marginBottom: 20 }} severity="error">
          Oops! Infelizmente não existe cobertura disponível para o endereço informado!
        </Alert>
      )} */}

      <AddressDataContainer>
        <AddressDataInputContainer>
          <Input
            label="CEP"
            icon={<BiLocationPlus />}
            height="30px"
            mask="99.999-999"
            placeholder="CEP"
            value={address?.zip_code}
            onChange={handleChangeCep}
            endIcon={loading && <ReactLoading color={loadingColor} type="spin" />}
          />
          <>
            <Input
              height="30px"
              label="Logradouro"
              value={address.street}
              onChange={(event) => {
                setAddress({ ...address, street: event.target.value });
                setHasChange(true);
              }}
            />
            <Input
              height="30px"
              label="Número"
              value={address?.number}
              onChange={(event) => {
                setAddress({ ...address, number: String(event.target.value) });
                setHasChange(true);
              }}
            />
            <Input
              height="30px"
              label="Complemento (opcional)"
              value={address.complement}
              onChange={(event) => {
                setAddress({ ...address, complement: event.target.value });
                setHasChange(true);
              }}
            />
          </>
        </AddressDataInputContainer>
        <AddressDataInputContainer>
          <Input
            height="30px"
            label="Bairro"
            value={address.district}
            onChange={(event) => {
              setAddress({ ...address, district: event.target.value });
              setHasChange(true);
            }}
          />

          <Input
            height="30px"
            label="Cidade"
            value={address.city}
            onChange={(event) => {
              setAddress({ ...address, city: event.target.value });
              setHasChange(true);
            }}
          />

          <Input
            height="30px"
            label="Estado"
            value={address.state}
            onChange={(event) => {
              setAddress({ ...address, state: event.target.value });
              setHasChange(true);
            }}
          />
        </AddressDataInputContainer>
      </AddressDataContainer>

      <Button
        disabled={
          !address?.zip_code ||
          !address?.street ||
          !address?.number ||
          !address?.district ||
          !address?.city ||
          !address?.state ||
          loading ||
          addressError
        }
        buttonColor="#fe9501"
        buttonBoxShadowColor="#fe9501"
        style={{ marginLeft: 0 }}
        height="35px"
        onClick={() => {
          hasChange ? handleSubmit() : nextStep();
        }}
      >
        {!loading && "Continuar"}
            {loading && <ReactLoading color={primary} height={24} width={24} type="spin" />}
      </Button>
    </Container>
  );
};

export default AddressStep;
