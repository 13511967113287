import styled from 'styled-components';
import { middleGray } from 'styles/colorProvider';

export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border: none;
    border-radius: 10px;
    height: 100%;
    cursor: pointer;
    flex-direction: row;
    padding: 0;
    -webkit-box-shadow: 4px 4px 10px -2px #000000; 
    box-shadow: 4px 4px 10px -2px #000000;
    margin: 8px 0;

    .icon-container{
        display: flex;
        width: 10%;
        align-items: center;
        justify-content: center;
        margin: 10px;
    }

    .info-container{
        display: flex;
        width: 75%;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin: 10px;

        h3{
            font-weight: 500;
            color: ${middleGray};
            font-size: 16px;
            margin: 0;
        }
        p{
            font-size: 12px;
            margin: 0;
        }
    }
`