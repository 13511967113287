import { FaMotorcycle, FaTruck, FaCarSide } from 'react-icons/fa';
import { formatPolicyStatus } from 'utils/policyStatus';

import { Container, ContainerText, BrandAndModel, Plate } from './styles';

function ObjectVehicleNew({ data, onClick, active }) {
  return (
    <Container onClick={onClick} active={active}>
      <div className="description">
        {(data?.id_type === 1 || data?.id_type === 2) && <FaCarSide color={formatPolicyStatus(data?.status)?.color} size={28} />}
        {(data?.id_type === 4 || data?.id_type === 7) && (
          <FaMotorcycle color={formatPolicyStatus(data?.status)?.color} size={28} />
        )}
        {data?.id_type === 8 && <FaTruck color={formatPolicyStatus(data?.status)?.color} size={28} />}

        <ContainerText>
          <BrandAndModel>{data?.model}</BrandAndModel>
          <Plate>{`${data?.build_year}/${data?.year}`}</Plate>
        </ContainerText>
      </div>
    </Container>
  );
}

export default ObjectVehicleNew;
