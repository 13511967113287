import styled from 'styled-components';
import { primary, secondary } from 'styles/colorProvider';
export const ContainerMainInputs = styled.div`
  display: flex;
`;
export const ContainerRadio = styled.div`
  margin: 1rem 0;
  label {
    margin: 1rem;
  }
`;
export const Radio = styled.input`
  :after {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${primary};
  }

  :checked:after {
    transition-delay: 0.2s, 170ms;
    transition-property: background-color;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${secondary};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${primary};
  }
`;
