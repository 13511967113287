import { success, error, warn } from 'styles/colorProvider';

export const formatPaymentStatus = (status) => {
  if (status === 'open') return { label: 'Aguardando pagamento', color: warn };
  if (status === 'awaiting_payment') return { label: 'Aguardando pagamento', color: warn };
  if (status === 'awaiting_pix') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_creditcard') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_debitcard') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_billet') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'paid') return { label: 'Pago', color: success };
  if (status === 'overdue') return { label: 'Vencido', color: error };
  if (status === 'due') return { label: 'Vencido', color: error };
  if (status === 'canceled') return { label: 'Cancelado', color: error };
};
