import styled from 'styled-components';
import { primary, white, lightGray, success, lighterGray, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  .section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    color: ${primary};
    cursor: pointer;
    margin: 30px 0 10px 0px;
    user-select: none;

    @media (max-width: 1000px) {
      margin: 20px 0px;
    }
  }

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const DivPicker = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  p {
    font-size: 12px;
    margin: 0 0 4px 0;
  }

  @media (max-width: 1000px) {
    justify-content: space-around;
    width: 100%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1.5%;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 28px;
    color: ${primary};
    margin-top: 5%;

    @media (max-width: 1000px) {
      text-align: center;
      margin-top: 10%;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const DivImage = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 98%;
  padding: 20px;
  border: 1px solid ${lightGray};
  margin-right: 30px;
  border-radius: 5px;

  hr {
    width: 95%;
    margin: -5px 5% 0 0;
    background-color: ${lightGray};
    border: none;
    height: 1px;
  }

  @media (max-width: 1000px) {
    width: 80%;
    margin: 20px 0;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  .button-container {
    display: flex;
    width: 300px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    margin: 0;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Image = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 100px;
  margin: 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &&:hover {
    opacity: 0.8;
  }
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 360px 360px 350px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  .grid-item {
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .items-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const AreaButtom = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 5px 0 10px 0;
  text-align: center;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${success};
    border: none !important;
    width: 100px;
    height: 28px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    background: transparent;
    transition: all ease-in-out 0.15s;
    margin-top: 10px;

    :disabled {
      text-decoration: line-through;
      opacity: 0.5;
    }

    :hover {
      background: ${lighterGray};
      opacity: 0.8;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  position: fixed;
  height: 8vh;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: ${lightGray};
  color: white;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1000px) {
    height: 10vh;
  }

  .area-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 20vw;
    margin-right: 40px;
    height: 100%;
    align-items: center;

    @media (max-width: 1000px) {
      width: 100vw;
      margin: 0;
      justify-content: space-around;
    }
  }
`;

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 100%;
  text-align: left;

  h3 {
    margin: -5px 0 10px 0;
    font-size: 16px;
    padding: 0;
    color: ${middleGray};
    font-weight: 500;
  }
  div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    margin-top: 10px;
  }
`;
export const ListProduct = styled.div`
  display: grid;
  align-items: center;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: flex-start;

  p {
    font-size: 16px;
    margin: 8px 0;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
`;

export const AutocompleteOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: fls;

  cursor: pointer;
  transition: all ease-in-out 0.3s;
  padding: 5px 15px;
  margin-bottom: 1px solid ${lighterGray};
  margin-bottom: 10px;

  &:hover {
    background-color: ${lighterGray};
  }

  .title {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    width: 100%;
  }

  .subtitle {
    margin: 0px;
    font-size: 12px;
    text-align: left;
    width: 100%;
    color: ${lightGray};
  }
`;
