import styled from 'styled-components';
import { primary, secondary, white } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    font-size: 26px;
    max-width: 100%;
  }

  .subtitle {
    font-size: 24px;
    @media (max-width: 1000px) {
      font-size: 20px;
      font-weight: normal;
    }
  }
`;

export const ClaimInformationArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;

  .go-back {
    font-size: 12px;
    margin: 0px;
    color: ${primary};
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: ${secondary};
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

export const ClaimOptionsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`

export const ClaimOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all ease-in-out 0.3s;
  box-sizing: border-box;
  padding: 20px;
  width: 200px;
  min-height: ${(props) => props.minHeight};
  margin: 15px 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: ${primary};
  cursor: pointer;
  border: ${(props) => props.selected && `1px solid ${primary}`};
  font-size: 14px;
  text-align: center;

  img {
    height: 100px;
  }

  .selected {
    border: 1px solid ${primary};
  }

  &:hover {
    border: 1px solid ${primary};
  }

  @media (max-width: 1000px) {
    width: 260px;
    font-size: 20px;

    img {
      height: 150px;
    }
  }
`;

export const StepArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 60vh;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .left {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
      flex-direction: column;
      width: 100%;
      flex-direction: column-reverse;
    }
  }

  .right {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 1000px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .options-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 1000px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .input-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .new-third-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: ${secondary};
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: ${primary};
    }
  }

  label {
    font-size: 14px;
    margin-top: 20px;
    margin-left: 20px;
  }
`;

export const DivPicker = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 1000px) {
    justify-content: space-around;
    width: 100%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
`;
