//////////////////////////////BILLETS//////////////////////////////
import React from 'react';
import { Header, SideMenu } from 'components';
import { Container, ContentArea, Title, Div } from './styles';

function Billets() {
    return (
        <>
            <Header />
            <Container>
                <SideMenu />
                <ContentArea>
                    <Div>
                        <Title>BOLETOS</Title>
                    </Div>
                </ContentArea>
            </Container>
        </>
    );
}

export default Billets;