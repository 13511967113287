/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import DataTable from 'react-data-table-component';
import api from 'api';
import { Header, SideMenu, Search, Button, Input, Dialog, Select } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton } from './styles';
import { loadingColor, primary, white } from 'styles/colorProvider';
import { TiFilter } from 'react-icons/ti';
import { dataColumns } from './helper';
import { toast } from 'react-toastify';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ExportToExcel from 'components/ExportToExcel/index';

function Policies({ history }) {
  const scrollParent = useRef();
  const [loading, setLoading] = useState(false);
  const [policiesList, setPoliciesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const [selectStatus, setSelectStatus] = useState('all');
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [automaticCharge, setAutomaticCharge] = useState(false);
  const [touchFilter, setTouchFilter] = useState();
  const [partnerVendors, setPartnerVendors] = useState([]);
  const [selectedPartnerVendor, setSelectedPartnerVendor] = useState('all');
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportData, setExportData] = useState();
  const [openExport, setOpenExport] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/company-policies`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          search,
          startdate: startData === '' ? undefined : startData,
          enddate: endData === '' ? undefined : endData,
          status: selectStatus === 'all' ? undefined : selectStatus,
          author: selectedPartnerVendor === 'all' ? undefined : selectedPartnerVendor,
          automaticcharge: automaticCharge === true ? true : false,
          page: currentPage,
          take: 20,
        },
        json: true,
      });

      const vendorsResp = await api({
        method: 'GET',
        url: `/list-partner-vendors`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });

      setPartnerVendors(vendorsResp?.data);

      setPoliciesList(resp?.data?.policies);
      setTotalCount(resp?.data?.totalCount);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoading(false);
      setTouchFilter(false);
    } catch (error) {
      if (error.response.status === 401) history.push('/nao-autorizado');
    }
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);

        const resp = await api({
          method: 'GET',
          url: `/company-policies`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          params: {
            search,
            startdate: startData === '' ? undefined : startData,
            enddate: endData === '' ? undefined : endData,
            status: selectStatus === 'all' ? undefined : selectStatus,
            author: selectedPartnerVendor === 'all' ? undefined : selectedPartnerVendor,
            automaticcharge: automaticCharge === true ? true : false,
            page,
            take: 20,
          },

          json: true,
        });

        const data = policiesList;
        const ndata = data.concat(resp?.data?.policies);

        setCurrentPage(page);
        setPoliciesList(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  const handleClear = () => {
    setSelectStatus('all');
    setStartdata('');
    setEndData('');
  };

  const handleExport = async () => {
    setLoadingExport(true);

    try {
      const resp = await api({
        method: 'GET',
        url: `/report/export-policies`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
          feature: 'export-policies',
        },
        json: true,
      });
      setExportData(resp.data);
      setLoadingExport(false);
    } catch (error) {
      toast.error("Não foi possível exportar apólices", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setOpenExport(false);
      setLoadingExport(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setAutomaticCharge(e.target.checked);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleOpenFilter = () => {
    setOpen(true);
  };

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar apólice">
      <Select height="30px" label="Filtrar por status" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
        <option value={'all'}>Todos os Status</option>
        <option value={'active'}>Ativo</option>
        <option value={'canceled'}>Cancelado</option>
      </Select>

      <Select
        height="30px"
        label="Filtrar por agente de vendas"
        value={selectedPartnerVendor}
        onChange={(e) => setSelectedPartnerVendor(e.target.value)}
      >
        <option value={'all'}>Todos os agentes</option>
        {partnerVendors?.map((item) => (
          <option value={item?.id}>{item?.name?.toUpperCase()}</option>
        ))}
      </Select>

      <Input
        value={startData}
        onChange={(e) => setStartdata(e.target.value)}
        widht="30%"
        label="Data inicial"
        mask="99/99/9999"
        height="30px"
      />
      <Input
        value={endData}
        onChange={(e) => setEndData(e.target.value)}
        widht="30%"
        label="Data final"
        mask="99/99/9999"
        height="30px"
      />
      <div className="checkbox-item">
        <input type="checkbox" id="checkbox" checked={automaticCharge} onChange={handleCheckboxChange} />
        <label for="checkbox">Apenas apólices com cobrança automática ativa</label>
      </div>

      <ModalButton>
        <Button
          height="35px"
          outlined
          disabled={selectStatus === 'all' && startData === '' && endData === ''}
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          height="35px"
          onClick={() => {
            setTouchFilter(true);
            loadData();
            setOpen(false);
          }}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);
        setExportData();
      }}
      width="50%"
      title="Exportar apólices"
    >
      {exportData?.hasData ? (
        <>
          <p style={{ margin: 0 }}>Seu arquivo está pronto!</p>
          <small style={{ margin: 0, marginBottom: 20 }}>{exportData?.data?.length} registros encontrados.</small>
          <ExportToExcel exportData={exportData?.data} fileName={`apolices_${new Date().toLocaleDateString()}`} />
        </>
      ) : exportData?.message ? (
        <p>{exportData?.message}</p>
      ) : (
        <Button height="35px" style={{ margin: 0, marginTop: 30 }} onClick={handleExport} disabled={loadingExport}>
          {loadingExport ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Exportar'}
        </Button>
      )}
    </Dialog>
  );

  return (
    <>
      <Header />
      <Container>
        {renderExportDialog()}
        {renderFilterDialog()}
        <SideMenu />

        <ContentArea>
          <Div>
            <Title>APÓLICES</Title>
            <div className="containerTitle">
              <Button title="Exportar" height="30px" style={{ marginRight: -5 }} onClick={() => setOpenExport(true)}>
                <RiFileExcel2Fill size={18} />
              </Button>
              <Button title="Filtrar" height="30px" onClick={handleOpenFilter}>
                <TiFilter size={18} />
              </Button>
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <DataTable
                onRowClicked={(row) => history.push(`/backoffice/detalhes-da-apolice/${row?.id}`)}
                persistTableHead
                responsive
                columns={dataColumns}
                data={policiesList}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma apólice encontrada</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {policiesList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default Policies;
