/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@material-ui/core/IconButton';
import { saveFilesBucket } from 'utils/aws';
import api from 'api';
import Lottie from 'react-lottie';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'moment/locale/pt-br';
import moment from 'moment';
import ClaimCanceled from 'assets/claim_canceled.json';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import ClaimFinished from 'assets/success.json';
import { loadingColor } from 'styles/colorProvider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Header, Button, ClaimFileItem, Dialog } from 'components';
import {
  Container,
  ContentArea,
  StepArea,
  StepAreaMobile,
  Title,
  BodyArea,
  ContainerUpload,
  Divider,
  ClaimOption,
  OptionsArea,
  ContentFile,
  EmptyArea,
  LoadingArea,
} from './styles';
import { BsChevronLeft } from 'react-icons/bs';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { formatPlate } from 'react-data-formatter';
import { success } from 'styles/colorProvider';
import { Alert } from '@material-ui/lab';

function ClaimDetails({ history, location }) {
  const claimId = parseInt(location.pathname.split('/sinistro/')[1]);

  const fileInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [claimData, setClaimData] = useState({});
  const [hasUploadFiles, setHasUploadFiles] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [upFiles, setupFiles] = useState([]);
  const [counter, setCounter] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [protocol, setProtocol] = useState();
  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);
  const [descriptionFeedback, setDescriptionFeedback] = useState('');

  function stepsStatus(status) {
    if (claimData?.TB_CLAIM_CAUSE_LOSS?.id === 3 || claimData?.TB_CLAIM_CAUSE_LOSS?.id === 6) {
      if (status === 'created' || status === 'noticed') return 1;
      if (status === 'awaiting_payment' || status === 'paid_deductible') return 2;
      if (status === 'repair_approved') return 3;
      if (status === 'in_repair') return 4;
      if (status === 'repair_finished') return 5;
      if (status === 'avaliation') return 6;
    }
    if (claimData?.TB_CLAIM_CAUSE_LOSS?.id !== 3 || claimData?.TB_CLAIM_CAUSE_LOSS?.id !== 6) {
      if (status === 'created' || status === 'noticed') return 1;
      if (status === 'awaiting_payment' || status === 'paid_deductible') return 2;
      if (status === 'indemnity_total_approved' || status === 'indemnity_partial_approved' || status === 'indemnity_disapproved')
        return 3;
      if (status === 'indemnity_total_paid' || status === 'indemnity_partial_paid') return 4;
      if (status === 'avaliation') return 5;
    }
    if (!status) return;
  }

  function getSteps(status) {
    if (claimData?.TB_CLAIM_CAUSE_LOSS?.id === 3 || claimData?.TB_CLAIM_CAUSE_LOSS?.id === 6) {
      if (status === 'created' || status === 'noticed') return [1];
      if (status === 'awaiting_payment' || status === 'paid_deductible') return [1, 2];
      if (status === 'repair_approved') return [1, 2, 3];
      if (status === 'in_repair') return [1, 2, 3, 4];
      if (status === 'repair_finished') return [1, 2, 3, 4, 5];
      if (status === 'avaliation') return [1, 2, 3, 4, 5, 6];
    }
    if (claimData?.TB_CLAIM_CAUSE_LOSS?.id !== 3 || claimData?.TB_CLAIM_CAUSE_LOSS?.id !== 6) {
      if (status === 'created' || status === 'noticed') return [1];
      if (status === 'awaiting_payment' || status === 'paid_deductible') return [1, 2];
      if (status === 'indemnity_total_approved' || status === 'indemnity_partial_approved') return [1, 2, 3];
      if (status === 'indemnity_total_paid' || status === 'indemnity_partial_paid') return [1, 2, 3, 4];
      if (status === 'avaliation') return [1, 2, 3, 4, 5];
    }
    if (!status) return;
  }

  function navStep(id) {
    const stepList = getSteps(claimData?.client_status);
    if (stepList.includes(id)) setCurrentStep(id);
  }

  const steps =
    !claimData?.indemnity_total && !claimData?.indemnity_partial
      ? [
          { id: 1, label: 'Em análise' },
          { id: 2, label: 'Pagamento da franquia' },
          { id: 3, label: 'Reparo aprovado' },
          { id: 4, label: 'Em reparo' },
          { id: 5, label: 'Reparo finalizado' },
          { id: 6, label: 'Avaliação final' },
        ]
      : [
          { id: 1, label: 'Em análise' },
          { id: 2, label: 'Pagamento da franquia' },
          { id: 3, label: 'Indenização aprovada' },
          { id: 4, label: 'Indenização paga' },
          { id: 5, label: 'Avaliação final' },
        ];

  const ratings = {
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Bom',
    4: 'Ótimo',
    5: 'Excelente',
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${ratings[value]}`;
  }

  const loadData = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/claim-client/${claimId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setProtocol(response.data?.protocol);
    let stepsInitital = getSteps(response.data?.status);
    setClaimData(response.data);
    setLoading(false);
    setCurrentStep(stepsInitital.at(-1));
  };

  const handleUploadIncidentReport = (e) => {
    const oldList = upFiles;
    oldList.push(e.target.files[0]);
    setupFiles(oldList);
    setCounter(counter + 1);
  };

  const handleUploadFiles = async (resp) => {
    setLoading(true);
    upFiles.map((i) => {
      i.file_type = null;
      i.description = description;
    });

    const allFiles = upFiles;

    for (const file of allFiles) {
      const ext = file.name.replaceAll(' ', '').toLowerCase().split('.').pop();
      saveFilesBucket({
        Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
        Body: file,
        Key: `${protocol}/${file.file_type}/${new Date().getTime()}.${ext}`,
        onEnd: async (err, data) => {
          const list = [...uploadedFiles, { type_file: file.file_type, description: file.description, url: data?.file }];
          setUploadedFiles(list);

          await api({
            method: 'POST',
            url: `/send-file-claim`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('splitriskwl-authtoken'),
            },
            data: {
              files: list,
              description: description,
              protocol: protocol,
              id_claim: claimId,
            },
            json: true,
          });
          setLoading(false);
          handleClose();
          toast.success('Arquivo enviado com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        },
      });
    }
  };

  const handleSubmitFeedBack = async () => {
    setLoadingFeedback(true);
    const resp = await api({
      method: 'POST',
      url: `/send-feedback`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      data: {
        id_claim: claimId,
        rating: value,
        description: descriptionFeedback,
      },
      json: true,
    });
    toast.success('Avaliação enviado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
    setLoadingFeedback(false);
    window.location.reload();
  };

  const handleClose = () => {
    setHasUploadFiles(false);
    setDescription('');
    setupFiles([]);
  };

  const handleRemoveIncidentReport = (index) => {
    const oldList = upFiles;
    oldList.splice(index, 1);
    setupFiles(oldList);
    setCounter(counter + 1);
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <>
            <Title>
              <IconButton size="small" onClick={() => history.goBack()} style={{ marginLeft: -10, marginRight: 10 }}>
                <BsChevronLeft size={18} />
              </IconButton>{' '}
              DETALHES DO SINISTRO
            </Title>
            <ContentArea>
              {claimData?.client_status === 'claim_canceled' ||
              claimData?.client_status === 'claim_disapproved' ||
              claimData?.client_status === 'indemnity_disapproved' ? (
                <EmptyArea>
                  <Lottie
                    autoplay
                    style={{ marginTop: 50 }}
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: ClaimCanceled,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={180}
                    width={180}
                  />
                  <h3>
                    {(claimData?.client_status === 'claim_canceled' && 'Sinistro cancelado') ||
                      (claimData?.client_status === 'claim_disapproved' && 'Sinistro reprovado') ||
                      (claimData?.client_status === 'indemnity_disapproved' && 'Indenização reprovado')}
                  </h3>
                  <p>Caso tenha dúvidas, entre em contato na nossa central de atendimento.</p>
                </EmptyArea>
              ) : null}

              {claimData?.client_status === 'finished' && (
                <EmptyArea>
                  <Lottie
                    autoplay
                    style={{ marginTop: 50 }}
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: ClaimFinished,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={180}
                    width={180}
                  />
                  <h3 style={{ color: success }}>Sinistro finalizado</h3>
                  <p>Obrigado por fazer parte da Split Risk.</p>
                </EmptyArea>
              )}
              {claimData?.client_status !== 'finished' &&
              claimData?.client_status !== 'claim_canceled' &&
              claimData?.client_status !== 'claim_disapproved' &&
              claimData?.client_status !== 'indemnity_disapproved' ? (
                <>
                  <StepArea>
                    <Stepper activeStep={stepsStatus(claimData?.client_status) - 1} alternativeLabel>
                      {steps?.map((step) => (
                        <Step key={step.id} onClick={() => navStep(step.id)}>
                          <StepLabel style={{ cursor: 'pointer' }}>
                            <p style={{ fontSize: 12 }}>{step.label}</p>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </StepArea>
                  <StepAreaMobile>
                    <Stepper activeStep={stepsStatus(claimData?.client_status) - 1} orientation="vertical">
                      {steps?.map((step) => (
                        <Step key={step.id} onClick={() => navStep(step.id)}>
                          <StepLabel style={{ cursor: 'pointer' }}>{step.label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </StepAreaMobile>
                  <Divider />

                  {!claimData?.indemnity_total && !claimData?.indemnity_partial ? (
                    <>
                      <BodyArea>
                        {currentStep === 1 && (
                          <div className="item">
                            <small className="concluded">
                              Aviso criado em: {moment(claimData?.created_at).format('DD/MM/YYYY HH:mm')}
                            </small>
                            <h3>⌛ Estamos analisando o seu sinistro!</h3>
                            <p>
                              Recebemos o seu aviso de colisão para o veículo{' '}
                              {claimData?.TB_POLICIES?.TB_VEHICLE?.plate === null
                                ? 'sem placa'
                                : `da placa ${formatPlate(claimData?.TB_POLICIES?.TB_VEHICLE?.plate)}`}
                            </p>
                            {claimData?.status === 'created' && (
                              <>
                                <h3>📋 Deseja adicionar algum documento?</h3>
                                <OptionsArea>
                                  <ClaimOption selected={hasUploadFiles === true} onClick={() => setHasUploadFiles(true)}>
                                    Sim
                                  </ClaimOption>
                                  <ClaimOption selected={hasUploadFiles === false} onClick={() => setHasUploadFiles(false)}>
                                    Não
                                  </ClaimOption>
                                </OptionsArea>
                                {hasUploadFiles === true && (
                                  <>
                                    <Dialog open={hasUploadFiles} onClose={handleClose}>
                                      <h3>Enviar arquivo</h3>
                                      <ContainerUpload>
                                        <TextareaAutosize
                                          minRows={1}
                                          placeholder="Descrição do arquivo..."
                                          value={description}
                                          onChange={(e) => setDescription(e.target.value)}
                                          style={{
                                            padding: 10,
                                            fontFamily: 'Nunito',
                                            fontSize: 14,
                                            marginLeft: '1%',
                                            marginBottom: '2%',
                                            width: '95%',
                                            height: '50px',
                                            borderRadius: 5,
                                            borderColor: '#d1d2d4',
                                          }}
                                        />
                                        <div>
                                          <label
                                            style={{ cursor: 'pointer', color: `${success}` }}
                                            className="new-third-button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              fileInputRef.current.click();
                                            }}
                                          >
                                            <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                                          </label>
                                          <input
                                            type="file"
                                            accept="image/*, application/pdf"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={(e) => handleUploadIncidentReport(e)}
                                          />
                                        </div>
                                      </ContainerUpload>
                                      <ContentFile>
                                        {upFiles?.map((report, index) => (
                                          <ClaimFileItem
                                            key={index}
                                            data={report}
                                            onClick={() => handleRemoveIncidentReport(index)}
                                          />
                                        ))}
                                        {upFiles.length >= 1 && description ? (
                                          <Button onClick={handleUploadFiles} style={{ margin: 0, marginTop: 30 }}>
                                            {loading ? (
                                              <ReactLoading color="white" height={24} width={24} type="spin" />
                                            ) : (
                                              'Enviar'
                                            )}
                                          </Button>
                                        ) : (
                                          <Alert style={{ margin: '20px 0 0px 0' }} severity="warning">
                                            Atenção, todos os campos são obrigatórios!
                                          </Alert>
                                        )}
                                      </ContentFile>
                                    </Dialog>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        {currentStep === 2 && (
                          <div className="item">
                            <h3>✅ Seu sinistro foi aprovado!</h3>

                            {claimData?.client_status === 'awaiting_payment' ? (
                              <>
                                <p>
                                  Falta pouco para iniciarmos o reparo do seu veículo! Por favor, realize o pagamento da franquia.
                                </p>
                                <Alert style={{ margin: '20px 0 0px 0' }} severity="warning">
                                  Sua franquia foi liberada para pagamento, clique em <b>REALIZAR PAGAMENTO</b> e será
                                  redirecionado para suas faturas!
                                </Alert>
                                <Button
                                  height="30px"
                                  style={{ margin: 0, marginTop: 30 }}
                                  onClick={() => history.push(`/minhas-faturas/${claimData?.id_policy}`)}
                                >
                                  Realizar pagamento
                                </Button>
                              </>
                            ) : (
                              <p>
                                Pagamento aprovado, agora só aguardar a nossa equipe prosseguir com os processos internos para dar
                                andamento ao seu sinistro.
                              </p>
                            )}
                          </div>
                        )}

                        {currentStep === 3 && (
                          <div className="item">
                            <h3>🛠️ Seu reparo foi aprovado!</h3>
                            <p>
                              Estamos procurando uma oficina para seu veículo e assim que localizado, iremos retornar com as
                              informações.
                            </p>
                          </div>
                        )}

                        {currentStep === 4 && (
                          <div className="item">
                            <h3>🛠️ Seu veículo já pode entrar em reparo!</h3>
                            <p>
                              Para iniciar o reparo, favor conduzir o seu veículo até a <b>{claimData?.TB_PROVIDER?.name}</b>.
                            </p>
                            <small style={{ margin: '20px 0' }}>
                              {claimData?.TB_PROVIDER?.name} <br /> Endereço: {claimData?.TB_PROVIDER?.TB_ADDRESS?.street} n°{' '}
                              {claimData?.TB_PROVIDER?.TB_ADDRESS?.number}, {claimData?.TB_PROVIDER?.TB_ADDRESS?.district},{' '}
                              {claimData?.TB_PROVIDER?.TB_ADDRESS?.city}/{claimData?.TB_PROVIDER?.TB_ADDRESS?.state} <br /> CEP:{' '}
                              {claimData?.TB_PROVIDER?.TB_ADDRESS?.zip_code} <br /> Telefone: {claimData?.TB_PROVIDER?.phone}
                            </small>
                            <p>Fique tranquilo que entraremos em contato assim que tivermos novidades.</p>
                          </div>
                        )}

                        {currentStep === 5 && (
                          <div className="item">
                            <h3>✅ Boas notícias!</h3>
                            <p>
                              Finalizamos o reparo do seu veículo. <br /> Estamos preparando para realizar a entrega e em breve
                              entraremos em contato com você.
                            </p>
                          </div>
                        )}

                        {currentStep === 6 && claimData?.client_status === 'avaliation' && (
                          <div className="item">
                            <h3>💬 Agora, precisamos ouvir você!</h3>
                            <p>Por favor, não deixe de nos avaliar.</p>
                            <p>Queremos muito saber a sua opinião sobre o nosso atendimento.</p>
                            <Box
                              sx={{
                                width: 250,
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '15px',
                              }}
                            >
                              <Rating
                                name="hover-feedback"
                                value={value}
                                precision={1}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                              {value !== null && <Box sx={{ ml: 2 }}>{ratings[hover !== -1 ? hover : value]}</Box>}
                            </Box>
                            <TextareaAutosize
                              minRows={1}
                              value={descriptionFeedback}
                              onChange={(e) => setDescriptionFeedback(e.target.value)}
                              placeholder="O seu Feedback vai nos ajudar a melhorar nossos serviços..."
                              style={{
                                padding: 10,
                                fontFamily: 'Nunito',
                                fontSize: 14,
                                marginTop: '3%',
                                width: '100%',
                                height: '80px',
                                borderRadius: 5,
                                borderColor: '#d1d2d4',
                              }}
                            />
                            <Button
                              height="30px"
                              style={{ margin: 0, marginTop: 30 }}
                              onClick={handleSubmitFeedBack}
                              disabled={loadingFeedback || !descriptionFeedback || !value}
                            >
                              {loadingFeedback ? (
                                <ReactLoading color="white" height={24} width={24} type="spin" />
                              ) : (
                                'Avaliar agora'
                              )}
                            </Button>
                          </div>
                        )}
                      </BodyArea>
                    </>
                  ) : (
                    <>
                      <BodyArea>
                        {currentStep === 1 && (
                          <div className="item">
                            <small className="concluded">
                              Aviso criado em: {moment(claimData?.created_at).format('DD/MM/YYYY HH:mm')}
                            </small>
                            <h3>⌛ Estamos analisando o seu sinistro!</h3>
                            <p>
                              Recebemos o seu aviso de colisão para o veículo{' '}
                              {claimData?.TB_POLICIES?.TB_VEHICLE?.plate === null
                                ? 'sem placa'
                                : `da placa ${formatPlate(claimData?.TB_POLICIES?.TB_VEHICLE?.plate)}`}
                            </p>
                            {claimData?.status === 'created' && (
                              <>
                                <h3>📋 Deseja adicionar algum documento?</h3>
                                <OptionsArea>
                                  <ClaimOption selected={hasUploadFiles === true} onClick={() => setHasUploadFiles(true)}>
                                    Sim
                                  </ClaimOption>
                                  <ClaimOption selected={hasUploadFiles === false} onClick={() => setHasUploadFiles(false)}>
                                    Não
                                  </ClaimOption>
                                </OptionsArea>
                                {hasUploadFiles === true && (
                                  <>
                                    <Dialog open={hasUploadFiles} onClose={handleClose}>
                                      <h3>Enviar arquivo</h3>
                                      <ContainerUpload>
                                        <TextareaAutosize
                                          minRows={1}
                                          placeholder="Descrição do arquivo..."
                                          value={description}
                                          onChange={(e) => setDescription(e.target.value)}
                                          style={{
                                            padding: 10,
                                            fontFamily: 'Nunito',
                                            fontSize: 14,
                                            marginLeft: '1%',
                                            marginBottom: '2%',
                                            width: '95%',
                                            height: '50px',
                                            borderRadius: 5,
                                            borderColor: '#d1d2d4',
                                          }}
                                        />
                                        <div>
                                          <label
                                            style={{ cursor: 'pointer', color: `${success}` }}
                                            className="new-third-button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              fileInputRef.current.click();
                                            }}
                                          >
                                            <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                                          </label>
                                          <input
                                            type="file"
                                            accept="image/*, application/pdf"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={(e) => handleUploadIncidentReport(e)}
                                          />
                                        </div>
                                      </ContainerUpload>
                                      <ContentFile>
                                        {upFiles?.map((report, index) => (
                                          <ClaimFileItem
                                            key={index}
                                            data={report}
                                            onClick={() => handleRemoveIncidentReport(index)}
                                          />
                                        ))}
                                        {upFiles.length >= 1 && description ? (
                                          <Button onClick={handleUploadFiles} style={{ margin: 0, marginTop: 30 }}>
                                            {loading ? (
                                              <ReactLoading color="white" height={24} width={24} type="spin" />
                                            ) : (
                                              'Enviar'
                                            )}
                                          </Button>
                                        ) : (
                                          <Alert style={{ margin: '20px 0 0px 0' }} severity="warning">
                                            Atenção, todos os campos são obrigatórios!
                                          </Alert>
                                        )}
                                      </ContentFile>
                                    </Dialog>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        {currentStep === 2 && (
                          <div className="item">
                            <h3>✅ Seu sinistro foi aprovado!</h3>
                            {claimData?.client_status === 'awaiting_payment' ? (
                              <>
                                <p>
                                  Falta pouco para iniciarmos o reparo do seu veículo! Por favor, realize o pagamento da franquia.
                                </p>
                                <Alert style={{ margin: '20px 0 0px 0' }} severity="warning">
                                  Sua franquia foi liberada para pagamento, clique em <b>REALIZAR PAGAMENTO</b> e será
                                  redirecionado para suas faturas!
                                </Alert>
                                <Button
                                  height="30px"
                                  style={{ margin: 0, marginTop: 30 }}
                                  onClick={() => history.push(`/minhas-faturas/${claimData?.id_policy}`)}
                                >
                                  Realizar pagamento
                                </Button>
                              </>
                            ) : (
                              <p>
                                Pagamento aprovado, agora só aguardar a nossa equipe prosseguir com os processos internos para dar
                                andamento ao seu sinistro.
                              </p>
                            )}
                          </div>
                        )}

                        {currentStep === 3 && (
                          <div className="item">
                            <h3>🤩 Boas notícias! Sua indenização foi aprovado!</h3>
                            <p>
                              Estamos organizando para efetuar o pagamento da sua indenização, fique atento que entraremos em
                              contato pra falar sobre o assunto.
                            </p>
                          </div>
                        )}

                        {currentStep === 4 && (
                          <div className="item">
                            <h3>✅ Sua indenização foi paga!</h3>
                            <p>
                              Obrigado por fazer parte da Split Risk! Caso tenha alguma dúvida ou algum problema com seu
                              recebimento, entre em contato em até 48 horas, se não, iremos prosseguir pra finalização do seu
                              sinistro.
                            </p>
                            <small style={{ margin: '20px 0' }}>
                              E-mail: <b>contato@splitrisk.com.br</b>
                            </small>
                          </div>
                        )}

                        {currentStep === 5 && claimData?.client_status === 'avaliation' && (
                          <div className="item">
                            <h3>💬 Agora, precisamos ouvir você!</h3>
                            <p>Por favor, não deixe de nos avaliar.</p>
                            <p>Queremos muito saber a sua opinião sobre o nosso atendimento.</p>
                            <Box
                              sx={{
                                width: 250,
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '15px',
                              }}
                            >
                              <Rating
                                name="hover-feedback"
                                value={value}
                                precision={1}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                              {value !== null && <Box sx={{ ml: 2 }}>{ratings[hover !== -1 ? hover : value]}</Box>}
                            </Box>
                            <TextareaAutosize
                              minRows={1}
                              value={descriptionFeedback}
                              onChange={(e) => setDescriptionFeedback(e.target.value)}
                              placeholder="O seu Feedback vai nos ajudar a melhorar nossos serviços..."
                              style={{
                                padding: 10,
                                fontFamily: 'Nunito',
                                fontSize: 14,
                                marginTop: '3%',
                                width: '100%',
                                height: '80px',
                                borderRadius: 5,
                                borderColor: '#d1d2d4',
                              }}
                            />
                            <Button
                              height="30px"
                              style={{ margin: 0, marginTop: 30 }}
                              onClick={handleSubmitFeedBack}
                              disabled={loadingFeedback || !descriptionFeedback || !value}
                            >
                              {loadingFeedback ? (
                                <ReactLoading color="white" height={24} width={24} type="spin" />
                              ) : (
                                'Avaliar agora'
                              )}
                            </Button>
                          </div>
                        )}
                      </BodyArea>
                    </>
                  )}
                </>
              ) : null}
            </ContentArea>
          </>
        )}
      </Container>
    </>
  );
}

export default ClaimDetails;
