import moment from 'moment';
import PartnersData from 'corporateSettings.json';
import { formatProposalStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';

export const dataColumns = [
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Cliente',
    selector: (row) => row?.user?.name,
    width: '15%',
    sortable: true,
  },
  {
    name: 'CPF',
    selector: (row) => formatCPF(row?.user?.cpf),
    width: '15%',
    sortable: true,
  },
  {
    name: 'Placa',
    selector: (row) => formatPlate(row?.vehicle?.plate) || 'Sem placa',
    width: '10%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => formatProposalStatus(row?.status)?.label,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Agente de vendas',
    selector: (row) => row?.author?.name,
    width: '20%',
    sortable: true,
  },
  {
    name: 'Enviada em',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '15%',
    sortable: true,
  },
];
