import styled from 'styled-components';
import { primary, white, middleGray, lightGray, darkGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  height: 88vh;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-top: 50px;

  @media (max-width: 1000px) {
    width: 80%;
    margin-left: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 15px;

  @media (max-width: 1000px) {
    align-items: center;
    justify-content: center;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0%;

  .lastItem {
    height: 9%;
  }

  img {
    width: 250px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 80%;
  }
`;

export const ItemPolicy = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 40%;
  padding: 2.2% 3%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
    margin-left: 50px;
  }
`;

export const ItemPolicyAvatar = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 40%;
  padding: 2.2% 3%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
    margin-left: 50px;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${darkGray};
  margin: 0;
  margin-top: 3%;

  span {
    color: ${middleGray};
  }
`;

export const DivImage = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 98%;
  padding: 20px;
  border: 1px solid ${lightGray};
  margin-right: 30px;
  border-radius: 5px;

  hr{
    width: 95%;
    margin: -5px 5% 0 0;
    background-color: ${lightGray};
    border: none;
    height: 1px;
  }

  @media (max-width: 1000px) {
    width: 80%;
    margin: 20px 0;
  }
`;

export const Image = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 100px;
  margin: 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &&:hover {
    opacity: 0.8;
  }
`;