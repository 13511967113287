/* eslint-disable no-restricted-globals */
import api from 'api/index';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const getUserPartners = async ({ setLoading, setUserPartners, setUserPartnerInfo, search }) => {
  try {
    setLoading(true);

    const userPartnersResp = await api({
      method: 'GET',
      url: `/list-backoffice-user-partners`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        search,
      },
      json: true,
    });

    setUserPartners(userPartnersResp?.data?.userPartners);
    const { loading_partner, max_loading_partner } = userPartnersResp?.data?.partner;

    setUserPartnerInfo({
      loading: loading_partner,
      maxLoading: max_loading_partner,
    });
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const createUserCommission = async ({
  setLoading,
  setUserPartnerCommissionInfo,
  userPartnerCommissionInfo,
  setOpenModalAdd,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
}) => {
  try {
    setLoading(true);

    await api({
      method: 'POST',
      url: `/create-backoffice-commission-user`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      data: {
        id_client: userPartnerCommissionInfo?.client_id,
        percent: userPartnerCommissionInfo?.percent,
        enabled: userPartnerCommissionInfo?.enabled,
      },
      json: true,
    });

    setUserPartnerCommissionInfo({
      id: undefined,
      client_id: undefined,
      enabled: false,
      percent: '',
    });

    setOpenModalAdd(false);

    await loadData({
      setCurrentPage,
      setUsercommissionsList,
      setHasNextPage,
      setLoading,
      setTotalCount,
      setTouchFilter,
      loadingDataTable,
      setLoadingDataTable,
    });
    await getUserPartners({ setLoading, setUserPartners, setUserPartnerInfo });
    //

    // BackOfficePartnerCreate
  } catch (error) {
    console.log(error);

    toast.error(error?.response?.data?.error, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  } finally {
    setLoading(false);
  }
};
const updateUserCommission = async ({
  setLoading,
  setUserPartnerCommissionInfo,
  userPartnerCommissionInfo,
  setOpenModalEdit,
  loadData,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  loadingDataTable,
  setLoadingDataTable,
}) => {
  try {
    setLoading(true);

    await api({
      method: 'PUT',
      url: `/backoffice-commission-user/${userPartnerCommissionInfo?.id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      // userId, percent, enabled
      data: {
        percent: userPartnerCommissionInfo?.percent,
        enabled: userPartnerCommissionInfo?.enabled,
      },
      json: true,
    });

    setUserPartnerCommissionInfo({
      id: undefined,
      client_id: undefined,
      enabled: false,
      percent: '',
    });

    setOpenModalEdit(false);

    await loadData({
      setCurrentPage,
      setUsercommissionsList,
      setHasNextPage,
      setLoading,
      setTotalCount,
      setTouchFilter,
      loadingDataTable,
      setLoadingDataTable,
    });
    //

    // BackOfficePartnerCreate
  } catch (error) {
    console.log(error);

    toast.error(error?.response?.data?.error, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  } finally {
    setLoading(false);
  }
};
const handleRemoveUserCommission = async ({
  setLoading,
  setUserPartnerCommissionInfo,
  userPartnerCommissionInfo,
  setOpenModalRemove,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
}) => {
  try {
    setLoading(true);

    await api({
      method: 'DELETE',
      url: `/backoffice-commission-user/${userPartnerCommissionInfo?.id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      // userId, percent, enabled
      json: true,
    });

    setUserPartnerCommissionInfo({
      ...userPartnerCommissionInfo,
      id: undefined,
    });

    setOpenModalRemove(false);

    await loadData({
      setCurrentPage,
      setUsercommissionsList,
      setHasNextPage,
      setTotalCount,
      setTouchFilter,
      setLoading,
      loadingDataTable,
      setLoadingDataTable,
    });
    await getUserPartners({ setLoading, setUserPartners, setUserPartnerInfo });
    //

    // BackOfficePartnerCreate
  } catch (error) {
    console.log(error);

    toast.error(error?.response?.data?.error, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  } finally {
    setLoading(false);
  }
};

const loadData = async ({
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setLoading,
  setTotalCount,
  setTouchFilter,
  search,
  loadingDataTable,
  setLoadingDataTable,
}) => {
  setLoading(true);
  setLoadingDataTable(true);
  try {
    setCurrentPage(1);
    const resp = await api({
      method: 'GET',
      url: `/list-backoffice-partners`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        search,
        page: 1,
        take: 12,
      },
      json: true,
    });

    console.log(resp?.data?.userComissions);

    setUsercommissionsList(resp?.data?.userComissions);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
    setLoadingDataTable(false);
    setTouchFilter(false);
  } catch (error) {
    if (error.response.status === 401) history.push('/nao-autorizado');
  }
};

const handleLoadMore = async ({
  touchFilter,
  search,
  startData,
  endData,
  selectStatus,
  selectedPartnerVendor,
  currentPage,
  hasNextPage,
  loadingMore,
  setLoadingMore,
  usercommissionsList,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
}) => {
  if (touchFilter) return;

  const page = currentPage + 1;

  if (!hasNextPage || loadingMore) return;
  try {
    const page = currentPage + 1;
    setLoadingMore(true);
    const resp = await api({
      method: 'GET',
      url: `/list-backoffice-partners`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        search,
        page,
        take: 12,
      },
      json: true,
    });
    const data = usercommissionsList;
    const ndata = data.concat(resp?.data?.userComissions);
    setCurrentPage(page);
    setUsercommissionsList(ndata);
    setHasNextPage(resp?.data?.hasNextPage);
  } catch (error) {
    console.log(error);
  } finally {
    setLoadingMore(false);
  }
};

export { getUserPartners, loadData, handleLoadMore, createUserCommission, updateUserCommission, handleRemoveUserCommission };
