import { useBarcode } from 'react-barcodes';
import moment from '../../../../../node_modules/moment/moment';

import { TableRow, TableColumn, Table, Text1, Text2, Text4, Text3, ColumnBlock } from '../style';

import logoSicoob from 'assets/billet/sicoob-logo.png';

import { updateValueToReal } from 'utils/updateValueToReal';
import { Scale } from '../../../../../node_modules/@mui/icons-material/index';

export const Bank = ({ userData }) => {
  const { inputRef } = useBarcode({
    value: userData.barcode,
    options: {
      format: 'ITF',
      displayValue: false,
      // width: 1,
      height: 60,
    },
  });
  return (
    <Table>
      <TableRow>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="both">
          <img src={logoSicoob} alt="Logo Sicoob" style={{ width: 150, height: 40 }} />
        </TableColumn>
        <TableColumn
          notHaveVerticalBorder="top"
          notHaveHorizontalBorder="right"
          padding="0 10px"
          flexDirection="row"
          alignItems="end"
        >
          <Text1>756-0</Text1>
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flexDirection="row" alignItems="end">
          <Text2>{userData?.typeable_barcode}</Text2>
        </TableColumn>
      </TableRow>

      {/* Linha 2 */}

      <TableRow>
        <TableColumn flex="1 1 65%" notHaveVerticalBorder="top" notHaveHorizontalBorder="both">
          <Text4>Local de pagamento</Text4>
          <Text3>{userData?.paymentPlace}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 35%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4 fontWeight="bold">Vencimento</Text4>
          <Text3 textAlign="end">{moment(userData?.due_at).format('DD/MM/YYYY')}</Text3>
        </TableColumn>
      </TableRow>

      {/* Linha 3 */}
      <TableRow>
        <TableColumn gap="10px" notHaveVerticalBorder="top" notHaveHorizontalBorder="both" flex="1 1 65%">
          <Text4>Beneficiário </Text4>
          <ColumnBlock>
            <Text3>{userData?.beneficiary}</Text3>
            <Text3>CNPJ: 36.851.667/0001-45</Text3>
            <Text3>AVENIDA JOAO NAVES DE AVILA,</Text3>
            <Text3>CENTRO, UBERLANDIA, MG. CEP: 3840042</Text3>
          </ColumnBlock>
        </TableColumn>
        <TableColumn flex="1 1 35%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Agência / Código beneficiário</Text4>
          <Text3 textAlign="end">3299-9 / {userData?.clientCode}</Text3>
        </TableColumn>
      </TableRow>

      {/* Linha 4 */}
      <TableRow>
        <TableColumn flex="1 1 8%" notHaveVerticalBorder="top" notHaveHorizontalBorder="both">
          <Text4>Data do documento</Text4>
          <Text3>{moment(userData?.emission_date).format('DD/MM/YYYY')}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 30%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>N. documento</Text4>
          <Text3>{userData.numberDocument}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 6%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Espécie</Text4>
          <Text3>{userData.typeDocument}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 6%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Aceite</Text4>
          <Text3>{userData?.aceite}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 8%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Data processamento</Text4>
          <Text3>{moment(userData?.dateProcess).format('DD/MM/YYYY')}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 33.9%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Nosso número</Text4>
          <Text3 textAlign="end">
            {userData?.walletCode} / {userData.our_number}
            {/* {userData?.walletCode} / 0000001 */}
          </Text3>
        </TableColumn>
      </TableRow>

      {/* linha 5 */}
      <TableRow>
        <TableColumn flex="1 1 15%" notHaveVerticalBorder="top" notHaveHorizontalBorder="both">
          <Text4>Uso do Banco</Text4>
          <Text3></Text3>
        </TableColumn>
        <TableColumn flex="1 1 7%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Carteira</Text4>
          <Text3>{userData?.walletCode}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 8%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Moeda</Text4>
          <Text3>{userData?.type}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 20%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Quantidade</Text4>
        </TableColumn>
        <TableColumn flex="1 1 15%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Valor</Text4>
          <Text3></Text3>
        </TableColumn>
        <TableColumn flex="1 1 34.9%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4 fontWeight="bold">Valor documento</Text4>
          <Text3 textAlign="end">{updateValueToReal(userData?.value)}</Text3>
        </TableColumn>
      </TableRow>

      {/* linha 6 */}
      <TableRow>
        <TableColumn flex="1 1 65%" gap="7px" notHaveVerticalBorder="top" notHaveHorizontalBorder="both">
          <Text4 whiteSpace="normal" fontWeight="bold">
            Instruções
          </Text4>
          <Text3>{userData?.instructions}</Text3>
        </TableColumn>
        <TableColumn
          flex="1 35%"
          padding="0"
          justifyContent="start"
          alignItems="stresh"
          notHaveHorizontalBorder="right"
          notHaveVerticalBorder="top"
        >
          <TableRow haveVerticalBorder="bottom" flexDirection="column" height="26px">
            <Text4>(-) Desconto</Text4>
            <Text4 textAlign="end"></Text4>
          </TableRow>
          <TableRow haveVerticalBorder="bottom" flexDirection="column" height="26px">
            <Text4>(-) Outras deduções/Abatimentos</Text4>
            <Text4 textAlign="end"></Text4>
          </TableRow>
          <TableRow haveVerticalBorder="bottom" flexDirection="column" height="26px">
            <Text4>(+) Mora/Multa/Juros</Text4>
            <Text4 textAlign="end"></Text4>
          </TableRow>
          <TableRow haveVerticalBorder="bottom" flexDirection="column" height="26px">
            <Text4>(+) Outros acrécimos</Text4>
            <Text4 textAlign="end"></Text4>
          </TableRow>
          <TableRow flexDirection="column" height="26px">
            <Text4>(=) Valor cobrado</Text4>
            <Text4 textAlign="end"></Text4>
          </TableRow>
        </TableColumn>
      </TableRow>
      <TableRow>
        <TableColumn flex="1 1 34%" notHaveHorizontalBorder="both" notHaveVerticalBorder="both">
          <Text4>Pagador</Text4>
          <Text3 fontWeight="bold">
            {userData?.name} - {userData?.cpf}
          </Text3>
          <Text3>
            {userData?.street} {userData?.number} - {userData?.city}{' '}
          </Text3>
          <Text3>
            {userData?.CEP} - {userData?.UF}
          </Text3>
        </TableColumn>
        <TableColumn notHaveHorizontalBorder="both" notHaveVerticalBorder="both">
          <Text4>Código de baixa:</Text4>
        </TableColumn>
      </TableRow>
      <br />
      <TableRow>
        <TableColumn alignItems="start" notHaveHorizontalBorder="both" notHaveVerticalBorder="both">
          <Text4>Sacador/Avalista</Text4>
        </TableColumn>
        <TableColumn notHaveHorizontalBorder="both" notHaveVerticalBorder="both">
          <Text4>Autenticação mecânica - Ficha de Compensação</Text4>
        </TableColumn>
      </TableRow>
      <TableRow>
        <svg ref={inputRef} />
      </TableRow>
    </Table>
  );
};
