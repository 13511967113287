import React, { useState, useEffect } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FiDollarSign } from 'react-icons/fi';
import { MdChevronLeft } from 'react-icons/md';
import { BsPrinter, BsShieldCheck, BsShieldExclamation, BsShieldX } from 'react-icons/bs';
import { useLocation, useHistory } from 'react-router-dom';
import { BiMenuAltRight } from 'react-icons/bi';
import { Dialog, Select, Button } from 'components';
import { Container, ItemMenu, TextItem, Div, Subtitle, ModalButton, Description, MobileMenu } from './styles';
import { middleGray } from 'styles/colorProvider';
import { toast } from 'react-toastify';

function PolicyMenu({ policyId, onCancel, onConfirm, policyData }) {
  const location = useLocation();
  const path = location.pathname;

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [listMotives, setListMotives] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingCancel, setLoadingCancel] = React.useState(false);
  const [selectMotive, setSelectMotive] = useState('');
  const [textInput, setTextInput] = useState('');

  const motiveCancel = async () => {
    const numberMotive = {
      method: 'GET',
      url: `/motives-cancel`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(numberMotive);
    setListMotives(response.data.motives);
    setLoading(false);
  };

  const cancelPolicy = async () => {
    setLoadingCancel(true);

    try {
      await api({
        method: 'POST',
        url: `/cancel-policy`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: policyId,
          id_motive: selectMotive,
          motive_detail: textInput,
        },

        json: true,
      });
      toast.success('Cancelamento realizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingCancel(false);
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingCancel(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    cancelPolicy();
    onConfirm(true);
    window.location.reload();
  };

  useEffect(() => {
    motiveCancel();
  }, []);

  return (
    <>
      <MobileMenu>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <BiMenuAltRight size={26} />
        </IconButton>

        <Menu
          style={{ marginTop: '50px', marginLeft: '-40px' }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            dense
            onClick={() => {
              history.push('/minhas-apolices');
            }}
          >
            <MdChevronLeft size="18" />
            <TextItem>Minha apólice</TextItem>
          </MenuItem>

          <MenuItem
            dense
            selected={path.includes('/detalhes-da-apolice')}
            onClick={() => {
              history.push(`/detalhes-da-apolice/${policyId}`);
            }}
          >
            <BsShieldCheck size="18" />
            <TextItem>Detalhes da apólice</TextItem>
          </MenuItem>

          <MenuItem
            dense
            selected={path.includes('/minhas-faturas')}
            onClick={() => {
              history.push(`/minhas-faturas/${policyId}`);
            }}
          >
            <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Faturas</TextItem>
          </MenuItem>

          <MenuItem
            dense
            onClick={() => {
              history.push(`/detalhes-da-apolice/${policyId}/imprimir`);
            }}
          >
            <BsPrinter size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Imprimir apólice</TextItem>
          </MenuItem>

          <MenuItem
            dense
            selected={path.includes('/meus-sinistros')}
            onClick={() => {
              history.push(`/meus-sinistros/${policyId}`);
            }}
          >
            <BsShieldExclamation size="18" />
            <TextItem>Sinistros</TextItem>
          </MenuItem>
        </Menu>
      </MobileMenu>

      <Container>
        <ItemMenu
          onClick={() => {
            history.push('/minhas-apolices');
          }}
        >
          <MdChevronLeft size="18" />
          <TextItem>Minhas apólice</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/detalhes-da-apolice') && 'select'}
          onClick={() => {
            history.push(`/detalhes-da-apolice/${policyId}`);
          }}
        >
          <BsShieldCheck size="18" />
          <TextItem>Detalhes da apólice</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/minhas-faturas') && 'select'}
          onClick={() => {
            history.push(`/minhas-faturas/${policyId}`);
          }}
        >
          <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Faturas</TextItem>
        </ItemMenu>

        <ItemMenu
          onClick={() => {
            history.push(`/backoffice/listar-documentos-apolice/${policyId}`);
          }}
        >
          <BsPrinter size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Imprimir apólice</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/meus-sinistros') && 'select'}
          onClick={() => {
            history.push(`/meus-sinistros/${policyId}`);
          }}
        >
          <BsShieldExclamation size="18" />
          <TextItem>Sinistros</TextItem>
        </ItemMenu>
      </Container>
    </>
  );
}

export default PolicyMenu;
