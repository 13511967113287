import React from 'react';
import 'moment/locale/pt-br';
import moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Container, ContainerArea, Div, Title, Subtitle, HistoricArea } from './styles';
import { formatComplaintStatus } from 'utils/complaintStatus';

function ComplaintHistoric({ data }) {
  return (
    <>
      <Container>
        <ContainerArea>
          <div className="description">
            <Div>
              <Title>Dados da reclamação</Title>

              <Subtitle>
                Motivo da reclamação: <span>{data?.type?.name}</span>
              </Subtitle>
              <Subtitle>
                Reclamação criada: <span>{moment(data?.created_at).format('DD/MM/YYYY - HH:mm')}</span>
              </Subtitle>
              <Subtitle>
                Abertura: <span>{data?.author?.name}</span>
              </Subtitle>
              <Subtitle>
                Status: <span>{data?.status?.name}</span>
              </Subtitle>
            </Div>
          </div>
        </ContainerArea>
        <ContainerArea>
          <div className="description">
            <Div>
              <Title>Histórico de atualização</Title>

              {data?.historic?.map((item, index) => (
                <HistoricArea>
                  <Timeline position="alternate">
                    <TimelineItem>
                      <TimelineOppositeContent style={{ fontSize: 12 }}>
                        <p style={{ color: formatComplaintStatus(item?.type).color, margin: 0, marginTop: -8 }}>
                          {formatComplaintStatus(item?.type).label} <br />
                        </p>
                        {moment(item?.created_at).format('DD/MM/YYYY - HH:mm') || 'Não foi atualizado'}
                      </TimelineOppositeContent>
                      <TimelineSeparator style={{ height: '100px', marginBottom: -230 }}>
                        <TimelineDot />
                        {index !== data?.historic?.length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent style={{ fontSize: 12, textAlign: 'justify' }}>{item?.description}</TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </HistoricArea>
              ))}
            </Div>
          </div>
        </ContainerArea>
      </Container>
    </>
  );
}

export default ComplaintHistoric;
