import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { secondary, darkGray, buttonTextColor } from 'styles/colorProvider';

export const UploadButton = styled(Button)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.outlined ? 'transparent' : props?.backgroundColor ? props?.backgroundColor : secondary};
    border: ${(props) => (props.showRequiredError ? `3px solid red` : 'none')};
    outline: none;
    cursor: pointer;
    margin: 0;
    transition: ${(props) => (props.showRequiredError ? 'none' : `all ease-in-out 0.3s`)};
    font-size: ${(props) => props.fontSize || '12px'};
    font-weight: normal;
    border-radius: 20px;
    height: ${(props) => props.height || '30px'};
    width: ${(props) => props.width || '230px'};
    color: ${(props) => (buttonTextColor && !props.outlined ? buttonTextColor : darkGray)};

    &&:hover {
      opacity: 0.7;
      color: ${(props) => (buttonTextColor && !props.outlined ? buttonTextColor : darkGray)};
      background-color: ${secondary}
    }

    &&:disabled {
      opacity: 0.7;
      cursor: default;
      color: ${(props) => (buttonTextColor && !props.outlined ? buttonTextColor : darkGray)};
    }

    @media (max-width: 1000px) {
      width: ${(props) => props.mobileWidth || '100%'};
    }
  }
`;
