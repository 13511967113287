import styled from 'styled-components';
import { primary, white, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100px;
  background-color: ${white};
  border-radius: 10px;
  bottom: 20px;
  left: 20px;
  box-shadow: rgba(100, 100, 100, 0.2) 0px 7px 29px 0px;
  box-sizing: border-box;
  padding: 20px;
  animation: fadein 1s;
  z-index: 9999;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .icon {
    height: 50px;
    margin-right: 20px;
  }

  .title {
    color: ${primary};
    font-size: 16px;
    margin: 0px;
  }

  .text {
    color: ${middleGray};
    font-size: 12px;
    margin: 0px;
    margin-right: 20px;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 1000px) {
    width: 90%;
    height: auto;

    .icon {
      display: none;
    }

    .text {
      color: ${middleGray};
      font-size: 10px;
      margin: 0px;
      margin-right: 5px;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
