/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import api from 'api';
import { Header, Input, Button, ConfirmDialog } from 'components';
import { Container, ContentArea, Title, LoadingArea, Subtitle, Form, FormPermissions, ButtonLine } from './styles';
import { error, success, white } from 'styles/colorProvider';
import { useHistory } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { formatRoleCategory } from 'utils/userCategory';
import { toast } from 'react-toastify';

function AccessGroupsDetails({ location }) {
  const history = useHistory();
  const accessGroupId = parseInt(location.pathname.split('/backoffice/grupo-de-acesso/')[1]);

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [accessGroupData, setAccessGroupData] = useState({});
  const [featureData, setFeatureData] = useState();
  const [rolesData, setRolesData] = useState([]);
  const [features, setFeatures] = useState([]);

  const loadData = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/access-group/${accessGroupId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });

    const features = await api({
      method: 'GET',
      url: `/list-features`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });

    const roles = await api({
      method: 'GET',
      url: `/all-access-groups`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });

    setFeatureData(features.data);
    setRolesData(roles.data);
    setAccessGroupData({ ...response.data, birthdate: moment(response.data.birthdate).format('DD/MM/YYYY') });
    setFeatures(response.data?.id_features?.split(',')?.map((i) => Number(i)));

    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChangeFeature = (feature) => {
    const isChecked = features?.findIndex((i) => i === feature) > -1;
    const curretFeaturesList = [...features];

    if (isChecked) {
      curretFeaturesList.splice(
        curretFeaturesList.findIndex((listItem) => listItem === feature),
        1,
      );
      setFeatures(curretFeaturesList);
      setAccessGroupData({ ...accessGroupData, features: curretFeaturesList });
    } else {
      curretFeaturesList.push(feature);
      setFeatures(curretFeaturesList);
      setAccessGroupData({ ...accessGroupData, features: curretFeaturesList });
    }
  };

  const handleUpdateAccessGroup = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'PATCH',
        url: `/access-group/${accessGroupId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          ...accessGroupData,
          name: accessGroupData.name,
          description: accessGroupData.description,
          features,
        },
        json: true,
      });
      setLoadingUpdate(false);
      toast.success('Grupo de acesso atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleDeleteAccessGroup = async () => {
    setLoadingDelete(true);
    try {
      await api({
        method: 'DELETE',
        url: `/access-groups/${accessGroupId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          ...accessGroupData,
          name: accessGroupData.name,
          description: accessGroupData.description,
          features,
        },
        json: true,
      });
      setLoadingDelete(false);
      toast.success('Grupo de acesso excluído com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      history.push('/backoffice/grupo-de-acesso');
    } catch (error) {
      setLoadingDelete(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const categories = [...new Set(featureData?.map((feature) => feature.category))].filter((i) => i !== 'user').sort();

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={showConfirmDelete}
      title="Excluir grupo de acesso"
      content="Tem certeza que deseja este grupo de acesso??"
      onConfirm={handleDeleteAccessGroup}
      onCancel={() => setShowConfirmDelete(false)}
      loading={loadingDelete}
    />
  );

  return (
    <>
      {renderConfirmDialog()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={success} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <Title>
              <IconButton size="small" style={{ marginRight: 15 }} onClick={() => history.push('/backoffice/grupos-de-acesso')}>
                <MdChevronLeft size={24} />
              </IconButton>
              DETALHES DO GRUPO DE ACESSO
            </Title>

            <Form>
              <div class="grid-item">
                <Input
                  height="35px"
                  label="Nome"
                  placeholder="Nome"
                  value={accessGroupData?.name}
                  onChange={(e) => setAccessGroupData({ ...accessGroupData, name: e.target.value })}
                />
                <Input
                  height="35px"
                  label="Descrição"
                  placeholder="Descrição"
                  value={accessGroupData?.description}
                  onChange={(e) => setAccessGroupData({ ...accessGroupData, description: e.target.value })}
                />
              </div>
            </Form>

            <Subtitle>Selecionar permissões:</Subtitle>

            <FormPermissions style={{ paddingTop: 10 }}>
              {categories
                ?.filter((cat) => cat !== 'public-api')
                ?.map((category) => (
                  <div class="permission-section">
                    <h3>{formatRoleCategory(category)}</h3>
                    <div className="items-list">
                      {featureData
                        ?.filter((f) => f.category === category)
                        ?.map((feature) => (
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id={feature?.id}
                              name={feature?.id}
                              checked={features?.findIndex((i) => i === feature.id) > -1}
                              onChange={() => handleChangeFeature(feature?.id)}
                            />
                            <label for={feature?.id}>{feature?.name}</label>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </FormPermissions>

            <ButtonLine style={{ marginTop: 20 }}>
              <Button
                styles={{ color: '#fff' }}
                backgroundColor={error}
                height="35px"
                disabled={loadingDelete}
                onClick={() => setShowConfirmDelete(true)}
              >
                {loadingDelete ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Excluir gupo de acesso'}
              </Button>
              <Button height="35px" disabled={loadingUpdate} onClick={handleUpdateAccessGroup}>
                {loadingUpdate ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Salvar alterações'}
              </Button>
            </ButtonLine>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default AccessGroupsDetails;
