import styled from 'styled-components';
import { styled as SliderStyled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { secondary } from 'styles/colorProvider';

export const CommissionSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: -15px;
`;

export const SliderContent = SliderStyled(Slider)({
  color: '#c7c7c7',
  height: 8,
  width: '80%',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: secondary,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '100%',
    backgroundColor: secondary,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -80%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -80%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export const CommissionValue = styled.div`
  display: flex;
  flex-direction: row;
  h5 {
    margin: 0;
  }
  p {
    margin: 0;
    strong {
      color: ${secondary};
      font-size: 26px;
    }
  }
  div {
    margin-left: 30px;
  }
`;
