import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Avatar from '@mui/material/Avatar';
import Cookies from 'js-cookie';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import api from 'api';

import { formatCPF, formatPhone } from 'react-data-formatter';
import { Header } from 'components';
import {
  Container,
  ContentArea,
  Title,
  ItemPolicy,
  ItemPolicyAvatar,
  PolicyInformationArea,
  Subtitle,
  Image,
  DivImage,
  LoadingArea,
  Text,
} from './styles';
import { loadingColor } from 'styles/colorProvider';
import { MdChevronLeft } from 'react-icons/md';
import { ChangePhoto, Dialog } from 'components';

function Profile({ history }) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [openPhoto, setOpenPhoto] = useState(false);

  const getUser = async () => {
    const userInfo = {
      method: 'GET',
      url: `/me`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(userInfo);

    setUserData(response.data);
    setLoading(false);
  };

  useEffect(() => getUser(), []);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          {loading && (
            <LoadingArea>
              <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
            </LoadingArea>
          )}
          {!loading && (
            <>
              <Title>
                <IconButton size="small" style={{ marginRight: 10 }} onClick={() => history.push('/minhas-apolices')}>
                  <MdChevronLeft size={24} />
                </IconButton>
                Meu perfil
              </Title>

              <PolicyInformationArea>
                <ItemPolicy>
                  <Subtitle>Dados do usuário</Subtitle>
                  <Text>
                    Nome completo: <span>{userData?.name}</span>
                  </Text>
                  <Text>
                    CPF: <span>{formatCPF(userData?.cpf)}</span>
                  </Text>
                  <Text>
                    Data de nascimento: <span>{moment(userData?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</span>
                  </Text>

                  {userData?.gender === null && (
                    <Text>
                      Sexo: <span>Não registrado</span>
                    </Text>
                  )}
                  {userData?.gender === 1 && (
                    <Text>
                      Sexo: <span>Masculino</span>
                    </Text>
                  )}
                  {userData?.gender === 2 && (
                    <Text>
                      Sexo: <span>Feminino</span>
                    </Text>
                  )}

                  <Text>
                    Telefone: <span>{formatPhone(userData?.phone)}</span>
                  </Text>
                  <Text>
                    E-mail: <span>{userData?.email}</span>
                  </Text>
                  <Text>
                    Empresa: <span>{userData?.company}</span>
                  </Text>
                </ItemPolicy>
                <ItemPolicyAvatar>
                  <Avatar
                    onClick={() => setOpenPhoto(true)}
                    src={userData?.signedUrl}
                    alt={userData?.name}
                    image={userData?.signedUrl}
                    sx={{ width: 150, height: 150 }}
                    style={{ marginRight: 9, cursor: 'pointer' }}
                  />
                  <p style={{ fontSize: 14, cursor: 'pointer' }} onClick={() => setOpenPhoto(true)}>
                    Alterar foto
                  </p>
                </ItemPolicyAvatar>
              </PolicyInformationArea>
              <Dialog open={openPhoto} onClose={() => setOpenPhoto()} title="Foto de perfil">
                <ChangePhoto userData={userData} onChange={() => getUser()} handleCloseModal={() => setOpenPhoto()} />
              </Dialog>
            </>
          )}
        </ContentArea>
      </Container>
    </>
  );
}

export default Profile;
