import moment from 'moment';

import { BsPrinter } from 'react-icons/bs';


export const dataColumns = [
    {
        selector: (row) => (
            <BsPrinter size="15" style={{ marginRight: 10 }} />
        ),
        width: '10%',
    },
    {
        name: 'Nº da apólice',
        selector: (row) => row?.code_policy,
        width: '20%',
    },
    {
        name: 'Inicio da vigência',
        selector: (row) => moment(row?.effective_date).format('DD/MM/YYYY'),
        width: '20%',
    },
    {
        name: 'Fim da vigência',
        selector: (row) => moment(row?.expiry_date).format('DD/MM/YYYY'),
        width: '20%',
    },

];
