import React from 'react';
import 'moment/locale/pt-br';
import { FaUserShield } from 'react-icons/fa';
import { Container, Div, Title, Subtitle, IconMobile } from './styles';
import { success } from 'styles/colorProvider';
import { formatCPF, formatCNPJ } from 'react-data-formatter';

function ProviderItem({ provider, children }) {
  return (
    <>
      <Container>
        <div className="description">
          <div className="iconCar">
            <FaUserShield size={23} color={success} />
          </div>
          <Div>
            <Title>{provider?.name}</Title>
            <Subtitle>{provider?.document.length === 11 ? formatCPF(provider.document) : formatCNPJ(provider.document)}</Subtitle>
          </Div>

          <Div>
            <Title>{provider?.email}</Title>
            <Subtitle>{provider?.phone}</Subtitle>
          </Div>

          <Div>
            <Subtitle>
              {provider?.TB_ADDRESS?.city}/{provider?.TB_ADDRESS?.state}
            </Subtitle>
          </Div>

          <IconMobile>
            <FaUserShield size={40} color={success} />
          </IconMobile>

          {children}
        </div>

      </Container>
    </>
  );
}

export default ProviderItem;
