import styled from 'styled-components';
import { middleGray, primary, white } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const PaymentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  .loadingArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      margin-top: 20px;
      font-size: 16px;
      color: ${middleGray};
      font-weight: 400;
      max-width: 80%;
      text-align: center;
    }
  }

  .billetArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 90px;
      margin-bottom: 0px;
    }
    h3 {
      margin-top: 10px;
    }
  }

  .buttonArea {
    display: flex;
    flex-direction: row;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }
  
  @media (max-width: 1000px) {
    margin-top: 7%;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  h1{
    margin-top: 40px;
    font-size: 28px;
    color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
  }

  @media (max-width: 1001.9px) {
    justify-content: center;
    flex-direction: column;
    width: 45%;
  }
`;
