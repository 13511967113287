import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 844px;
  min-width: 844px;
`;

export const Dotted = styled.hr`
  border-style: dashed;
  width: 100%;
`;
export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
`;
export const TableRow = styled.tr`
  flex: ${({ flex }) => flex || '1 0 26px'};
  display: flex;
  align-items: stretch;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'strash'};
  height: ${(height) => height || '26px'};
  min-height: ${(minHeight) => minHeight || '26px'};
  padding: ${({ padding }) => padding || '0 5px'};
  border-left: ${({ haveHorizontalBorder }) =>
    haveHorizontalBorder === 'left' || haveHorizontalBorder === 'both' ? '1px solid black' : 0};
  border-right: ${({ haveHorizontalBorder }) =>
    haveHorizontalBorder === 'right' || haveHorizontalBorder === 'both' ? '1px solid black' : 0};
  border-top: ${({ haveVerticalBorder }) =>
    haveVerticalBorder === 'top' || haveVerticalBorder === 'both' ? '1px solid black' : 0};
  border-bottom: ${({ haveVerticalBorder }) =>
    haveVerticalBorder === 'bottom' || haveVerticalBorder === 'both' ? '1px solid black' : 0};
  box-sizing: border-box;
`;

export const TableColumn = styled.td`
  flex: ${({ flex }) => flex || '1 0 26px'};
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  align-items: ${({ alignItems }) => alignItems || 'start'};
  gap: ${({ gap }) => gap || '0'};
  height: ${(height) => height || '26px'};
  min-height: ${(minHeight) => minHeight || '26px'};
  border: 1px solid black;
  border-left: ${({ notHaveHorizontalBorder }) =>
    notHaveHorizontalBorder === 'left' || notHaveHorizontalBorder === 'both' ? 0 : '1px solid black'};
  border-right: ${({ notHaveHorizontalBorder }) =>
    notHaveHorizontalBorder === 'right' || notHaveHorizontalBorder === 'both' ? 0 : '1px solid black'};
  border-top: ${({ notHaveVerticalBorder }) =>
    notHaveVerticalBorder === 'top' || notHaveVerticalBorder === 'both' ? 0 : '1px solid black'};
  border-bottom: ${({ notHaveVerticalBorder }) =>
    notHaveVerticalBorder === 'bottom' || notHaveVerticalBorder === 'both' ? 0 : '1px solid black'};
  padding: ${({ padding }) => padding || '0 5px'};
  box-sizing: border-box;
`;

export const ColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextDefault = styled.span`
  margin: 0;
  font-size: 22px;
  flex: fit-content;
  white-space: ${({ whiteSpace }) => whiteSpace || 'nowrap'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  width: ${({ width }) => width || '100%'};
  text-align: ${({ textAlign }) => textAlign || 'unset'};
  min-height: ${(height) => height || '12px'};
`;

export const Text1 = styled(TextDefault)``;

export const Text2 = styled(TextDefault)`
  font-size: 13px;
`;

export const Text3 = styled(TextDefault)`
  font-size: 10px;
  text-transform: uppercase;
  white-space: normal;
  line-height: 11px;
`;

export const Text4 = styled(TextDefault)`
  font-size: 8px;
`;

export const Div = styled.div`
  width: 844px;
  justify-content: center;
  text-align: center;
  @media print {
    display: none;
  }
`;

export const Text5 = styled.p`
  width: 400px;
  font-size: 10px;
  margin: 0 auto;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
`;

export const Button = styled.button`
  margin-top: 5px;
`;
