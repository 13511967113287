import React from 'react';
import 'moment/locale/pt-br';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteButton({ onClick }) {
    return (
        <>
            <DeleteIcon
                sx={{ marginRight: '15px' }}
                onClick={onClick}
                style={{ cursor: 'pointer' }}
                onMouseOver={({ target }) => (target.style.color = '#e74c3c')}
                onMouseOut={({ target }) => (target.style.color = '#121212')}
            />
        </>
    );
}

export default DeleteButton;