/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import { loadingColor, white } from 'styles/colorProvider';
import { Container, LoadingArea, ButtonArea, Printable } from './styles';
import { formatCPF, formatCNPJ, formatPlate, formatPhone, formatZipCode, formatCurrency } from 'react-data-formatter';
import { Button } from 'components';
import { BsPrinter } from 'react-icons/bs';
import { cpf } from 'cpf-cnpj-validator';

function BackofficePrintPolicy({ location, history }) {
  const policyId = parseInt(location.pathname.split('detalhes-da-apolice/')[1]);

  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';

  const loadData = async () => {
    const policyIssue = {
      method: 'GET',
      url: `/get-issue-document/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyIssue);
    let valueService = 0;
    let valueCoverage = 0;
    let hasServices = false;
    let hasCoverages = false;
    for (const item of response.data?.policyData?.coverages) {
      if (item.isService) {
        hasServices = true;
        valueService += Number(item.value);
      } else {
        hasCoverages = true;
        valueCoverage += Number(item.value);
      }
    }

    if (response?.data?.policyData?.oldCoverages?.length) {
      hasCoverages = true;
      setValueCoverage(response?.data?.price?.total_premium);
      if (response?.data?.proposalData) response.data.proposalData.iof = response?.data?.price?.iof;
    } else {
      setValueCoverage(valueCoverage);
    }

    setPolicyData(response.data);

    setHasServices(hasServices);
    setHasCoverages(hasCoverages);

    setLoading(false);
    setValueService(valueService);
  };

  useEffect(() => loadData(), []);

  const adjustmentFactor = policyData?.vehicle?.auction
    ? '70%'
    : (policyData?.policyData?.adjustment || policyData?.policyData?.adjustment_factor) + '%';

  const isCpf = cpf.isValid(policyData?.client?.cpf);

  return (
    <>
      {!loading && (
        <ButtonArea>
          <Button style={{ color: white }} height="30px" width="130px" onClick={() => window.print()}>
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <Container>
          <Printable>
            <div className="header">
              <div className="logoArea">
                <img src={policyData?.logo} />
              </div>
              <div className="titleArea">
                <h2>
                  CERTIFICADO DE SEGURO AUTOMÓVEL
                  <br /> Processo Susep: 15414.618377/2020-87
                </h2>
              </div>
            </div>

            <section>
              <h2 className="name">Olá, {policyData?.client?.name?.split(' ')[0]}</h2>
              <h2 className="descriptionheader">
                Esse documento contém todas as informações simplificadas sobre o seu seguro auto.
                <br /> A gente fez isso para você que está sempre em movimento
              </h2>

              <div className="columns">
                <div className="leftArea">
                  <h4>Nº da apólice</h4>
                  <p className="highlight">{policyData?.header?.numberPolicy}</p>
                  <h4>Nº da proposta</h4>
                  <p className="highlight">{policyData?.header?.numberProposal}</p>
                  {/* <h4>Nº do certificado</h4>
                                    <p className="highlight">{policyData?.header?.numberCertificate}</p> */}
                  <h4>Ramo</h4>
                  <p className="highlight">{policyData?.header?.branch}</p>
                </div>
                <div className="rightArea">
                  <h4>Data da emissão</h4>
                  <p className="dateInfo">{moment(policyData?.header?.emissionDate).format('DD/MM/YYYY [às]  HH:mm')}</p>
                  <h4>Início Vigência Individual</h4>
                  <p className="dateInfo">{moment(policyData?.header?.effective_date).format('DD/MM/YYYY [às]  HH:mm ')}</p>
                  <h4>Fim Vigência Individual</h4>
                  <p className="dateInfo">{moment(policyData?.header?.expiry_date).format('DD/MM/YYYY [às]  HH:mm')}</p>
                  <p className="smallInfo">
                    Proposta enviada por: {policyData?.policyData?.provider_assistance?.social_reason} - CNPJ{' '}
                    {formatCNPJ(policyData?.policyData?.provider_assistance?.cnpj)}
                  </p>
                  <p className="smallInfo">Apólice emitida por: Split Risk Seguradora S.A. - CNPJ 43.505.273/0001-09</p>
                </div>
              </div>
            </section>

            <section>
              <h1>Dados do segurado</h1>
              <h4>Segurado: {policyData?.client?.name?.toUpperCase()}</h4>
              <h4>CPF/CNPJ: {formatCPF(policyData?.client?.cpf)}</h4>
              {isCpf && (
                <>
                  <h4>Nome social: {policyData?.client?.social_name?.toUpperCase()}</h4>
                  <h4>Data de nascimento: {moment(policyData?.client?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</h4>
                  <h4>Sexo: {policyData.client?.gender === 1 ? 'Masculino' : 'Feminino'}</h4>
                </>
              )}
              <h4>E-mail: {policyData?.client?.email}</h4>
              <h4>Telefone: {formatPhone(policyData?.client?.phone)}</h4>
              <h4>Endereço: {policyData?.address?.street}</h4>
              <h4>Número: {policyData?.address?.number}</h4>
              <h4>Bairro: {policyData?.address?.district}</h4>
              <h4>Cidade/UF: {policyData?.address?.city}</h4>
              {policyData?.address?.complement && <h4>Complemento: {policyData?.address?.complement}</h4>}
              <h4>CEP: {policyData?.address?.zip_code}</h4>
            </section>

            <section>
              <h1>Dados do condutor principal</h1>
              <h4>Nome: {policyData?.proposalData?.maindriver_name || policyData?.proposalData?.user?.name}</h4>
              <h4>CPF: {formatCPF(policyData?.proposalData?.maindriver_document || policyData?.proposalData?.user?.cpf)}</h4>
              {isCpf && (
                <h4>
                  Data de nascimento:{' '}
                  {moment(policyData?.proposalData?.maindriver_birthdate || policyData?.proposalData?.user?.birthdate)
                    .utcOffset(0)
                    .format('DD/MM/YYYY')}
                </h4>
              )}
            </section>

            {policyData?.findPartnerAssistance?.enabled_partner && (
              <section>
                <h1>Representante/Assessoria</h1>
                <h4>Name: {policyData?.findPartnerAssistance?.trade_name}</h4>
                <h4>CPF/CNPJ: {policyData?.findPartnerAssistance?.cnpj}</h4>
                <h4>E-mail: {policyData?.findPartnerAssistance?.email}</h4>
              </section>
            )}

            {policyData?.policyData?.provider_assistance?.enabled_agent && !isOceanica && (
              <section>
                <h1>Corretor</h1>
                <h4>{policyData?.findAgent?.name}</h4>
                <h4>CPF/CNPJ: {policyData?.findAgent?.cpf}</h4>
              </section>
            )}

            {policyData?.policyData?.provider_assistance?.enabled_agent && isOceanica && (
              <section>
                <h1>Corretora</h1>
                <h4>{policyData?.findAgent?.name}</h4>
              </section>
            )}

            <section>
              <h1>Dados do veículo</h1>
              <h4>Status: Seguro Novo</h4>
              <h4>Fabricante: {policyData?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.name}</h4>
              <h4>Modelo: {policyData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.name}</h4>
              <h4>
                Ano fabricação/ano modelo: {policyData?.vehicle?.manufacture_year}/{policyData?.vehicle?.year_model}
              </h4>
              <h4>Codigo Fipe: {policyData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.fipe_code}</h4>
              <h4>Chassi: {policyData?.vehicle?.chassi}</h4>
              <h4>Placa: {policyData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(policyData?.vehicle?.plate)}</h4>
              <h4>Veículo de Leilão: {policyData?.vehicle?.auction || policyData?.vehicle?.auction === 1 ? 'Sim' : 'Não'}</h4>
              <h4>Chassis Remarcado/Veiculo Recuperado de Sinistro: {policyData?.vehicle?.remarked ? 'Sim' : 'Não'}</h4>
              <h4>Uso do veículo: {policyData?.vehicle?.TB_CONSTANT_VEHICLE_USES.name}</h4>
              <h4>
                Já possui outro seguro:{' '}
                {policyData?.vehicle?.vehicle_already_insured || policyData?.vehicle?.vehicle_already_insured === 1
                  ? 'Sim'
                  : 'Não'}
              </h4>
              <h4>
                Condutores entre 18 e 25 anos:{' '}
                {policyData?.proposalData?.some_driver_18_25_years_old ||
                policyData?.proposalData?.some_driver_18_25_years_old === 1
                  ? 'Sim'
                  : 'Não'}
              </h4>
              <h4>0km: {policyData?.vehicle?.zero_km || policyData?.vehicle?.zero_km === 1 ? 'Sim' : 'Não'}</h4>
              <h4>Possui GNV: {policyData?.vehicle?.gnv || policyData?.vehicle?.gnv === 1 ? 'Sim' : 'Não'}</h4>
              <h4>Cor do veículo: {policyData?.vehicle?.TB_CONSTANT_VEHICLE_COLOR?.name}</h4>
              <h4>Valor*: {policyData?.vehicle?.value && formatCurrency(policyData?.vehicle?.value)}</h4>

              <small style={{ fontSize: 10 }}>*Valor da Tabela Fipe do veículo na data da contratação</small>
            </section>

            <section>
              <h1>Dados da apólice</h1>
              {/* <h4>Modalidade de Contratação: {policyData?.policyData?.contracting_modality}</h4> */}
              <h4>Modalidade de Contratação: VMR - Valor de Mercado Referenciado</h4>
              <h4>Fator de Ajuste: {adjustmentFactor}</h4>

              <h4>LIMITE MÁXIMO DE INDENIZAÇÃO (LMI): </h4>
              <p style={{ fontSize: 10 }}>
                Valor máximo a ser pago pela SEGURADORA, contratada para cada cobertura, escolhida pelo SEGURADO(A) e expresso na
                apólice.
              </p>

              <h4>PEÇAS E REPAROS:</h4>
              <p style={{ fontSize: 10 }}>
                UTILIZAÇÃO DE PEÇAS (NOVAS, USADAS, ORIGINAIS OU NÃO), desde que mantenham a especificação técnica do fabricante.
              </p>
              <p style={{ fontSize: 10 }}>
                *Esta definição não se aplica, caso tenha sido contratada Cobertura adicional de Utilização Exclusiva de Peças
                Genuínas e/ou Originais.
              </p>

              {hasCoverages && (
                <table>
                  <thead>
                    <tr>
                      <td width={200}>Cobertura</td>
                      <td width={200}>Limite Máximo Indenização</td>
                      <td width={200}>Prêmio</td>
                      <td width={200}>Franquias</td>
                    </tr>
                  </thead>
                  <tbody>
                    {policyData?.policyData?.coverages
                      ? policyData?.policyData?.coverages
                          .filter((item) => item.isService === false)
                          .map((product) => (
                            <tr>
                              <td>{product?.name}</td>
                              <td>{formatCurrency(product?.lmi || '--')}</td>
                              <td>{formatCurrency(product?.value || '--')}</td>
                              <td>{product?.deductible || '--'}</td>
                            </tr>
                          ))
                      : ''}

                    {policyData?.policyData?.oldCoverages
                      ? policyData?.policyData?.oldCoverages.map((product) => (
                          <tr>
                            <td>{product?.name}</td>
                            <td>{formatCurrency(product?.lmi || '--')}</td>
                            <td>{formatCurrency(product?.value || '--')}</td>
                            <td>{product?.deductible || '--'}</td>
                          </tr>
                        ))
                      : ''}
                  </tbody>
                </table>
              )}
              {hasServices && (
                <table>
                  <thead>
                    <tr>
                      <td width={200}>Serviço</td>
                      <td width={200}>Limite Máximo Indenização</td>
                      <td width={200}>Valor</td>
                      <td width={200}>Franquias</td>
                    </tr>
                  </thead>
                  <tbody>
                    {policyData?.policyData?.coverages
                      ? policyData?.policyData?.coverages
                          .filter((item) => item.isService === true)
                          .map((product) => (
                            <tr>
                              <td>{product?.name}</td>
                              <td>{formatCurrency(product?.lmi || '--')}</td>
                              <td>{formatCurrency(product?.value || '--')}</td>
                              <td>{product?.deductible || '--'}</td>
                            </tr>
                          ))
                      : ''}

                    {policyData?.policyData?.oldCoverages
                      ? policyData?.policyData?.oldCoverages.map((product) => (
                          <tr>
                            <td>{product?.name}</td>
                            <td>{formatCurrency(product?.lmi || '--')}</td>
                            <td>{formatCurrency(product?.value || '--')}</td>
                            <td>{product?.deductible || '--'}</td>
                          </tr>
                        ))
                      : ''}
                  </tbody>
                </table>
              )}

              <small style={{ fontSize: 10 }}>* Se selecionada a opção de leilão, o fator de ajuste deverá ser de 70% </small>
            </section>

            <section>
              <h1>Demonstrativo do prêmio </h1>
              <h4 className="valueInfo">
                Prêmio Líquido Coberturas:{' '}
                {formatCurrency((policyData?.isHinova ? policyData?.valueFinal : valueCoverage) - policyData?.proposalData?.iof)}
              </h4>
              {/* <h4 className="valueInfo">Juros: {policyData?.price?.fees}</h4> */}
              <h4 className="valueInfo">IOF: {formatCurrency(policyData?.proposalData?.iof || 0)}</h4>
              {hasCoverages && (
                <h4 className="valueInfo">
                  Prêmio Total Coberturas:{' '}
                  {formatCurrency(
                    (policyData?.isHinova ? policyData?.valueFinal : policyData?.price?.total_premium) - valueService,
                  )}
                </h4>
              )}
              {hasServices && <h4 className="valueInfo">Custo Serviços: {formatCurrency(valueService)}</h4>}
              <h4 className="valueInfo">
                Valor Total Seguro:{' '}
                {policyData?.isHinova
                  ? formatCurrency(policyData?.valueFinal || 0)
                  : formatCurrency(policyData?.price?.total_premium || 0)}
              </h4>
              <h4 className="valueInfo">Forma de pagamento: {policyData?.price?.payment_method}</h4>
            </section>

            <section style={{ borderBottom: 'none' }}>
              <h1>Resumo das coberturas</h1>
              {policyData?.policyData?.coverages
                ? policyData?.policyData?.coverages.map((product) => (
                    <>
                      <span className="generalConditionsTitle">{product?.name}</span>{' '}
                      <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>{product?.description}</p>
                    </>
                  ))
                : ''}

              {policyData?.policyData?.oldCoverages
                ? policyData?.policyData?.oldCoverages.map((product) => (
                    <>
                      <span className="generalConditionsTitle">{product?.name}</span>{' '}
                      <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>{product?.description}</p>
                    </>
                  ))
                : ''}

              <>
                <span className="generalConditionsTitle">VMR - Valor de Mercado Referenciado:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  Esta modalidade de seguro garante, no caso de indenização integral, o pagamento de quantia variável, em moeda
                  corrente nacional, determinada de acordo com a tabela FIPE de referência de cotação para veículo, previamente
                  fixada na proposta do seguro e discriminada na apólice, conjugada com o fator de ajuste (percentual a ser
                  aplicado sobre a tabela no cálculo do valor da indenização), na data da ocorrência do sinistro, aplicado o fator
                  de ajuste contratado e definido na apólice.
                </p>
              </>

              <>
                <span className="generalConditionsTitle">Fator de ajuste:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É o percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contratação do seguro, que será
                  aplicado sobre o valor que constar no LMI-Limite Máximo de Indenização de cotação para o veículo, para a
                  estipulação do valor da indenização integral do veículo segurado. A aplicação do fator de ajuste pode resultar
                  em valor inferior àquele valor do LMI-Limite Máximo de Indenização estabelecido na proposta, de acordo com as
                  características do veículo e seu estado de conservação.
                </p>
              </>

              <>
                <span className="generalConditionsTitle"></span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  A Split Risk privilegia construir relações de longo prazo com seus clientes e parceiros e tem o compromisso da
                  excelência na prestação de serviços, da criatividade na busca de soluções e da transparência na condução dos
                  negócios.
                </p>
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  Sempre que precisar você pode utilizar os nossos canais de atendimento:
                  {policyData?.findPartnerAssistance?.TB_PROVIDER?.phone}
                </p>
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  Portal Sinistros: www.splitrisk.com.br (Comunicar Ocorrência de Sinistro)
                </p>
              </>
            </section>

            <section style={{ borderBottom: 'none' }}>
              <small style={{ fontSize: 10 }}>
                Este seguro é garantido pela Split Risk Seguradora S.A. - CNPJ 43.505.273/0001-09 - Processo SUSEP no
                15414.618377/2020-87, cuja matriz está situada na Avenida Gabriela Junqueira Freitas, número 392, Bairro Jardim
                Patrícia, CEP 38414-126, na cidade de Uberlândia, MG. Reclamações podem ser feitas em www.consumidor.gov.br e para
                deficientes auditivos/fala, ligue para 0800-201-1838. A SUSEP - Superintendência de Seguros Privados - é a
                autarquia federal responsável pela fiscalização, normatização e controle dos mercados de seguro, previdência
                complementar aberta, capitalização, resseguro e corretagem de seguros. O telefone de atendimento gratuito ao
                público da SUSEP é 0800 021 8484 (apenas ligações originadas de telefones fixos).
              </small>
            </section>
          </Printable>
        </Container>
      )}
    </>
  );
}

export default BackofficePrintPolicy;
