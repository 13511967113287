import styled from 'styled-components';
import { primary, white, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 90%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 30px;
  padding-left: 90px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const ListPolicy = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  flex-wrap: wrap;

  .no-policy {
    color: ${middleGray};
    font-size: 14px;
    margin: 20px 0px;
  }

  .add {
    margin-left: 4%;
    cursor: pointer;
  }

  div {
    margin-right: 4%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 0px;

    .add {
      margin-left: 0;
    }
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const EmptyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: ${middleGray};
  }
`;
