import styled from 'styled-components';
import { primary, white } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 30px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  .title-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${primary};
    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

