import styled from 'styled-components';
import MaterialButton from '@material-ui/core/Button';
import { primary, white, middleGray, lightGray, lighterGray, darkGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -1.6%;

  .lastItem {
    height: 9%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const ItemPolicy = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 41%;
  padding: 2.2% 3%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10%;
    margin-top: 15%;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${darkGray};
  margin: 0;
  margin-top: 2%;

  span {
    color: ${middleGray};
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ContractBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 80px;

  h1 {
    margin: 10px 0px;
    margin-top: 30px;
    color: ${primary};
    font-size: 28px;
  }

  .contractArea {
    height: 65vh !important;
    max-height: 65vh !important;
    width: 100%;
    background-color: ${lighterGray};
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${primary};
      border-radius: 10px;
    }
  }
`;

export const SubscriptionChargeUrlButton = styled(MaterialButton)({
  a: {
    textDecoration: 'none',
    color: `${primary}`
  }
})

