/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import Divider from '@mui/material/Divider';
import api from 'api';
import IconButton from '@material-ui/core/IconButton';
import { Header, Input, ConfirmDialog, Search, Button, Dialog } from 'components';
import { Container, ContentArea, Title, LoadingArea, Form, DialogBody } from './styles';
import { loadingColor } from 'styles/colorProvider';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdChevronLeft } from 'react-icons/md';
import { BiMessageSquareEdit } from 'react-icons/bi';
import { formatCPF } from 'react-data-formatter';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function TeamDetails({ location }) {
  const history = useHistory();
  const teamId = parseInt(location.pathname.split('/equipe/')[1]);
  const host = location?.pathname;

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [teamData, setTeamData] = useState({});

  const [clientSearch, setClientSearch] = useState();
  const [clientsList, setClientsList] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState();

  const [showEditManager, setShowEditManager] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);

  const [loadingAddUser, setLoadingAddUser] = useState();
  const [selectedAddUser, setSelectedAddUser] = useState();

  const loadData = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/team/${teamId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });

    setTeamData(response.data);
    setLoading(false);
  };

  const loadClients = async () => {
    setLoadingClients(true);
    const resp = await api({
      method: 'GET',
      url: '/users',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        search: clientSearch?.replaceAll('.', '').replaceAll('-', ''),
      },
    });
    setClientsList(resp?.data?.response);
    setLoadingClients(false);
  };

  const handleUpdateTeam = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'PATCH',
        url: `/team/${teamId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          ...teamData,
        },
        json: true,
      });
      setLoadingUpdate(false);
      handleCleanClients();
      toast.success('Equipe atualizada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setShowEditManager(false);
      loadData();
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleRemoveUserTeam = async (id) => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'DELETE',
        url: `/remove-user-team`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_user: id,
          id_team: teamId,
        },
        json: true,
      });
      setLoadingUpdate(false);
      toast.success('Usuário removido da equipe com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      const nlist = teamData?.members.filter((i) => i.id !== id);
      setTeamData({ ...teamData, members: nlist });
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleCleanClients = () => {
    setClientSearch();
    setClientsList([]);
  };

  const handleAddUserTeam = async () => {
    setLoadingAddUser(true);
    try {
      await api({
        method: 'POST',
        url: `/add-user-team`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_user: selectedAddUser,
          id_team: teamId,
        },
        json: true,
      });
      toast.success('Usuário adicionado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingAddUser(false);
      setShowAddUser(false);
      handleCleanClients();
      loadData();
    } catch (error) {
      setLoadingAddUser(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleDelete = async (id) => {
    setLoadingDelete(true);
    try {
      await api({
        method: 'DELETE',
        url: `/team/${teamId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_user: id,
          id_team: teamId,
        },
        json: true,
      });
      setLoadingDelete(false);
      history.push('/equipes');
      toast.success('Time excluído com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (error) {
      setLoadingDelete(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={showConfirmDialog}
      onCancel={() => setShowConfirmDialog(false)}
      content="Tem certeza de que deseja excluir esta equipe? Esta ação não poderá ser desfeita!"
      title="Excluir equipe?"
      confirmLabel="Excluir"
      invertButtons
      onConfirm={handleDelete}
      loading={loadingDelete}
    />
  );

  const renderSelectManager = () => (
    <Dialog
      width="40vw"
      open={showEditManager}
      onClose={() => {
        setShowEditManager(false);
        handleCleanClients();
      }}
      title="Editar gestor"
    >
      <DialogBody>
        <div class="input-group">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div className="search-area">
              <p style={{ fontSize: 12 }}>Usuário:</p>
              <Search
                style={{ margin: 0 }}
                help="Buscar pelo nome ou cpf do cliente."
                value={clientSearch}
                onChange={(e) => setClientSearch(e.target.value)}
                onClear={() => {
                  setClientSearch('');
                  setClientsList([]);
                }}
                onConfirm={() => loadClients()}
              />
            </div>
            <div className="result-area">
              {loadingClients && <ReactLoading color={loadingColor} height={24} width={24} type="spin" />}
              {!loadingClients && clientsList?.length > 0 && (
                <div>
                  {clientsList?.map((client) => (
                    <div
                      key={client.id}
                      className={teamData?.id_manager === client?.id ? 'result-item result-item-selected' : 'result-item'}
                      onClick={() => setTeamData({ ...teamData, id_manager: client.id })}
                    >
                      <p>{client?.name}</p>
                      <small>{formatCPF(client?.cpf)}</small>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <Button heigth="30px" style={{ marginLeft: 0 }} loading={loadingUpdate} onClick={() => handleUpdateTeam()}>
              Salvar alterações
            </Button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );

  const renderAddUser = () => (
    <Dialog
      width="40vw"
      open={showAddUser}
      onClose={() => {
        setShowAddUser(false);
        handleCleanClients();
      }}
      title="Adicionar usuário"
    >
      <DialogBody>
        <div class="input-group">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div className="search-area">
              <p style={{ fontSize: 12 }}>Usuário:</p>
              <Search
                style={{ margin: 0 }}
                help="Buscar pelo nome ou cpf do cliente."
                value={clientSearch}
                onChange={(e) => setClientSearch(e.target.value)}
                onClear={() => {
                  setClientSearch('');
                  setClientsList([]);
                }}
                onConfirm={() => loadClients()}
              />
            </div>
            <div className="result-area">
              {loadingClients && <ReactLoading color={loadingColor} height={24} width={24} type="spin" />}
              {!loadingClients && clientsList?.length > 0 && (
                <div>
                  {clientsList?.map((client) => (
                    <div
                      key={client.id}
                      className={selectedAddUser === client?.id ? 'result-item result-item-selected' : 'result-item'}
                      onClick={() => setSelectedAddUser(client.id)}
                    >
                      <p>{client?.name}</p>
                      <small>{formatCPF(client?.cpf)}</small>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <Button
              disabled={loadingAddUser || !selectedAddUser}
              loading={loadingAddUser}
              heigth="30px"
              style={{ marginLeft: 0 }}
              onClick={() => handleAddUserTeam()}
            >
              Adicionar usuário
            </Button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );

  const permissions = JSON.parse(Cookies.get('splitriskwl-backoffice-permissions')) || [];

  const hasPermission = (menu) => {
    const menuIndex = permissions.findIndex((i) => i.feature === menu);
    if (menuIndex === -1) return;
    return permissions[menuIndex].hasPermission;
  };

  const canEdit = hasPermission('alterar-equipes-corretor') || hasPermission('alterar-equipes-backoffice');

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {renderSelectManager()}
      {renderConfirmDialog()}
      {renderAddUser()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <Title>
              <div>
                <IconButton size="small" style={{ marginRight: 10 }} onClick={() => history.goBack()}>
                  <MdChevronLeft size={24} />
                </IconButton>
                DETALHES DA EQUIPE
              </div>
              {/* <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
                <CgMenuGridO size={24} />
              </IconButton> */}
            </Title>

            <Menu
              style={{ marginTop: '50px', marginLeft: '-40px' }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => setShowConfirmDialog(true)} dense>
                Excluir equipe
              </MenuItem>
            </Menu>

            <Form style={{ paddingBottom: 30 }}>
              <div class="input-group">
                <Input
                  disabled={!canEdit}
                  height="35px"
                  label="Nome da equipe"
                  value={teamData?.name}
                  onChange={(e) => setTeamData({ ...teamData, name: e.target.value })}
                />
              </div>
              <div class="input-group">
                <Input
                  height="35px"
                  label="Nome do gestor"
                  disabled
                  value={`${teamData?.manager?.name}`}
                  endButtonIcon={canEdit && <BiMessageSquareEdit />}
                  onEndButtonClick={() => setShowEditManager(true)}
                />
              </div>

              {canEdit && (
                <div className="button-area">
                  <Button height="35px" style={{ marginLeft: 0 }} loading={loadingUpdate} onClick={handleUpdateTeam}>
                    Salvar alterações
                  </Button>
                </div>
              )}
            </Form>
            <Divider />
            <div>
              <p>Usuários da equipe:</p>
              <div>
                {teamData?.members?.length < 1 && <small>Nenhum usuário encontrado.</small>}
                {teamData?.members?.map((user) =>
                  !canEdit ? (
                    <Chip
                      style={{
                        borderWidth: 1,
                        borderRadius: 20,
                        borderColor: '#eee',
                        borderStyle: 'solid',
                        marginBottom: 5,
                        marginRight: 5,
                      }}
                      avatar={<Avatar alt={user?.name} src={user?.picture} />}
                      label={user?.name}
                      variant="oulined"
                    />
                  ) : (
                    <Chip
                      style={{
                        borderWidth: 1,
                        borderRadius: 20,
                        borderColor: '#eee',
                        borderStyle: 'solid',
                        marginBottom: 5,
                        marginRight: 5,
                      }}
                      avatar={<Avatar alt={user?.name} src={user?.picture} />}
                      label={user?.name}
                      variant="oulined"
                      onClick={() => window.open(`/backoffice/detalhes-do-usuario/${user?.id}`)}
                      onDelete={() => handleRemoveUserTeam(user?.id)}
                    />
                  ),
                )}
                {canEdit && (
                  <Button onClick={() => setShowAddUser(true)} height="35px" style={{ marginLeft: 0, marginTop: 20 }}>
                    Adicionar usuário
                  </Button>
                )}
              </div>
            </div>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default TeamDetails;
