import { action } from 'easy-peasy';
import withResetState from './withResetState';

const initialState = {
  brokerPermission: null,
  reloadBrokerPermission: true,
};

const store = {
  setBrokerPermission: action((state, payload) => {
    state.brokerPermission = payload;
  }),
};

export default withResetState(store, initialState);
