export const inputFieldsLogin = [
  {
    field: 'emailOrCpf',
    label: 'E-mail ou CPF',
    placeholder: 'Digite seu e-mail ou CPF',
    type: 'text',
  },
  {
    field: 'password',
    label: 'Senha',
    placeholder: 'Digite sua senha',
    type: 'password',
  },
];
