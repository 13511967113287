import React from 'react';

import { Container } from './styles';

function InfoText({ label, value, ...props }) {
  return (
    <Container {...props}>
      <span className="label">{label}:</span>
      <span className="value">{value}</span>
    </Container>
  );
}

export default InfoText;
