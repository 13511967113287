import styled from 'styled-components';
import { primary, secondary, white } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  box-sizing: border-box;
  justify-content: center;

  header {
    padding: 5px 30px;
    box-sizing: border-box;
  }

  section {
    text-align: justify;
    color: ${primary};
  }

  .definition {
    color: ${primary};
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
  }

  h2 {
    font-size: 18px;
  }

  .subitem-text {
    font-size: 16px;
    font-weight: bold;
    color: ${primary};
    margin-bottom: 25px;
  }

  p {
    color: #121212;
  }

  strong {
    color: ${primary};
    margin: 10px 0px;
    font-size: 14px;
    font-weight: bold;
  }

  .printBrake {
    @media print {
      margin-top: 100px;
      margin-bottom: 30px;
    }
  }

  .logo {
    max-width: 300px;
    height: auto;
    align-self: center;
  }
  .boxTitulo {
    width: 100%;
  }
  .name {
    color: ${secondary};
    font-weight: 800;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Line = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  gap: 40px;
  width: 900px;
  max-width: 90%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media print {
    display: none;
  }
  Button {
    background-color: ${secondary};
  }
`;

// _______________________________________________________
export const WrapRow = styled.hr`
  flex: ${({ flex }) => flex || 'auto'};
  width: ${({ size }) => size || '900px'};
  height: ${({ height }) => height || '1px'};
  background: ${({ color }) => color || primary};
  margin: 0px auto;
  border: 0 !important;
  opacity: ${({ opacity }) => opacity || '0.4'};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: ${({ gap }) => gap || '20px 40px'};
  width: 900px;
  max-width: 90%;
  padding: ${({ padding }) => padding || '20px 0'};
  margin: 0 auto;
`;

export const ColumnContainer = styled.div`
  flex: ${({ flex }) => flex || '1 1'};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${({ gap }) => gap || '10px 20px'};
  box-sizing: border-box;
  background: ${({ background }) => background || 'none'};
  h1 {
    margin: 0;
    line-height: 20px;
  }
`;

export const BlockText = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || '0'};
  box-sizing: border-box;
`;

export const Text = styled.p`
  flex: ${({ flex }) => flex || '1 1 100%'};
  margin: 0;
  color: ${({ color }) => color || primary};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: 500;
`;

export const HightLightText = styled(Text)`
  flex: ${({ flex }) => flex || '0 1 300px'};
  background: ${primary};
  color: white;
  padding: 3px 5px;
`;

export const Printable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  color: ${primary};

  @media print {
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 5px solid ${secondary};
    margin-bottom: 30px;
    padding: 20px 0px 40px 0px;
  }

  .logoArea {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
    }
  }

  .titleArea {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h1 {
      margin: 0px;
      margin-bottom: 5px;
      font-size: 22px;
    }
    h2 {
      margin: 0px;
    }
  }

  section {
    width: 80%;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid ${primary};
    margin-bottom: 20px;
    padding-bottom: 20px;
    @media print {
      border-bottom: 3px solid black;
    }

    h1 {
      margin: 0px;
      margin-bottom: 10px;
      font-size: 22px;
    }
  }

  h4 {
    margin: 0px;
    font-weight: normal;
  }

  p {
    margin: 0px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .leftArea {
    width: 50%;
    margin-right: 50px;
  }

  .rightArea {
    width: 50%;
  }

  .highlight {
    background-color: ${primary} !important;
    color: #fff;
    width: 330px;
    padding: 2px 4px;
    @media print {
      background-color: #000 !important;
      width: 310px;
    }
  }

  .dateInfo {
    color: ${secondary};
    font-size: 25px;
  }

  .smallInfo {
    font-size: 11px;
    margin: 0px;
  }

  .valueInfo {
    width: 100%;
    border-bottom: 1px solid ${primary};
  }

  .generalConditionsTitle {
    margin-bottom: 10px;
    margin: 10px 0px;
    color: ${primary};
    font-weight: bold;
    font-size: 14px;
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0px 30px 0px;

    .logoArea {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .barArea {
      width: 80%;
      background-color: ${primary};
      height: 60px;
      @media print {
        background-color: #000 !important;
      }
    }
  }
`;
