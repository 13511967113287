import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';

// services
import { updateProvider } from '../services/updateProvider';

// components
import { Dialog, Button, Input } from 'components';

// styles
import { ErrorMsg, ModalButton } from '../styles';
import { error, white } from 'styles/colorProvider';

const formDataValuesDefault = {
  name: '',
  phone: '',
  email: '',
  document: '',
  TB_ADDRESS: {
    zip_code: '',
    city: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    state: '',
  },
};
export const EditProviderModal = ({ openEdit = true, handleCloseProvider = () => {}, provider, loadData }) => {
  const [formDataValues, setFormDataValues] = useState(formDataValuesDefault);
  const [cepWasFinded, setCepWasFinded] = useState(false);
  const [lastCepFinded, setLastCepFinded] = useState(false);
  const [buttonCreateLoading, setButtonCreateLoading] = useState(false);
  const cepIsInvalid = !cepWasFinded && formDataValues.TB_ADDRESS.zip_code.length === 10;

  //atualiza os valores do formulário com um novo valor
  const updateFormDataValues = (newDataValues = {}) => {
    setFormDataValues((formDataValues) => {
      return { ...formDataValues, ...newDataValues };
    });
  };

  //atualiza o endereço
  const updateTbAddress = (newTbAddress) => {
    setFormDataValues((formDataValues) => {
      const currentTableAdress = formDataValues.TB_ADDRESS;
      const updatedTbAddress = { ...currentTableAdress, ...newTbAddress };
      const newFormData = { ...formDataValues, TB_ADDRESS: updatedTbAddress };
      return newFormData;
    });
  };

  const inputHandleChangeAddress = (event, targetPropName) => {
    const currentInputValue = event.target.value;
    const newAddressDataValue = {};
    newAddressDataValue[targetPropName] = currentInputValue;
    updateTbAddress(newAddressDataValue);
  };

  const inputHandleChange = (event, targetPropName) => {
    const currentInputValue = event.target.value;
    const newDataValue = {};
    newDataValue[targetPropName] = currentInputValue;
    updateFormDataValues(newDataValue);
  };

  const setAddress = (address) => {
    if (address) {
      const { bairro, localidade, logradouro, uf } = address;
      const newAddressDataValue = { district: bairro, city: localidade, street: logradouro, state: uf };
      updateTbAddress(newAddressDataValue);
    } else {
      const clearAddressDataValue = { district: '', city: '', street: '', state: '' };
      updateTbAddress(clearAddressDataValue);
    }
  };

  const handleChangeCep = async (event) => {
    const cep = event.target.value;
    const cepHave10Char = cep.length === 10;

    if (cepHave10Char) {
      try {
        const cepResponse = await axios.get(`https://viacep.com.br/ws/${cep?.replaceAll('-', '').replaceAll('.', '')}/json/`);
        const cepFinded = !cepResponse.data.erro;

        if (cepFinded) {
          setCepWasFinded(true);
          setLastCepFinded(cepResponse?.data);
          setAddress(cepResponse?.data);
        } else {
          setCepWasFinded(false);
          setLastCepFinded(false);
        }
      } catch (err) {
        setCepWasFinded(false);
        setLastCepFinded(null);
      }
    } else {
      setAddress(false);
      setCepWasFinded(false);
      setLastCepFinded(null);
    }
  };

  const handleSaveButton = async () => {
    setButtonCreateLoading(true);
    await updateProvider(formDataValues);
    setButtonCreateLoading(false);
    handleCloseProvider(false);
    loadData();
  };

  const handleClose = () => {
    handleCloseProvider();
  };

  useEffect(() => {
    setLastCepFinded(false);
    if (provider.TB_ADDRESS) {
      setFormDataValues(provider);
    }
  }, [provider]);

  return (
    <Dialog open={openEdit} onClose={handleClose} width="50%" title="Editar parceiro">
      <Input
        value={formDataValues.name}
        onChange={(event) => inputHandleChange(event, 'name')}
        widht="30%"
        label="Nome do parceiro"
        height="30px"
        placeholder="Empresa ou pessoa"
      />
      <Input
        value={formDataValues.phone}
        onChange={(event) => inputHandleChange(event, 'phone')}
        widht="30%"
        label="Telefone"
        height="30px"
        placeholder="(__) ____-____"
        mask="(99) 9999-9999"
      />
      <Input
        value={formDataValues.email}
        onChange={(event) => inputHandleChange(event, 'email')}
        widht="30%"
        label="E-mail"
        height="30px"
        placeholder="email@email.com"
      />
      <Input
        value={formDataValues.document}
        onChange={(event) => inputHandleChange(event, 'document')}
        widht="30%"
        label="CPF ou CNPJ"
        height="30px"
        placeholder="Documento"
      />
      <Input
        value={formDataValues.TB_ADDRESS?.zip_code}
        onChange={(event) => {
          inputHandleChangeAddress(event, 'zip_code');
          handleChangeCep(event);
        }}
        widht="30%"
        label="CEP"
        mask="99.999-999"
        height="30px"
        placeholder="___.___-___"
      />
      {cepIsInvalid && <ErrorMsg>CEP Inválido</ErrorMsg>}

      <Input
        value={formDataValues.TB_ADDRESS.state}
        onChange={(event) => {
          inputHandleChangeAddress(event, 'state');
          const currentValue = event.target.value;
          if (currentValue.length >= 3) {
            const newAddressDataValue = {};
            newAddressDataValue.state = currentValue.substring(0, currentValue.length - 1);
            updateTbAddress(newAddressDataValue);
          }
        }}
        widht="30%"
        label="Estado"
        height="30px"
        disabled={lastCepFinded?.uf?.length}
      />

      <Input
        value={formDataValues.TB_ADDRESS.city}
        onChange={(event) => inputHandleChangeAddress(event, 'city')}
        widht="30%"
        label="Cidade"
        height="30px"
        disabled={lastCepFinded?.localidade?.length}
      />

      <Input
        value={formDataValues.TB_ADDRESS.district}
        onChange={(event) => inputHandleChangeAddress(event, 'district')}
        widht="30%"
        label="Bairro"
        height="30px"
        disabled={lastCepFinded?.bairro?.length}
      />

      <Input
        value={formDataValues.TB_ADDRESS.street}
        onChange={(event) => inputHandleChangeAddress(event, 'street')}
        widht="30%"
        label="Endereço"
        height="30px"
        disabled={lastCepFinded?.logradouro?.length}
      />

      <Input
        value={formDataValues.TB_ADDRESS.number}
        onChange={(event) => inputHandleChangeAddress(event, 'number')}
        widht="30%"
        label="Número"
        height="30px"
        type="number"
      />

      <Input
        value={formDataValues.TB_ADDRESS.complement}
        onChange={(event) => inputHandleChangeAddress(event, 'complement')}
        widht="30%"
        label="Complemento"
        height="30px"
      />

      <ModalButton>
        <Button
          height="35px"
          style={{ backgroundColor: 'transparent', border: `1px solid ${error}`, color: error }}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button height="35px" type="submit" onClick={handleSaveButton}>
          {buttonCreateLoading ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Salvar'}
        </Button>
      </ModalButton>
    </Dialog>
  );
};
