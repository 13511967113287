import React from 'react';
import 'moment/locale/pt-br';
import moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Container, ContainerArea } from './styles';
import { formatHistoricStatusClaim, formatClaimStatusHistory } from 'utils/policyStatus';

function ClaimHistoryItem({ data }) {
  return (
    <>
      <Container>
        <ContainerArea>
          <Timeline style={{ width: '100%', marginLeft: -150 }}>
            {data?.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent style={{ fontSize: 12 }}>
                  {moment(item?.created_at)?.format('DD/MM/YYYY')} <br />
                  <p style={{ margin: 0, fontSize: 12, color: '#868686' }}>{moment(item?.created_at)?.format('HH:mm')}</p>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    style={{ backgroundColor: formatHistoricStatusClaim(item?.description)?.color, cursor: 'pointer' }}
                  >
                    {formatHistoricStatusClaim(item?.description)?.icon}
                  </TimelineDot>
                  {index !== data.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent style={{ fontSize: 14 }}>
                  {item?.description} <br />
                  <p style={{ margin: 0, fontSize: 12, color: '#868686', maxWidth: '60%', textAlign: 'justify' }}>
                    {item.description === 'Envio aviso de sinistro' &&
                      `${item.details === null ? 'Aviso enviado' : item.details}`}
                    {item.description === 'Cancelar aviso de sinistro' &&
                      `Descrição: ${item.details === null ? 'Não possuí descrição' : item.details}`}
                    {item.description === 'Sinistro aprovado' && `${item.details === null ? null : item.details}`}
                    {item.description === 'Atendimento registrado' &&
                      `${item.details === null ? null :  item.details}`}
                    {item.description === 'Mudança de status' &&
                      `${ formatClaimStatusHistory(item.current_status) }`}
                    {item.description === 'Sinistro recusado' &&
                      `${item.details === 'Não possuí descrição' ? null : item.details}`}
                  </p>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </ContainerArea>
      </Container>
    </>
  );
}

export default ClaimHistoryItem;
