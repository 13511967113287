import styled from 'styled-components';
import { primary } from 'styles/colorProvider';

export const ModalCard = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: white;
  min-height: 300px;
  padding: 30px;
  z-index: 1;
  transition: 0.3s;

  .centralizer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    h1 {
      color: ${primary};
      font-size: 2em;
    }
  }
`;
