import { createStore } from 'easy-peasy';
import user from './user';
import brokerPermission from './brokerPermission';
import backofficePermission from './backofficePermission';

const model = {
  user,
  brokerPermission,
  backofficePermission,
};

export default createStore(model);
