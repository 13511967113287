export const orderArray = (array, property) =>
  array?.sort((a, b) => {
    if (a[property] > b[property]) {
      return 1;
    }
    if (a[property] < b[property]) {
      return -1;
    }
    return 0;
  });

export const orderCoveragesArray = (array, property) =>
  array?.sort((a, b) => {
    var numA = parseInt(a[property].match(/\d+/g));
    var numB = parseInt(b[property].match(/\d+/g));
    return numA - numB;
  });
