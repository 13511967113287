import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import { Container } from './styles';
import { FiTrash } from 'react-icons/fi';
import { primary } from 'styles/colorProvider';

function ClaimFileItem({ data, onClick }) {
  return (
    <Container>
      <div className="info-area">
        <p>{(data?.name ? data?.name : data)}</p>
      </div>
      <div className="button-area">
        <IconButton onClick={onClick}>
          <FiTrash size={14} color={primary} />
        </IconButton>
      </div>
    </Container>
  );
}

export default ClaimFileItem;
