import styled from 'styled-components';
import { middleGray, black, primary, secondary, white, lightGray, lighterGray, success } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 90%;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: ${(props) => props.fontSize || '28px'};
  color: ${(props) => props.color || primary};
  margin-top: 5%;

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
    width: 100%;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 40px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${black};
  margin: 0;
  margin-top: 2%;

  span {
    color: ${middleGray};
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ItemMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 2%;
  transition: all ease-in-out 0.3s;

  :hover {
    color: ${secondary};
  }

  @media (max-width: 1000px) {
    margin-right: 5%;
  }
`;

export const TextItem = styled.p`
  font-size: 16px;
  margin-left: 8px;
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .containerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media (max-width: 1000px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-bottom: 10px;
    }
  }

  .editUser {
    font-size: 20px;
    margin-left: 15px;
    cursor: pointer;
    color: ${primary};

    :hover {
      opacity: 0.5;
      color: ${primary};
    }
  }
  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  .result-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0px;
  }

  .result-item {
    background-color: #fafafa;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    box-sizing: border-box;
    padding: 10px 15px;
    margin: 0px;
    margin-bottom: 15px;
    width: 25%;

    p {
      font-size: 13px;
      margin: 0px;
    }

    small {
      margin: 0px;
      font-size: 10px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .result-item-selected {
    border: 1px solid ${success};
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  .input-group {
    margin-right: 30px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .items-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .inspection-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${primary};
    font-weight: bold;
  }

  .inspection-area {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .inspection-photo {
    height: 120px;
    width: auto;
    margin-right: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    border: 1px solid #eee;
    &:hover {
      opacity: 0.5;
    }
  }

  .button-area {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
`;

export const FormPermissions = styled.div`
  display: grid;
  grid-template-columns: 280px 280px 280px 280px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  .grid-item {
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }

  .items-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .checkbox-item {
    display: flex;
    flex-direction: row;
    font-size: 12px;
  }
`;

export const SelectLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0px;
  width: 40%;

  @media (max-width: 1000px) {
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0px 50px;
  margin-top: 10px;

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const MenuArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-top: 10px;
`;

export const SecondMenuItem = styled.p`
  margin-right: 25px;
  font-size: 17px;
  color: ${(props) => (props.selected ? primary : middleGray)};
  font-weight: ${(props) => (props.selected ? 'bolder' : 'normal')};
  transition: all ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const VehicleItem = styled.div`
  width: 300px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${lightGray};
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 13px;
  position: relative;
  padding: 10px 30px;
  transition: all ease-in-out 0.3s;
  margin-right: 15px;

  &:hover {
    opacity: 0.5;
  }

  img {
    height: auto;
    width: 55px;
    margin-right: 30px;
  }

  .info {
    p {
      margin: 0px;
    }
  }
`;

export const TransactionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${lightGray};
  border-radius: 10px;
  margin-top: 15px;
  font-size: 13px;
  position: relative;
  padding: 10px 30px;
  transition: all ease-in-out 0.3s;
  margin-right: 15px;

  img {
    height: 30px;
    margin-right: 30px;
  }

  p {
    margin: 0px;
  }
`;

export const CarTitle = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  /* box-shadow: rgba(0, 0, 0, 0.01) 0px 6px 24px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px; */

  h1 {
    font-size: 24px;
    margin: 0px;
    font-weight: normal;
  }

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    font-size: 18px;
    color: ${middleGray};
  }

  b {
    color: ${primary};
  }

  .icon {
    height: 33px;
  }

  .model-name {
    color: ${lightGray};
    font-size: 16px;
  }

  .car-img {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    margin-right: 30px;
    object-fit: contain;
    background-color: ${lighterGray};
    box-sizing: border-box;
    padding: 3px;
  }
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  h4 {
    margin: 0px;
    width: 100%;
    text-align: left;
  }

  .divider {
    margin: 10px 0px;
    border-bottom: 1px solid ${lightGray};
    padding-bottom: 10px;
  }
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;

  .search-area {
    width: 260px;
  }

  .result-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0px;
  }

  .result-item {
    background-color: #fafafa;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    box-sizing: border-box;
    padding: 10px 15px;
    margin: 0px;
    margin-bottom: 15px;

    p {
      font-size: 13px;
      margin: 0px;
    }

    small {
      margin: 0px;
      font-size: 10px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .result-item-selected {
    border: 1px solid ${success};
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }
`;
