import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import Login from './screens/AuthArea/Login';
import PasswordRecovery from 'screens/AuthArea/PasswordRecovery';
import CustomerRegistration from 'screens/AuthArea/CustomerRegistration';

import MyPolicies from './screens/ClientArea/MyPolicies';
import PolicyDetails from 'screens/ClientArea/PolicyDetails';
import ProposalDetails from 'screens/ClientArea/ProposalDetails';
import PolicyClaim from 'screens/ClientArea/PolicyClaim';
import NewClaim from 'screens/ClientArea/NewClaim';
import ClaimDetails from 'screens/ClientArea/ClaimDetails';
import FinancialStatement from 'screens/ClientArea/FinancialStatement';
import Checkout from 'screens/ClientArea/Checkout';
import Doubt from 'screens/ClientArea/Doubt';
import PrintPolicy from 'screens/ClientArea/PrintPolicy';
import PrintProposal from 'screens/ClientArea/PrintProposal';
import Wallet from 'screens/ClientArea/Wallet';
import GeneralConditions from 'screens/ClientArea/PrintGeneralConditions';
import ErrorPage from 'screens/ClientArea/Error';

import BrokerArea from 'screens/BrokerArea/BrokerArea';
import NewQuotation from 'screens/BrokerArea/NewQuotation';
import PrintQuotation from 'screens/BrokerArea/PrintQuotation';
import QuotationList from 'screens/BrokerArea/QuotationList';
import BrokerProposalList from 'screens/BrokerArea/ProposalList';
import BrokerPolicyList from 'screens/BrokerArea/PolicyList';
import BackofficePolicyList from 'screens/BackofficeArea/PolicyList';
import ListDocumentsPolicy from 'screens/BackofficeArea/ListDocumentsPolicy';
import BackofficePolicyDetails from 'screens/BackofficeArea/PolicyDetails';
import BackofficeComplaint from 'screens/BackofficeArea/PolicyComplaint';
import BackofficeExtract from 'screens/BackofficeArea/PolicyExtract';
import BackofficeProposalList from 'screens/BackofficeArea/ProposalList';
import BackofficeProposalDetails from 'screens/BackofficeArea/ProposalDetails';
import BackofficeUserList from 'screens/BackofficeArea/UserList';
import BackofficeUserDetails from 'screens/BackofficeArea/UserDetails';
import BackofficeComplaintList from 'screens/BackofficeArea/ComplaintList';
import BackofficeComplaintDetails from 'screens/BackofficeArea/ComplaintDetails';
import BackofficeNewComplaint from 'screens/BackofficeArea/NewComplaint';
import BackofficeInvoice from 'screens/BackofficeArea/Invoice';
import BackofficeInvoiceProposal from 'screens/BackofficeArea/Invoice/InvoiceProposal';
import BackofficeCancelList from 'screens/BackofficeArea/CancelList';
import BackofficeClaimList from 'screens/BackofficeArea/ClaimList';
import BackofficeClaimDetails from 'screens/BackofficeArea/ClaimDetails';
import BackofficeClaimHistory from 'screens/BackofficeArea/ClaimHistory';
import BackofficeClaimFile from 'screens/BackofficeArea/ClaimFile';
import BackofficeThirdPartyFile from 'screens/BackofficeArea/ThirdPartyFile';
import BackofficeSettings from 'screens/BackofficeArea/Settings';
import BackofficePolicyHistory from 'screens/BackofficeArea/PolicyHistory';
import BackofficeProviders from 'screens/BackofficeArea/ProvidersList';
import BackofficeBillet from 'screens/BackofficeArea/Invoice/Billet';
import BackofficeBillets from 'screens/BackofficeArea/Financial/Billets';
import BackofficeBilletProposal from 'screens/BackofficeArea/Invoice/BilletProposal';
import BackofficeDefaulters from 'screens/BackofficeArea/Financial/Defaulters';
import BackofficeBilletPolicy from 'screens/BackofficeArea/Invoice/BilletPolicy';
import BackofficeWallet from 'screens/BackofficeArea/BackofficeWallet';
import PrintPolicyBackoffice from './screens/BackofficeArea/PrintPolicy';
import PrintProposalBackoffice from './screens/BackofficeArea/PrintProposalBackoffice';
import ChargesListBackoffice from './screens/BackofficeArea/ChargesList';

import BackofficeAccessGroupsList from 'screens/BackofficeArea/AccessGroupsList';
import BackofficeAccessGroupsDetails from 'screens/BackofficeArea/AccessGroupsDetails';
import BackofficeAccessGroupNew from 'screens/BackofficeArea/AccessGroupsNew';

import TeamsList from 'screens/BackofficeArea/TeamsList';
import TeamDetails from 'screens/BackofficeArea/TeamDetails';

import KeyAccountList from 'screens/BackofficeArea/KeyAccountList';
import KeyAccountDetails from 'screens/BackofficeArea/KeyAccountDetails';
import BackOfficeProductsPartners from 'screens/BackofficeArea/Products/Partners';

import MyProfile from 'screens/ClientArea//Profile';

import { Billet } from 'screens/ClientArea/Billet';
import BackOfficeProductsPartnersComission from 'screens/BackofficeArea/Products/PartnersComission';

const handleClickHelpButton = () => {
  window.open('https://splitriskseguradora.atlassian.net/servicedesk/customer/portal/2', '_blank');
};

const PublicRoute = ({ component: Component, path, ...rest }) => {
  // Não mostra o botão nas telas de impressão
  const showHelpButton = !path?.includes('imprimir');
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          Cookies.get('splitriskwl-authtoken') ? (
            <Redirect to={{ pathname: '/minhas-apolices', state: { from: props.location } }} />
          ) : (
            <Component {...props} />
          )
        }
      />
      {showHelpButton && (
        <div class="question-widget" onClick={handleClickHelpButton}>
          <span class="question-icon">?</span>
        </div>
      )}
    </>
  );
};

// Detect url
const PrivateRoute = ({ component: Component, path, ...rest }) => {
  // Não mostra o botão nas telas de impressão
  const showHelpButton = !path?.includes('imprimir');
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          Cookies.get('splitriskwl-authtoken') ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
      {showHelpButton && (
        <div class="question-widget" onClick={handleClickHelpButton}>
          <span class="question-icon">?</span>
        </div>
      )}
    </>
  );
};

// Get current route
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Auth */}
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/recuperar-senha" component={PasswordRecovery} />
        <PublicRoute exact path="/cadastro-cliente" component={CustomerRegistration} />

        {/* Client Area */}
        <PrivateRoute exact path="/minhas-apolices" component={MyPolicies} />
        <PrivateRoute exact path="/detalhes-da-apolice/:id" component={PolicyDetails} />
        <PrivateRoute exact path="/meus-sinistros/:id" component={PolicyClaim} />
        <PrivateRoute exact path="/novo-sinistro/:id" component={NewClaim} />
        <PrivateRoute exact path="/sinistro/:id" component={ClaimDetails} />
        <PrivateRoute exact path="/detalhes-da-apolice/:id/imprimir" component={PrintPolicy} />
        <PrivateRoute exact path="/detalhes-da-proposta/:id/imprimir" component={PrintProposal} />
        <PrivateRoute exact path="/detalhes-da-proposta/:id" component={ProposalDetails} />
        <PrivateRoute exact path="/minhas-faturas/:id" component={FinancialStatement} />
        <PrivateRoute exact path="/duvidas" component={Doubt} />
        <PrivateRoute exact path="/meu-perfil" component={MyProfile} />
        <PrivateRoute exact path="/minha-carteira" component={Wallet} />
        <PrivateRoute exact path="/pagamento/:id" component={Checkout} />
        <PrivateRoute exact path="/pagamentos/boleto" component={Billet} />

        {/* Broker Area */}

        <PrivateRoute exact path="/cotacao" component={NewQuotation} />
        <PrivateRoute exact path="/imprimir-cotacao/:id" component={PrintQuotation} />
        <PrivateRoute exact path="/area-comercial" component={BrokerArea} />
        <PrivateRoute exact path="/comercial/minhas-cotacoes" component={QuotationList} />
        <PrivateRoute exact path="/comercial/minhas-propostas" component={BrokerProposalList} />
        <PrivateRoute exact path="/comercial/detalhes-da-proposta/:id" component={BackofficeProposalDetails} />
        <PrivateRoute exact path="/comercial/faturas-proposta/:id" component={BackofficeInvoiceProposal} />
        <PrivateRoute exact path="/comercial/boletos-proposta/:id" component={BackofficeBilletProposal} />

        <PrivateRoute exact path="/comercial/minhas-apolices" component={BrokerPolicyList} />
        <PrivateRoute exact path="/comercial/detalhes-da-apolice/:id" component={BackofficePolicyDetails} />
        <PrivateRoute exact path="/comercial/equipes" component={TeamsList} />
        <PrivateRoute exact path="/comercial/equipe/:id" component={TeamDetails} />

        {/* Backoffice Area*/}

        <PrivateRoute exact path="/backoffice/apolices" component={BackofficePolicyList} />
        <PrivateRoute exact path="/backoffice/detalhes-da-apolice/:id" component={BackofficePolicyDetails} />
        <PrivateRoute exact path="/backoffice/historico-da-apolice/:id" component={BackofficePolicyHistory} />
        <PrivateRoute exact path="/backoffice/listar-documentos-apolice/:id" component={ListDocumentsPolicy} />
        <PrivateRoute exact path="/backoffice/faturas/:id" component={BackofficeInvoice} />
        <PrivateRoute exact path="/backoffice/boletos-proposta/:id" component={BackofficeBilletProposal} />
        <PrivateRoute exact path="/backoffice/faturas-proposta/:id" component={BackofficeInvoiceProposal} />
        <PrivateRoute exact path="/backoffice/boleto/:id" component={BackofficeBillet} />
        <PrivateRoute exact path="/backoffice/propostas" component={BackofficeProposalList} />
        <PrivateRoute exact path="/backoffice/detalhes-da-proposta/:id" component={BackofficeProposalDetails} />
        <PrivateRoute exact path="/backoffice/detalhes-da-proposta/:id/imprimir" component={PrintProposalBackoffice} />
        <PrivateRoute exact path="/backoffice/usuarios" component={BackofficeUserList} />
        <PrivateRoute exact path="/backoffice/detalhes-do-usuario/:id" component={BackofficeUserDetails} />
        <PrivateRoute exact path="/backoffice/reclamacoes" component={BackofficeComplaintList} />
        <PrivateRoute exact path="/backoffice/historico-reclamacao/:id" component={BackofficeComplaint} />
        <PrivateRoute exact path="/backoffice/detalhes-reclamacao/:id" component={BackofficeComplaintDetails} />
        <PrivateRoute exact path="/backoffice/historico-extrato/:id" component={BackofficeExtract} />
        <PrivateRoute exact path="/backoffice/abrir-reclamacao/:id" component={BackofficeNewComplaint} />
        <PrivateRoute exact path="/backoffice/parceiros" component={BackofficeProviders} />
        <PrivateRoute exact path="/backoffice/sinistros" component={BackofficeClaimList} />
        <PrivateRoute exact path="/backoffice/detalhes-do-sinistro/:id" component={BackofficeClaimDetails} />
        <PrivateRoute exact path="/backoffice/enviar-arquivo/:id" component={BackofficeClaimFile} />
        <PrivateRoute exact path="/backoffice/terceiro-arquivo/:id" component={BackofficeThirdPartyFile} />
        <PrivateRoute exact path="/backoffice/historico-sinistro/:id" component={BackofficeClaimHistory} />
        <PrivateRoute exact path="/backoffice/boletos" component={BackofficeBillets} />
        <PrivateRoute exact path="/backoffice/inadimplentes" component={BackofficeDefaulters} />
        <PrivateRoute exact path="/backoffice/cancelamentos" component={BackofficeCancelList} />
        <PrivateRoute exact path="/backoffice/configuracoes" component={BackofficeSettings} />
        <PrivateRoute exact path="/backoffice/equipes" component={TeamsList} />
        <PrivateRoute exact path="/backoffice/equipe/:id" component={TeamDetails} />
        <PrivateRoute exact path="/backoffice/key-account" component={KeyAccountList} />
        <PrivateRoute exact path="/backoffice/key-account/:id" component={KeyAccountDetails} />
        <PrivateRoute exact path="/backoffice/boletos-apolice/:id" component={BackofficeBilletPolicy} />
        <PrivateRoute exact path="/backoffice/carteira-do-usuario/:id" component={BackofficeWallet} />
        <PrivateRoute exact path="/backoffice/detalhes-da-apolice/:id/imprimir" component={PrintPolicyBackoffice} />

        <PrivateRoute exact path="/backoffice/grupos-de-acesso" component={BackofficeAccessGroupsList} />
        <PrivateRoute exact path="/backoffice/grupo-de-acesso/:id" component={BackofficeAccessGroupsDetails} />
        <PrivateRoute exact path="/backoffice/criar-grupo-de-acesso" component={BackofficeAccessGroupNew} />

        <PrivateRoute exact path="/backoffice/cobrancas" component={ChargesListBackoffice} />

        {/* Backoffice Area  - Products*/}
        <PrivateRoute exact path="/backoffice/produtos/parceiros" component={BackOfficeProductsPartners} />
        <PrivateRoute exact path="/backoffice/produtos/parceiros-comissao" component={BackOfficeProductsPartnersComission} />

        <PrivateRoute exact path="/condicoes-gerais" component={GeneralConditions} />
        <PrivateRoute exact path="/nao-autorizado" component={ErrorPage} />
        <PrivateRoute exact path="/nao-autorizado" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
