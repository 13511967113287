import moment from '../../../../../node_modules/moment/moment';
import { Dotted, TableRow, TableColumn, Table, Text1, Text2, Text4, Text3, ColumnBlock } from '../style';

import logoSicoob from 'assets/billet/sicoob-logo.png';

import { updateValueToReal } from 'utils/updateValueToReal';

export const Payer = ({ userData }) => {
  return (
    <Table>
      <Dotted />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <p style={{ marginTop: 0, fontWeight: 'bold', fontSize: 10, fontFamily: 'arial' }}>Recibo do pagador</p>
      </div>

      {/* Linha 1 */}
      <TableRow>
        <TableColumn notHaveHorizontalBorder="both" notHaveVerticalBorder="top">
          <img src={logoSicoob} alt="Logo Sicoob" style={{ width: 150, height: 40 }} />
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" padding="0 10px" flexDirection="row" alignItems="end">
          <Text1>756-0</Text1>
        </TableColumn>
        <TableColumn notHaveHorizontalBorder="both" notHaveVerticalBorder="top" flexDirection="row" alignItems="end">
          <Text2>{userData?.typeable_barcode}</Text2>
        </TableColumn>
      </TableRow>

      {/* Linha 2 */}
      <TableRow>
        <TableColumn gap="10px" flex="1 1 48%" notHaveHorizontalBorder="both" notHaveVerticalBorder="top">
          <Text4>Beneficiário </Text4>
          <ColumnBlock>
            <Text3>{userData?.beneficiary}</Text3>
            <Text3>CNPJ: 36.851.667/0001-45</Text3>
            <Text3>AVENIDA JOAO NAVES DE AVILA,</Text3>
            <Text3>CENTRO, UBERLANDIA, MG. CEP: 3840042</Text3>
          </ColumnBlock>
        </TableColumn>

        <TableColumn flex="1 1 18%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Agência/Código do Beneficiário</Text4>
          <Text3>3299-9 / {userData?.clientCode}</Text3>
        </TableColumn>

        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 7%">
          <Text4>Espécie</Text4>
          <Text3>{userData?.type}</Text3>
        </TableColumn>

        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 8%">
          <Text4>Quantidade</Text4>
          <Text3></Text3>
        </TableColumn>

        <TableColumn flex="1 1 18%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Carteira / Nosso Número</Text4>
          <Text3>
            {userData?.walletCode} / {userData?.our_number}
            {/* {userData?.walletCode} / 0000001 */}
          </Text3>
        </TableColumn>
      </TableRow>

      {/* Linha 3 */}
      <TableRow>
        <TableColumn flex="1 1 29.7%" notHaveVerticalBorder="top" notHaveHorizontalBorder="both">
          <Text4>Número do documento</Text4>
          <Text3>{userData?.numberDocument}</Text3>
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 22%">
          <Text4>CPF/CNPJ</Text4>
          <Text3>35.485.048/0001-11</Text3>
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 22%">
          <Text4>Vencimento</Text4>
          <Text3>{moment(userData?.due_at).format('DD/MM/YYYY')}</Text3>
        </TableColumn>
        <TableColumn flex="1 1 26.3%" notHaveVerticalBorder="top" notHaveHorizontalBorder="right">
          <Text4>Valor do documento</Text4>
          <Text3 textAlign="end" fontWeight="bold">
            {updateValueToReal(userData?.value)}
          </Text3>
        </TableColumn>
      </TableRow>

      {/* Linha 4 */}
      <TableRow>
        <TableColumn notHaveHorizontalBorder="both" notHaveVerticalBorder="top" flex="1 1 18.5%">
          <Text4>(-) Desconto / Abatimentos</Text4>
          <Text3>.</Text3>
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 18.4%">
          <Text4>(-) Outras deduções</Text4>
          <Text3></Text3>
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 18.4%">
          <Text4>(+) Mora / Multa</Text4>
          <Text3></Text3>
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 18.4%">
          <Text4>(+) Outros acréscimos</Text4>
          <Text3></Text3>
        </TableColumn>
        <TableColumn notHaveVerticalBorder="top" notHaveHorizontalBorder="right" flex="1 1 26.3%">
          <Text4>(=) Valor cobrado</Text4>
          <Text3></Text3>
        </TableColumn>
      </TableRow>

      {/* Linha 5 */}
      <TableRow>
        <TableColumn notHaveHorizontalBorder="both" notHaveVerticalBorder="top">
          <Text4>Pagador</Text4>
          <Text3>
            {userData?.name} - {userData?.cpf}
          </Text3>
          <Text3>
            {userData?.street} {userData?.number} - {userData?.city}{' '}
          </Text3>
          <Text3>
            {userData?.CEP} - {userData?.UF}
          </Text3>
        </TableColumn>
      </TableRow>

      {/* Linha 6 */}
      <TableRow>
        <TableColumn notHaveHorizontalBorder="both" notHaveVerticalBorder="both" alignItems="strash" flex="1 1 100%">
          <TableRow justifyContent="space-between">
            <Text4 widht="fit-content">Instruções</Text4>
            <Text4 widht="fit-content" textAlign="end">
              Autenticação mecânica
            </Text4>
          </TableRow>
          <Text3>{userData?.instructions}</Text3>
        <br/>
        </TableColumn>
      </TableRow>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <p style={{ margin: 0, fontWeight: 'bold', fontSize: 10, fontFamily: 'arial' }}>Corte na linha pontilhada</p>
      </div>
      <Dotted />
      <br />
    </Table>
  );
};
