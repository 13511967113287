import { useState } from 'react';
import { CommissionSliderContainer, SliderContent } from './style';

const ComissionSlider = ({ setPercentCommission, grievance_percent, percentCommission }) => {
  const [percentCommissionLocal, setPercentCommissionLocal] = useState(percentCommission);

  return (
    <CommissionSliderContainer style={{ padding: 30 }}>
      {grievance_percent !== 0 && (
        <>
          <p>Percentual de comissionamento</p>
          <SliderContent
            track={false}
            aria-labelledby="track-false-slider"
            valueLabelDisplay="auto"
            valueLabelFormat={(e) => {
              return `${(percentCommissionLocal * grievance_percent) / 100}%`;
            }}
            getAriaValueText={(value) => `${value}`}
            defaultValue={0}
            value={percentCommissionLocal}
            onChange={(event) => {
              setPercentCommissionLocal(event.target.value);
            }}
            onChangeCommitted={() => {
              setPercentCommission(percentCommissionLocal);
            }}
            min={0}
            max={100}
            step={1}
            marks={[
              { value: 0, label: '0%' },
              { value: 100, label: `${grievance_percent}%` },
            ]}
          />
        </>
      )}
    </CommissionSliderContainer>
  );
};

export default ComissionSlider;
