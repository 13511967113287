import styled from 'styled-components';
import pixIcon from '../../assets/pixIcon.png';
import { secondary, white, black, lighterGray } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;

  border: 2px solid ${white};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  background-color: ${lighterGray};

  :hover {
    border-color: ${secondary};
  }

  div {
    width: 80%;
  }

  p {
    font-size: 14px;
    color: ${black};
    margin: 0;
    margin-left: 20px;
    text-transform: lowercase;

    :first-letter {
      text-transform: uppercase;
    }
  }

  .pix {
    width: 28px;
    height: 28px;
    background-image: url(${pixIcon});
  }

  @media (max-width: 1000px) {
    width: 90%;
  }
`;
