import styled from 'styled-components';
import { black, middleGray, lightGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 320px;
  min-width: 320px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${lightGray};
  border-radius: 5px;
  cursor: ${({ clickable }) => clickable && 'pointer'};
  margin: 10px;

  @media (max-width: 1000px) {
    margin-left: 0px !important;
  }

  transition: all 0.3s ease-in-out;
  &&:hover {
    box-shadow: ${({ clickable }) => clickable && 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'};
  }

  .description {
    display: flex;
    align-items: center;
  }

  div {
    display: flex;
    margin-left: 7% !important;
    width: 80%;
  }

  .iconArrow {
    margin-right: 7%;
  }

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  height: 60%;
  margin-left: 7%;
`;

export const BrandAndModel = styled.p`
  color: ${black};
  margin: 0;
  font-size: 14px;
`;

export const Plate = styled.p`
  color: ${middleGray};
  font-size: 13px;
  margin: 0;
`;

export const Status = styled.p`
  color: ${(props) => props.color || middleGray};
  font-size: 12px;
  margin: 0;
`;
