import React from 'react';
import 'moment/locale/pt-br';
import EditIcon from '@mui/icons-material/Edit';

function EditButton({ onClick }) {
    return (
        <>
            <EditIcon
                sx={{ marginRight: '15px' }}
                onClick={onClick}
                style={{ cursor: 'pointer' }}
                onMouseOver={({ target }) => (target.style.color = '#62ec83')}
                onMouseOut={({ target }) => (target.style.color = '#121212')}
            />
        </>
    );
}

export default EditButton;
