import React from 'react';
import 'moment/locale/pt-br';
import moment from 'moment';
import { RiCarLine } from 'react-icons/ri';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { formatCPF, formatPlate } from 'react-data-formatter';
import { Container, Div, Title, Subtitle, Requested, IconMobile, Icon } from './styles';
import { success } from 'styles/colorProvider';

function CancelItem({ policy, onClick }) {
  return (
    <>
      <Container onClick={onClick}>
        <div className="description">
          <div className="iconCar">
            <RiCarLine size={23} color={success} />
          </div>
          <Div>
            <Title>{policy?.TB_USERS?.name}</Title>
            <Subtitle>{formatCPF(policy?.TB_USERS?.cpf)}</Subtitle>
          </Div>

          <Div>
            <Title>{policy?.policy_number}</Title>
            <Subtitle>{policy?.TB_VEHICLE?.plate === null ? 'Sem placa' : formatPlate(policy?.TB_VEHICLE?.plate)}</Subtitle>
          </Div>

          <Div>
            <Requested>Solicitado em {moment(policy?.date_requested_cancel).format('DD/MM/YYYY')}</Requested>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>

          <IconMobile>
            <RiCarLine size={40} color={success} />
          </IconMobile>
        </div>
      </Container>
    </>
  );
}

export default CancelItem;
