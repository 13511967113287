import styled from 'styled-components';
import { primary, white, lightGray } from 'styles/colorProvider';


export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 90%;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 40px;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 360px 360px 350px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  .grid-item {
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .items-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const FormPermissions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  .grid-item {
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }

  .permission-section {
    width: 100%;
    h3 {
      font-size: 15px;
      padding-bottom: 5px;
      border-bottom: 1px solid ${lightGray};
    }
  }

  .items-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .checkbox-item {
    display: flex;
    flex-direction: row;
    font-size: 12px;
  }
`;

export const SelectLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0px;
  width: 40%;

  @media (max-width: 1000px) {
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0px 50px;
  margin-top: 10px;

  border-top: 1px solid ${lightGray};
  padding-top: 25px;

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
