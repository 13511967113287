import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { BsShieldExclamation } from 'react-icons/bs';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { formatClaimStatus } from 'utils/policyStatus';
import { formatCPF } from 'react-data-formatter';

function ClaimBackofficeItem({ claim }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(`/backoffice/detalhes-do-sinistro/${claim?.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconCar">
            <BsShieldExclamation size={23} color={formatClaimStatus(claim?.client_status)?.color} />
          </div>

          <Div>
            <Title>{claim?.TB_USERS_TB_CLAIM_id_ownerToTB_USERS?.name}</Title>
            <Subtitle>{formatCPF(claim?.TB_USERS_TB_CLAIM_id_ownerToTB_USERS?.cpf)}</Subtitle>
          </Div>

          <Div>
            <Title>Protocolo: {claim?.protocol}</Title>
            <Subtitle>Causa: {claim?.TB_CLAIM_CAUSE_LOSS?.name}</Subtitle>
          </Div>

          <Div>
            <Title
              color={
                claim?.client_status === 'noticed'
                  ? formatClaimStatus(claim?.client_status)?.color
                  : formatClaimStatus(claim?.client_status)?.color
              }
            >
              {claim?.client_status === 'noticed' ? 'Aguardando aprovação' : formatClaimStatus(claim?.client_status)?.label}
            </Title>
            <Subtitle>Aviso criado em {moment(claim?.created_at).format('DD/MM/YYYY HH:mm')}</Subtitle>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>

          <IconMobile>
            <BsShieldExclamation size={40} color={formatClaimStatus(claim?.status)?.color} />
          </IconMobile>
        </div>
      </Container>
    </>
  );
}

export default ClaimBackofficeItem;
