import styled from 'styled-components';
import { primary, white, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const ListPaymentStatus = styled.div`
  @media (max-width: 1000px) {
    margin-top: 7%;
  }
`;

export const EmptyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: ${middleGray};
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1001.9px) {
    justify-content: center;
    flex-direction: column;
    width: 45%;
  }
`;

export const DivPicker = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  p{
    font-size: 12px;
    margin: 0 0 4px 0;
  }

  @media (max-width: 1000px) {
    justify-content: space-around;
    width: 100%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`