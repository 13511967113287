import Cookies from 'js-cookie';
import api from 'api';

export const updateProvider = async (provider) => {
  try {
    const { id, ...providerData } = provider;
    const { name, phone, email, document, TB_ADDRESS } = providerData;
    const { zip_code, city, street, number, complement, district, state } = TB_ADDRESS;
    const formatedZipCode = zip_code.replaceAll(' ', '').replaceAll('-', '').replaceAll('.', '');

    return await api({
      method: 'PATCH',
      url: `/update-provider/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      data: {
        name,
        phone,
        email,
        document,
        address: {
          zip_code: formatedZipCode,
          city,
          street,
          number,
          complement,
          district,
          state,
        },
      },
      json: true,
    });
  } catch (error) {
    return error;
  }
};
