import styled from 'styled-components';
import { primary, secondary, middleGray, lightGray, logo } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 52%;
  height: 100%;
  background-size: cover;
  background-color: ${primary};

  div {
    height: 260px;
    width: 324px;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;

  @media (max-width: 1000px) {
    width: 80%;
  }

  .line {
    width: 139px;
    border-top: 1px solid ${lightGray};
    margin-top: 2%;

    @media (max-width: 1000px) {
      margin-top: 12%;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
  }

  .areaLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .logo {
    height: 36px;
    width: 209px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .buttonArea {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
`;

export const ForgotText = styled.a`
  margin-top: 5%;
  color: ${middleGray};
  font-size: 14px;
  cursor: pointer;

  @media (max-width: 1000px) {
    margin-top: 30%;
  }
`;

export const CreateAnAccount = styled.a`
  margin-top: 2%;
  color: ${middleGray};
  font-size: 14px;
  cursor: pointer;

  span {
    color: ${secondary};
  }

  @media (max-width: 1000px) {
    margin-top: 12%;
  }
`;
