import React, { useEffect } from 'react';
import Routes from './routes';
import store from './stores';

import { GlobalStyle } from 'styles/globalStyle';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CookiesPermission } from 'components';
import { primary, logoSmall } from 'styles/colorProvider';
import { StoreProvider } from 'easy-peasy';
import 'react-toastify/dist/ReactToastify.css';
import './styles/fontProvider.css';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: { main: primary },
    },
  });

  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = logoSmall;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, []);

  return (
    <div className="App">
      <StoreProvider store={store}>
        <CookiesPermission />
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <GlobalStyle />
          <Routes />
        </ThemeProvider>
      </StoreProvider>
    </div>
  );
};

export default App;
