import { action } from 'easy-peasy';
import withResetState from './withResetState';

const initialState = {
  user: null,
  reloadUser: true,
};

const store = {
  setUser: action((state, payload) => {
    state.user = payload;
  }),
};

export default withResetState(store, initialState);
