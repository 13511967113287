import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon } from './styles';
import { useHistory } from 'react-router-dom';
import { formatProposalStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';
import { error, warn, middleGray } from '../../styles/colorProvider';

function ProposalItem({ proposal, commercial }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    commercial
      ? history.push(`/comercial/detalhes-da-proposta/${proposal?.id}`)
      : history.push(`/backoffice/detalhes-da-proposta/${proposal?.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconProposal">
            {proposal?.status === 'awaiting_inspection' && <IoDocumentTextOutline size={23} color={middleGray} />}
            {proposal?.status === 'awaiting_payment' && <IoDocumentTextOutline size={23} color={middleGray} />}
            {proposal?.status === 'cancelled' && <IoDocumentTextOutline size={23} color={error} />}
            {proposal?.status === 'rejected' && <IoDocumentTextOutline size={23} color={error} />}
            {proposal?.status === 'expired' && <IoDocumentTextOutline size={23} color={error} />}
            {proposal?.status === 'awaiting_registration' && <IoDocumentTextOutline size={23} color={warn} />}
            {proposal?.status === 'awaiting_vistei' && <IoDocumentTextOutline size={23} color={warn} />}
            {proposal?.status === 'awaiting_complementar' && <IoDocumentTextOutline size={23} color={warn} />}
            {proposal?.status === 'awaiting_complementar_report' && <IoDocumentTextOutline size={23} color={warn} />}
          </div>

          <Div style={{ width: '50%' }}>
            <Title>{proposal?.user?.name}</Title>
            <Subtitle>{formatCPF(proposal?.user.cpf)}</Subtitle>
          </Div>

          <Div>
            <Title color={formatProposalStatus(proposal?.status)?.color}>
              {formatProposalStatus(proposal?.status)?.icon} {formatProposalStatus(proposal?.status)?.label}
            </Title>
            <Subtitle>
              Placa: {proposal?.vehicle?.plate !== null || undefined ? formatPlate(proposal?.vehicle?.plate) : 'Sem placa'}
            </Subtitle>
          </Div>

          <Div>
            {commercial && (
              <Title color={formatProposalStatus(proposal?.status)?.color}>Enviada por: {proposal?.author?.name}</Title>
            )}
            {!commercial && (
              <Title color={formatProposalStatus(proposal?.status)?.color}>
                Responsável: {formatProposalStatus(proposal?.status)?.owner}
              </Title>
            )}
            <Subtitle>Enviada em {moment(proposal?.created_at).format('DD/MM/YYYY HH:mm')}</Subtitle>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default ProposalItem;
