import styled from 'styled-components';
import { primary, secondary, white, darkGray, middleGray, lightGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 90%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 30px;
  padding-left: 90px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px 20px 25px;
  }
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    word-break: break-word;
  }
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${primary};
    border-radius: 10px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;

export const CountArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 12px;

  p {
    margin: 0px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: ${primary};
  background: ${secondary};
  margin-right: 20px;

  :hover {
    opacity: 0.8;
    color: ${darkGray};
  }

  @media (max-width: 1000px) {
    margin: 0 0 15px 0;
  }
`;
export const ModalButton = styled.div`
  display: flex;
  margin-top: 5%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }
`;
export const Observation = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 95%;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
  }
`;
export const DivModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid ${lightGray};

  .left {
    width: 50%;
    justify-content: column;

    @media (max-width: 1000px) {
      width: 100%;
      border-bottom: 1px solid ${lightGray};
    }
  }
  .right {
    width: 50%;
    justify-content: column;
    margin-bottom: 10px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: column;
    flex-direction: column;
    border: none;
    border-bottom: 1.5px solid ${lightGray};
  }
`;

export const TextModal = styled.p`
  font-size: 15px;
  color: ${darkGray};
  line-height: 15px;
  span {
    color: ${middleGray};
  }

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;
