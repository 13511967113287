import styled from 'styled-components';
import { darkGray } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: space-between;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  transition: all ease-in-out 0.3s;
  border: 1.5px solid #d1d2d4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0px 10px;
  height: ${(props) => props.height || '30px'};

  button {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 14px;
    color: #868686;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Input = styled.input`
  width: ${(props) => props.width || '200px'};
  color: ${darkGray};
  font-size: 14px;
  font-weight: 400;
  padding: 3px 10px;
  outline: none;
  border: none;
`;
