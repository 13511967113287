/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import api from 'api';

import { Header, BackofficeMenu, ExtractItem } from 'components';
import { Container, ContentArea } from './styles';

function Extract({ location, history }) {
    const policyId = parseInt(location.pathname.split('/backoffice/historico-extrato/')[1]);

    const [loading, setLoading] = useState(false);
    const [extractData, setExtractData] = useState({});

    const loadData = async () => {
        setLoading(true);
        const extract = await api({
            method: 'GET',
            url: `/backoffice/extract/${policyId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: Cookies.get('splitriskwl-authtoken'),
            },
            json: true,
        });
        console.log({ extract })

        setExtractData(extract?.data?.response);
        setLoading(false);
    };
    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Header />
            <Container>
                <ContentArea>
                    <BackofficeMenu policyId={policyId} />
                    {!loading && Array.isArray(extractData) && (
                        <>
                            <h3>Extrato do usuário</h3>
                            <div className="extractArea">
                                {extractData?.map((extract) => (
                                    <ExtractItem data={extract} />
                                ))}
                            </div>
                        </>
                    )}

                </ContentArea>
            </Container>
        </>
    );
}

export default Extract;
