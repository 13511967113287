import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Text, Text1 } from './styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Questions({question, answer}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>

      <Accordion sytle={{marginBottom: '15px'}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}> 
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center', marginRight: '10px'}}
        >
          <Text sx={{ width: '100%', flexShrink: 0 }}>
            {question}
          </Text> 
        </AccordionSummary>
        <AccordionDetails>
          <Text1>
            {answer}
          </Text1>
        </AccordionDetails>
      </Accordion >

      
    </Box>
  );
}
