import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import styled from 'styled-components';
import { primary, lightGray, white, middleGray, darkGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -1.6%;

  .lastItem {
    height: 9%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 80%;
  }
`;

export const ItemPolicy = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 41%;
  padding: 2.2% 3%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${darkGray};
  margin: 0;
  margin-top: 2%;

  span {
    color: ${middleGray};
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
    font-size: 12px;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const StyledAccordion = styled(Accordion)`
  border: 0px solid #fff !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border-bottom: none !important;
  margin-bottom: 10px;
  padding: 0px !important;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  border: 0px solid #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  width: 95%;
  padding: 0px !important;
  margin-bottom: 0px !important;
  p {
    font-size: 14px;
    margin: 0px !important;
    font-weight: bold;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border: 0px solid #fff !important;
  width: 87% !important;
  margin: 0px !important;
  text-align: justify !important;
  padding-bottom: 0px !important;
  `
  
export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 30px;

  width: 100%;

  div{
    height: 100%;
    align-items: center;
    margin-left: 5px;
    justify-content: center;
    text-align: center;

    img{
      align-items: center;
      width: 20px;
      margin-top: 9px;
    }
  }
`;
