import ReactLoading from 'react-loading';
import api from 'api';
import { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { Button } from 'components';
import { ModalCard } from './style';
import { secondary } from 'styles/colorProvider';

export const NewFipeSearch = ({ onFinish }) => {
  const [vehicleData, setVehicleData] = useState();
  const [selectsIsLoading, setSelectsIsLoading] = useState({});
  const [selecstOptionsList, setSelectsOptionsList] = useState({
    vehicleType: [
      { Value: 1, Label: 'Carro' },
      { Value: 7, Label: 'Moto' },
    ],
    brand: [],
    model: [],
    version: [],
  });

  const listBrands = async (vehicleType) => {
    setSelectsIsLoading({ ...selectsIsLoading, brandList: true });
    try {
      const resp = await api({
        method: 'GET',
        url: `/listBrands`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        params: {
          type: vehicleType?.Value,
        },
      });

      setSelectsOptionsList({ ...selecstOptionsList, brand: resp?.data });
      setSelectsIsLoading({ ...selectsIsLoading, brandList: false });
    } catch (error) {
      setSelectsIsLoading({ ...selectsIsLoading, brandList: false });
    }
  };

  const listModels = async (vehicleBrand) => {
    setSelectsIsLoading({ ...selectsIsLoading, modelsList: true });
    try {
      const resp = await api({
        method: 'POST',
        url: `/listModels`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        data: {
          brand: vehicleBrand,
          type: vehicleData?.type,
        },
      });
      setSelectsOptionsList({ ...selecstOptionsList, model: resp?.data });
      setSelectsIsLoading({ ...selectsIsLoading, modelsList: false });
    } catch (error) {
      setSelectsIsLoading({ ...selectsIsLoading, modelsList: false });
    }
  };

  const listVersions = async (vehicleModel) => {
    setSelectsIsLoading({ ...selectsIsLoading, versionsList: true });
    try {
      const resp = await api({
        method: 'POST',
        url: `/listYearModels`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        data: {
          model: vehicleModel,
          brand: vehicleData?.id_brand,
          type: vehicleData?.type,
        },
      });
      setSelectsOptionsList({ ...selecstOptionsList, version: resp?.data });
      setSelectsIsLoading({ ...selectsIsLoading, versionsList: false });
    } catch (error) {
      setSelectsIsLoading({ ...selectsIsLoading, versionsList: false });
    }
  };

  const getFipe = async (vehicleVersion, vehicleFuel) => {
    setSelectsIsLoading({ ...selectsIsLoading, getFipe: true });

    try {
      const resp = await api({
        method: 'POST',
        url: `/getFipe`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        data: {
          fuel: Number(vehicleFuel),
          yearModel: Number(vehicleVersion),
          model: Number(vehicleData?.id_model),
          brand: Number(vehicleData?.id_brand),
          type: Number(vehicleData?.type),
        },
      });

      setVehicleData({
        ...vehicleData,
        brand: resp?.data?.brand,
        model: resp?.data?.model,
        fipe_code: resp?.data?.fipe_code,
        value: resp?.data?.value,
        year: resp?.data?.year,
        id_fuel: resp?.data?.id_fuel,
        fuel: resp?.data?.fuel,
      });
      setSelectsIsLoading({ ...selectsIsLoading, getFipe: false });
    } catch (error) {
      setSelectsIsLoading({ ...selectsIsLoading, getFipe: false });
    }
  };

  return (
    <ModalCard>
      <div className="centralizer">
        <h1>Procurar veículo</h1>

        {/* VEHICLE TYPE */}
        <Autocomplete
          disabled={selectsIsLoading.vehicleType}
          loading={selectsIsLoading.vehicleType}
          loadingText="Carregando..."
          options={selecstOptionsList.vehicleType || []}
          noOptionsText="Sem opções..."
          sx={{ width: 310 }}
          getOptionLabel={(option) => {
            return option?.Label;
          }}
          value={vehicleData?.type}
          onChange={(event, data) => {
            setVehicleData({ ...vehicleData, type: data.Value });
            listBrands(data);
          }}
          renderOption={(props, option) => {
            return (
              <p style={{ paddingLeft: 10, cursor: 'pointer' }} {...props} className="title">
                {option?.Label}
              </p>
            );
          }}
          renderInput={(params) => (
            <TextField disabled={false} {...params} label="Selecione o tipo" placeholder="Digite para buscar..." />
          )}
        />

        {/* VEHICLE BRAND */}
        {selectsIsLoading.brandList && <ReactLoading color={secondary} height={24} width={24} type="bubbles" />}
        <Autocomplete
          disabled={selectsIsLoading.brandList || selecstOptionsList?.brand?.length < 1}
          loading={selectsIsLoading.brandList}
          loadingText="Carregando..."
          options={selecstOptionsList?.brand || []}
          noOptionsText="Sem opções..."
          sx={{ width: 310 }}
          getOptionLabel={(option) => {
            return option.Label;
          }}
          onChange={(event, data) => {
            setVehicleData({ ...vehicleData, id_brand: data.Value });
            listModels(data.Value);
          }}
          renderOption={(props, option) => (
            <p style={{ paddingLeft: 10, cursor: 'pointer' }} {...props} className="title">
              {option?.Label}
            </p>
          )}
          renderInput={(params) => (
            <TextField disabled={false} {...params} label="Selecione a marca" placeholder="Digite para buscar..." />
          )}
          value={vehicleData?.type.brand}
        />

        {/* VEHICLE MODEL */}
        {selectsIsLoading.modelsList && <ReactLoading color={secondary} height={24} width={24} type="bubbles" />}
        <Autocomplete
          disabled={selectsIsLoading.model || selecstOptionsList?.model?.length < 1}
          loading={selectsIsLoading.model}
          loadingText="Carregando..."
          options={selecstOptionsList?.model || []}
          noOptionsText="Sem opções..."
          sx={{ width: 310 }}
          getOptionLabel={(option) => {
            return option.Label;
          }}
          // onChange={(event, modelInputValue) => selectModelHandleChange(modelInputValue)}
          onChange={(event, data) => {
            setVehicleData({ ...vehicleData, id_model: data.Value });
            listVersions(data.Value);
          }}
          renderOption={(props, option) => (
            <p style={{ paddingLeft: 10, cursor: 'pointer' }} {...props} className="title">
              {option?.Label}
            </p>
          )}
          renderInput={(params) => (
            <TextField disabled={false} {...params} label="Selecione o modelo" placeholder="Digite para buscar..." />
          )}
          value={vehicleData?.type.model}
          isOptionEqualToValue={(option, value) => {
            return option.Value === value.Value;
          }}
        />

        {/* VEHICLE YEAR MODEL */}
        {selectsIsLoading.versionsList && <ReactLoading color={secondary} height={24} width={24} type="bubbles" />}
        <Autocomplete
          disabled={selectsIsLoading.version || selecstOptionsList?.version?.length < 1}
          loading={selectsIsLoading.version}
          loadingText="Carregando..."
          options={selecstOptionsList?.version || []}
          noOptionsText="Sem opções..."
          sx={{ width: 310 }}
          getOptionLabel={(option) => {
            return option.Label;
          }}
          onChange={(event, data) => {
            const [build_year, fuel] = data.Label?.split(' ');
            setVehicleData({ ...vehicleData, yearModel: data.Value });
            getFipe(build_year, fuel === 'Gasolina' ? 1 : fuel === 'Diesel' ? 3 : 2);
          }}
          renderOption={(props, option) => (
            <p style={{ paddingLeft: 10, cursor: 'pointer' }} {...props} className="title">
              {option?.Label}
            </p>
          )}
          renderInput={(params) => <TextField {...params} label="Selecione a versão" placeholder="Digite para buscar..." />}
          value={vehicleData?.yearModel}
          isOptionEqualToValue={(option, value) => {
            return option?.Value === value?.Value;
          }}
        />

        {selectsIsLoading.getFipe && <ReactLoading color={secondary} height={24} width={24} type="bubbles" />}
        <Button
          loading={selectsIsLoading?.getFipe}
          disabled={!vehicleData?.value}
          width="300px"
          style={{ marginTop: 20, marginBottom: 50 }}
          onClick={() => {
            const data = {
              type: vehicleData?.type,
              id_brand: vehicleData?.id_brand,
              brand: vehicleData?.brand,
              id_model: vehicleData?.id_model,
              model: vehicleData?.model,
              year: vehicleData?.year,
              build_year: vehicleData?.year,
              value: vehicleData?.value,
              id_fuel: vehicleData?.id_fuel,
              fuel: vehicleData?.fuel,
              fipe_code: vehicleData?.fipe_code,
              id_type: vehicleData?.type,
            };
            onFinish(data);
          }}
        >
          Selecionar
        </Button>
      </div>
    </ModalCard>
  );
};
