import styled from 'styled-components';
import { primary, lightGray, white, middleGray, darkGray, error } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }

  .buttonCancelProposal {
    width: 150px;
    display: flex;
    height: 30px;
    background-color: ${error};
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -1.6%;

  .lastItem {
    height: 9%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 80%;
  }
`;

export const ItemPolicy = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 41%;
  padding: 2.2% 3%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${darkGray};
  margin: 0;
  margin-top: 2%;

  span {
    color: ${middleGray};
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
    font-size: 12px;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const InspectionImage = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  margin: 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &&:hover {
    opacity: 0.8;
  }
`;

export const PhotosArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PhotosItem = styled.img`
  height: 100px;
  width: 100px;
  margin: 5px;
  border-radius: 10px;
  object-fit: cover;
  border: ${(props) => props.status === 'approved' && `3px solid #2ecc71`};
  border: ${(props) => props.status === 'rejected' && `3px solid #e74c3c`};
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 30px;

  width: 100%;

  div{
    height: 100%;
    align-items: center;
    margin-left: 5px;
    justify-content: center;
    text-align: center;

    img{
      align-items: center;
      width: 20px;
      margin-top: 9px;
    }
  }
`;