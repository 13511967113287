import { useHistory } from 'react-router-dom';

import { Container, ItemMenu, TextItem } from './styles';

function PolicyMenu() {
  const history = useHistory();

  return (
    <Container>
      <ItemMenu
        className="select"
        onClick={() => {
          history.push('/cotacao');
        }}
      >
        <TextItem>Nova cotação</TextItem>
      </ItemMenu>
    </Container>
  );
}

export default PolicyMenu;
