import React, { useState } from 'react';
import Cookies from 'js-cookie';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import api from 'api';
import moment from 'moment';
import 'moment/locale/pt-br';
import { error, success, warn, middleGray } from 'styles/colorProvider';
import { Dialog, Button, PaymentMethod } from 'components';
import { AiOutlineBarcode, AiFillStar } from 'react-icons/ai';
import { toast } from 'react-toastify';
import corporateSettings from 'corporateSettings.json';

import {
  HiOutlineClock,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineCalendar,
  HiOutlineXCircle,
  HiOutlineBan,
} from 'react-icons/hi';
import {
  Container,
  StatusText,
  MonthText,
  Value,
  ButtonRedirectToWallet,
  ConfirmAndCancelButtonsContainer,
  CancelButton,
} from './styles';
import { formatPaymentStatus } from 'utils/paymentStatus';
import { useHistory } from 'react-router-dom';
import { formatCurrency } from 'react-data-formatter';

const translateModality = {
  billet: 'Boleto',
  pix: 'Pix',
  credit_card: 'Cartão de crédito',
};

function ChargeItem({
  id,
  status,
  proposalId,
  policyId,
  month,
  value,
  message,
  type,
  billet,
  data,
  backoffice,
  backofficePolicy,
  loadData,
  policyStatus,
  invoiceUrl,
  billingType,
  canBeReprocessed,
  canChangeModalityToCreditCard,
  isHinova = false,
}) {
  const history = useHistory();

  const payDate =
    moment().endOf('day').format() === moment(message).endOf('day').format() ? (
      <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 14 }}>
        <AiFillStar color={warn} style={{ marginRight: 5 }} /> Vence hoje
      </p>
    ) : moment().endOf('day') <= moment(message).endOf('day') ? (
      `Vence em ${moment.utc(message).format('DD/MM/YYYY')}`
    ) : (
      `Vencido em ${moment.utc(message).format('DD/MM/YYYY')}`
    );
  const paidDate = `Pago em ${moment.utc(message).format('DD/MM/YYYY')}`;
  const dueAt = `Vencido em ${moment.utc(message).format('DD/MM/YYYY')}`;

  const [selectedModality, setSelectedModality] = useState('');

  const [changePaymentModality, setChangePaymentModality] = useState(false);
  const [confirmChangePaymentModality, setConfirmChangePaymentModality] = useState(false);

  const [confirmManualPayment, setConfirmManualPayment] = useState(false);
  const [textInput, setTextInput] = useState();

  const [confirmReprocessPayment, setConfirmReprocessPayment] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);

  const host = window.location.hostname;

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleSelectModality = (newModality) => {
    setSelectedModality(newModality);
    setChangePaymentModality(false);
    setConfirmChangePaymentModality(true);
  };

  const handleChangeModality = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'post',
        url: '/change-charge-modality',
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          newModality: selectedModality,
          id_charge: id,
          id_policy: type === 'resubscription' ? policyId : null,
          id_proposal: type === 'subscription' ? proposalId : null,
        },
      });
      toast.success('Modalide de pagamento alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmChangePaymentModality(false);
      await sleep(3000);
      loadData();
    } catch (e) {
      toast.error(e?.response?.data?.error || 'Não foi possivel alterar a modalidade de pagamento para essa cobrança', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmChangePaymentModality(false);
    }
  };

  const handleManualPayment = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'post',
        url: `/manual-payment`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_charge: id,
          description: textInput,
        },
      });
      toast.success('Baixa manual realizada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmManualPayment(false);
      setTextInput('');
      await sleep(3000);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Não foi possível processar baixa manual para essa cobrança', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmManualPayment(false);
      setTextInput('');
    }
  };

  const handleReprocessPayment = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'post',
        url: `/reprocess-charge-with-credit-card/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
      toast.success('Cobrança reprocessada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmReprocessPayment(false);
      await sleep(3000);
      loadData();
    } catch (e) {
      toast.error(e?.response?.data?.error || 'Não foi possível reprocessar esse pagamento', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmReprocessPayment(false);
    }
  };

  const renderConfirmManualPayment = () => (
    <Dialog title="Baixa manual" open={confirmManualPayment} onClose={() => setConfirmManualPayment(false)}>
      <label>Descrição:</label>
      <TextareaAutosize
        minRows={1}
        onChange={(e) => setTextInput(e.target.value)}
        value={textInput}
        placeholder="Insira aqui a descrição"
        style={{
          padding: 10,
          fontFamily: 'Nunito',
          fontSize: 14,
          marginTop: '3%',
          width: '100%',
          height: '100px',
          borderRadius: 5,
          borderColor: '#d1d2d4',
        }}
      />

      <Button
        disabled={loadingButton || !textInput}
        loading={loadingButton}
        onClick={handleManualPayment}
        style={{ margin: 0, marginTop: 20 }}
      >
        Confirmar baixa
      </Button>
    </Dialog>
  );

  const renderConfirmReprocessPayment = () => (
    <Dialog title="Reprocessar cobrança" open={confirmReprocessPayment} onClose={() => setConfirmReprocessPayment(false)}>
      <label>Isso fará com que a cobrança seja reprocessada caso o cliente tenha um cartão de credito cadastrado</label>
      <ConfirmAndCancelButtonsContainer>
        <Button className="cancel" onClick={() => setConfirmReprocessPayment(false)}>
          Cancelar
        </Button>
        <Button disabled={loadingButton} loading={loadingButton} onClick={handleReprocessPayment}>
          Confirmar reprocessamento
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  const renderChangePaymentModality = () => (
    <Dialog title="Alterar modalidade de pagamento" open={changePaymentModality} onClose={() => setChangePaymentModality(false)}>
      <label>
        Ao alterar a modalidade de pagamento para essa cobrança, todas as próximas cobranças serão geradas na modalidade
        escolhida.
      </label>
      {!canChangeModalityToCreditCard &&
        billingType !== 'credit_card' &&
        (backoffice || backofficePolicy ? (
          <label>
            Atenção: para poder escolher a opção "Cartão de credito" o cliente dessa apólice precisa ter um cartão de crédito
            cadastrado.
          </label>
        ) : (
          <label>
            Atenção: para poder escolher a opção "Cartão de credito" você primeiro precisa ter um cartão cadastrado. Pode
            cadastrar na sua carteira,
            <ButtonRedirectToWallet onClick={() => history.push('/minha-carteira')}>clicando aqui</ButtonRedirectToWallet>
          </label>
        ))}
      {canChangeModalityToCreditCard && billingType !== 'credit_card' && (
        <PaymentMethod
          disabled={true}
          style={{ marginTop: 10, width: '50%' }}
          type="cartão de crédito"
          onClick={() => handleSelectModality('credit_card')}
        />
      )}
      {billingType !== 'billet' && (
        <PaymentMethod style={{ marginTop: 10, width: '50%' }} type="boleto" onClick={() => handleSelectModality('billet')} />
      )}
      {billingType !== 'pix' && (
        <PaymentMethod style={{ marginTop: 10, width: '50%' }} type="pix" onClick={() => handleSelectModality('pix')} />
      )}
      <CancelButton onClick={() => setChangePaymentModality(false)}>Cancelar</CancelButton>
    </Dialog>
  );

  const renderConfirmChangePaymentModality = () => (
    <Dialog
      title="Confirmar alteração de modalidade de pagamento"
      open={confirmChangePaymentModality}
      onClose={() => {
        setConfirmChangePaymentModality(false);
        setChangePaymentModality(true);
      }}
    >
      <label>
        Tem certeza que deseja alterar a modalidade de pagamento para <strong>{translateModality[selectedModality]}</strong>?
      </label>
      {selectedModality === 'credit_card' && (
        <label>
          Ao confirmar essa alteração para cartão de crédito, {!!proposalId && 'caso exista um cartão cadastrado '}
          <strong>
            será feita a cobrança dessa fatura no {backoffice || backofficePolicy ? 'cartão do cliente' : 'seu cartão de crédito'}
          </strong>
        </label>
      )}
      <ConfirmAndCancelButtonsContainer>
        <Button
          className="cancel"
          onClick={() => {
            setConfirmChangePaymentModality(false);
            setChangePaymentModality(true);
          }}
        >
          Cancelar
        </Button>
        <Button className="confirm" onClick={() => handleChangeModality()} loading={loadingButton} disabled={loadingButton}>
          Confirmar
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  return (
    <>
      {renderConfirmManualPayment()}
      {renderConfirmReprocessPayment()}
      {renderChangePaymentModality()}
      {renderConfirmChangePaymentModality()}
      <Container>
        <div className="description">
          {/* /MONTH */}
          <div className="box" style={{ width: '10%' }}>
            <HiOutlineCalendar color={middleGray} size={16} />
            <MonthText>{month}</MonthText>
          </div>

          {/* STATUS */}
          <div className="box" style={{ width: '20%' }}>
            {status === 'awaiting_payment' && <HiOutlineClock color={warn} size={20} />}
            {status === 'paid' && <HiOutlineCheckCircle color={success} size={20} />}
            {status === 'overdue' && <HiOutlineExclamationCircle color={error} size={20} />}
            {status === 'due' && <HiOutlineXCircle color={error} size={20} />}
            {status === 'canceled' && <HiOutlineBan color={error} size={20} />}
            <StatusText>{formatPaymentStatus(status)?.label}</StatusText>
          </div>

          {/* VALUE */}
          <div className="box" style={{ width: '10%' }}>
            <Value>{formatCurrency(value || 0)}</Value>
          </div>

          {/* TYPE */}
          <div className="box" style={{ width: '10%' }}>
            {type === 'subscription' && 'Subscrição'}
            {type === 'resubscription' && 'Resubscrição'}
            {type === 'deductible' && 'Franquia'}
            {type === 'other' && 'Outros'}
          </div>

          {/* BILLING TYPE */}
          <div className="box" style={{ width: '15%' }}>
            {billingType && translateModality[billingType]}
          </div>

          {/* STATUS */}
          <div className="box" style={{ width: '15%' }}>
            {status === 'paid' && <nobr>{paidDate}</nobr>}
            {status === 'overdue' && <nobr>{dueAt}</nobr>}
            {<nobr>{status !== 'paid' && status !== 'overdue' && payDate}</nobr>}
          </div>
        </div>

        {/* OPTIONS */}
        <div className="box">
          <div className="charge-buttons">
            {data === 0 && status === 'awaiting_payment' && billet && (
              <div className="button">
                <button onClick={() => history.push(`/backoffice/boleto/${id}`)}>
                  <AiOutlineBarcode size={18} />
                </button>
              </div>
            )}

            {/* {billingType !== 'credit_card' && !['paid', 'canceled'].includes(status) &&
            (backoffice || backofficePolicy) && (
              <button
                onClick={() => setConfirmManualPayment(true)}
                height="30px"
                style={{ width: 150 }}
                className="billet-create"
                disabled={policyStatus === 'canceled'}
              >
                Baixa manual
              </button>
            )} */}

            {billingType === 'credit_card' &&
              !['paid', 'canceled'].includes(status) &&
              canBeReprocessed &&
              (backoffice || backofficePolicy) && (
                <button
                  onClick={() => setConfirmReprocessPayment(true)}
                  height="30px"
                  style={{ width: '10rem' }}
                  className="reprocess-charge"
                >
                  Reprocessar
                </button>
              )}

            {!['paid', 'canceled'].includes(status) && !isHinova && (
              <button onClick={() => setChangePaymentModality(true)} className="change-modality">
                Alterar modalidade
              </button>
            )}

            {invoiceUrl && status !== 'paid' && (
              <a href={invoiceUrl} target="_blank" rel="noopener noreferrer" className="view-charge">
                Ver cobrança
              </a>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

export default ChargeItem;
