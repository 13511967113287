import styled from 'styled-components';
import { black, middleGray, lightGray, success, secondary } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${lightGray};
  border-radius: 20px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  margin-top: 20px;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  &&:hover {
    box-shadow: ${(props) => props.clickable && 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'};
  }

  @media (max-width: 1280px) {
    align-items: start;
    flex-direction: column;
  }

  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    justifycontent: flex-end;

    @media (max-width: 1280px) {
      width: 100%;
      align-items: center;
    }
  }

 .charge-buttons {
    display: flex;
    @media (max-width: 1280px) {
      width: 100%;
      margin-top: 20px;
    }
    @media (max-width: 600px) {
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
      flex-direction: column;
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 2%;
    width: 90%;
    margin-right: 30px;
    justify-content: space-between;

    @media (max-width: 1280px) {
      align-items: start;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      gap: 5px;
    }
  }

  .iconArrow {
    margin-right: 5%;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .button {
    margin-right: 5%;

    button {
      border: none;
      background: ${success};
      border-radius: 5px;
      width: 40px;
      height: 25px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all ease-in-out 0.2s;
      margin: 0px;

      :hover {
        opacity: 0.8;
      }
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .billet-create {
    border: none;
    background: ${lightGray};
    border-radius: 30px;
    width: 100px;
    height: 25px;
    margin-right: 36px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    align-items: center;
    justify-content: center;
    :hover {
      opacity: 0.8;
    }

    @media (max-width: 400px) {
      margin-right: 0;
    }
  }

  .view-charge,
  .change-modality,
  .reprocess-charge {
    text-decoration: none;
    color: ${black};
    text-align: center;
    border: none;
    background: ${secondary};
    border-radius: 30px;
    width: 170px;
    height: 25px;
    margin-right: 18px;
    padding: 1px 6px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      opacity: 0.8;
    }

  }

  .isDisabled {
    opacity: 0.4;
    cursor: default;
    &:hover {
      opacity: 0.4;
    }
  }

  @media (max-width: 1280px) {
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 10%;
    height: auto;
  }
`;

export const ButtonRedirectToWallet = styled.button`
  background: ${secondary};
  border: 3px solid ${secondary};
  border-radius: 30px;
  color: black;
  border: none;
  height: 20px;
  text-align: center;
  padding: 1px 6px;
  padding: 0;
  cursor: pointer;
  outline: inherit;
`;

export const ConfirmAndCancelButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    text-decoration: none;
    text-align: center;
    border: none;
    border-radius: 30px;
    width: 40%;
    margin-top: 100px;
    height: 40px;
    margin-right: 18px;
    padding: 1px 6px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      opacity: 0.8;
    }
  }
  button.confirm {
    background: ${secondary};
  }
  button.cancel {
    background: ${lightGray};
  }
`;

export const CancelButton = styled.button`
  text-decoration: none;
  text-align: center;
  background: ${lightGray};
  border: none;
  border-radius: 30px;
  width: 40%;
  margin-top: 15px;
  height: 40px;
  margin-right: 18px;
  padding: 1px 6px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    opacity: 0.8;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2%;
  width: 80%;

  @media (max-width: 1000px) {
    width: 50%;
  }
`;

export const StatusText = styled.p`
  color: ${black};
  margin-left: 5%;
`;

export const Month = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2%;
  width: 80%;

  @media (max-width: 1000px) {
    width: 40%;
    margin: 0;
  }
`;

export const MonthText = styled.p`
  color: ${black};
  margin-left: 5%;
  text-transform: capitalize;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: left;
  margin-left: 2%;
  width: 80%;

  @media (max-width: 1000px) {
    margin-top: 1%;
  }
`;

export const Value = styled.p`
  color: ${middleGray};
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const Message = styled.p`
  color: ${middleGray};
  font-size: 12px;
  text-align: left;
  width: 50%;

  @media (max-width: 1000px) {
    margin-left: 2%;
    margin-top: 1%;
    font-size: 13px;
  }
`;

export const Billet = styled.p`
  display: none;

  @media (max-width: 1280px) {
    display: flex;
    margin-left: 2%;
    margin-top: 1%;
    color: white;
    font-size: 14px;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: ${success};
    border-radius: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    :hover {
      opacity: 0.8;
    }
  }
`;
