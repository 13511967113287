import styled from 'styled-components';
import { black, lightGray, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${lightGray};
  border-radius: 20px;
  margin: 5px 0px;
  font-size: 13px;
  position: relative;

  .description {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .iconCar {
      display: flex;
      align-items: center;
      width: 5%;
      margin-left: 2%;

      @media (max-width: 1000px) {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
      padding: 10px;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 10%;
    height: 100px;
    min-height: 100px;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-left: 4%;
  width: 80%;

  @media (max-width: 1000px) {
    width: 80%;
    align-items: right;
    margin-bottom: 5px;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 15.5%;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    width: 10%;
    position: absolute;
    margin-left: 80%;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h3`
  font-size: 14px;
  color: ${(props) => props.color || black};
  font-weight: 500;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  color: ${middleGray};
  margin: 0;
`;

