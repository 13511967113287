import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';

import { Container, MenuItem, MenuButton } from './styles';

import { BsShieldCheck } from 'react-icons/bs';
import { HiMenu } from 'react-icons/hi';
import { TbReportMoney } from 'react-icons/tb';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';

function SideMenuBroker() {
  const location = useLocation();
  const path = location.pathname;

  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);

  const permissions = JSON.parse(Cookies.get('splitriskwl-backoffice-permissions'));

  const hasPermission = (menu) => {
    const menuIndex = permissions.findIndex((i) => i.feature === menu);
    if (menuIndex === -1) return;
    return permissions[menuIndex].hasPermission;
  };

  return (
    <Container collapsed={collapsed}>
      <MenuButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        <HiMenu />
      </MenuButton>

      <MenuItem
        collapsed={collapsed}
        className={path.includes('/comercial/minhas-cotacoes') && 'select'}
        onClick={() => history.push('/comercial/minhas-cotacoes')}
      >
        <TbReportMoney />
        <p className="menuText">Cotações</p>
      </MenuItem>

      <MenuItem
        collapsed={collapsed}
        className={path.includes('/comercial/minhas-propostas') && 'select'}
        onClick={() => history.push('/comercial/minhas-propostas')}
      >
        <IoDocumentTextOutline />
        <p className="menuText">Propostas</p>
      </MenuItem>

      <MenuItem
        collapsed={collapsed}
        className={path.includes('/comercial/minhas-apolices') && 'select'}
        onClick={() => history.push('/comercial/minhas-apolices')}
      >
        <BsShieldCheck />
        <p className="menuText">Apólices</p>
      </MenuItem>

      {!isOceanica ? (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/comercial/equipes') && 'select'}
          onClick={() => history.push('/comercial/equipes')}
        >


          <FiUsers />
          <p className="menuText">Equipes</p>
        </MenuItem>
      ) :
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/comercial/equipes') && 'select'}
          onClick={() => history.push('/comercial/equipes')}
        >
          <FiUsers />
          <p className="menuText">Corretores</p>
        </MenuItem>}
    </Container>
  );
}

export default SideMenuBroker;
