import styled from 'styled-components';
import { darkGray, primary, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .info-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .button-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
  }

  p {
    margin: 0px;
    font-size: 12px;
    color: ${darkGray};
    font-weight: bold;
  }

  span {
    color: ${middleGray};
    font-weight: normal;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
