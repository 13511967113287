import React from 'react';
import Avatar from '@mui/material/Avatar';
import 'moment/locale/pt-br';
import { Container, Div, Title, Subtitle, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { RiTeamLine } from 'react-icons/ri';

function TeamItem({ team, isCommercial }) {
  const history = useHistory();
  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica'

  const navigate = async (e) => {
    e.preventDefault();

    isCommercial ? history.push(`/comercial/equipe/${team.id}`) : history.push(`/backoffice/equipe/${team.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconProfile">
            <RiTeamLine size={20} />
          </div>

          {!isOceanica ? (
            <Div style={{ width: '15%' }}>
              <Title>Equipe</Title>
              <Subtitle>{team?.name}</Subtitle>
            </Div>
          ) :
            <Div style={{ width: '15%' }}>
              <Title>Corretor</Title>
              <Subtitle>{team?.name}</Subtitle>
            </Div>
          }

          <Div style={{ width: '25%' }}>
            <Title>Gestor</Title>
            <Subtitle>{team?.manager?.name}</Subtitle>
          </Div>

          <Div style={{ width: '25%' }}>
            <Title>Membros</Title>
            <Subtitle>{team?.members}</Subtitle>
          </Div>

          <IconMobile>
            <Avatar sx={{ width: 50, height: 50 }} style={{ marginRight: 9, cursor: 'pointer' }} />
          </IconMobile>
        </div>
      </Container>
    </>
  );
}

export default TeamItem;
