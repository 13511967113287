/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import api from 'api';
import { Input, Search, Button } from 'components';
import { FormBody } from './styles';
import { toast } from 'react-toastify';
import { loadingColor } from 'styles/colorProvider';
import { formatCPF } from 'react-data-formatter';

function AddTeamForm({ onFinish }) {
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [clientsList, setClientsList] = useState([]);
  const [clientSearch, setClientSearch] = useState();
  const [loadingClients, setLoadingClients] = useState(false);

  const [name, setName] = useState();
  const [idManager, setIdManager] = useState();

  const loadClients = async () => {
    setLoadingClients(true);
    const resp = await api({
      method: 'GET',
      url: '/users',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        search: clientSearch?.replaceAll('.', '').replaceAll('-', ''),
      },
    });
    setClientsList(resp?.data?.response);
    setLoadingClients(false);
  };

  const handleCreateUser = async () => {
    setLoadingCreate(true);
    try {
      await api({
        method: 'POST',
        url: `/team`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          name,
          id_manager: idManager,
        },
      });
      onFinish();
      toast.success('Equipe cadastrada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      setLoadingCreate(false);
    } catch (e) {
      setLoadingCreate(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  return (
    <FormBody>
      <Input height="35px" label="Nome da equipe" value={name} onChange={(e) => setName(e.target.value?.toUpperCase())} />

      <div style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <div className="search-area">
          <p>Selecione um gestor:</p>
          <p style={{ fontSize: 14 }}>Pesquise o gestor pelo nome ou CPF:</p>
          <Search
            style={{ margin: 0 }}
            help="Buscar pelo nome ou cpf do gestor."
            value={clientSearch}
            onChange={(e) => setClientSearch(e.target.value)}
            onClear={() => {
              setClientSearch('');
              setClientsList([]);
            }}
            onConfirm={() => loadClients()}
          />
        </div>
        <div className="result-area">
          {loadingClients && <ReactLoading color={loadingColor} height={24} width={24} type="spin" />}
          {!loadingClients && clientsList?.length > 0 && (
            <div>
              {clientsList?.length < 1 && <small>Nenhum usuário encontrado</small>}
              {clientsList?.map((client) => (
                <div
                  key={client.id}
                  className={idManager === client?.id ? 'result-item result-item-selected' : 'result-item'}
                  onClick={() => setIdManager(client.id)}
                >
                  <p>{client?.name}</p>
                  <small>{formatCPF(client?.cpf)}</small>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <Button
          loading={loadingCreate}
          disabled={loadingCreate || !name || !idManager}
          height="35px"
          style={{ marginLeft: 0 }}
          onClick={handleCreateUser}
        >
          Cadastrar equipe
        </Button>
      </div>
    </FormBody>
  );
}

export default AddTeamForm;
