/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import ReactLoading from 'react-loading';
import Divider from '@mui/material/Divider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import 'moment/locale/pt-br';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Header, BackofficeMenu, HistoryItem, Button, Dialog } from 'components';
import { Container, LoadingArea, ContentArea } from './styles';
import { loadingColor, white } from 'styles/colorProvider';

function PolicyHistory({ location }) {
  const policyId = parseInt(location.pathname.split('/backoffice/historico-da-apolice/')[1]);

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [historyData, setHistoryData] = useState();
  const [attendanceAction, setAttendanceAction] = useState();
  const [attendanceData, setAttendanceData] = useState();

  const loadData = async () => {
    const policyNumber = {
      method: 'GET',
      url: `/policy-history/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyNumber);

    setHistoryData(response.data);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    setLoadingCreate(true);
    try {
      await api({
        method: 'POST',
        url: `/policy-history/${policyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          description: attendanceData,
        },
        json: true,
      });
      setLoadingCreate(false);
      toast.success('Atendimento registrado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
      handleClose();
    } catch (error) {
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleClose = () => {
    setAttendanceData();
    setAttendanceAction();
  };

  const renderDialog = () => (
    <Dialog
      open={attendanceAction}
      requestCancel
      onClose={handleClose}
      title={attendanceAction === 'new' ? 'Novo Atendimento' : attendanceAction === 'view' ? 'Visualizar Atendimento' : ''}
      loading={loading}
      invertButtons
    >
      <div style={{ width: '80%' }}>
        {attendanceAction === 'view' && <p style={{ fontSize: 14 }}>Atendimento realizado por: {attendanceData?.author?.name}</p>}
        {attendanceAction === 'view' && (
          <p style={{ fontSize: 14 }}>
            Atendimento realizado em: {moment(attendanceData?.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </p>
        )}

        <p style={{ fontSize: 14 }}>Descrição do atendimento:</p>
        <TextareaAutosize
          minRows={1}
          onChange={(e) => setAttendanceData(e.target.value)}
          value={attendanceAction === 'new' ? attendanceData : attendanceData?.description}
          placeholder="Insira a descrição do atendimento..."
          disabled={attendanceAction === 'view'}
          style={{
            padding: 10,
            fontFamily: 'Nunito',
            fontSize: 14,
            width: '100%',
            height: '100px',
            borderRadius: 5,
            borderColor: '#d1d2d4',
          }}
        />
        {attendanceAction === 'new' && (
          <Button height="30px" style={{ margin: 0, marginTop: 20 }} disabled={loadingCreate} onClick={handleSubmit}>
            {loadingCreate ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Registrar atendimento'}
          </Button>
        )}
        {attendanceAction === 'view' && (
          <Button outlined height="30px" style={{ margin: 0, marginTop: 20 }} onClick={handleClose}>
            Concluir
          </Button>
        )}
      </div>
    </Dialog>
  );

  return (
    <>
      {renderDialog()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <BackofficeMenu policyId={policyId} />
            <div className="title-area">
              <h2>Histórico da apólice</h2>
              <Button height="30px" onClick={() => setAttendanceAction('new')}>
                Registrar atendimento
              </Button>
            </div>
            <Divider />
            <HistoryItem data={historyData} setAttendanceData={setAttendanceData} setAttendanceAction={setAttendanceAction} />
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default PolicyHistory;
