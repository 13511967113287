import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards-br';
import api from 'api';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { loadingColor } from 'styles/colorProvider';
import { Header, ExtractItem, Button, ConfirmDialog, Input, Card, Dialog } from 'components';
import { Container, ContentArea, MenuArea, ItemMenu, TextItem, CardsArea, MobileMenu, CardClient, Title } from './style';
import { MdChevronLeft } from 'react-icons/md';
import { FiDollarSign } from 'react-icons/fi';
import { BsCreditCard } from 'react-icons/bs';
import { BiMenuAltRight } from 'react-icons/bi';
import emptyAnimation from 'assets/empty.json';
import { AiOutlinePlus } from 'react-icons/ai';
import './cardStyle.css';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';

function BackofficeWallet({ history, location }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedSection, setSelectedSection] = useState('cards');
    const [card, setCard] = useState([]);
    const [cardData, setCardData] = useState();
    const [loading, setLoading] = useState();
    const [loadingRemove, setLoadingRemove] = useState();
    const [loadingSelectCard, setLoadingSelectCard] = useState();
    const [extractData, setExtractData] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showAddCard, setShowAddCard] = useState(false);
    const [showSelectCard, setShowSelectCard] = useState(false);
    const [addCardData, setAddCardData] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: '',
    });
    const [listClients, setlistClients] = useState();
    const clientId = parseInt(location.pathname.split('/backoffice/carteira-do-usuario/')[1]);

    const listCardsClient = async () => {
        setLoading(true);
        const resp = await api({
            method: 'GET',
            url: `/backoffice-list-cards/${clientId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: Cookies.get('splitriskwl-authtoken'),
            },
            json: true,
        });
        setCard(resp.data.cards);
        setCardData(resp.data.cards);
        setlistClients(resp.data);
        setLoading(false);
    };
    useEffect(() => {
        listCardsClient();
    }, [])

    useEffect(() => {
        listCardsClient();
    }, []);

    const handleRemoveCard = async (e) => {
        setLoadingRemove(true);
        try {
            await api({
                method: 'POST',
                url: `/backoffice-remove-card-client/${clientId}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Cookies.get('splitriskwl-authtoken'),
                },
                data: { id_payment_profile: showConfirm?.id },
                json: true,
            });
            setShowConfirm();
            setLoadingRemove(false);
            listCardsClient();
            toast.success('Cartão removido com sucesso!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
            });
            window.location.reload();
        } catch (e) {
            setLoadingRemove(false);
            toast.error(e?.response?.data?.error, {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
            });
            window.location.reload();
        }
    };
    // const handleSelectCard = async (e) => {
    //   setLoadingSelectCard(true);
    //   try {
    //     await api({
    //       method: 'POST',
    //       url: `/me/main-card`,
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: Cookies.get('splitriskwl-authtoken'),
    //       },
    //       data: { id_payment_profile: showSelectCard?.id },
    //       json: true,
    //     });
    //     setShowSelectCard();
    //     setLoadingSelectCard(false);
    //     listCardsClient();
    //     toast.success('Cartão alterado com sucesso!', {
    //       position: toast.POSITION.TOP_RIGHT,
    //       theme: 'colored',
    //     });
    //   } catch (e) {
    //     setLoadingSelectCard(false);
    //     toast.error(e?.response?.data?.error, {
    //       position: toast.POSITION.TOP_RIGHT,
    //       theme: 'colored',
    //     });
    //   }
    // };

    const handleAddCard = async (e) => {
        setLoading(true);
        try {
            await api({
                method: 'POST',
                url: `/backoffice-add-card-client/${clientId}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Cookies.get('splitriskwl-authtoken'),
                },
                data: {
                    holder_name: addCardData?.name,
                    card_expiration: addCardData?.expiry,
                    card_number: addCardData?.number,
                    card_cvv: addCardData?.cvc,
                },
                json: true,
            });
            setShowAddCard();
            setAddCardData({
                number: '',
                name: '',
                expiry: '',
                cvc: '',
                focused: '',
            });
            window.location.reload();
        } catch (e) {
            toast.error(e?.response?.data?.error, {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
            });
            setLoading(false);
        }
    };

    return (
        <>
            <ConfirmDialog
                open={showConfirm}
                onCancel={() => setShowConfirm(false)}
                content="Tem certeza de que deseja excluir este cartão?"
                title="Excluir cartão?"
                onConfirm={handleRemoveCard}
                loading={loadingRemove}
            />
            {/* <ConfirmDialog
        open={showSelectCard}
        onCancel={() => setShowSelectCard(false)}
        content="Tem certeza de que deseja tornar este cartão o principal para cobranças?"
        title="Selecionar cartão"
        onConfirm={handleSelectCard}
        loading={loadingSelectCard}
      /> */}
            <Header />
            <Container>
                <ContentArea>
                    <MobileMenu>
                        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                            <BiMenuAltRight size={26} />
                        </IconButton>

                        <Menu
                            style={{ marginTop: '50px', marginLeft: '-40px' }}
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem
                                onClick={() => {
                                    history.push('/backoffice/propostas');
                                }}
                            >
                                <MdChevronLeft size="18" />
                                <TextItem>Voltar</TextItem>
                            </MenuItem>

                            <MenuItem selected={selectedSection === 'cards'} onClick={() => setSelectedSection('cards')}>
                                <BsCreditCard size="18" style={{ marginRight: 5 }} />
                                Meu cartão
                            </MenuItem>
                        </Menu>
                    </MobileMenu>

                    <MenuArea>
                        <ItemMenu
                            onClick={() => {
                                history.push('/backoffice/propostas');
                            }}
                        >
                            <MdChevronLeft size="18" />
                            <TextItem>Voltar</TextItem>
                        </ItemMenu>

                        <ItemMenu className={selectedSection === 'cards' && 'select'} onClick={() => setSelectedSection('cards')}>
                            <BsCreditCard size="18" style={{ marginRight: 5 }} />
                            Cartão do cliente
                        </ItemMenu>

                    </MenuArea>

                    {loading && (
                        <CardsArea style={{ paddingTop: 30 }}>
                            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                        </CardsArea>
                    )}

                    {!loading && selectedSection === 'cards' && !!card.length && (
                        <CardClient>
                            <Title>
                                <h3>Cartão do cliente</h3>
                                <Button style={{ width: '45px', height: '30px', borderRadius: '10px' }} onClick={setShowAddCard}>
                                    <AiOutlinePlus />
                                </Button>
                            </Title>
                            {card
                                .sort((x) => (x.default ? -1 : 1))
                                .map((cardData) => (
                                    <Card
                                        cardData={cardData}
                                        remove={() => setShowConfirm(cardData)}
                                        disableSelectCard
                                    // selectCard={() => setShowSelectCard(cardData)}
                                    />
                                ))}
                        </CardClient>
                    )}
                    {!loading && selectedSection === 'cards' && !card.length && (
                        <CardsArea>
                            <Lottie
                                autoplay
                                options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: emptyAnimation,
                                }}
                                height={180}
                                width={280}
                            />
                            <h3>Cliente não tem nenhum cartão cadastrado</h3>
                            <Button style={{ marginTop: 20 }} onClick={setShowAddCard}>
                                Adicionar cartão
                            </Button>
                        </CardsArea>
                    )}

                    {!loading && selectedSection === 'cards' && showAddCard && (
                        <>
                            <Dialog open={setShowAddCard} onClose={() => setShowAddCard(false)}>
                                <CardsArea>
                                    <h3>Cadastrar novo cartão</h3>
                                    <div className="cardLine">
                                        <div className="cardForm">
                                            <Input
                                                label="Número do cartão"
                                                disableLabel
                                                number
                                                mask="9999 9999 9999 9999"
                                                placeholder="Insira o número do cartão"
                                                value={addCardData?.number}
                                                onChange={(e) => setAddCardData({ ...addCardData, number: e.target.value })}
                                                onFocus={() => setAddCardData({ ...addCardData, focused: 'number' })}
                                            />
                                            <Input
                                                label="Nome do titular"
                                                disableLabel
                                                placeholder="Insira o nome do titular"
                                                value={addCardData?.name}
                                                onChange={(e) => setAddCardData({ ...addCardData, name: e.target.value.toUpperCase() })}
                                                onFocus={() => setAddCardData({ ...addCardData, focused: 'name' })}
                                            />
                                            <Input
                                                number
                                                disableLabel
                                                label="Validade do cartão"
                                                mask="99/99"
                                                placeholder="MM/AA"
                                                value={addCardData?.expiry}
                                                onChange={(e) => setAddCardData({ ...addCardData, expiry: e.target.value })}
                                                onFocus={() => setAddCardData({ ...addCardData, focused: 'expiry' })}
                                            />
                                            <Input
                                                number
                                                mask="999"
                                                disableLabel
                                                placeholder="CVC/CVV"
                                                label="CVC/CVV"
                                                value={addCardData?.cvc}
                                                onChange={(e) => setAddCardData({ ...addCardData, cvc: e.target.value })}
                                                onFocus={() => setAddCardData({ ...addCardData, focused: 'cvc' })}
                                            />
                                        </div>
                                        <div>
                                            <div className="cardBox">
                                                <Cards
                                                    locale={{ valid: 'Validade' }}
                                                    placeholders={{ name: 'NOME DO TITULAR' }}
                                                    number={addCardData?.number}
                                                    name={addCardData?.name}
                                                    expiry={addCardData?.expiry}
                                                    cvc={addCardData?.cvc}
                                                    focused={addCardData?.focused}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Alert style={{ marginTop: 20 }} severity="warning">
                                        Ao adicionar o seu cartão, o modo <b>débito automático</b> da sua apólice também será ativado!
                                    </Alert>
                                    <Button style={{ marginTop: 20 }} onClick={handleAddCard}>
                                        Adicionar cartão
                                    </Button>
                                </CardsArea>
                            </Dialog>
                        </>
                    )}
                </ContentArea>
            </Container>
        </>
    );
}

export default BackofficeWallet;