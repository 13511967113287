import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import { SliderContent } from '../Partners/styles';
import { Switch, FormControlLabel, withStyles, Checkbox } from '@material-ui/core';
import { loadingColor, primary } from 'styles/colorProvider';
import { DialogBody, DivCenter, ModalButton, ModalError, RemoveButton } from './styles';
import ReactLoading from 'react-loading';
import { createUserCommission, handleRemoveUserCommission, updateUserCommission } from './requests';
import { ConfirmDialog } from 'components/index';

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '12px', // Defina o tamanho da fonte desejado aqui
  },
})(FormControlLabel);

const renderEditUserCommission = ({
  openModalEdit,
  setOpenModalEdit,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  userPartnerInfo,
  loadData,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  loadingDataTable,
  setLoadingDataTable,
}) => (
  <Dialog
    open={openModalEdit}
    onClose={() => {
      setOpenModalEdit(false);
      setUserPartnerCommissionInfo({
        id: undefined,
        client_id: undefined,
        enabled: false,
        percent: '',
      });
    }}
    width="100%"
    title="Editar trava"
  >
    <p
      style={{
        fontSize: '12px',
      }}
    >
      Comissão
    </p>
    <div
      style={{
        display: 'flex',
        margin: '0 auto',
        width: '95%',
      }}
    >
      <SliderContent
        track={false}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(e) => {
          return `${Math.round(e)}%`;
        }}
        getAriaValueText={(value) => `${value}`}
        // defaultValue={grievanceValue || 0}
        defaultValue={userPartnerCommissionInfo.percent || 0}
        value={userPartnerCommissionInfo.percent}
        onChange={(event) => {
          setUserPartnerCommissionInfo({
            ...userPartnerCommissionInfo,
            percent: event.target.value,
          });
        }}
        onChangeCommitted={() => {
          // setDiscountValue(0);
          // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
        }}
        min={0}
        max={userPartnerInfo.maxLoading - userPartnerInfo.loading}
        step={1}
        marks={[
          {
            value: 0,
            label: `0%`,
          },
          {
            value: userPartnerInfo.maxLoading - userPartnerInfo.loading,
            label: `${userPartnerInfo.maxLoading - userPartnerInfo.loading}%`,
          },
        ]}
      />
    </div>

    <CustomFormControlLabel
      control={
        <Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
          style={{ color: primary }}
          checked={userPartnerCommissionInfo.enabled}
          onChange={(e) => {
            setUserPartnerCommissionInfo({
              ...userPartnerCommissionInfo,
              enabled: e.target.checked,
            });
          }}
        />
      }
      label="Regra ativa?"
    />

    <ModalError showError={userPartnerCommissionInfo.percent > userPartnerInfo.maxLoading - userPartnerInfo.loading}>
      A comissão não pode ser maior que o carregamento
    </ModalError>

    <ModalButton>
      {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

      <Button
        height="35px"
        onClick={() => {
          updateUserCommission({
            setLoading,
            setUserPartnerCommissionInfo,
            userPartnerCommissionInfo,
            setOpenModalEdit,
            loadData,
            setCurrentPage,
            setUsercommissionsList,
            setHasNextPage,
            setTotalCount,
            setTouchFilter,
            loadingDataTable,
            setLoadingDataTable,
          });
        }}
        disabled={loading}
      >
        Editar
      </Button>
    </ModalButton>
  </Dialog>
);

const renderAddUserCommission = ({
  openModalAdd,
  setOpenModalAdd,
  userPartners,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  userPartnerInfo,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
  getUserPartners,
  searchUser,
  setSearchUser,
}) => (
  <Dialog
    open={openModalAdd}
    onClose={() => {
      setOpenModalAdd(false);
      setUserPartnerCommissionInfo({
        id: undefined,
        client_id: undefined,
        enabled: false,
        percent: '',
      });
    }}
    width="100%"
    title="Adicionar trava"
  >
    <DialogBody>
      <div class="input-group">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <div className="search-area">
            <p style={{ fontSize: 12 }}>Usuário:</p>
            <Search
              style={{ margin: 0 }}
              help="Buscar pelo nome ou cpf do corretor"
              value={searchUser}
              onChange={(e) => setSearchUser(e.target.value)}
              onClear={() => {
                setSearchUser('');
                setUserPartners([]);
              }}
              onConfirm={() => {
                getUserPartners({
                  setLoading,
                  setUserPartners,
                  setUserPartnerInfo,
                  search: searchUser,
                });
              }}
            />
          </div>
          <div className="result-area">
            <div>
              {userPartners?.map((partner) => (
                <div
                  key={partner.id}
                  className={
                    userPartnerCommissionInfo?.client_id === partner?.id ? 'result-item result-item-selected' : 'result-item'
                  }
                  onClick={() =>
                    setUserPartnerCommissionInfo({
                      ...userPartnerCommissionInfo,
                      client_id: partner?.id,
                    })
                  }
                >
                  <p>{partner?.name}</p>
                  <small>{partner?.cpf}</small>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <p
        style={{
          fontSize: '12px',
        }}
      >
        Comissão
      </p>
      <div
        style={{
          display: 'flex',
          margin: '0 auto',
          width: '95%',
        }}
      >
        <SliderContent
          track={false}
          aria-labelledby="track-false-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={(e) => {
            return `${Math.round(e)}%`;
          }}
          getAriaValueText={(value) => `${value}`}
          // defaultValue={grievanceValue || 0}
          defaultValue={userPartnerCommissionInfo.percent || 0}
          value={userPartnerCommissionInfo.percent}
          onChange={(event) => {
            setUserPartnerCommissionInfo({
              ...userPartnerCommissionInfo,
              percent: event.target.value,
            });
          }}
          onChangeCommitted={() => {
            // setDiscountValue(0);
            // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
          }}
          min={0}
          max={userPartnerInfo.maxLoading - userPartnerInfo.loading}
          step={1}
          marks={[
            {
              value: 0,
              label: `0%`,
            },
            {
              value: userPartnerInfo.maxLoading - userPartnerInfo.loading,
              label: `${userPartnerInfo.maxLoading - userPartnerInfo.loading}%`,
            },
          ]}
        />
      </div>

      <CustomFormControlLabel
        control={
          <Checkbox
            inputProps={{ 'aria-label': 'controlled' }}
            size="small"
            style={{ color: primary }}
            checked={userPartnerCommissionInfo.enabled}
            onChange={(e) => {
              setUserPartnerCommissionInfo({
                ...userPartnerCommissionInfo,
                enabled: e.target.checked,
              });
            }}
          />
        }
        label="Regra ativa?"
      />

      <ModalError showError={userPartnerCommissionInfo.percent > userPartnerInfo.maxLoading - userPartnerInfo.loading}>
        A comissão não pode ser maior que o carregamento + comercialização
      </ModalError>

      <ModalButton>
        {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

        <Button
          height="35px"
          onClick={() => {
            createUserCommission({
              setLoading,
              setUserPartnerCommissionInfo,
              userPartnerCommissionInfo,
              setOpenModalAdd,
              setCurrentPage,
              setUsercommissionsList,
              setHasNextPage,
              setTotalCount,
              setTouchFilter,
              setUserPartners,
              setUserPartnerInfo,
              loadingDataTable,
              setLoadingDataTable,
            });
          }}
          disabled={loading || !userPartnerCommissionInfo.percent || !userPartnerCommissionInfo.client_id}
        >
          Adicionar
        </Button>
      </ModalButton>
    </DialogBody>
  </Dialog>
);

const renderRemoveUserCommission = ({
  openModalRemove,
  setOpenModalRemove,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
}) => (
  <ConfirmDialog
    open={openModalRemove}
    onCancel={() => {
      setOpenModalRemove(false);
      setUserPartnerCommissionInfo({
        ...userPartnerCommissionInfo,
        id: undefined,
      });
    }}
    content="Tem certeza de que deseja excluir essa trava?"
    title="Excluir trava"
    onConfirm={() => {
      handleRemoveUserCommission({
        setLoading,
        setUserPartnerCommissionInfo,
        userPartnerCommissionInfo,
        setOpenModalRemove,
        setCurrentPage,
        setUsercommissionsList,
        setHasNextPage,
        setTotalCount,
        setTouchFilter,
        setUserPartners,
        setUserPartnerInfo,
        loadingDataTable,
        setLoadingDataTable,
      });
    }}
    loading={loading}
  />
);

export { renderEditUserCommission, renderAddUserCommission, renderRemoveUserCommission };
