import React from 'react';
import 'moment/locale/pt-br';
import * as XLSX from 'xlsx';
import { secondary } from 'styles/colorProvider';

const ExportToExcel = ({ exportData, fileName }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToXLSX = (exportData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    XLSX.writeFile(wb, fileName + fileExtension);
  };

  return (
    <button
      style={{
        height: 30,
        width: '100%',
        margin: 0,
        cursor: 'pointer',
        borderRadius: 20,
        border: 'none',
        backgroundColor: secondary,
      }}
      onClick={(e) => exportToXLSX(exportData, fileName)}
    >
      Baixar
    </button>
  );
};

export default ExportToExcel;
