/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import CsvDownload from 'react-json-to-csv';
import Tooltip from '@mui/material/Tooltip';
import api from 'api';
import { Header, SideMenu, Button, Input, Dialog, Select } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton } from './styles';
import { loadingColor, primary, secondary } from 'styles/colorProvider';
import { TiFilter } from 'react-icons/ti';
import { toast } from 'react-toastify';
import { dataColumns } from './helper';

function ListDocumentsPolicy({ history, location }) {
  const policyId = parseInt(location.pathname.split('/backoffice/listar-documentos-apolice/')[1]);

  const scrollParent = useRef();
  const [loading, setLoading] = useState(false);
  const [policiesList, setPoliciesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [touchFilter, setTouchFilter] = useState();

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'POST',
      url: `/list-issues-documents`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        page: currentPage,
        take: 20,
      },
      data: {
        id_policy: parseInt(policyId),
      },
      json: true,
    });

    setPoliciesList(resp?.data?.files);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
    setTouchFilter(false);
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);

        const resp = await api({
          method: 'POST',
          url: `/list-issues-documents`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          params: {
            page,
            take: 10,
          },
          data: {
            id_policy: parseInt(policyId),
          },
          json: true,
        });

        const data = policiesList;
        const ndata = data.concat(resp?.data);

        setCurrentPage(page);
        setPoliciesList(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <SideMenu />

        <ContentArea>
          <Div>
            <Title>LISTA DE ARQUIVOS DE APÓLICES</Title>
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <DataTable
                onRowClicked={(row) => window.open(`/backoffice/detalhes-da-apolice/${row?.id}/imprimir`, '_blank')}
                persistTableHead
                responsive
                columns={dataColumns}
                data={policiesList}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhum arquivo encontrado</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {policiesList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default ListDocumentsPolicy;
