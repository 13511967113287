import corporateSettings from '../corporateSettings.json';
const host = window.location.hostname;
const subdomain = host.split('.')[0];

let currentCorporate;

for (const partner of corporateSettings) {
  if (subdomain === partner?.subdomain) currentCorporate = partner?.id;
}

const currentCorporateObj = corporateSettings[corporateSettings.findIndex((i) => i?.id === currentCorporate)];

export const primary = currentCorporateObj?.theme?.secondary || '#421b67';
export const secondary = currentCorporateObj?.theme?.primary || '#62ec83';
export const loadingColor = currentCorporateObj?.theme?.primary || '#62ec83';

// Favor não inserir || nessa variável, ela é opcional e depende da lógica atual nos estilos.
export const buttonTextColor = currentCorporateObj?.theme?.buttonTextColor;

export const success = '#2ecc71';
export const error = '#e74c3c';
export const warn = '#F1C40F';
export const white = '#FFFFFF';
export const black = '#000000';
export const blue = '#0000d4';
export const darkGray = '#121212';
export const middleGray = '#868686';
export const middleGraySecondary = '#5c5a5a';
export const lightGray = '#d1d2d4';
export const lighterGray = '#fafafa';
export const lighterPrimary = '#fafcff';
export const backgroundGray = '#e3eef6';
export const primaryGradient = `linear-gradient(90deg, ${currentCorporateObj?.theme?.primary || '#62ec83'} 0%, ${
  currentCorporateObj?.theme?.primary || '#62ec83'
} 100%)`;
export const lineGradient = `linear-gradient(73deg, ${currentCorporateObj?.theme?.primary || '#62ec83'} 0%, ${
  currentCorporateObj?.theme?.primary || '#62ec83'
} 50%, ${currentCorporateObj?.theme?.primary || '#62ec83'} 100%)`;

const logoSplitRisk = 'https://splitrisk-corporate.s3.amazonaws.com/43505273000109_Split_Risk/logo-full-clear.png';
const logoSplitRiskDefault = 'https://splitrisk-corporate.s3.amazonaws.com/43505273000109_Split_Risk/logo-full.png';
const logoSplitRiskSmall = 'https://splitrisk-corporate.s3.amazonaws.com/43505273000109_Split_Risk/logo-small.png';

export const logo = currentCorporateObj?.logos?.fullClear || logoSplitRisk;
export const logoDefault = currentCorporateObj?.logos?.full || logoSplitRiskDefault;
export const logoSmall = currentCorporateObj?.logos?.small || logoSplitRiskSmall;

export const companyName = currentCorporateObj?.name || 'Split Risk';
document.title = `Portal - ${companyName}`;
