/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import { Container, LoadingArea, ButtonArea, Printable } from './styles';
import { formatCPF, formatCNPJ, formatPlate, formatPhone, formatZipCode, formatCurrency } from 'react-data-formatter';
import { formatPolicyStatus } from 'utils/policyStatus';
import { loadingColor, white, logoDefault as myPassLogo } from 'styles/colorProvider';
import { Button } from 'components';
import { MdChevronLeft } from 'react-icons/md';
import { BsPrinter } from 'react-icons/bs';
import { cpf } from 'cpf-cnpj-validator';

function PrintPolicy({ location, history }) {
  const iof = 0.0738;

  const policyId = parseInt(location.pathname.split('/detalhes-da-apolice/')[1]);

  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    const policyNumber = {
      method: 'GET',
      url: `/policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyNumber);

    setPolicyData(response.data);
    setLoading(false);
  };

  useEffect(() => loadData(), []);

  const isCpf = cpf.isValid(policyData?.user?.cpf);

  return (
    <>
      {!loading && (
        <ButtonArea>
          <Button
            style={{ color: white }}
            height="30px"
            width="110px"
            onClick={() => history.push(`/detalhes-da-apolice/${policyId}`)}
          >
            <MdChevronLeft size="15" /> Voltar
          </Button>
          <Button style={{ color: white }} height="30px" width="130px" onClick={() => window.print()}>
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <Container>
          <Printable>
            <div className="header">
              <div className="logoArea">
                <img src={myPassLogo} />
              </div>
              <div className="titleArea">
                <h1 className="name">Olá, {policyData?.user?.name?.split(' ')[0]}!</h1>
                <h2>
                  Aqui estão todos os detalhes do seu Seguro Auto,
                  <br /> para você que está sempre em movimento.
                </h2>
              </div>
            </div>

            <section>
              <div className="columns">
                <div className="leftArea">
                  <h4>Nº da proposta</h4>
                  <p className="highlight">{policyData?.proposal?.protocol}</p>
                  <h4>Nº da apólice</h4>
                  <p className="highlight">{policyData?.code_ebao}</p>
                  <h4>Nº do bilhete</h4>
                  <p className="highlight">{policyData?.policy_number}</p>
                  <h4>Status da apólice: {policyData?.status && formatPolicyStatus(policyData?.status).label}</h4>
                </div>
                <div className="rightArea">
                  <h4>Data da emissão</h4>
                  <p className="dateInfo">{moment(policyData?.created_at).format('DD/MM/YYYY [às]  HH:mm')}</p>
                  <h4>Vigência do bilhete</h4>
                  <p className="dateInfo">{moment(policyData?.expiration_date).format('DD/MM/YYYY')}</p>
                  <p className="smallInfo">
                    Proposta enviada por: {policyData?.company?.social_reason} - CNPJ {formatCNPJ(policyData?.company?.cnpj)}
                  </p>
                  <p className="smallInfo">Apólice emitida por: Split Risk Seguradora S.A. - CNPJ 43.505.273/0001-09</p>
                </div>
              </div>
            </section>

            <section>
              <h1>Dados do segurado</h1>
              <h4>Nome do segurado: {policyData?.user?.name?.toUpperCase()}</h4>
              <h4>CPF: {formatCPF(policyData?.user?.cpf)}</h4>
              {isCpf && (
                <>
                  <h4>Data de nascimento: {moment(policyData?.user?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</h4>
                  <h4>Sexo: {policyData?.user?.gender === 1 ? 'Masculino' : 'Feminino'}</h4>
                </>
              )}
              <h4>E-mail: {policyData?.user?.email}</h4>
              <h4>Telefone: {formatPhone(policyData?.user?.phone)}</h4>
            </section>

            <section>
              <h1>Endereço do segurado</h1>
              <h4>Logradouro: {policyData?.user?.address?.street}</h4>
              <h4>Número: {policyData?.user?.address?.number}</h4>
              <h4>Bairro: {policyData?.user?.address?.district}</h4>
              <h4>
                Cidade/UF: {policyData?.user?.address?.city}/{policyData?.user?.address?.state}
              </h4>
              {policyData?.user?.address?.complement && <h4>Complemento: {policyData?.user?.address?.complement}</h4>}
              <h4>CEP {formatZipCode(policyData?.user?.address?.zip_code)}</h4>
            </section>
            <section>
              <h1>Dados do condutor principal</h1>
              <h4>Nome: {policyData?.TB_PROPOSAL?.maindriver_name || 'Não possuí'}</h4>
              <h4>CPF: {policyData?.TB_PROPOSAL?.maindriver_document || 'Não possuí'}</h4>
              {isCpf && (
                <h4>
                  Data de nascimento:{' '}
                  {moment(policyData?.TB_PROPOSAL?.maindriver_birthdate || policyData?.TB_PROPOSAL?.user?.birthdate)
                    .utcOffset(0)
                    .format('DD/MM/YYYY')}
                </h4>
              )}
            </section>

            <section>
              <h1>Dados do veículo</h1>
              <h4>Placa: {policyData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(policyData?.vehicle?.plate)}</h4>
              <h4>
                Marca e modelo: {policyData?.vehicle?.brand} {policyData?.vehicle?.model?.split(' ')[0]}
              </h4>
              <h4>
                Ano fabricação/modelo: {policyData?.vehicle?.manufacture_year}/{policyData?.vehicle?.year_model}
              </h4>
              <h4>Codigo Fipe: {policyData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.fipe_code}</h4>
              <h4>Chassi: {policyData?.vehicle?.chassi}</h4>
              <h4>Veículo de Leilão: {policyData?.vehicle?.auction || policyData?.vehicle?.auction === 1 ? 'Sim' : 'Não'}</h4>
              <h4>Valor*: {policyData?.vehicle && formatCurrency(policyData?.vehicle?.value)}</h4>
              <small style={{ fontSize: 10, marginTop: 20 }}>*Valor da Tabela Fipe do veículo na data da contratação.</small>
            </section>

            <section>
              <h1>Dados da apólice</h1>
              <p>Modalidade de Contratação: VMR - Valor de Mercado Referenciado</p>
              <p style={{ margin: 0, marginTop: 10, fontWeight: 'bold' }}>LIMITE MÁXIMO DE INDENIZAÇÃO (LMI): </p>
              <p style={{ fontSize: 14 }}>
                Valor máximo a ser pago pela SEGURADORA, contratada para cada cobertura, escolhida pelo SEGURADO(A) e expresso na
                apólice.
              </p>
              <p style={{ margin: 0, marginTop: 10, fontWeight: 'bold' }}>PEÇAS E REPAROS:</p>
              <p style={{ fontSize: 10 }}>
                UTILIZAÇÃO DE PEÇAS (NOVAS, USADAS, ORIGINAIS OU NÃO), desde que mantenham a especificação técnica do fabricante.
              </p>
              <p style={{ fontSize: 10 }}>
                *Esta definição não se aplica, caso tenha sido contratada Cobertura adicional de Utilização Exclusiva de Peças
                Genuínas e/ou Originais.
              </p>

              {policyData?.vehicle?.auction || policyData?.vehicle?.auction === 1 ? (
                <p>
                  Fator de Ajuste LMI: 70% <sup>*</sup>
                </p>
              ) : (
                <p>
                  Fator de Ajuste LMI: 100% <sup>*</sup>
                </p>
              )}
              <table>
                <thead>
                  <td width={200}>Cobertura</td>
                  <td>
                    LMI<sup>**</sup>
                  </td>
                </thead>

                <tbody>
                  {policyData?.coverages
                    ? policyData?.coverages.map((product) => (
                        <tr>
                          <td>{product?.name}</td>
                          <td>
                            {!product?.lmi && ''}
                            {product?.lmi_type === 'vehicle_value'
                              ? policyData?.vehicle?.value && formatCurrency(policyData?.vehicle?.value)
                              : product?.lmi_value && formatCurrency(product?.lmi_value)}
                          </td>
                        </tr>
                      ))
                    : policyData?.products?.map((product) => (
                        <tr>
                          <td>{product?.name}</td>
                          <td>
                            {!product?.lmi && '-'}
                            {product?.lmi === -1
                              ? policyData?.vehicle?.value && formatCurrency(policyData?.vehicle?.value)
                              : product?.lmi && formatCurrency(product?.lmi)}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <small style={{ fontSize: 10 }}>* Se selecionada a opção de leilão, o fator de ajuste deverá ser de 70%</small>
              <small style={{ fontSize: 10 }}>** Limite máximo de indenização</small>
            </section>

            <section>
              <h1>Demonstrativo do prêmio </h1>
              <h4 className="valueInfo">
                Prêmio Líquido Total:{' '}
                {policyData?.price?.total_premium && formatCurrency(policyData?.price?.total_premium * 0.931272)}
              </h4>
              <h4 className="valueInfo">Juros: {policyData?.price?.fees}</h4>
              <h4 className="valueInfo">IOF: {policyData?.price?.iof && formatCurrency(policyData?.price?.iof)}</h4>
              <h4 className="valueInfo">
                Prêmio Total: {policyData?.price?.total_premium && formatCurrency(policyData?.price?.total_premium)}
              </h4>
              <h4 className="valueInfo">Forma de pagamento: {policyData?.price?.payment_method}</h4>
            </section>

            <section>
              <h1>Valores da apólice</h1>
              <h4 className="valueInfo">Prêmio total: {formatCurrency(policyData?.value || 0)}</h4>
              <h4 className="valueInfo">Taxa de juros: 0%</h4>
              <h4>Valor total:</h4>
              <p className="highlight">
                {' '}
                {policyData?.value_final
                  ? formatCurrency(policyData?.value_final)
                  : policyData?.value
                    ? formatCurrency(policyData?.value * (1 - (policyData?.proposal?.discount_new || 0)))
                    : 0}
              </p>
            </section>

            <section style={{ borderBottom: 'none' }}>
              <h1>Resumo das coberturas</h1>
              {(policyData?.products?.findIndex((i) => i.name === 'Colisão') > -1 ||
                policyData?.coverages?.findIndex((i) => i.name === 'Colisão') > -1) && (
                <>
                  <span className="generalConditionsTitle">Colisão, Fenômenos Naturais e Incêndio:</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    Contratando a cobertura de colisão o SEGURADO terá direito a uma indenização em virtude de Contratando a
                    cobertura de colisão, fenômenos naturais ou incêndio, o(a) SEGURADO(A) terá direito a uma indenização ou
                    reparo direcionado à rede referenciada, em virtude de prejuízos ocasionados ao veículo segurado provenientes
                    de: a) Colisão ou capotagem acidental; b) Queda acidental em precipícios ou de pontes; c) Queda acidental de
                    qualquer agente externo sobre o veículo segurado, desde que tal agente não faça parte integrante do veículo e
                    não esteja nele afixado; d) Queda sobre o veículo segurado da carga por ele transportada, em decorrência de
                    acidente de trânsito e não por simples freada; e) Acidente durante seu transporte por meio apropriado, como,
                    exemplificativamente, cegonha ou guincho indicado pela SEGURADORA; f) Atos danosos praticados por terceiros,
                    exceto os constantes no item “Prejuízos não indenizáveis para todas as coberturas”; g) Despesas necessárias ao
                    socorro e salvamento do veículo em consequência de um dos riscos cobertos de intervenção humana ou não (raios,
                    enchentes, dentre outros); h) Despesas referentes a danos materiais comprovadamente causados pelo(a)
                    SEGURADO(A) e/ou por terceiros na tentativa de evitar o sinistro, minorar o dano ou salvar a coisa; i)
                    Incêndio ou explosão acidental não intencionada.
                  </p>
                </>
              )}
              {(policyData?.products?.findIndex((i) => i.name === 'Roubo') > -1 ||
                policyData?.coverages?.findIndex((i) => i.name === 'Roubo') > -1) && (
                <>
                  <span className="generalConditionsTitle">Roubo ou Furto:</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    Contratando a cobertura de roubo e furto, o(a) SEGURADO(A) terá direito a uma indenização/reparação em virtude
                    de prejuízos ocasionados ao veículo segurado provenientes de: a) Roubo ou furto total do veículo segurado; b)
                    Danos sofridos pelo veículo segurado durante o tempo em que, como consequência de roubo ou furto esteve em
                    poder de terceiros, deduzida da indenização a franquia estipulada na apólice para o veículo quando for de
                    reparação parcial; c) Despesas necessárias ao socorro e salvamento do veículo em consequência de um dos riscos
                    cobertos e decorrentes de intervenções humanas; d) Despesas referentes a danos materiais comprovadamente
                    causados pelo(a) SEGURADO(A) e/ou por terceiros na tentativa de evitar o sinistro, minorar o dano ou salvar a
                    coisa; e) Roubo ou furto total exclusivo do rádio, toca-fitas, toca cd’s, tacógrafo e kit gás, desde que tais
                    itens façam parte do modelo original do veículo, descontado do valor da indenização a franquia estipulada na
                    apólice para o veículo quando for de reparação parcial.
                  </p>
                </>
              )}
              {(policyData?.products?.findIndex((i) => i.name === 'Assistência 24 horas') > -1 ||
                policyData?.coverages?.findIndex((i) => i.name === 'Assistência 24 horas') > -1) && (
                <>
                  <span className="generalConditionsTitle">Assistência 24 horas:</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    A Assistência veicular é um serviço complementar ao seguro e sua prestação não implica, para qualquer efeito,
                    no reconhecimento, pela SEGURADORA, de cobertura em relação aos itens descritos na proposta de cotação, termo
                    de aceite ou na apólice de seguro, que se rege por suas próprias condições contratuais entre SEGURADO(A) e
                    SEGURADORA. a) Prestação de serviço de assistência 24 (vinte e quatro) horas a(o) SEGURADO(A) em caráter
                    emergencial mediante a impossibilidade de locomoção do veículo em razão de acidentes (quando cobertos pelo
                    plano contratado), panes, roubos ou furtos e fenômenos naturais, devendo ser solicitado através dos meios
                    oficiais de comunicação. Não são considerados serviços emergenciais eventos que se referem à manutenção do
                    veículo, que não esteja amparado no termo de aceite, proposta de contratação do seguro, apólice de seguro ou
                    casos em que o cliente não realize o acionamento no mesmo dia/hora do fato ocorrido; b) A inadimplência do(a)
                    SEGURADO(A), de acordo com as regras destas Condições Gerais, acarretará na inativação da prestação de
                    serviços da Assistência 24 horas; c) Apenas em caso de necessidade e explícita autorização por parte da
                    SEGURADORA, poderá a SEGURADORA ou empresa terceirizada, autorizar o acionamento particular por parte do(a)
                    SEGURADO(A), e ressarci-lo(a) do desembolso para tal fim, respeitando o procedimento de reembolso; d) Não
                    serão indenizados reboques que excedam a quilometragem constante no termo de aceite, na apólice de seguro ou
                    que desrespeitem as normas das Condições Gerais; e) O(A) SEGURADO(A) deverá contatar a central de assistência
                    no momento do evento e fornecer as informações solicitadas de forma clara e completa para a devida
                    identificação do usuário e do veículo assistido, bem como para a confirmação de sua inclusão do cadastro de
                    análise das condições da Assistência contratada; f) No caso de cancelamento do serviço após a liberação do
                    prestador, seja qual for o motivo do cancelamento, o serviço será considerado como concedido, prestado e
                    concluído, não tendo o cliente o direito a uma nova solicitação para o mesmo evento dentro do período corrente
                    de cobertura da apólice. As garantias e os serviços referentes a essas cláusulas devem ser solicitados à
                    Central de Assistência 24 horas. Somente os prestadores da rede referenciada poderão executar tais serviços.
                    Em nenhuma hipótese, serão reembolsados gastos relativos a serviços executados por prestadores não
                    referenciados. Para essas cláusulas, não há limites de acionamentos.
                  </p>
                </>
              )}
              {(policyData?.products?.findIndex((i) => i.name === 'APP - Morte') > -1 ||
                policyData?.coverages?.findIndex((i) => i.name === 'APP - Morte') > -1) && (
                <>
                  <span className="generalConditionsTitle">APP (Acidente Pessoal Por Passageiro):</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    Contratando a cobertura de Acidente Pessoal por Passageiro, o(a) SEGURADO(A) terá direito a uma indenização em
                    virtude de prejuízos ocasionados ao veículo segurado provenientes de evento de sinistro comunicado e
                    autorizado pela SEGURADORA. a) Cobertura no valor de até R$ 10.000,00 (dez mil reais) por passageiro do
                    veículo integrante da relação com a SEGURADORA, em caso de morte; b) O APP é limitado ao valor de até R$
                    50.000,00 (cinquenta mil reais) por veículo; c) O ressarcimento de despesas médicas/hospitalares é de até R$
                    2.000,00 (dois mil reais) por passageiro, limitado ao valor de R$ 10.000,00 (dez mil reais) por veículo,
                    exceto passageiros de motocicleta tendo em vista que os mesmos não fazem jus a referida cobertura; d) Os
                    ressarcimentos serão avaliados mediante comprovação documentada a ser exigida. 1 Assistência ao veículo
                    (Reboque): O limite de quilometragem constará na apólice, poderá ser acionada mediante contratação e cobertura
                    para os casos de Colisão, Fenômenos Naturais, Incêndio, Roubo, Furto e pane mecânica/elétrica em que o veículo
                    não esteja em condições de rodagem.
                  </p>
                </>
              )}
              {(policyData?.products?.findIndex((i) => i.name.includes('Carro reserva')) > -1 ||
                policyData?.coverages?.findIndex((i) => i.name.includes('Carro reserva')) > -1) && (
                <>
                  <span className="generalConditionsTitle">Carro Reserva:</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    Contratando a cobertura de Carro Reserva, o(a) SEGURADO(A) terá direito a uma utilização em virtude de
                    prejuízos ocasionados ao veículo segurado provenientes de evento de sinistro comunicado e autorizado pela
                    SEGURADORA. a) Poderá ser solicitado respeitando a quantidade de dias devidamente contratados pelo(a)
                    SEGURADO(A) e expresso na apólice de seguro, em casos de roubo, furto, colisão, incêndio ou fenômenos naturais
                    indenizados pela SEGURADORA, mediante aprovação do sinistro e apresentação de boletim de ocorrência,
                    respeitando-se todas as regras determinadas pelas locadoras indicadas, em conformidade com as condições gerais
                    da SEGURADORA. b) Sendo necessário ter no mínimo: • 2 (dois) anos de habilitação na categoria “B” ou acima; •
                    21 (vinte e um) anos de idade; • Limite disponível em cartão de crédito no valor mínimo de R$ 1.000,00. Para
                    todos os casos, qualquer outro critério a ser exigido pela locadora deverá ser respeitado pelo(a) SEGURADO(A),
                    além disto, fica excluída qualquer responsabilidade por parte da SEGURADORA para itens como: motorista
                    adicional, pagamento de seguro do veículo locado, dentre outros. c) O veículo será de categoria popular
                    básico, contendo ar-condicionado e direção hidráulica para o máximo de 5 ocupantes.
                  </p>
                </>
              )}
              {(policyData?.products?.findIndex((i) => i.name.includes('APP')) > -1 ||
                policyData?.coverages?.findIndex((i) => i.name.includes('APP')) > -1) && (
                <>
                  <span className="generalConditionsTitle">Carro Reserva:</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    Contratando a cobertura de Carro Reserva, o(a) SEGURADO(A) terá direito a uma utilização em virtude de
                    prejuízos ocasionados ao veículo segurado provenientes de evento de sinistro comunicado e autorizado pela
                    SEGURADORA. a) Poderá ser solicitado respeitando a quantidade de dias devidamente contratados pelo(a)
                    SEGURADO(A) e expresso na apólice de seguro, em casos de roubo, furto, colisão, incêndio ou fenômenos naturais
                    indenizados pela SEGURADORA, mediante aprovação do sinistro e apresentação de boletim de ocorrência,
                    respeitando-se todas as regras determinadas pelas locadoras indicadas, em conformidade com as condições gerais
                    da SEGURADORA. b) Sendo necessário ter no mínimo: • 2 (dois) anos de habilitação na categoria “B” ou acima; •
                    21 (vinte e um) anos de idade; • Limite disponível em cartão de crédito no valor mínimo de R$ 1.000,00. Para
                    todos os casos, qualquer outro critério a ser exigido pela locadora deverá ser respeitado pelo(a) SEGURADO(A),
                    além disto, fica excluída qualquer responsabilidade por parte da SEGURADORA para itens como: motorista
                    adicional, pagamento de seguro do veículo locado, dentre outros. c) O veículo será de categoria popular
                    básico, contendo ar-condicionado e direção hidráulica para o máximo de 5 ocupantes.
                  </p>
                </>
              )}
              {(policyData?.products?.findIndex((i) => i.name.includes('terceiros')) > -1 ||
                policyData?.coverages?.findIndex((i) => i.name.includes('terceiros')) > -1) && (
                <>
                  <span className="generalConditionsTitle">Danos a Terceiros:</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    Serviço disponível mediante contratação para cobertura material a terceiros envolvidos em evento de sinistro,
                    que decorram de evento comunicado e autorizado pela SEGURADORA. a) É limitado ao valor máximo de indenização
                    (LMI) de acordo com o contratado pelo SEGURADO e expresso na apólice do SEGURADO; b) Esta cobertura adicional
                    apenas se aplica em caso de aprovação do sinistro que o(a) SEGURADO(A) tenha dado causa, não sendo aplicável
                    para casos em que o terceiro envolvido seja causador. c) Referido valor diz respeito apenas para danos
                    materiais, não sendo possível reparação por danos morais, estéticos, médicos, lucros cessantes ou quaisquer
                    outras finalidades, em conformidade com as condições gerais da SEGURADORA.
                  </p>
                </>
              )}
              {(policyData?.products?.findIndex((i) => i.name.includes('terceiros')) > -1 ||
                policyData?.coverages?.findIndex((i) => i.name.includes('terceiros')) > -1) && (
                <>
                  <span className="generalConditionsTitle">Cobertura para Vidros: faróis, lanternas e vidros:</span>{' '}
                  <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                    Serviço disponível mediante contratação para cobertura de vidros e espelhos retrovisores em evento de sinistro
                    que decorram de evento comunicado e autorizado pela SEGURADORA. a) Cobertura dos vidros laterais, traseiro,
                    para-brisa, faróis, lanternas e vidros (lentes) dos retrovisores, os limites máximos de indenizações constarão
                    na apólice; b) Para esta cobertura o limite de acionamentos será de um evento por mês; c) Não serão
                    autorizadas trocas por má uso/manutenção ou decurso de uso e conservação natural do veículo segurado.
                  </p>
                </>
              )}
              <>
                <span className="generalConditionsTitle">VMR - Valor de Mercado Referenciado:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  Esta modalidade de seguro garante, no caso de indenização integral, o pagamento de quantia variável, em moeda
                  corrente nacional, determinada de acordo com a tabela FIPE de referência de cotação para veículo, previamente
                  fixada na proposta do seguro e discriminada na apólice, conjugada com o fator de ajuste (percentual a ser
                  aplicado sobre a tabela no cálculo do valor da indenização), na data da ocorrência do sinistro, aplicado o fator
                  de ajuste contratado e definido na apólice.
                </p>
              </>

              <>
                <span className="generalConditionsTitle">Fator de ajuste:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É o percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contratação do seguro, que será
                  aplicado sobre o valor que constar no LMI-Limite Máximo de Indenização de cotação para o veículo, para a
                  estipulação do valor da indenização integral do veículo segurado. A aplicação do fator de ajuste pode resultar
                  em valor inferior àquele valor do LMI-Limite Máximo de Indenização estabelecido na proposta, de acordo com as
                  características do veículo e seu estado de conservação.
                </p>
              </>
            </section>

            <footer>
              <div className="logoArea">
                <img src={myPassLogo} />
              </div>
              <div className="barArea"></div>
            </footer>

            <section style={{ borderBottom: 'none' }}>
              <small style={{ fontSize: 10 }}>
                Esta apólice é emitida pela Split Risk Seguradora, regularizada na SUSEP pelo projeto Sandbox, no processo
                15414.618377/2020-87:
              </small>
              <small style={{ fontStyle: 'italic', fontSize: 10 }}>
                https://www.gov.br/susep/pt-br/assuntos/sandbox-regulatorio/sandbox-regulatorio-1a-edicao
              </small>
            </section>
          </Printable>
        </Container>
      )}
    </>
  );
}

export default PrintPolicy;
