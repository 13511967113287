import styled from 'styled-components';
import { black, middleGray, primary, lightGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${lightGray};
  border-radius: 20px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  &&:hover {
    box-shadow: ${(props) => props.clickable && 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'};
  }

  .description {
    display: flex;
    align-items: center;
    margin-left: 2%;
    width: 80%;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
      padding: 4% 6%;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .iconArrow {
    margin-right: 5%;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 10%;
    height: auto;
  }
`;

export const StatusText = styled.p`
  color: ${black};
  margin: 0px;
`;

export const MonthText = styled.p`
  color: ${black};
  margin: 0px;
  text-transform: capitalize;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const Value = styled.p`
  color: ${primary};
  font-weight: bold;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const Message = styled.p`
  color: ${middleGray};
  font-size: 12px;
  text-align: left;
  width: 50%;

  @media (max-width: 1000px) {
    margin-left: 2%;
    margin-top: 1%;
    font-size: 13px;
  }
`;

export const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: ${(props) => props.width || '100%'};
  max-width: 100% !important;

  small {
    color: ${middleGray};
  }

  @media (max-width: 1000px) {
    width: 50%;
    margin-bottom: 5px;
  }
`;
