import styled from 'styled-components';
import { primary, white, darkGray, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-top: 5%;

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const TextModal = styled.p`
  font-size: 16px;
  color: ${darkGray};
  font-weight: 500;
  line-height: 15px;
  margin: 20px 0 8px 0;


  span {
    color: ${middleGray};
  }

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1.5%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const ModalButton = styled.div`
  width: 100%;
  display: flex;
  margin-top: 3%;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  }
  `
export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const Area = styled.div`
  width: 100%;
  display: flex;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 100%;

  }
`;
