import React from 'react';
import { BsShieldCheck, BsShieldExclamation } from 'react-icons/bs';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { formatClaimStatus } from 'utils/policyStatus';
import { formatPlate } from 'react-data-formatter';

function ClaimItem({ claim, policy }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(`/sinistro/${claim?.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconCar">
            <BsShieldExclamation size={23} color={formatClaimStatus(claim?.client_status)?.color} />
          </div>

          <Div>
            <Title>Protocolo: {claim?.protocol}</Title>
            <Subtitle>Causa: {claim?.TB_CLAIM_CAUSE_LOSS?.name}</Subtitle>
          </Div>

          <Div>
            <Title>{policy?.vehicle?.model}</Title>
            <Subtitle>Placa: {policy?.vehicle?.plate === null ? 'Sem placa' : formatPlate(policy?.vehicle?.plate)}</Subtitle>
          </Div>

          <Div>
            <Title>Apólice: {policy?.policy_number}</Title>
            <Title color={formatClaimStatus(claim?.client_status)?.color}>
              Status: {formatClaimStatus(claim?.client_status)?.label}
            </Title>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>

          <IconMobile>
            <BsShieldCheck size={40} color={formatClaimStatus(claim?.client_status)?.color} />
          </IconMobile>
        </div>
      </Container>
    </>
  );
}

export default ClaimItem;
