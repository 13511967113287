import styled from 'styled-components';
import { lightGray, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 41%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 2%;
  }
`;

export const Select = styled.select`
  display: flex;
  border: 1px solid ${lightGray};
  border-radius: 5px;
  align-items: center;
  margin-top: 15px;
  width: 422px;
  height: 35px;
  padding: 5px;
  font-family: 'Nunito';
  font-size: 14px;

  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 3%;
  }
`;

export const Option = styled.option`
  color: ${middleGray};
`;
