import styled from 'styled-components';
import { primary, secondary, white, middleGray, buttonTextColor, lightGray } from 'styles/colorProvider';

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    display: none;
  }

  .select {
    background-color: ${secondary};
    border-radius: 50px;
    padding: 0px 20px;
    height: 28px;
    color: ${buttonTextColor || primary};

    :hover {
      color: ${buttonTextColor ? lightGray : white};
    }
  }

  .scrollArea {
    display: flex;
    width: 90%;
    max-width: 90%;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 0;
    }
  }

  div:nth-child(1) {
    margin-left: 0;
  }

  @media (max-width: 1000px) {
    ::-webkit-scrollbar {
      width: 0;
    }

    .select {
      padding: 5px;
      margin-right: 10px;
    }

    .scrollArea {
      margin-top: 10px;
    }
  }
`;

export const ItemMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
  margin-right: 30px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  :hover {
    color: ${secondary};
  }

  @media (max-width: 1000px) {
    margin: 0px;
    margin-right: 10px;

    min-width: 150px;
    max-width: 200px;
  }
`;

export const TextItem = styled.p`
  font-size: 16px;
  margin-left: 8px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  color: ${middleGray};
  margin-top: 0;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: column;
  }
`;
export const Div = styled.div`
  display: flex;
  width: 95%;
  justify-content: flex-start;
  flex-direction: column;

  /* @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: column;
  } */
`;
export const Description = styled.div`
  display: flex;
  width: 52%;
  margin-top: 5px;
  padding: 0;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: column;
  }
`;

export const ModalButton = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 0px;
    padding-right: 50px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;
