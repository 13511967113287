import styled from 'styled-components';
import { black, lightGray, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  height: 70px;
  min-height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${lightGray};
  border-radius: 20px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 13px;
  position: relative;

  .description {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    @media (max-width: 1000px) {
      align-items: flex-start;
      flex-direction: column;
    }

    .iconProfile {
      width: 50px;
      margin-left: 20px;
      margin-right: 0px;

      @media (max-width: 1000px) {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
      padding: 10px;
      justify-content: flex-start;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 10%;
    display: flex;
    height: auto;
    justify-content: column;
    align-self: column;
    flex-direction: column;
    align-items: column;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-left: 10px;
  width: 10%;

  @media (max-width: 1000px) {
    align-items: right;
    padding: 10px;
    width: 50%;
    margin-bottom: -10px;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 10%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Title = styled.h3`
  font-size: 14px;
  color: ${(props) => props.color || black};
  font-weight: 500;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  color: ${middleGray};
  margin: 0;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: column;
  }
`;

export const IconMobile = styled.div`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    width: 10%;
    position: absolute;
    margin: 50px 0 0 82.5%;
    justify-content: center;
    align-items: center;
  }
`;
