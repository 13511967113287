/* eslint-disable react-hooks/exhaustive-deps */
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import BackofficeProposalMenu from 'components/BackofficeProposalMenu';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import Cookies from 'js-cookie';
import moment from 'moment';
import api from 'api';
import axios from 'axios';
import { formatZipCode } from 'react-data-formatter';
import { loadingColor } from 'styles/colorProvider';
import { isBirthDateValid } from 'utils/birthDate';
import momentBD from 'utils/momentBusinessDays';
import { Header, Button, Dialog, Input, ConfirmDialog, DataTable, Select } from 'components';
import {
  ModalButton,
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  PolicyResume,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  Edit,
  Line,
  Footer,
  Content,
  DiscountButton,
  PhotosArea,
  PhotosItem,
  ButtonsContainer,
} from './styles';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IoCopyOutline } from 'react-icons/io5';
import { formatCPF, formatPlate, formatCurrency, formatPhone } from 'react-data-formatter';
import { formatProposalStatus } from 'utils/policyStatus';
import { toast } from 'react-toastify';
import { success, lightGray, error } from 'styles/colorProvider';
import { dataColumns } from './helper';
import { Tooltip } from '@material-ui/core';
import settings from 'settings.json';
import EditCoverages from './EditCoverages';
import { ADJUST_VALUE, IOF } from 'utils/general';
import { cpf } from 'cpf-cnpj-validator';

function ProposalDetails({ history, location }) {
  const proposalId = parseInt(location.pathname.split('detalhes-da-proposta/')[1]);
  const [newPolicyPassword, setNewPolicyPassword] = useState('');
  const [proposalData, setProposalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [confirmNewInspection, setConfirmNewInspection] = useState(false);
  const [confirmCancelProposal, setConfirmCancelProposal] = useState(false);
  const [confirmUpdateStatus, setConfirmUpdateStatus] = useState(false);
  const [confirmNewPolicy, setConfirmNewPolicy] = useState(false);
  const [openVehicle, setOpenVehicle] = useState(false);

  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [userData, setUserData] = useState();
  const [loggedUserData, setLoggedUserData] = useState();

  const [loadingSearchAddress, setLoadingSearchAddress] = useState(false);
  const [loadingUpdateAddress, setLoadingUpdateAddress] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [addressData, setAddressData] = useState();

  const [openCoverages, setOpenCoverages] = useState(false);
  const [coveragesData, setCoveragesData] = useState();

  const [vehicleData, setVehicleData] = useState();
  const [loadingUpdateVehicle, setLoadingUpdateVehicle] = useState(false);

  const [discountPermission, setDiscountPermission] = useState(false);
  const [openDiscount, setOpenDiscount] = useState(false);
  const [discountData, setDiscountData] = useState();
  const [loadingUpdateDiscount, setLoadingUpdateDiscount] = useState(false);
  const [showEditDiscount, setShowEditDiscount] = useState(true);
  const [loadingApplyDiscount, setLoadingApplyDiscount] = useState(false);
  const [discountOptions, setDiscountOptions] = useState({
    discountType: 'percent',
    discountInput: undefined,
    finalDiscountValue: undefined,
    finalDiscountPercent: undefined,
    finalValue: undefined,
  });

  const [discountValue, setDiscountValue] = useState(0);
  const [quotationData, setQuotationData] = useState(0);
  const [responseFinancialUpdade, setResponseFinancialUpdate] = useState(0);

  const [raiseValue, setRaiseValue] = useState(0);
  const [openRaise, setOpenRaise] = useState(false);
  const [loadingUpdateRaise, setLoadingUpdateRaise] = useState(false);

  const [openDiscountOceanica, setOpenDiscountOceanica] = useState(false);
  const [loadingUpdateDiscountOceanica, setLoadingUpdateDiscountOceanica] = useState(false);
  const [discountOceanica, setDiscountOceanica] = useState(0);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [driverData, setDriverData] = useState({});
  const [loadingUpdateDriver, setLoadingUpdateDriver] = useState(false);

  const [openAccept, setOpenAccept] = useState(false);
  const [proposalType, setProposalType] = useState('subscription');
  const [proposalInfo, setProposalInfo] = useState({
    adjustmentFactor: '70%',
    adjustmentFactorValue: 70,
    userBetween18and25Years: false,
    franchiseValue: 'Normal-Obrigatória 10%',
  });

  const percentDiscountInformations = proposalData?.TB_LOG_PROPOSAL?.[0]?.description
    ? JSON.parse(proposalData?.TB_LOG_PROPOSAL?.[0]?.description)
    : null;

  const loadData = async () => {
    setLoading(true);

    try {
      const proposalNumber = {
        method: 'GET',
        url: `/company-proposal/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      };

      const verifyPermission = {
        method: 'GET',
        url: `/has-feature`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
          feature: `/company-proposal/${proposalId}`,
        },
        json: true,
      };

      const getUser = {
        method: 'GET',
        url: `/me`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
          feature: '/me',
        },
        json: true,
      };

      const response = await api(proposalNumber);

      const responsePermission = await api(verifyPermission);
      const responseMe = await api(getUser);

      setDiscountPermission(responsePermission.data);
      setUserData({ ...response.data.user, birthdate: moment(response.data.user.birthdate).format('DD/MM/YYYY') });
      setLoggedUserData(responseMe.data);
      setVehicleData({ ...response.data.vehicle });
      setAddressData({ ...response.data.address });
      setDriverData({
        maindriver_name: response.data.maindriver_name || response.data.user.name,
        maindriver_document: response.data.maindriver_document || response.data.user?.cpf,
        maindriver_birthdate: moment.utc(response.data.maindriver_birthdate || response.data.user.birthdate).format('DD/MM/YYYY'),
      });
      let valueService = 0;
      let valueCoverage = 0;
      let hasServices = false;
      let hasCoverages = false;
      for (const item of response.data?.arrayUnique || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
      if (response.data?.products) {
        for (const item of response.data?.products || []) {
          if (item.isService) {
            hasServices = true;
            valueService += Number(item.value);
          } else {
            hasCoverages = true;
            valueCoverage += Number(item.value);
          }
        }
      }
      if (response.data?.coverages) {
        for (const item of response.data?.coverages || []) {
          if (item.isService) {
            hasServices = true;
            valueService += Number(item.value);
          } else {
            hasCoverages = true;
            valueCoverage += Number(item.value);
          }
        }
      }

      if (response.data?.coverages?.length) {
        hasCoverages = true;
        setValueCoverage(response?.data?.value_final);

        if (response?.data) {
          response.data.iof = response?.data?.value_final * IOF;
        }
      } else {
        setValueCoverage(valueCoverage);
      }

      setProposalData(response.data);

      setHasCoverages(hasCoverages);
      setHasServices(hasServices);
      setLoading(false);
      setValueService(valueService);
    } catch (error) {
      history.push('/nao-autorizado');
    }
  };

  const handleUpdateUser = async () => {
    setLoadingUpdateUser(true);
    try {
      await api({
        method: 'PATCH',
        url: `/backoffice-update-user/${userData.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          ...userData,
          cpf: userData.cpf.replaceAll('.', '').replace('-', ''),
          social_name: userData?.social_name?.toUpperCase(),
          phone: userData.phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
          birthdate: moment(userData.birthdate, 'DD/MM/YYYY'),
          gender: parseInt(userData.gender),
        },
        json: true,
      });
      setLoadingUpdateUser(false);
      setOpenUser(false);
      toast.success('Usuário atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdateUser(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateVehicle = async () => {
    setLoadingUpdateVehicle(true);
    try {
      await api({
        method: 'POST',
        url: `/update-proposal-vehicle`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_proposal: proposalId,
          vehicle: {
            plate: vehicleData?.plate,
            chassi: vehicleData?.chassi,
            manufacture_year: vehicleData?.manufacture_year,
          },
        },
        json: true,
      });
      setLoadingUpdateVehicle(false);
      setOpenVehicle(false);
      toast.success('Veículo atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdateVehicle(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleChangeCep = async (cep) => {
    setAddressData({ ...addressData, zip_code: cep });

    if (cep.length === 10) {
      setLoadingSearchAddress(true);
      const resp = await axios.get(`https://viacep.com.br/ws/${cep?.replaceAll('-', '').replaceAll('.', '')}/json/`);
      setAddressData({
        ...addressData,
        zip_code: resp?.data?.cep,
        city: resp?.data?.localidade,
        street: resp?.data?.logradouro,
        district: resp?.data?.bairro,
        state: resp?.data?.uf,
        number: '',
        complement: '',
      });
      setLoadingSearchAddress(false);
    }
  };

  const handleUpdateStatusProposal = async () => {
    setLoading(true);
    try {
      await api({
        method: 'post',
        url: `/status-registration-proposal/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
      setLoading(false);
      toast.success('O status da proposta foi alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmUpdateStatus(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
      setConfirmUpdateStatus(false);
    }
  };

  const handleUpdateAddress = async () => {
    setLoadingUpdateAddress(true);
    try {
      await api({
        method: 'POST',
        url: `/update-proposal-address`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_proposal: proposalId,
          address: {
            ...addressData,
            zip_code: addressData?.zip_code?.replace(/\D/g, ''),
          },
        },
        json: true,
      });
      setLoadingUpdateAddress(false);
      setOpenAddress(false);
      toast.success('Veículo atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdateAddress(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateDiscount = async () => {
    setLoadingUpdateDiscount(true);
    try {
      const response = await api({
        method: 'POST',
        url: `/update-proposal-discount`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          backoffice: true,
        },
        data: {
          id_proposal: proposalId,
          discount_new: parseFloat(quotationData.discount_new),
        },
        json: true,
      });
      setLoadingUpdateDiscount(false);
      setOpenDiscount(false);
      toast.success('Desconto aplicado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setResponseFinancialUpdate(response.data);
      loadData();
    } catch (error) {
      setLoadingUpdateDiscount(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateDiscountOceanica = async () => {
    setLoadingUpdateDiscountOceanica(true);
    try {
      const response = await api({
        method: 'POST',
        url: `/update-proposal-discount`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          backoffice: true,
        },
        data: {
          id_proposal: proposalId,
          discount_new: parseFloat(quotationData.discount_new),
        },
        json: true,
      });
      setLoadingUpdateDiscountOceanica(false);
      setOpenDiscountOceanica(false);
      toast.success('Desconto aplicado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setResponseFinancialUpdate(response.data);
      loadData();
    } catch (error) {
      setLoadingUpdateDiscountOceanica(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateRaise = async () => {
    setLoadingUpdateRaise(true);
    try {
      const response = await api({
        method: 'POST',
        url: `/update-proposal-raise-price`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          backoffice: true,
        },
        data: {
          id_proposal: proposalId,
          raise_price: parseFloat(quotationData.raise_price),
        },
        json: true,
      });
      setLoadingUpdateRaise(false);
      setOpenRaise(false);
      toast.success('Agravo aplicado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setResponseFinancialUpdate(response.data);
      loadData();
    } catch (error) {
      setLoadingUpdateRaise(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateDriver = async () => {
    setLoadingUpdateDriver(true);
    try {
      await api({
        method: 'patch',
        url: `/update-proposal-driver/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          maindriver_name: driverData.maindriver_name,
          maindriver_document: driverData.maindriver_document,
          maindriver_birthdate: moment(driverData.maindriver_birthdate, 'DD/MM/YYYY').format(),
        },
      });
      setLoadingUpdateDriver(false);
      toast.success('Condutor principal alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setOpenDriver(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingUpdateDriver(false);
      setOpenDriver(false);
    }
  };

  useEffect(() => loadData(), []);

  const host = window.location.hostname.split('.')[0];
  const isOceanica = host.split('.')[0] === 'oceanica';

  const isDiscountAllowed = settings.allowed_discount_partners?.includes(host);
  const isRegisterAllowed = settings.allowed_register_partners?.includes(host);
  const allowedUsers = [14645, 14666, 137, 24925];
  const isUserAllowed = allowedUsers.includes(loggedUserData?.id);

  const handleReject = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/reject-registration`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: proposalData.id,
        },
        json: true,
      });
      setLoading(false);
      setOpenAccept(false);
      toast.success('Cadastro recusado', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/accept-registration`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: proposalData.id,
          type: proposalType,
        },
        json: true,
      });
      setLoading(false);
      setOpenAccept(false);
      toast.success('Cadastro aprovado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => loadData(), []);

  const handleNewInspection = async () => {
    setLoadingRequest(true);
    try {
      await api({
        method: 'post',
        url: `/request-proposal-inspection/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
      setLoadingRequest(false);
      toast.success('Vistoria solicitada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmNewInspection(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingRequest(false);
      setConfirmNewInspection(false);
    }
  };

  const handleCancelProposal = async () => {
    setLoading(true);
    try {
      await api({
        method: 'post',
        url: `/cancel-proposal/${proposalId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
      setLoading(false);
      toast.success('A proposta foi cancelada!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmCancelProposal(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
      setConfirmCancelProposal(false);
    }
  };

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={confirmNewInspection}
      onCancel={() => setConfirmNewInspection(false)}
      content="Tem certeza de que deseja solicitar uma nova vistoria? A vistoria atual será inativada"
      title="Solicitar nova vistoria"
      onConfirm={handleNewInspection}
      loading={loadingRequest}
    />
  );

  const handleApplyDiscountProposal = async (discountInput) => {
    setLoadingApplyDiscount(true);
    try {
      await api({
        method: 'POST',
        url: `/apply-discount/${proposalData?.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          discount_percent: discountInput,
        },
        json: true,
      });

      toast.success('Desconto aplicado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingApplyDiscount(false);
      loadData();
    } catch (error) {
      setLoadingApplyDiscount(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const renderConfirmCancel = () => (
    <ConfirmDialog
      open={confirmCancelProposal}
      onCancel={() => setConfirmCancelProposal(false)}
      content="Tem certeza de que deseja cancelar a proposta?"
      title="Cancelar proposta"
      onConfirm={handleCancelProposal}
      loading={loadingRequest}
    />
  );

  const renderConfirmUpdateStatus = () => (
    <ConfirmDialog
      open={confirmUpdateStatus}
      onCancel={() => setConfirmUpdateStatus(false)}
      content="Tem certeza de que deseja mudar o status da proposta?"
      title="Status da proposta"
      onConfirm={handleUpdateStatusProposal}
      loading={loadingRequest}
    />
  );

  const renderConfirmNewPolicy = () => (
    <ConfirmDialog
      open={confirmNewPolicy}
      onCancel={() => setConfirmNewPolicy(false)}
      content={
        <Input
          widht="30%"
          label="Digite a senha"
          // value={""}
          onChange={(event) => setNewPolicyPassword(event.target.value)}
          height="30px"
          type="password"
          password
        />
      }
      title="Solicitar emissão da apólice"
      onConfirm={async () => {
        setLoading(true);
        setConfirmNewPolicy(false);
        try {
          await api({
            method: 'POST',
            url: `/policy-manual`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('splitriskwl-authtoken'),
              secret: newPolicyPassword,
            },
            data: {
              id_proposal: proposalData.id,
            },
            json: true,
          });
          toast.success('Apólice criada com sucesso', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } catch (error) {
          toast.error('Atenção! A apólice não foi criada! ', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
        setLoading(false);
        loadData();
      }}
    ></ConfirmDialog>
  );

  const applyDiscount = (discount) => {
    setDiscountValue(discount);
    setQuotationData({ ...quotationData, discount_new: discount / 100 });
    // handleUpdateDiscount(discount / 100);
  };

  const applyRaise = (discount) => {
    setRaiseValue(discount);
    setQuotationData({ ...quotationData, raise_price: discount / 100 });
    // handleUpdateDiscount(discount / 100);
  };

  const applyDiscountOceanica = (discount) => {
    setDiscountOceanica(discount);
    setQuotationData({ ...quotationData, discount_new: discount / 100 });
    // handleUpdateDiscount(discount / 100);
  };

  const handleDiscount = (e) => {
    const discountValue = parseFloat(e.target.value.replace('R$ ', '').replace(',', '.'));

    if (discountOptions?.discountType === 'value') {
      const fDiscountValue = discountValue;
      const fDiscountPercent = (discountValue * 100) / proposalData?.final_value;
      const fValue = proposalData?.final_value - fDiscountValue;
      setDiscountOptions({
        ...discountOptions,
        finalDiscountValue: fDiscountValue,
        finalDiscountPercent: fDiscountPercent,
        finalValue: fValue,
      });
    }

    if (discountOptions?.discountType === 'percent') {
      const fDiscountValue = (discountValue / 100) * proposalData?.value_final;
      const fDiscountPercent = discountValue;
      const fValue = proposalData?.value_final - fDiscountValue;
      setDiscountOptions({
        ...discountOptions,
        finalDiscountValue: fDiscountValue,
        finalDiscountPercent: fDiscountPercent,
        finalValue: fValue,
      });
    }
  };

  const actionDataColumns = [
    ...dataColumns,
    {
      name: 'Link',
      selector: (row) => (
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(row?.inspection_url);
            toast.success('Link para vistoria copiado com sucesso!', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            });
          }}
        >
          <IoCopyOutline size={15} />
        </IconButton>
      ),
      width: '10%',
      sortable: true,
    },
  ];

  useEffect(() => {
    // LMI LOGIC
    const auctionOrRemarked = proposalData?.vehicle?.auction || proposalData?.vehicle?.remarked;
    // Starts with 70% <- Auction or Remarked
    let adjustmentFactor = '70%';
    let adjustmentFactorValue = 70;

    if (!auctionOrRemarked) {
      // If has valid LMI or 100%
      adjustmentFactor = proposalData?.adjustmentFactor || '100%';
      adjustmentFactorValue = proposalData?.adjustmentFactorValue || 100;
    }

    // Franchise Logic
    const showFranchiseValue = proposalData?.show_franchise_value;
    const franchiseValue = proposalData?.franchise_value;

    // Apólices antigas
    const oldFranchiseValue = proposalData.coverages?.find((c) => c?.name?.includes('Franquia'))?.name_complete;

    // User Between 18 and 25 years
    const userBetween18and25Years = proposalData?.some_driver_18_25_years_old;

    setProposalInfo({
      ...proposalInfo,
      adjustmentFactor,
      adjustmentFactorValue,
      userBetween18and25Years,
      franchiseValue:
        showFranchiseValue && franchiseValue ? franchiseValue : oldFranchiseValue ? oldFranchiseValue : 'Normal-Obrigatória 10%',
    });
  }, [proposalData]);

  const isCpf = cpf.isValid(proposalData?.user?.cpf);

  return (
    <>
      {renderConfirmDialog()}
      {renderConfirmNewPolicy(false)}
      {renderConfirmCancel(false)}
      {renderConfirmUpdateStatus(false)}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <BackofficeProposalMenu proposalId={proposalId} proposalData={proposalData} />
            <Title>DETALHES DA PROPOSTA</Title>
            {/* <Subtitle className="labelchangeProposalStatus">Modificar status:</Subtitle> */}
            <PolicyResume>
              <p>Protocolo: {proposalData?.protocol}</p>
              <p>
                Status da proposta:{' '}
                <span style={{ color: formatProposalStatus(proposalData?.status)?.color }}>
                  {formatProposalStatus(proposalData?.status)?.label}
                </span>
              </p>
              <p>Valor: {formatCurrency(proposalData?.valueFinal || 0)}</p>
              <p>
                Fator de ajuste LMI: <span>{proposalInfo?.adjustmentFactor}</span>
              </p>
              <p>
                Franquia: <span>{proposalInfo?.franchiseValue}</span>
              </p>

              {proposalData?.status === 'awaiting_registration' && (
                <FormControl style={{ marginLeft: '10px', marginTop: '10px', display: 'none' }}>
                  <RadioGroup
                    value={proposalType}
                    onChange={(e) => {
                      setProposalType(e.target.value);
                    }}
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="subscription"
                      sx={{ color: '#2a034f' }}
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: '#07bc0c',
                            },
                          }}
                        />
                      }
                      label="Subscrição(padrão)"
                    />
                    <FormControlLabel
                      value="other"
                      sx={{ color: '#2a034f' }}
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: '#07bc0c',
                            },
                          }}
                        />
                      }
                      label="Outros"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {proposalData?.status === 'rejected' && (
                <p style={{ marginTop: '20px' }}>Motivo de rejeição da proposta: {proposalData?.reject_motive}</p>
              )}
              {proposalData?.insurance_observation && <p>Observação: {proposalData?.insurance_observation}</p>}
            </PolicyResume>

            {proposalData?.status !== 'cancelled' && (
              <>
                <Button className="buttonCancelProposal" onClick={() => setConfirmCancelProposal(true)}>
                  Cancelar proposta
                </Button>
                {/* {proposalData?.status === 'awaiting_inspection' ? (
                  <Button className="buttonUpdateStatusProposal" onClick={() => setConfirmUpdateStatus(true)}>
                    Aguardando cadastro
                  </Button>
                ) : null} */}
              </>
            )}

            {proposalData?.status === 'awaiting_activation' && (
              <div>
                <div className="buttonCreatePolicy">
                  <Button
                    height="20px"
                    style={{ marginLeft: 0, maxWidth: '120px', marginTop: '40px' }}
                    onClick={() => setConfirmNewPolicy(true)}
                  >
                    Emitir apólice
                  </Button>
                </div>
              </div>
            )}

            <PolicyInformationArea style={{ marginTop: 40 }}>
              <Title fontSize="20px" color={success}>
                <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                  <BsFillCheckCircleFill size={20} color={success} />
                </IconButton>
                Etapa 1 - Vistoria
              </Title>
              <Line />

              {/* STEP 1 */}
              <ItemPolicy style={{ width: '94%' }}>
                <DataTable
                  responsive
                  columns={actionDataColumns}
                  data={proposalData?.inspections}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="100%"
                  progressPending={loading}
                  progressComponent={
                    <LoadingArea>
                      <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                    </LoadingArea>
                  }
                  noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma vistoria encontrada</small>}
                />
              </ItemPolicy>

              {proposalData?.status === 'awaiting_inspection' && (
                <Title fontSize="20px" color={lightGray}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={lightGray} />
                  </IconButton>
                  Etapa 2 - Cadastro
                </Title>
              )}
              {proposalData?.status !== 'awaiting_inspection' && (
                <Title fontSize="20px" color={success}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={success} />
                  </IconButton>
                  Etapa 2 - Cadastro
                </Title>
              )}

              <Line />

              {/* STEP 2 */}
              <div className="tempo">
                {/* SEGURADO */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Informações do segurado</Subtitle>
                    <Tooltip
                      title={proposalData?.status === 'activated' ? 'A proposta ativada não pode ser modificada.' : 'Editar'}
                    >
                      <Button
                        onClick={() => setOpenUser(true)}
                        disabled={proposalData?.status === 'awaiting_payment' || proposalData?.status === 'activated'}
                        height="20px"
                        width="70px"
                        style={{ fontSize: 12 }}
                      >
                        Editar
                      </Button>
                    </Tooltip>
                  </Edit>
                  <Text>
                    Nome do segurado: <span>{proposalData?.user?.name}</span>
                  </Text>
                  <Text>
                    Nome social: <span>{proposalData?.user?.social_name}</span>
                  </Text>
                  <Text>
                    CPF: <span>{formatCPF(proposalData?.user?.cpf)}</span>
                  </Text>
                  {isCpf && (
                    <Text>
                      Data de nascimento: <span>{moment.utc(proposalData?.user?.birthdate).format('DD/MM/YYYY')}</span>
                    </Text>
                  )}
                  <Text>
                    Telefone: <span>{formatPhone(proposalData?.user?.phone)}</span>
                  </Text>
                  <Text>
                    E-mail: <span>{proposalData?.user?.email}</span>
                  </Text>

                  <Dialog open={openUser} onClose={() => setOpenUser(false)} width="50%" title="Editar segurado">
                    <Input
                      widht="30%"
                      label="Nome"
                      value={userData?.name}
                      onChange={(e) => setUserData({ ...userData, name: e.target.value.toUpperCase() })}
                      height="30px"
                    />
                    <Input
                      widht="30%"
                      label="Nome social"
                      value={userData?.social_name}
                      onChange={(e) => setUserData({ ...userData, social_name: e.target.value.toUpperCase() })}
                      height="30px"
                    />
                    <Input
                      widht="30%"
                      label="CPF"
                      mask={'999.999.999-99'}
                      value={userData?.cpf}
                      onChange={(e) => setUserData({ ...userData, cpf: e.target.value.toUpperCase() })}
                      height="30px"
                    />
                    <Input
                      widht="30%"
                      type="email"
                      label="E-mail"
                      value={userData?.email}
                      onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      height="30px"
                    />
                    <Input
                      height="35px"
                      mask="99/99/9999"
                      label="Data de nascimento"
                      placeholder="Data de nascimento"
                      value={userData?.birthdate}
                      onChange={(e) => setUserData({ ...userData, birthdate: e.target.value })}
                    />
                    <Input
                      widht="30%"
                      label="Telefone"
                      mask="(99) 9 9999-9999"
                      value={userData?.phone}
                      onChange={(e) => setUserData({ ...userData, phone: e.target.value.toUpperCase() })}
                      height="30px"
                    />

                    <ModalButton>
                      <Button
                        loading={loadingUpdateUser}
                        height="35px"
                        onClick={handleUpdateUser}
                        disabled={
                          loadingUpdateUser ||
                          !userData?.name ||
                          !userData?.cpf ||
                          !userData?.email ||
                          !userData?.phone ||
                          !userData?.birthdate
                        }
                      >
                        Editar
                      </Button>
                    </ModalButton>
                  </Dialog>
                </ItemPolicy>

                {/* CONDUTOR PRINCIPAL */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Informações do condutor principal</Subtitle>
                    <Button height="20px" width="70px" style={{ fontSize: 12 }} onClick={() => setOpenDriver(true)}>
                      Editar
                    </Button>
                  </Edit>

                  <Text>
                    Nome: <span>{driverData?.maindriver_name || driverData?.user?.name}</span>
                  </Text>
                  <Text>
                    CPF: <span>{formatCPF(driverData?.maindriver_document || driverData?.user?.cpf)}</span>
                  </Text>

                  {isCpf && (
                    <Text>
                      Data de nascimento: <span>{driverData?.maindriver_birthdate || driverData?.user?.birthdate}</span>
                    </Text>
                  )}

                  <Dialog open={openDriver} onClose={() => setOpenDriver(false)} width="50%" title="Editar condutor">
                    <Input
                      height="30px"
                      label="Nome"
                      value={driverData?.maindriver_name}
                      onChange={(e) => setDriverData({ ...driverData, maindriver_name: e.target.value.toUpperCase() })}
                    />
                    <Input
                      height="30px"
                      label="CPF"
                      mask="999.999.999-99"
                      value={driverData?.maindriver_document}
                      onChange={(e) => setDriverData({ ...driverData, maindriver_document: e.target.value.toUpperCase() })}
                    />
                    <Input
                      height="30px"
                      label="Data de nascimento"
                      mask="99/99/9999"
                      value={driverData?.maindriver_birthdate}
                      onChange={(e) => setDriverData({ ...driverData, maindriver_birthdate: e.target.value })}
                    />
                    <ModalButton>
                      <Button
                        loading={loadingUpdateDriver}
                        height="35px"
                        onClick={handleUpdateDriver}
                        style={{ margin: 0, marginTop: 20 }}
                        disabled={
                          loadingUpdateVehicle ||
                          !driverData?.maindriver_name ||
                          !driverData?.maindriver_document ||
                          !driverData?.maindriver_birthdate
                        }
                      >
                        Editar
                      </Button>
                    </ModalButton>
                  </Dialog>
                </ItemPolicy>

                {/* ENDEREÇO */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Endereço do segurado</Subtitle>
                    <Tooltip
                      title={proposalData?.status === 'activated' ? 'A proposta ativada não pode ser modificada.' : 'Editar'}
                    >
                      <Button
                        onClick={() => setOpenAddress(true)}
                        disabled={proposalData?.status === 'activated'}
                        height="20px"
                        width="70px"
                        style={{ fontSize: 12 }}
                      >
                        Editar
                      </Button>
                    </Tooltip>
                  </Edit>
                  <Text>
                    CEP: <span>{formatZipCode(proposalData?.address?.zip_code)}</span>
                  </Text>
                  <Text>
                    Endereço: <span>{proposalData?.address?.street}</span>
                  </Text>
                  <Text>
                    Número:{' '}
                    <span>
                      {proposalData?.address?.number} {proposalData?.address?.complement}
                    </span>
                  </Text>
                  <Text>
                    Bairro: <span>{proposalData?.address?.district}</span>
                  </Text>
                  <Text>
                    Cidade/UF:{' '}
                    <span>
                      {proposalData?.address?.city}/{proposalData?.address?.state}
                    </span>
                  </Text>
                  <Text>
                    Complemento: <span>{proposalData?.address?.complement}</span>
                  </Text>

                  <Dialog open={openAddress} onClose={() => setOpenAddress(false)} width="50%" title="Editar endereço">
                    <Input
                      value={addressData?.zip_code}
                      onChange={(e) => handleChangeCep(e.target.value)}
                      widht="30%"
                      label="CEP"
                      mask="99.999-999"
                      height="30px"
                    />
                    <Input
                      value={addressData?.city}
                      onChange={(e) => setAddressData({ ...addressData, city: e.target.value })}
                      widht="30%"
                      label="Cidade"
                      height="30px"
                      disabled
                    />
                    <Input
                      value={addressData?.district}
                      onChange={(e) => setAddressData({ ...addressData, district: e.target.value })}
                      widht="30%"
                      label="Bairro"
                      name="district"
                      height="30px"
                      disabled
                    />
                    <Input
                      value={addressData?.street}
                      onChange={(e) => setAddressData({ ...addressData, street: e.target.value })}
                      widht="30%"
                      label="Endereço"
                      height="30px"
                      name="address"
                      disabled
                    />
                    <Input
                      value={addressData?.number}
                      onChange={(e) => setAddressData({ ...addressData, number: e.target.value })}
                      widht="30%"
                      label="Número"
                      height="30px"
                      type="number"
                    />
                    <Input
                      value={addressData?.complement}
                      onChange={(e) => setAddressData({ ...addressData, complement: e.target.value })}
                      widht="30%"
                      label="Complemento"
                      height="30px"
                    />
                    <ModalButton>
                      <Button
                        loading={loadingUpdateAddress || loadingSearchAddress}
                        height="35px"
                        onClick={handleUpdateAddress}
                        disabled={
                          loadingUpdateAddress ||
                          loadingSearchAddress ||
                          !addressData?.zip_code ||
                          !addressData?.city ||
                          !addressData?.street ||
                          !addressData?.district ||
                          !addressData?.state ||
                          !addressData?.number
                        }
                      >
                        Salvar alterações
                      </Button>
                    </ModalButton>
                  </Dialog>
                </ItemPolicy>

                {/* VEICULO */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Informações do veículo</Subtitle>
                    <Tooltip
                      title={proposalData?.status === 'activated' ? 'A proposta ativada não pode ser modificada.' : 'Editar'}
                    >
                      <Button
                        onClick={() => setOpenVehicle(true)}
                        disabled={proposalData?.status === 'activated'}
                        height="20px"
                        width="70px"
                        style={{ fontSize: 12 }}
                      >
                        Editar
                      </Button>
                    </Tooltip>
                  </Edit>
                  <Text>
                    Marca e modelo: <span>{`${proposalData?.vehicle?.brand} ${proposalData?.vehicle?.model}`}</span>
                  </Text>
                  <Text>
                    Ano/modelo: <span>{`${proposalData?.vehicle?.manufacture_year}/${proposalData?.vehicle?.year_model}`}</span>
                  </Text>
                  <Text>
                    Placa: <span>{formatPlate(proposalData?.vehicle?.plate) || 'Sem placa'}</span>
                  </Text>
                  <Text>
                    Chassi: <span>{proposalData?.vehicle?.chassi}</span>
                  </Text>
                  <Text>
                    Valor FIPE: <span>{formatCurrency(proposalData?.vehicle?.value || 0)}</span>
                  </Text>
                  <Text>
                    LMI {proposalInfo?.adjustmentFactor}:{' '}
                    <span>{formatCurrency(proposalData?.vehicle?.value * (proposalInfo?.adjustmentFactorValue / 100) || 0)}</span>
                  </Text>
                  <Text>
                    Uso do veículo: <span>{`${proposalData?.vehicle?.vehicle_use?.name}`}</span>
                  </Text>
                  <Text>
                    Veículo de leilão: <span>{proposalData?.vehicle?.auction === 1 ? 'SIM' : 'NÃO'}</span>
                  </Text>
                  <Text>
                    Chassis Remarcado/Veiculo Recuperado de Sinistro:{' '}
                    <span>{proposalData?.vehicle?.remarked ? 'SIM' : 'NÃO'}</span>
                  </Text>
                  <Text>
                    GNV: <span>{proposalData?.vehicle?.gnv ? 'SIM' : 'NÃO'}</span>
                  </Text>
                  <Text>
                    Condutor entre 18 e 25 anos: <span>{proposalInfo?.userBetween18and25Years ? 'SIM' : 'NÃO'}</span>
                  </Text>

                  <Dialog open={openVehicle} onClose={() => setOpenVehicle(false)} width="50%" title="Editar veículo">
                    <Input
                      height="30px"
                      label="Placa"
                      value={vehicleData?.plate}
                      onChange={(e) => setVehicleData({ ...vehicleData, plate: e.target.value.toUpperCase() })}
                    />
                    <Input
                      height="30px"
                      label="Chassi"
                      mask="*****************"
                      value={vehicleData?.chassi}
                      onChange={(e) => setVehicleData({ ...vehicleData, chassi: e.target.value.toUpperCase() })}
                    />
                    <Input
                      height="30px"
                      label="Ano de fabricação"
                      mask="9999"
                      value={vehicleData?.manufacture_year}
                      onChange={(e) => setVehicleData({ ...vehicleData, manufacture_year: e.target.value })}
                    />
                    <ModalButton>
                      <Button
                        loading={loadingUpdateVehicle}
                        height="35px"
                        onClick={handleUpdateVehicle}
                        disabled={loadingUpdateVehicle || !vehicleData?.plate || !vehicleData?.chassi}
                      >
                        Editar
                      </Button>
                    </ModalButton>
                  </Dialog>
                </ItemPolicy>

                {/* FINANCEIRO */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle style={{ width: '100%', justifyContent: 'space-between' }}>Informações financeiras </Subtitle>
                  </Edit>
                  {hasCoverages && (
                    <Text>
                      Valor Coberturas:{' '}
                      <span>{`${formatCurrency(
                        (proposalData?.isHinova ? proposalData?.valueFinal : valueCoverage) - proposalData?.iof,
                      )}`}</span>
                    </Text>
                  )}
                  {hasCoverages && (
                    <Text>
                      Valor IOF sobre Coberturas: <span>{`${formatCurrency(proposalData?.iof || 0)}`}</span>
                    </Text>
                  )}
                  {hasServices && (
                    <Text>
                      Valor Serviços: <span>{`${formatCurrency(valueService)}`}</span>
                    </Text>
                  )}
                  <Text>
                    Valor Total do Seguros: <span>{`${formatCurrency(proposalData?.valueFinal || 0)}`}</span>
                  </Text>
                  <h4> Informações sobre desconto:</h4>
                  {proposalData.discount_new > 0 && (
                    <Text>
                      Valor do desconto: <span>{formatCurrency(proposalData?.discount_new)}</span>
                    </Text>
                  )}

                  {proposalData.discount_new > 0 && (
                    <Text>
                      Percentual do desconto: <span>{`${percentDiscountInformations?.discount_percentage}%`}</span>
                    </Text>
                  )}

                  {proposalData.discount_new <= 0 && (
                    <Text>
                      Percentual do desconto <span>{`${proposalData?.TB_QUOTATION_PROPOSAL?.discount_percent || 0}%`}</span>
                    </Text>
                  )}

                  {percentDiscountInformations && (
                    <Text>
                      Valor final sem desconto: <span>{formatCurrency(percentDiscountInformations?.old_value || 0)}</span>
                    </Text>
                  )}

                  <Text>
                    Valor final com desconto: <span>{formatCurrency(proposalData?.valueFinal || 0)}</span>
                  </Text>

                  {!proposalData.isHinova &&
                    showEditDiscount &&
                    (proposalData.discount_new === 0 || proposalData.discount_new === null) && (
                      <section className="discount-area" style={{ marginTop: 30 }}>
                        <h4 style={{ margin: 0, marginBottom: 15 }}>Editar desconto</h4>
                        <Select
                          value={discountOptions?.discountType}
                          onChange={(e) => setDiscountOptions({ ...discountOptions, discountType: e.target.value })}
                          label="Tipo de desconto"
                          height="30px"
                          style={{ margin: 0 }}
                        >
                          <option value="percent">Porcentagem</option>
                        </Select>
                        <Input
                          style={{ margin: 0 }}
                          height="30px"
                          label={discountOptions?.discountType === 'percent' ? 'Percentual de desconto' : 'Valor de desconto'}
                          numeric
                          price={discountOptions?.discountType === 'value'}
                          value={discountOptions?.discountInput}
                          onChange={handleDiscount}
                        />

                        <>
                          <p style={{ margin: 0, fontSize: 12, marginTop: 10 }}>
                            Percentual de desconto:{' '}
                            {discountOptions?.finalDiscountPercent ? discountOptions?.finalDiscountPercent : 0}%{' '}
                          </p>
                          <p style={{ margin: 0, fontSize: 12 }}>
                            Valor final com desconto: {formatCurrency(discountOptions?.finalValue || 0)}
                          </p>
                          <Button
                            loading={loadingApplyDiscount}
                            disabled={loadingApplyDiscount}
                            onClick={() => handleApplyDiscountProposal(discountOptions?.finalDiscountPercent)}
                            height="30px"
                          >
                            Salvar alterações
                          </Button>
                        </>
                      </section>
                    )}
                </ItemPolicy>

                {/* <div>
                    <h4> Informações sobre agravo de valor:</h4>
                    <Text>
                      Valor do agravo: <span>{`${proposalData?.premium_seller && formatCurrency(proposalData?.premium_seller || 0)}`}</span>
                    </Text>
                    <Text>
                      Percentual de agravo: <span>{`${proposalData?.TB_QUOTATION_PROPOSAL?.grievance_percent || 0}%`}</span>
                    </Text>
                    <Text>
                      Valor final com agravo:{' '}
                      <span>{`${proposalData?.value_final && formatCurrency(proposalData?.value_final || 0)}`}</span>
                    </Text>
                  </div> }

                  <Dialog open={openDiscount} onClose={() => setOpenDiscount(false)} width="50%" title="Aplicar Desconto">
                    <Content>
                      {discountValue === 0 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>0%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountValue(0);
                            applyDiscount(0);
                          }}
                        >
                          0%
                        </DiscountButton>
                      )}
                      {discountValue === 5 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>5%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountValue(5);
                            applyDiscount(5);
                          }}
                        >
                          5%
                        </DiscountButton>
                      )}
                      {discountValue === 10 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>10%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountValue(10);
                            applyDiscount(10);
                          }}
                        >
                          10%
                        </DiscountButton>
                      )}
                      {discountValue === 15 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>15%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountValue(15);
                            applyDiscount(15);
                          }}
                        >
                          15%
                        </DiscountButton>
                      )}
                    </Content>

                    <>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor total sem desconto:
                        {formatCurrency(proposalData?.value || 0)}
                      </p>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Percentual de desconto: {discountValue || discountOceanica || 0}%{' '}
                      </p>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor do desconto:
                        {formatCurrency(proposalData.value * (discountValue / 100) || 0)}
                      </p>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor final com desconto:{' '}
                        {formatCurrency(proposalData.value - proposalData.value * (discountValue / 100) || 0)}
                      </p>
                    </>

                    {
                      <ModalButton>
                        <Button
                          loading={loadingUpdateDiscount}
                          height="35px"
                          style={{ margin: 0, marginTop: 20 }}
                          onClick={handleUpdateDiscount}
                          // disabled={!discountData}
                        >
                          Salvar alterações
                        </Button>
                      </ModalButton>
                    }
                  </Dialog>

                  <Dialog open={openRaise} onClose={() => setOpenRaise(false)} width="50%" title="Aplicar Agravo">
                    <Content>
                      {raiseValue === 0 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>0%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setRaiseValue(0);
                            applyRaise(0);
                          }}
                        >
                          0%
                        </DiscountButton>
                      )}
                      {raiseValue === 5 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>5%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setRaiseValue(5);
                            applyRaise(5);
                          }}
                        >
                          5%
                        </DiscountButton>
                      )}
                      {raiseValue === 10 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>10%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setRaiseValue(10);
                            applyRaise(10);
                          }}
                        >
                          10%
                        </DiscountButton>
                      )}
                      {raiseValue === 15 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>15%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setRaiseValue(15);
                            applyRaise(15);
                          }}
                        >
                          15%
                        </DiscountButton>
                      )}
                    </Content>

                    <>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor total sem agravo:
                        {formatCurrency(proposalData?.value_final || 0)}
                      </p>
                      <p style={{ margin: 0, fontSize: 12 }}>Percentual de agravo: {raiseValue || 0}% </p>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor do agravo:
                        {formatCurrency(proposalData.value * (raiseValue / 100) || 0)}
                      </p>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor final com agravo:{' '}
                        {formatCurrency(proposalData.value + proposalData.value * (raiseValue / 100) || 0)}
                      </p>
                    </>

                    {
                      <ModalButton>
                        <Button
                          loading={loadingUpdateRaise}
                          height="35px"
                          style={{ margin: 0, marginTop: 20 }}
                          onClick={handleUpdateRaise}
                          // disabled={!discountData}
                        >
                          Salvar alterações
                        </Button>
                      </ModalButton>
                    }
                  </Dialog>

                  <Dialog
                    open={openDiscountOceanica}
                    onClose={() => setOpenDiscountOceanica(false)}
                    width="50%"
                    title="Aplicar Desconto"
                  >
                    <Content>
                      {discountOceanica === 0 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>0%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountOceanica(0);
                            applyDiscountOceanica(0);
                          }}
                        >
                          0%
                        </DiscountButton>
                      )}
                      {discountOceanica === 5 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>5%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountOceanica(5);
                            applyDiscountOceanica(5);
                          }}
                        >
                          5%
                        </DiscountButton>
                      )}
                      {discountOceanica === 7 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>7%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountOceanica(7);
                            applyDiscountOceanica(7);
                          }}
                        >
                          7%
                        </DiscountButton>
                      )}
                      {discountOceanica === 10 ? (
                        <DiscountButton style={{ background: '#62ec83' }}>10%</DiscountButton>
                      ) : (
                        <DiscountButton
                          onClick={() => {
                            setDiscountOceanica(10);
                            applyDiscountOceanica(10);
                          }}
                        >
                          10%
                        </DiscountButton>
                      )}
                    </Content>

                    <>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor total sem desconto:
                        {formatCurrency(proposalData?.value_final || 0)}
                      </p>
                      <p style={{ margin: 0, fontSize: 12 }}>Percentual de desconto: {discountOceanica || 0}% </p>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor do desconto:
                        {formatCurrency(proposalData.value_final * (discountOceanica / 100) || 0)}
                      </p>
                      <p style={{ margin: 0, fontSize: 12 }}>
                        Valor final com desconto:{' '}
                        {formatCurrency(proposalData.value_final - proposalData.value_final * (discountOceanica / 100) || 0)}
                      </p>
                    </>

                    {
                      <ModalButton>
                        <Button
                          loading={loadingUpdateDiscountOceanica}
                          height="35px"
                          style={{ margin: 0, marginTop: 20 }}
                          onClick={handleUpdateDiscountOceanica}
                          // disabled={!discountData}
                        >
                          Salvar alterações
                        </Button>
                      </ModalButton>
                    }
                  </Dialog>
                </ItemPolicy>

                {/* COBERTURAS */}
                {hasCoverages && (
                  <ItemPolicy>
                    <Edit>
                      <Subtitle>Coberturas</Subtitle>
                    </Edit>
                    {proposalData?.products?.length &&
                      proposalData?.products
                        .filter((coverage) => coverage.isService === false)
                        .map((coverage) => <Text>• {coverage.name} </Text>)}
                    {proposalData?.coverages?.length &&
                      proposalData?.coverages
                        .filter((product) => product.isService === false)
                        .map((product) => <Text>• {product.name} </Text>)}
                    {proposalData?.arrayUnique?.length &&
                      proposalData?.arrayUnique
                        .filter((array) => array.isService === false)
                        .map((array) => <Text>• {array.name} </Text>)}
                    <Dialog open={openCoverages} onClose={() => setOpenCoverages(false)} width="50%" title="Editar coberturas">
                      <EditCoverages
                        proposalData={proposalData}
                        setproposalData={setProposalData}
                        setCoveragesData={setCoveragesData}
                      />
                    </Dialog>
                  </ItemPolicy>
                )}

                {/* REPRESENTANTE */}
                <ItemPolicy>
                  <Edit>
                    <Subtitle>Informações do representante</Subtitle>
                  </Edit>
                  <Text>
                    Nome: <span>{proposalData?.author?.name}</span>
                  </Text>
                  <Text>
                    CPF: <span>{formatCPF(proposalData?.author?.cpf)}</span>
                  </Text>

                  <Text>
                    Telefone: <span>{formatPhone(proposalData?.author?.phone)}</span>
                  </Text>
                  <Text>
                    E-mail: <span>{proposalData?.author?.email}</span>
                  </Text>
                  <Text>
                    Equipe: <span>{proposalData?.author?.teams[0]?.name || 'Sem equipe'}</span>
                  </Text>
                </ItemPolicy>
                {hasServices && (
                  <ItemPolicy>
                    <Edit>
                      <Subtitle>Serviços</Subtitle>
                    </Edit>
                    {proposalData?.products?.length &&
                      proposalData?.products
                        .filter((coverage) => coverage.isService === true)
                        .map((coverage) => <Text>• {coverage.name} </Text>)}
                    {proposalData?.coverages?.length &&
                      proposalData?.coverages
                        .filter((product) => product.isService === true)
                        .map((product) => <Text>• {product.name} </Text>)}
                    {proposalData?.arrayUnique?.length &&
                      proposalData?.arrayUnique
                        .filter((array) => array.isService === true)
                        .map((array) => <Text>• {array.name} </Text>)}
                    <Dialog open={openCoverages} onClose={() => setOpenCoverages(false)} width="50%" title="Editar coberturas">
                      <EditCoverages
                        proposalData={proposalData}
                        setproposalData={setProposalData}
                        setCoveragesData={setCoveragesData}
                      />
                    </Dialog>
                  </ItemPolicy>
                )}
              </div>
              {proposalData?.status !== 'awaiting_payment' && proposalData?.status !== 'cancelled' && (
                <Title fontSize="20px" color={lightGray}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={lightGray} />
                  </IconButton>
                  Etapa 3 - Pagamento
                </Title>
              )}
              {proposalData?.status === 'awaiting_payment' && (
                <Title fontSize="20px" color={success}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={success} />
                  </IconButton>
                  Etapa 3 - Pagamento
                </Title>
              )}
              {proposalData?.status === 'cancelled' && (
                <Title fontSize="20px" color={success}>
                  <IconButton size="small" style={{ marginRight: 10, marginBottom: 2 }}>
                    <BsFillCheckCircleFill size={20} color={success} />
                  </IconButton>
                  Etapa 3 - Pagamento
                </Title>
              )}

              <Line />

              {/* STEP 3 */}
              <ItemPolicy>
                <Subtitle>Primeiro pagamento</Subtitle>
                <Text>
                  Status da proposta: <span>{formatProposalStatus(proposalData?.status)?.label}</span>
                </Text>
                <Text>
                  Vencimento: <span>{momentBD(proposalData?.accepted_at).businessAdd(3, 'days').format('DD/MM/YYYY')}</span>
                </Text>
                <Text>
                  Mensalidade: <span>{proposalData?.value_final && formatCurrency(proposalData?.value_final)}</span>
                </Text>
                <Text>
                  IOF: <span>{`${proposalData?.iof && formatCurrency(proposalData?.iof)}`}</span>
                </Text>
                <Text>
                  Valor Total: <span>{proposalData?.value_final && formatCurrency(proposalData?.value_final)}</span>
                </Text>
              </ItemPolicy>
              {proposalData?.status === 'awaiting_registration' && isUserAllowed && (
                <Footer>
                  <div className="area-button">
                    <Button
                      onClick={() => handleReject()}
                      style={{
                        width: '160px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${error}`,
                        color: '#fff',
                      }}
                    >
                      Reprovar cadastro
                    </Button>
                    <Button
                      onClick={() => handleAccept()}
                      style={{
                        width: '160px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${success}`,
                        color: '#fff',
                      }}
                    >
                      Aprovar cadastro
                    </Button>
                  </div>
                </Footer>
              )}
            </PolicyInformationArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default ProposalDetails;
