import React from 'react';
import 'moment/locale/pt-br';
import { FaUserShield } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container, Div, Title, Subtitle, Icon } from './styles';
import { error, success } from 'styles/colorProvider';

function VictimisItem({ item, deleteItem, claimData }) {
  return (
    <>
      <Container>
        <div className="description">
          <div className="iconCar">
            <FaUserShield size={25} color={success} />
          </div>

          <Div>
            <Title>Nome da vítima:</Title>
            <Subtitle>{item?.name}</Subtitle>
          </Div>

          <Div>
            <Title>CPF:</Title>
            <Subtitle>{item?.cpf === '' || item.cpf === null ? 'Terceiro sem CPF' : item.cpf}</Subtitle>
          </Div>

          <Div>
            <Title>Contato</Title>
            <Subtitle>{item.phone}</Subtitle>
          </Div>

          <Icon>
            {claimData?.status === 'created' && <DeleteIcon style={{ cursor: 'pointer', color: error }} onClick={deleteItem} />}
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default VictimisItem;
