import styled from 'styled-components';
import { primary, white, middleGray, lightGray, darkGray, error, warn, secondary } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }

  .buttonCancelProposal {
    width: 150px;
    display: flex;
    height: 30px;
    margin-left: 90%;
    margin-top: -60px;
    background-color: ${error};
  }
`;

export const Title = styled.h1`
  font-size: ${(props) => props.fontSize || '28px'};
  color: ${(props) => props.color || primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
    width: 100%;
  }
`;

export const PolicyResume = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  /* margin-top: px; */
  /* background-color: blue; */
  width: 60%;

  h2 {
    font-size: 18px;
    margin: 0px;
    margin-bottom: 5px;
    color: ${primary};
  }

  p {
    margin: 0px;
    font-size: 14px;
    color: ${middleGray};
  }
`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  margin-top: 1%;

  p {
    margin: 0px;
  }

  .tempo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;

    @media (max-width: 1000px) {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  .lastItem {
    height: 9%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const ItemPolicy = styled.div`
  /* border: 1px solid ${lightGray}; */
  border-radius: 20px;
  width: 41%;
  padding: 20px 30px;
  margin-top: 10px;

  .imageArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .buttonArea {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10%;
    margin-top: 15%;
  }
`;
export const Edit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${darkGray};
  margin-top: 2%;

  span {
    color: ${middleGray};
  }
`;
export const Line = styled.hr`
  background-color: #eee;
  width: 100%;
  height: 1px;
  border: none;
  margin: 0;
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ModalButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const Footer = styled.div`
  display: flex;
  position: fixed;
  height: 8vh;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: #eee;
  color: white;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1000px) {
    height: 10vh;
  }

  .area-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 20vw;
    margin-right: 40px;
    height: 100%;
    align-items: center;

    @media (max-width: 1000px) {
      width: 100vw;
      margin: 0;
      justify-content: space-around;
    }
  }
`;

export const InspectionImage = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  margin: 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &&:hover {
    opacity: 0.8;
  }
`;

export const PhotosArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const PhotosItem = styled.img`
  height: 100px;
  width: 100px;
  margin: 5px;
  border-radius: 10px;
  object-fit: cover;
  border: ${(props) => props.status === 'approved' && `3px solid #2ecc71`};
  border: ${(props) => props.status === 'rejected' && `3px solid #e74c3c`};
  transition: all ease-in-out 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const DiscountButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: ${(props) => (props.outlined ? `1px solid ${secondary}` : 'none')};
  outline: none;
  cursor: pointer;
  margin: 1rem;
  transition: all ease-in-out 0.3s;
  font-size: ${(props) => props.fontSize || '15px'};
  border-radius: 50px;
  height: ${(props) => props.height || '45px'};
  width: 3rem;
  color: ${darkGray};

  &&:hover {
    opacity: 0.7;
    color: ${darkGray};
  }
  &&:disabled {
    opacity: 0.7;
    cursor: default;
    color: ${darkGray};
  }

  @media (max-width: 1000px) {
    width: ${(props) => props.mobileWidth || '100%'};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  // above 700px
  @media (max-width: 700px) {
    width: 70%;
  }
  button.reprove {
    background: ${error};
  }
  button.complementary-inspection {
    background: ${warn};
  }
`;
