import styled from 'styled-components';
import { primary, white, middleGray, lightGray, lighterGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 30px;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }
`;

export const PaymentArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 80%;
    align-items: center;
  }

  .text {
    margin: 2px 0px !important;
    font-size: 14px !important;
    color: ${middleGray};
  }

  .detail {
    padding-left: 5px;
    text-transform: uppercase;
    font-size: 10px !important;
    opacity: 0.7;
  }

  .descriptionArea {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-right: 20px;
    border: 1px solid ${lightGray};
    border-radius: 20px;
    padding: 20px 30px;

    @media (max-width: 1000px) {
      width: 100%;
      margin: 10px 0px;
    }

    h3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${primary};
    }

    p {
      margin: 5px 0px;
      font-size: 14px;
    }
  }

  .paymentInfoArea {
    display: flex;
    flex-direction: column;
    width: 75%;
    border: 1px solid ${lightGray};
    border-radius: 20px;
    padding: 20px 30px;

    @media (max-width: 1000px) {
      width: 100%;
      margin: 10px 0px;
    }

    h3 {
      color: ${primary};
    }

    .text {
      margin: 3px 0px;
      font-size: 14px;
    }

    .paymentMethods {
      width: 50%;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }

  .cardArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  }

  .creditCardArea {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 95%;
  }

  .cardLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 15px;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }

  .cardLineBox {
    background-color: ${lighterGray};
    height: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 20px;
    margin-bottom: 3px;
    width: 100%;
    label {
      color: ${primary};
      font-size: 12px;
      cursor: pointer;
    }
  }

  .radioOption {
    margin-right: 15px;
  }

  .pixArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 170px;
      width: 170px;
      margin: 10px 0px;
    }
    h3 {
      margin-bottom: 0px;
    }
    small {
      font-size: 12px;
      text-align: center;
      width: 70%;
      color: ${middleGray};
      margin-bottom: 20px;
    }
    @media (max-width: 1000px) {
      padding: 0px;
    }
  }

  .debitCardArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 90px;
    }
    h3 {
      margin-bottom: 20px;
      margin-top: 0px;
    }
    small {
      font-size: 13px;
      text-align: center;
      width: 70%;
      color: ${middleGray};
      margin-bottom: 20px;
    }
  }

  .billetArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 90px;
      margin-bottom: 0px;
    }
    h3 {
      margin-top: 10px;
    }
  }

  .loadingArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      margin-top: 20px;
      font-size: 16px;
      color: ${middleGray};
      font-weight: 400;
      max-width: 80%;
      text-align: center;
    }
  }

  .cardBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-left: 30px;
    height: 100%;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      margin-bottom: 30px;
    }
  }

  .cardForm {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .checkboxArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 30px 0px;
    font-size: 14px;
    color: ${primary};
    font-size: 12px;
  }

  .buttonArea {
    display: flex;
    flex-direction: row;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }

  .flagIcon {
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }

  .resumeCardLine {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .payLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    h3 {
      margin: 20px 0px 0px;
    }
    p {
      margin: 10px 0px 0px;
      font-size: 14px;
      color: ${middleGray};
    }
  }

  .payStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 50px 0px;
    h3 {
      margin: 20px 0px 0px;
    }
    p {
      margin: 10px 0px 0px;
      font-size: 14px;
      color: ${middleGray};
    }
  }
`;

export const QrcodePix = styled.iframe`
  height: 170px;
  width: 170px;
  border: none;
  margin: 40px;
  background-size: cover;
`;
