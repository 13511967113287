/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';
import api from 'api';
import Cookies from 'js-cookie';
import { Header, ObjectPolicy, Dialog, Input, Button } from 'components';
import { Container, ContentArea, Title, ListPolicy, LoadingArea, EmptyArea, ModalButton } from './styles';
import { getGreeting } from 'utils/greeting';
import { useStoreActions, useStoreState } from 'easy-peasy';
import emptyAnimation from 'assets/empty.json';
import { loadingColor, primary } from 'styles/colorProvider';
import { toast } from 'react-toastify';

function MyPolicies() {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [user, setUser] = [useStoreState(({ user }) => user.user), useStoreActions(({ user }) => user.setUser)];
  const [open, setOpen] = useState([user?.change_password === null ? false : true]);

  const loadUser = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/me`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setUser(response.data);
    setLoading(false);
  };

  const loadData = async () => {
    setLoading(true);
    const responsePolicies = await api({
      method: 'GET',
      url: `/me/all-contracts`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setPolicies(responsePolicies.data.policies);
    setProposals(responsePolicies.data.proposals);
    setLoading(false);
  };

  const handleSubmitPassword = async () => {
    setLoadingSubmit(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/alter-password/${user.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          oldPassword,
          newPassword,
        },
        json: true,
      });
      setLoadingSubmit(false);
      setOpen(false);
      toast.success('Senha alterada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
      loadUser();
    } catch (error) {
      toast.error('Senha provisória errada', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingSubmit(false);
    }
  };

  useEffect(() => !user && loadUser(), [user]);
  useEffect(() => user && loadData(), [user]);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          {loading && (
            <LoadingArea>
              <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
            </LoadingArea>
          )}

          {!loading && user?.change_password !== null && (
            <Dialog open={open}>
              <h3 style={{ color: `${primary}`, fontSize: '22px', margin: '0' }}>Altere sua senha</h3>
              <p style={{ color: 'black', fontSize: '16px', margin: '10px 0', fontWeight: 500 }}>
                Por motivos de segurança, no seu primeiro acesso será necessário trocar a sua senha provisória.
              </p>
              <Input
                label="Senha provisória"
                placeholder="Digite sua senha provisória"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                password
                type="password"
              />
              <Input
                label="Nova senha"
                placeholder="Digite sua senha definitiva"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                password
                type="password"
              />
              <ModalButton>
                <Button
                  style={{ height: '35px', width: '140px' }}
                  onClick={handleSubmitPassword}
                  disabled={loadingSubmit || !newPassword || !oldPassword}
                >
                  {loadingSubmit ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Confirmar'}
                </Button>
              </ModalButton>
            </Dialog>
          )}

          {!loading && user?.change_password === null && (
            <>
              <Title style={{ marginBottom: 30 }}>
                {getGreeting()}, {user?.name?.split(' ')[0]}!
              </Title>

              {!policies.length && !proposals.length && <p>Nenhuma apólice encontrada.</p>}

              {policies.length >= 1 && (
                <>
                  <Title style={{ fontSize: 20 }}>Minhas apólices</Title>
                  <ListPolicy>
                    {policies?.map((policy) => (
                      <ObjectPolicy category="policy" data={policy} />
                    ))}
                  </ListPolicy>
                </>
              )}

              {proposals.length >= 1 && (
                <>
                  <Title style={{ fontSize: 20 }}>Aguardando ativação</Title>
                  <ListPolicy>
                    {proposals?.map((proposal) => {
                      const isAwaitingActivation = proposal?.status != 'awaiting_activation';
                      return <ObjectPolicy category="proposal" data={proposal} clickable={isAwaitingActivation} />;
                    })}
                  </ListPolicy>
                </>
              )}

              {!proposals && !policies && (
                <EmptyArea>
                  <Lottie
                    autoplay
                    style={{ marginTop: 50, opacity: 0.6 }}
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: emptyAnimation,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={140}
                    width={240}
                  />
                  <p>Nenhuma apólice encontrada.</p>
                </EmptyArea>
              )}
            </>
          )}
        </ContentArea>
      </Container>
    </>
  );
}

export default MyPolicies;
