/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import ReactLoading from 'react-loading';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import api from 'api';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { saveFilesBucket } from 'utils/aws';

import { Header, Button, ClaimThirdPartItem, Dialog, Input, ClaimFileItem } from 'components';
import {
  Container,
  ContentArea,
  ClaimInformationArea,
  Title,
  ClaimOptionsArea,
  ClaimOption,
  StepArea,
  LoadingArea,
  DivPicker,
  Div,
} from './styles';
import { middleGray, loadingColor, success } from 'styles/colorProvider';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { BsShieldCheck } from 'react-icons/bs';
import { formatPlate } from 'react-data-formatter';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import 'moment/locale/pt-br';

import incendioImg from 'assets/incendio.png';
import rouboFurtoImg from 'assets/roubo-furto.png';
import fenomenosNaturaisImg from 'assets/fenomenos-naturais.png';
import colisaoImg from 'assets/colisao.png';
import vidrosImg from 'assets/vidros.png';
import glasses1 from 'assets/glasses1.png';
import glasses2 from 'assets/glasses2.png';
import glasses3 from 'assets/glasses3.png';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

function NewClaim({ history, location }) {
  const fileInputRef = useRef();
  const policyId = parseInt(location.pathname.split('/novo-sinistro/')[1]);
  const [productData, setProductData] = useState([{}]);
  const [coverageData, setCoverageData] = useState([{}]);

  const [time, setTime] = useState(new Date());
  const [date, setDate] = useState(new Date());

  const [loading, setLoading] = useState();
  const [policies, setPolicies] = useState();

  const [isUserPresent, setIsUserPresent] = useState();

  const [hasIncidentReport, setHasIncidentReport] = useState();
  const [incidentReportFile, setIncidentReportFile] = useState([]);

  const [claimCoverage, setClaimCoverage] = useState();
  const [claimDescription, setClaimDescription] = useState();

  const [claimHasExtraFile, setClaimHasExtraFile] = useState(false);
  const [claimExtraFile, setClaimExtraFile] = useState([]);

  const [claimHasThirdPart, setHasClaimThirdPart] = useState();
  const [claimThirdPart, setClaimThirdPart] = useState([]);

  const [clainHasVictims, setClaimHasVictims] = useState();
  const [claimVictims, setClaimVictims] = useState([]);

  const [showAddThirdPart, setShowAddThirdPart] = useState(false);
  const [showAddVictim, setShowAddVictim] = useState(false);
  const [showSelectPolicy, setShowSelectPolicy] = useState(false);

  const [currentThirdPart, setCurrentThirdPart] = useState();
  const [currentVictim, setCurrentVictim] = useState();

  const [glassDamages, setGlassDamages] = useState([]);
  const [glassPhotos, setGlassPhotos] = useState([]);

  const [causeLoss, setCauseLoss] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [idCauseLoss, setIdCauseLoss] = useState();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [protocolResult, setProtocolResult] = useState();

  const [claimOwner, setClaimOwner] = useState(null);

  const [step, setStep] = useState(1);
  const [counter, setCounter] = useState(1);

  const loadData = async () => {
    setLoading(true);
    const responsePolicies = await api({
      method: 'GET',
      url: `/me/all-contracts`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setPolicies(responsePolicies.data.policies);
    setLoading(false);
  };

  const CauseLoss = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/cause-loss`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setCauseLoss(response.data.response);
    setLoading(false);
  };

  const Products = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setProductData(response.data?.products);
    setCoverageData(response.data?.coverages);
    setLoading(false);
  };

  const handleCreateClaim = async () => {
    setLoadingCreate(true);
    const newDate = moment(date).format('MM-DD-YYYY');
    const newTime = moment(time).format('HH:mm');
    const accidentTime = moment(`${newDate} ${newTime}`).format();

    try {
      const resp = await api({
        method: 'POST',
        url: `/create-notice`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_policy: parseInt(policyId),
          id_cause_loss: parseInt(idCauseLoss),
          incident_report: !hasIncidentReport ? false : hasIncidentReport,
          damage_third_parties: !claimHasThirdPart ? false : claimHasThirdPart,
          side_rear_windshield: glassDamages.findIndex((i) => i === 'windshield') > -1,
          rearview_mirror: glassDamages.findIndex((i) => i === 'rearview') > -1,
          headlights_lanterns: glassDamages.findIndex((i) => i === 'headlights') > -1,
          description: claimDescription,
          accident_time: accidentTime,
          third_parties: claimThirdPart,
          accident_victims: claimVictims,
          claim_owner: claimOwner === null && idCauseLoss !== 3 ? true : claimOwner,
        },
        json: true,
      });

      setLoadingCreate(false);
      setProtocolResult(resp.data?.claim?.protocol);
      handleUploadFiles(resp.data);
      setStep(step + 1);
      toast.success('Sinistro criado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingCreate(false);
    }
  };

  const handleUploadFiles = async (resp) => {
    incidentReportFile.map((i) => {
      i.file_type = 'incident_report';
      i.description = 'Boletim de ocorrência';
    });

    claimExtraFile.map((i) => {
      i.file_type = 'extra_files';
      i.description = 'Arquivo adicional';
    });

    glassPhotos.map((i) => {
      i.file_type = 'glass_photos';
      i.description = 'Foto de vidros';
    });
    const allFiles = incidentReportFile.concat(claimExtraFile).concat(glassPhotos);

    for (const file of allFiles) {
      const ext = file.name.replaceAll(' ', '').toLowerCase().split('.').pop();
      saveFilesBucket({
        Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
        Body: file,
        Key: `${resp?.claim?.protocol}/${file.file_type}/${new Date().getTime()}.${ext}`,
        onEnd: async (err, data) => {
          const list = [...uploadedFiles, { type_file: file.file_type, description: file.description, url: data?.file }];
          setUploadedFiles(list);

          await api({
            method: 'POST',
            url: `/send-file-claim`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('splitriskwl-authtoken'),
            },
            data: {
              id_claim: resp?.claim?.id,
              files: list,
            },
            json: true,
          });
        },
      });
    }
  };

  const goBack = () => {
    if (step === 1) history.push(`/meus-sinistros/${policyId}`);
    if (step > 1) setStep(step - 1);
  };

  const handleAddClaimThirdPart = () => {
    setHasClaimThirdPart(true);
    const oldList = claimThirdPart;
    oldList.push(currentThirdPart);
    setClaimThirdPart(oldList);
    setCurrentThirdPart();
    setShowAddThirdPart();
  };

  const handleRemoveClaimThirdPart = (index) => {
    const oldList = claimThirdPart;
    oldList.splice(index, 1);
    setClaimThirdPart(oldList);
    setCounter(counter + 1);
  };

  const handleAddClaimVictim = () => {
    setClaimHasVictims(true);
    const oldList = claimVictims;
    oldList.push(currentVictim);
    setClaimVictims(oldList);
    setCurrentVictim();
    setShowAddVictim();
  };

  const handleRemoveClaimVictim = (index) => {
    const oldList = claimVictims;
    oldList.splice(index, 1);
    setClaimVictims(oldList);
    setCounter(counter + 1);
  };

  const handleUploadIncidentReport = (e) => {
    const oldList = incidentReportFile;
    oldList.push(e.target.files[0]);
    setIncidentReportFile(oldList);
    setCounter(counter + 1);
  };

  const handleRemoveIncidentReport = (index) => {
    const oldList = incidentReportFile;
    oldList.splice(index, 1);
    setIncidentReportFile(oldList);
    setCounter(counter + 1);
  };

  const handleUploadAdditionalFile = (e) => {
    const oldList = claimExtraFile;
    oldList.push(e.target.files[0]);
    setClaimExtraFile(oldList);
    setCounter(counter + 1);
  };

  const handleRemoveAdditionalFile = (index) => {
    const oldList = claimExtraFile;
    oldList.splice(index, 1);
    setClaimExtraFile(oldList);
    setCounter(counter + 1);
  };

  const handleUploadGlassPhotos = (e) => {
    const oldList = glassPhotos;
    oldList.push(e.target.files[0]);
    setGlassPhotos(oldList);
    setCounter(counter + 1);
  };

  const handleRemoveGlassPhotos = (index) => {
    const oldList = glassPhotos;
    oldList.splice(index, 1);
    setGlassPhotos(oldList);
    setCounter(counter + 1);
  };

  const toogleGlassDamages = (option) => {
    const oldList = glassDamages;
    const hasDamage = oldList.findIndex((i) => i === option) > -1;

    if (hasDamage) {
      const index = oldList.findIndex((i) => i === option);
      oldList.splice(index, 1);
      setGlassDamages(oldList);
      setCounter(counter + 1);
    } else {
      oldList.push(option);
      setGlassDamages(oldList);
      setCounter(counter + 1);
    }
  };

  const renderAddThirdPart = () => (
    <Dialog open={showAddThirdPart} title="Adicionar veículo envolvido" width="450px" onClose={() => setShowAddThirdPart(false)}>
      <Input
        value={currentThirdPart?.name}
        onChange={(e) => setCurrentThirdPart({ ...currentThirdPart, name: e.target.value.toUpperCase() })}
        label="Nome do condutor"
        marginRight="10px"
      />
      <Input
        value={currentThirdPart?.phone}
        onChange={(e) => setCurrentThirdPart({ ...currentThirdPart, phone: e.target.value })}
        label="Telefone do condutor"
        mask="(99) 99999-9999"
      />
      <Input
        value={currentThirdPart?.plate}
        onChange={(e) => setCurrentThirdPart({ ...currentThirdPart, plate: e.target.value.toUpperCase() })}
        label="Placa do veículo"
        mask="aaa-9*99"
        disabled={currentThirdPart?.plate === null}
      />
      <Alert style={{ marginTop: 20, marginBottom: 20 }} severity="warning">
        Atenção! Caso o terceiro não possuí <b>placa</b>, você enviará somente o campo de <b>placa</b> não preenchido.
      </Alert>

      <Button
        disabled={!currentThirdPart?.name || !currentThirdPart?.phone || currentThirdPart?.phone.length < 15}
        onClick={handleAddClaimThirdPart}
        style={{ margin: 0, width: 310, marginTop: 20 }}
      >
        Adicionar veículo envolvido
      </Button>
    </Dialog>
  );

  const renderAddVictim = () => (
    <Dialog open={showAddVictim} title="Adicionar vítima" width="450px" onClose={() => setShowAddVictim(false)}>
      <Input
        value={currentVictim?.name}
        onChange={(e) => setCurrentVictim({ ...currentVictim, name: e.target.value.toUpperCase() })}
        label="Nome da vítima"
        marginRight="10px"
      />
      <Input
        value={currentVictim?.cpf}
        onChange={(e) => setCurrentVictim({ ...currentVictim, cpf: e.target.value.toUpperCase() })}
        label="CPF da vítima"
        marginRight="10px"
        mask="999.999.999-99"
      />
      <Input
        value={currentVictim?.phone}
        onChange={(e) => setCurrentVictim({ ...currentVictim, phone: e.target.value })}
        label="Telefone da vítima"
        mask="(99) 99999-9999"
      />

      <Button
        disabled={!currentVictim?.name || !currentVictim?.cpf || !currentVictim?.phone || currentVictim?.phone.length < 15}
        onClick={handleAddClaimVictim}
        style={{ margin: 0, width: 310, marginTop: 20 }}
      >
        Adicionar vítima
      </Button>
    </Dialog>
  );

  const renderSelectPolicy = () => (
    <Dialog open={showSelectPolicy} title="Selecionar apólice" width="800px">
      <p style={{ margin: 0, marginTop: -10, marginBottom: 20, color: middleGray }}>
        Para iniciar, por favor, selecione uma apólice:
      </p>
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <>
          <Div>
            {policies?.map((policy) => (
              <ClaimOption
                onClick={() => {
                  setShowSelectPolicy(false);
                }}
              >
                <p style={{ margin: 0, color: success, textTransform: 'uppercase' }}>
                  <BsShieldCheck size={20} style={{ marginRight: 5 }} />
                </p>
                <p style={{ margin: 0, fontSize: 10, marginBottom: 10 }}>{policy?.policy_number}</p>
                <p style={{ margin: 0, fontSize: 13 }}>
                  {policy?.vehicle?.brand} {policy?.vehicle?.model?.split(' ')[0]}
                </p>
                <p style={{ margin: 0, fontSize: 12 }}>{formatPlate(policy?.vehicle?.plate)}</p>
              </ClaimOption>
            ))}
          </Div>
        </>
      )}
    </Dialog>
  );

  useEffect(() => {
    if (!policyId) {
      setShowSelectPolicy(true);
      loadData();
    }
    CauseLoss();
    Products();
  }, []);

  return (
    <>
      {renderAddThirdPart()}
      {renderAddVictim()}
      {renderSelectPolicy()}
      <Header />
      <Container>
        <ContentArea>
          <ClaimInformationArea>
            {step === 1 && (
              <>
                <Title style={{ textAlign: 'center' }}>
                  😕 Que pena que você teve um problema com o seu veículo. <br />
                  <span className="subtitle">Fique tranquilo, pois estamos aqui para te ajudar!</span>
                </Title>

                <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 20, textAlign: 'center' }}>
                  Por favor, nos conte o que aconteceu com o seu veículo...
                </h3>
                <ClaimOptionsArea>
                  {productData?.find((products) => products.name === 'Roubo' || products.name === 'Furto') ||
                    (coverageData.find((products) => products.name === 'Roubo' || products.name === 'Furto') &&
                      causeLoss?.findIndex((i) => i.id === 2 || i.id === 2) > -1 && (
                        <ClaimOption
                          minHeight="190px"
                          onClick={() => setClaimCoverage('theft')}
                          selected={claimCoverage === 'theft'}
                        >
                          <img src={rouboFurtoImg} alt="Roubo ou furto" />
                          Roubo ou furto
                        </ClaimOption>
                      ))}

                  {productData?.find((products) => products.name === 'Colisão' || products.name === 'Danos a terceiros') ||
                    (coverageData.find((products) => products.name === 'Colisão' || products.name === 'Danos a terceiros') &&
                      causeLoss?.findIndex((i) => i.id === 3) > -1 && (
                        <ClaimOption
                          minHeight="190px"
                          onClick={() => {
                            setClaimCoverage('collision');
                            setIdCauseLoss(3);
                          }}
                          selected={claimCoverage === 'collision'}
                        >
                          <img src={colisaoImg} alt="Colisão" />
                          Colisão
                        </ClaimOption>
                      ))}
                  {productData?.find((products) => products.name === 'Incêndio') ||
                    (coverageData.find((products) => products.name === 'Incêndio') &&
                      causeLoss?.findIndex((i) => i.id === 3) > -1 &&
                      causeLoss?.findIndex((i) => i.id === 4) > -1 && (
                        <ClaimOption
                          minHeight="190px"
                          onClick={() => {
                            setClaimCoverage('fire');
                            setIdCauseLoss(4);
                          }}
                          selected={claimCoverage === 'fire'}
                        >
                          <img src={incendioImg} alt="Incêndio" />
                          Incêndio
                        </ClaimOption>
                      ))}
                  {productData?.find((products) => products.name === 'Fenômenos naturais') ||
                    (coverageData?.find((products) => products.name === 'Fenômenos naturais') &&
                      causeLoss?.findIndex((i) => i.id === 5) > -1 && (
                        <ClaimOption
                          minHeight="190px"
                          onClick={() => {
                            setClaimCoverage('nature');
                            setIdCauseLoss(5);
                          }}
                          selected={claimCoverage === 'nature'}
                        >
                          <img src={fenomenosNaturaisImg} alt="Fenômenos naturais" />
                          Fenômenos naturais
                        </ClaimOption>
                      ))}
                  {productData?.find((products) => products.name === 'Faróis, lanternas e vidros') ||
                    (productData?.find((products) => products.name === 'Faróis, lanternas e vidros') &&
                      causeLoss?.findIndex((i) => i.id === 6) > -1 && (
                        <ClaimOption
                          minHeight="190px"
                          onClick={() => {
                            setClaimCoverage('glasses');
                            setIdCauseLoss(6);
                          }}
                          selected={claimCoverage === 'glasses'}
                        >
                          <img src={vidrosImg} alt="Vidros" />
                          Faróis, vidros ou retrovisores
                        </ClaimOption>
                      ))}
                </ClaimOptionsArea>
                {claimCoverage && (
                  <Button onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                    Continuar
                  </Button>
                )}

                <p className="go-back" onClick={goBack} style={{ marginTop: 20 }}>
                  Voltar
                </p>
              </>
            )}

            {/* ROUBO E FURTO */}

            {step === 2 && claimCoverage === 'theft' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={rouboFurtoImg} alt="Roubo ou furto" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>📍 Você presenciou o momento em que o veículo foi roubado/furtado?</Title>
                    <div className="options-area">
                      <ClaimOption
                        selected={isUserPresent === true}
                        onClick={() => {
                          setIsUserPresent(true);
                          setIdCauseLoss(1);
                        }}
                      >
                        Sim
                      </ClaimOption>
                      <ClaimOption
                        selected={isUserPresent === false}
                        onClick={() => {
                          setIsUserPresent(false);
                          setIdCauseLoss(2);
                        }}
                      >
                        Não
                      </ClaimOption>
                    </div>

                    {isUserPresent !== undefined && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 10 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 3 && claimCoverage === 'theft' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={rouboFurtoImg} alt="Roubo ou furto" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>📋 Você já registrou o boletim de ocorrência comunicando o roubo ou furto do veículo?</Title>
                    <div className="options-area">
                      <ClaimOption selected={hasIncidentReport === true} onClick={() => setHasIncidentReport(true)}>
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={hasIncidentReport === false} onClick={() => setHasIncidentReport(false)}>
                        Não
                      </ClaimOption>
                    </div>

                    {hasIncidentReport === false && (
                      <Alert style={{ marginTop: 20, marginBottom: 20 }} severity="error">
                        Atenção! O boletim de ocorrência é um <b>documento essencial</b> para a análise do seu Sinistro. Você pode
                        enviar este documento depois, mas precisaremos dele para iniciar a análise do seu caso.
                      </Alert>
                    )}

                    {hasIncidentReport === true && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, nos envie uma cópia do boletim de ocorrência que você registrou:
                        </h3>
                        <label
                          style={{ marginBottom: 30 }}
                          className="new-third-button"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                        </label>
                        <input
                          type="file"
                          accept="image/*, application/pdf"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={(e) => handleUploadIncidentReport(e)}
                        />
                      </>
                    )}

                    <div>
                      {incidentReportFile?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveIncidentReport(index)} />
                      ))}
                    </div>

                    {(hasIncidentReport === false || incidentReportFile.length >= 1) && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 10 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 4 && claimCoverage === 'theft' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={rouboFurtoImg} alt="Roubo ou furto" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🗓️ Por favor, nos informe a data e o horário do acontecimento</Title>

                    <DivPicker>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={date}
                          onChange={setDate}
                          maxDate={new Date()}
                          minDate={moment().subtract(1, 'months')}
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          invalidDateMessage="Data inválida"
                        />
                        <TimePicker
                          value={time}
                          onChange={setTime}
                          ampm={false}
                          inputVariant="outlined"
                          invalidLabel="Data ou horário inválida"
                        />
                      </MuiPickersUtilsProvider>
                    </DivPicker>

                    <Button disabled={!date} onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                      Continuar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 5 && claimCoverage === 'theft' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={rouboFurtoImg} alt="Roubo ou furto" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>💬 Por favor, nos conte detalhadamente o que aconteceu...</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Dica: quanto mais detalhes você informar nesta etapa, mais rápido conseguiremos analisar o seu caso.
                    </h3>
                    <TextareaAutosize
                      minRows={1}
                      onChange={(e) => setClaimDescription(e.target.value)}
                      value={claimDescription}
                      placeholder="Descreva o que aconteceu..."
                      style={{
                        padding: 10,
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        marginTop: '3%',
                        width: '100%',
                        height: '100px',
                        borderRadius: 5,
                        borderColor: '#d1d2d4',
                        outline: 'none',
                      }}
                    />
                    <Button disabled={!claimDescription} onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                      Continuar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 6 && claimCoverage === 'theft' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={rouboFurtoImg} alt="Roubo ou furto" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🏷️ Você gostaria de anexar mais alguma foto ou documento para auxiliar na análise do seu caso?</Title>
                    <div className="options-area">
                      <ClaimOption selected={claimHasExtraFile === 'yes'} onClick={() => setClaimHasExtraFile('yes')}>
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={claimHasExtraFile === 'no'} onClick={() => setClaimHasExtraFile('no')}>
                        Não
                      </ClaimOption>
                    </div>

                    {claimHasExtraFile === 'yes' && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, envie os arquivos que você gostaria de anexar:
                        </h3>
                        <label
                          style={{ marginBottom: 30 }}
                          className="new-third-button"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                        </label>
                        <input
                          type="file"
                          accept="image/*, application/pdf"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={(e) => handleUploadAdditionalFile(e)}
                        />
                      </>
                    )}

                    <div>
                      {claimExtraFile?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveAdditionalFile(index)} />
                      ))}
                    </div>

                    {(claimHasExtraFile === 'no' || claimExtraFile.length >= 1) && (
                      <Button onClick={handleCreateClaim} style={{ margin: 0, marginTop: 30 }}>
                        {loadingCreate ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Continuar'}
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 7 && claimCoverage === 'theft' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={rouboFurtoImg} alt="Roubo ou furto" />
                  </div>
                  <div className="right">
                    <Title>✅ Por enquanto já temos tudo que precisamos para analisar o seu caso!</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Protocolo do sinistro: {protocolResult}
                    </h3>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 0 }}>
                      Fique tranquilo, iremos entrar em contato para informar sobre qualquer atualização. <br /> Você também pode
                      consultar o andamento deste sinistro em <em>Detalhes da Apólice {'>'} Sinistros</em>.
                    </h3>

                    <Button onClick={() => history.push(`/meus-sinistros/${policyId}`)} style={{ margin: 0, marginTop: 30 }}>
                      Finalizar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {/* COLISÃO */}
            {step === 2 && claimCoverage === 'collision' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={colisaoImg} alt="Colisão" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🗯️ Deseja abrir sinistro para o seu veículo ou apenas para veículos de terceiros?</Title>
                    <div className="options-area">
                      <ClaimOption selected={claimOwner === true} onClick={() => setClaimOwner(true)}>
                        Incluir meu veículo
                      </ClaimOption>
                      <ClaimOption selected={claimOwner === false} onClick={() => setClaimOwner(false)}>
                        Apenas terceiros
                      </ClaimOption>
                    </div>
                    {claimOwner !== null && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 25 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 3 && claimCoverage === 'collision' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={colisaoImg} alt="Colisão" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🗓️ Por favor, nos informe a data e o horário do acontecimento</Title>
                    <DivPicker>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={date}
                          onChange={setDate}
                          maxDate={new Date()}
                          minDate={moment().subtract(1, 'months')}
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          invalidDateMessage="Data inválida"
                        />
                        <TimePicker
                          value={time}
                          onChange={setTime}
                          ampm={false}
                          inputVariant="outlined"
                          invalidLabel="Data ou horário inválida"
                        />
                      </MuiPickersUtilsProvider>
                    </DivPicker>

                    <Button disabled={!date} onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                      Continuar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 4 && claimCoverage === 'collision' && (
              <>
                {claimOwner ? (
                  <StepArea>
                    <div className="left">
                      <img src={colisaoImg} alt="Colisão" />
                      <p className="go-back" onClick={goBack}>
                        Etapa anterior
                      </p>
                    </div>
                    <div className="right">
                      <Title>🚘 Além do seu veículo, houve mais algum veículo envolvido nesta colisão?</Title>
                      <div className="options-area">
                        <ClaimOption
                          selected={claimHasThirdPart === true}
                          onClick={() => {
                            if (claimThirdPart.length) {
                              setHasClaimThirdPart(true);
                            } else {
                              setShowAddThirdPart(true);
                            }
                          }}
                        >
                          Sim
                        </ClaimOption>
                        <ClaimOption selected={claimHasThirdPart === false} onClick={() => setHasClaimThirdPart(false)}>
                          Não
                        </ClaimOption>
                      </div>

                      {claimHasThirdPart === true && (
                        <>
                          <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                            Por favor, nos informe os veículos envolvidos na colisão:
                          </h3>
                          <div className="input-area">
                            {claimThirdPart?.map((part, index) => (
                              <ClaimThirdPartItem key={index} data={part} onClick={() => handleRemoveClaimThirdPart(index)} />
                            ))}
                          </div>

                          <label className="new-third-button" onClick={() => setShowAddThirdPart(true)}>
                            <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Adicionar veículo envolvido
                          </label>
                        </>
                      )}

                      {(claimHasThirdPart === false || claimThirdPart.length >= 1) && (
                        <Button onClick={() => setStep(step + 1)} style={{ marginTop: 25 }}>
                          Continuar
                        </Button>
                      )}
                    </div>
                  </StepArea>
                ) : (
                  <StepArea>
                    <div className="left">
                      <img src={colisaoImg} alt="Colisão" />
                      <p className="go-back" onClick={goBack}>
                        Etapa anterior
                      </p>
                    </div>
                    <div className="right">
                      <Title>🚘 Veículo envolvido nesta colisão</Title>
                      <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                        Por favor, nos informe os veículos envolvidos na colisão:
                      </h3>

                      <div className="input-area">
                        {claimThirdPart?.map((part, index) => (
                          <ClaimThirdPartItem key={index} data={part} onClick={() => handleRemoveClaimThirdPart(index)} />
                        ))}
                      </div>

                      <label className="new-third-button" onClick={() => setShowAddThirdPart(true)}>
                        <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Adicionar veículo envolvido
                      </label>

                      {claimThirdPart.length >= 1 && (
                        <Button onClick={() => setStep(step + 1)} style={{ marginTop: 25 }}>
                          Continuar
                        </Button>
                      )}
                    </div>
                  </StepArea>
                )}
              </>
            )}

            {step === 5 && claimCoverage === 'collision' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={colisaoImg} alt="Colisão" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🚑 Houve vítimas nesta colisão?</Title>
                    <div className="options-area">
                      <ClaimOption
                        selected={clainHasVictims === true}
                        onClick={() => {
                          if (!claimVictims?.length) {
                            setShowAddVictim(true);
                          } else {
                            setClaimHasVictims(true);
                          }
                        }}
                      >
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={clainHasVictims === false} onClick={() => setClaimHasVictims(false)}>
                        Não
                      </ClaimOption>
                    </div>

                    {clainHasVictims === true && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, nos informe as vítimas da colisão:
                        </h3>
                        <div className="input-area">
                          {claimVictims?.map((part, index) => (
                            <ClaimThirdPartItem victim key={index} data={part} onClick={() => handleRemoveClaimVictim(index)} />
                          ))}
                        </div>

                        <label className="new-third-button" onClick={() => setShowAddVictim(true)}>
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Adicionar vítima
                        </label>
                      </>
                    )}

                    {(clainHasVictims === false || claimVictims?.length >= 1) && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 25 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 6 && claimCoverage === 'collision' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={colisaoImg} alt="Colisão" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>💬 Por favor, nos conte detalhadamente o que aconteceu...</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Dica: quanto mais detalhes você informar nesta etapa, mais rápido conseguiremos analisar o seu caso.
                    </h3>
                    <TextareaAutosize
                      minRows={1}
                      onChange={(e) => setClaimDescription(e.target.value)}
                      value={claimDescription}
                      placeholder="Descreva o que aconteceu..."
                      style={{
                        padding: 10,
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        marginTop: '3%',
                        width: '100%',
                        height: '100px',
                        borderRadius: 5,
                        borderColor: '#d1d2d4',
                        outline: 'none',
                      }}
                    />
                    <Button disabled={!claimDescription} onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                      Continuar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 7 && claimCoverage === 'collision' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={colisaoImg} alt="Colisão" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>📋 Você já registrou o boletim de ocorrência comunicando essa colisão?</Title>
                    <div className="options-area">
                      <ClaimOption selected={hasIncidentReport === true} onClick={() => setHasIncidentReport(true)}>
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={hasIncidentReport === false} onClick={() => setHasIncidentReport(false)}>
                        Não
                      </ClaimOption>
                    </div>

                    {hasIncidentReport === false && (
                      <Alert style={{ marginTop: 20 }} severity="error">
                        Atenção! O boletim de ocorrência é um <b>documento essencial</b> para a análise do seu Sinistro. Você pode
                        enviar este documento depois, mas precisaremos dele para iniciar a análise do seu caso.
                      </Alert>
                    )}

                    {hasIncidentReport === true && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, nos envie uma cópia do boletim de ocorrência que você registrou:
                        </h3>
                        <label
                          style={{ marginBottom: 30 }}
                          className="new-third-button"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                        </label>
                        <input
                          type="file"
                          accept="image/*, application/pdf"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={(e) => handleUploadIncidentReport(e)}
                        />
                      </>
                    )}

                    <div>
                      {incidentReportFile?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveIncidentReport(index)} />
                      ))}
                    </div>

                    {(hasIncidentReport === false || incidentReportFile.length >= 1) && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 8 && claimCoverage === 'collision' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={colisaoImg} alt="Colisão" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🏷️ Você gostaria de anexar mais alguma foto ou documento para auxiliar na análise do seu caso?</Title>
                    <div className="options-area">
                      <ClaimOption selected={claimHasExtraFile === 'yes'} onClick={() => setClaimHasExtraFile('yes')}>
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={claimHasExtraFile === 'no'} onClick={() => setClaimHasExtraFile('no')}>
                        Não
                      </ClaimOption>
                    </div>

                    {claimHasExtraFile === 'yes' && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, envie os arquivos que você gostaria de anexar:
                        </h3>
                        <label
                          style={{ marginBottom: 30 }}
                          className="new-third-button"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                        </label>
                        <input
                          type="file"
                          accept="image/*, application/pdf"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={(e) => handleUploadAdditionalFile(e)}
                        />
                      </>
                    )}

                    <div>
                      {claimExtraFile?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveAdditionalFile(index)} />
                      ))}
                    </div>

                    {(claimHasExtraFile === 'no' || claimExtraFile.length >= 1) && (
                      <Button onClick={handleCreateClaim} style={{ margin: 0, marginTop: 30 }}>
                        {loadingCreate ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Continuar'}
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 9 && claimCoverage === 'collision' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={colisaoImg} alt="Colisão" />
                  </div>
                  <div className="right">
                    <Title>✅ Por enquanto já temos tudo que precisamos para analisar o seu caso!</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Protocolo do sinistro: {protocolResult}
                    </h3>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 0 }}>
                      Fique tranquilo, iremos entrar em contato para informar sobre qualquer atualização. <br /> Você também pode
                      consultar o andamento deste sinistro em <em>Detalhes da Apólice {'>'} Sinistros</em>.
                    </h3>

                    <Button onClick={() => history.push(`/meus-sinistros/${policyId}`)} style={{ margin: 0, marginTop: 30 }}>
                      Finalizar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {/* INCENDIO OU FENOMENOS NATURAIS */}

            {step === 2 && (claimCoverage === 'fire' || claimCoverage === 'nature') && (
              <>
                <StepArea>
                  <div className="left">
                    <img
                      src={claimCoverage === 'fire' ? incendioImg : fenomenosNaturaisImg}
                      alt={claimCoverage === 'fire' ? 'Incêndio' : 'Fenômenos naturais'}
                    />{' '}
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🗓️ Por favor, nos informe a data e o horário do acontecimento</Title>
                    <DivPicker>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={date}
                          onChange={setDate}
                          maxDate={new Date()}
                          minDate={moment().subtract(1, 'months')}
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          invalidDateMessage="Data inválida"
                        />
                        <TimePicker
                          value={time}
                          onChange={setTime}
                          ampm={false}
                          inputVariant="outlined"
                          invalidLabel="Data ou horário inválida"
                        />
                      </MuiPickersUtilsProvider>
                    </DivPicker>

                    <Button disabled={!date} onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                      Continuar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 3 && (claimCoverage === 'fire' || claimCoverage === 'nature') && (
              <>
                <StepArea>
                  <div className="left">
                    <img
                      src={claimCoverage === 'fire' ? incendioImg : fenomenosNaturaisImg}
                      alt={claimCoverage === 'fire' ? 'Incêndio' : 'Fenômenos naturais'}
                    />{' '}
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>📋 Você já registrou o boletim de ocorrência comunicando essa colisão?</Title>
                    <div className="options-area">
                      <ClaimOption selected={hasIncidentReport === true} onClick={() => setHasIncidentReport(true)}>
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={hasIncidentReport === false} onClick={() => setHasIncidentReport(false)}>
                        Não
                      </ClaimOption>
                    </div>

                    {hasIncidentReport === false && (
                      <Alert style={{ marginTop: 20 }} severity="error">
                        Atenção! O boletim de ocorrência é um <b>documento essencial</b> para a análise do seu Sinistro. Você pode
                        enviar este documento depois, mas precisaremos dele para iniciar a análise do seu caso.
                      </Alert>
                    )}

                    {hasIncidentReport === true && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, nos envie uma cópia do boletim de ocorrência que você registrou:
                        </h3>
                        <label
                          style={{ marginBottom: 30 }}
                          className="new-third-button"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                        </label>
                        <input
                          type="file"
                          accept="image/*, application/pdf"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={(e) => handleUploadIncidentReport(e)}
                        />
                      </>
                    )}

                    <div>
                      {incidentReportFile?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveIncidentReport(index)} />
                      ))}
                    </div>

                    {(hasIncidentReport === false || incidentReportFile.length >= 1) && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 4 && (claimCoverage === 'fire' || claimCoverage === 'nature') && (
              <>
                <StepArea>
                  <div className="left">
                    <img
                      src={claimCoverage === 'fire' ? incendioImg : fenomenosNaturaisImg}
                      alt={claimCoverage === 'fire' ? 'Incêndio' : 'Fenômenos naturais'}
                    />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>💬 Por favor, nos conte detalhadamente o que aconteceu...</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Dica: quanto mais detalhes você informar nesta etapa, mais rápido conseguiremos analisar o seu caso.
                    </h3>
                    <TextareaAutosize
                      minRows={1}
                      onChange={(e) => setClaimDescription(e.target.value)}
                      value={claimDescription}
                      placeholder="Descreva o que aconteceu..."
                      style={{
                        padding: 10,
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        marginTop: '3%',
                        width: '100%',
                        height: '100px',
                        borderRadius: 5,
                        borderColor: '#d1d2d4',
                        outline: 'none',
                      }}
                    />
                    <Button disabled={!claimDescription} onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                      Continuar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 5 && (claimCoverage === 'fire' || claimCoverage === 'nature') && (
              <>
                <StepArea>
                  <div className="left">
                    <img
                      src={claimCoverage === 'fire' ? incendioImg : fenomenosNaturaisImg}
                      alt={claimCoverage === 'fire' ? 'Incêndio' : 'Fenômenos naturais'}
                    />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🏷️ Você gostaria de anexar alguma foto ou documento para auxiliar na análise do seu caso?</Title>
                    <div className="options-area">
                      <ClaimOption selected={claimHasExtraFile === 'yes'} onClick={() => setClaimHasExtraFile('yes')}>
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={claimHasExtraFile === 'no'} onClick={() => setClaimHasExtraFile('no')}>
                        Não
                      </ClaimOption>
                    </div>

                    {claimHasExtraFile === 'yes' && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, envie os arquivos que você gostaria de anexar:
                        </h3>
                        <label
                          style={{ marginBottom: 30 }}
                          className="new-third-button"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                        </label>
                        <input
                          type="file"
                          accept="image/*, application/pdf"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={(e) => handleUploadAdditionalFile(e)}
                        />
                      </>
                    )}

                    <div>
                      {claimExtraFile?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveAdditionalFile(index)} />
                      ))}
                    </div>

                    {(claimHasExtraFile === 'no' || claimExtraFile.length >= 1) && (
                      <Button onClick={handleCreateClaim} style={{ margin: 0, marginTop: 30 }}>
                        {loadingCreate ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Continuar'}
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 6 && (claimCoverage === 'fire' || claimCoverage === 'nature') && (
              <>
                <StepArea>
                  <div className="left">
                    <img
                      src={claimCoverage === 'fire' ? incendioImg : fenomenosNaturaisImg}
                      alt={claimCoverage === 'fire' ? 'Incêndio' : 'Fenômenos naturais'}
                    />
                  </div>
                  <div className="right">
                    <Title>✅ Por enquanto já temos tudo que precisamos para analisar o seu caso!</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Protocolo do sinistro: {protocolResult}
                    </h3>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 0 }}>
                      Fique tranquilo, iremos entrar em contato para informar sobre qualquer atualização. <br /> Você também pode
                      consultar o andamento deste sinistro em <em>Detalhes da Apólice {'>'} Sinistros</em>.
                    </h3>

                    <Button onClick={() => history.push(`/meus-sinistros/${policyId}`)} style={{ margin: 0, marginTop: 30 }}>
                      Finalizar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {/* VIDROS */}

            {step === 2 && claimCoverage === 'glasses' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={vidrosImg} alt="Vidros" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>💢 Quais vidros foram danificados?</Title>

                    <ClaimOptionsArea style={{ marginLeft: -40 }}>
                      <ClaimOption
                        minHeight="120px"
                        onClick={() => toogleGlassDamages('windshield')}
                        selected={glassDamages.findIndex((i) => i === 'windshield') > -1}
                      >
                        <img style={{ height: 40 }} src={glasses1} alt="Vidros" />
                        Vidros laterais, traseiros ou parabrisa
                      </ClaimOption>
                      <ClaimOption
                        minHeight="120px"
                        onClick={() => toogleGlassDamages('rearview')}
                        selected={glassDamages.findIndex((i) => i === 'rearview') > -1}
                      >
                        <img style={{ height: 40 }} src={glasses2} alt="Vidros" />
                        Espelhos retrovisores
                      </ClaimOption>
                      <ClaimOption
                        minHeight="120px"
                        onClick={() => toogleGlassDamages('headlights')}
                        selected={glassDamages.findIndex((i) => i === 'headlights') > -1}
                      >
                        <img style={{ height: 40 }} src={glasses3} alt="Vidros" />
                        Faróis ou lanternas
                      </ClaimOption>
                    </ClaimOptionsArea>

                    {glassDamages?.length >= 1 && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}
            {step === 3 && claimCoverage === 'glasses' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={vidrosImg} alt="Vidros" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>📷 Agora, precisamos que você nos envie as fotos dos vidros danificados.</Title>

                    <>
                      <label
                        style={{ marginBottom: 30 }}
                        className="new-third-button"
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRef.current.click();
                        }}
                      >
                        <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar foto
                      </label>
                      <input
                        type="file"
                        accept="image/*, application/pdf"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={(e) => handleUploadGlassPhotos(e)}
                      />
                    </>

                    <div>
                      {glassPhotos?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveGlassPhotos(index)} />
                      ))}
                    </div>

                    {glassPhotos.length >= 1 && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 4 && claimCoverage === 'glasses' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={vidrosImg} alt="Vidros" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>🗓️ Por favor, nos informe a data e o horário do acontecimento</Title>
                    <DivPicker>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={date}
                          onChange={setDate}
                          maxDate={new Date()}
                          minDate={moment().subtract(1, 'months')}
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          invalidDateMessage="Data inválida"
                        />
                        <TimePicker
                          value={time}
                          onChange={setTime}
                          ampm={false}
                          inputVariant="outlined"
                          invalidLabel="Data ou horário inválida"
                        />
                      </MuiPickersUtilsProvider>
                    </DivPicker>

                    <Button disabled={!date} onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                      Continuar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 5 && claimCoverage === 'glasses' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={vidrosImg} alt="Vidros" />

                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>📋 Você já registrou o boletim de ocorrência comunicando essa colisão?</Title>
                    <div className="options-area">
                      <ClaimOption selected={hasIncidentReport === true} onClick={() => setHasIncidentReport(true)}>
                        Sim
                      </ClaimOption>
                      <ClaimOption selected={hasIncidentReport === false} onClick={() => setHasIncidentReport(false)}>
                        Não
                      </ClaimOption>
                    </div>

                    {hasIncidentReport === false && (
                      <Alert style={{ marginTop: 20 }} severity="error">
                        Atenção! O boletim de ocorrência é um <b>documento essencial</b> para a análise do seu Sinistro. Você pode
                        enviar este documento depois, mas precisaremos dele para iniciar a análise do seu caso.
                      </Alert>
                    )}

                    {hasIncidentReport === true && (
                      <>
                        <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 10, textAlign: 'center' }}>
                          Por favor, nos envie uma cópia do boletim de ocorrência que você registrou:
                        </h3>
                        <label
                          style={{ marginBottom: 30 }}
                          className="new-third-button"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                        </label>
                        <input
                          type="file"
                          accept="image/*, application/pdf"
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={(e) => handleUploadIncidentReport(e)}
                        />
                      </>
                    )}

                    <div>
                      {incidentReportFile?.map((report, index) => (
                        <ClaimFileItem key={index} data={report} onClick={() => handleRemoveIncidentReport(index)} />
                      ))}
                    </div>

                    {(hasIncidentReport === false || incidentReportFile.length >= 1) && (
                      <Button onClick={() => setStep(step + 1)} style={{ marginTop: 50 }}>
                        Continuar
                      </Button>
                    )}
                  </div>
                </StepArea>
              </>
            )}

            {step === 6 && claimCoverage === 'glasses' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={vidrosImg} alt="Vidros" />
                    <p className="go-back" onClick={goBack}>
                      Etapa anterior
                    </p>
                  </div>
                  <div className="right">
                    <Title>💬 Por favor, nos conte detalhadamente o que aconteceu...</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Dica: quanto mais detalhes você informar nesta etapa, mais rápido conseguiremos analisar o seu caso.
                    </h3>
                    <TextareaAutosize
                      minRows={1}
                      onChange={(e) => setClaimDescription(e.target.value)}
                      value={claimDescription}
                      placeholder="Descreva o que aconteceu..."
                      style={{
                        padding: 10,
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        marginTop: '3%',
                        width: '100%',
                        height: '100px',
                        borderRadius: 5,
                        borderColor: '#d1d2d4',
                        outline: 'none',
                      }}
                    />
                    <Button onClick={handleCreateClaim} style={{ margin: 0, marginTop: 30 }}>
                      {loadingCreate ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Continuar'}
                    </Button>
                  </div>
                </StepArea>
              </>
            )}

            {step === 7 && claimCoverage === 'glasses' && (
              <>
                <StepArea>
                  <div className="left">
                    <img src={vidrosImg} alt="Vidros" />
                  </div>
                  <div className="right">
                    <Title>✅ Por enquanto já temos tudo que precisamos para analisar o seu caso!</Title>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 5 }}>
                      Protocolo do sinistro: {protocolResult}
                    </h3>
                    <h3 style={{ color: middleGray, fontWeight: 'normal', marginTop: 0 }}>
                      Fique tranquilo, iremos entrar em contato para informar sobre qualquer atualização. <br /> Você também pode
                      consultar o andamento deste sinistro em <em>Detalhes da Apólice {'>'} Sinistros</em>.
                    </h3>

                    <Button onClick={() => history.push(`/meus-sinistros/${policyId}`)} style={{ margin: 0, marginTop: 30 }}>
                      Finalizar
                    </Button>
                  </div>
                </StepArea>
              </>
            )}
          </ClaimInformationArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default NewClaim;
