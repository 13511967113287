import { action } from 'easy-peasy';
import withResetState from './withResetState';

const initialState = {
  backofficePermission: null,
  reloadBackofficePermission: true,
};

const store = {
  setBackofficePermission: action((state, payload) => {
    state.backofficePermission = payload;
  }),
};

export default withResetState(store, initialState);
