import styled from 'styled-components';
import { primary, secondary, middleGray, lightGray, logo } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;

  @media (max-width: 1000px) {
    flex-direction: column;
    position: relative;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 52%;
  height: 100%;
  background-size: cover;
  background-color: ${primary};

  div {
    height: 160px;
    width: 224px;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  overflow-y: scroll;

  .line {
    width: 139px;
    border-top: 1px solid ${lightGray};
    margin-top: 2%;

    @media (max-width: 1000px) {
      margin-top: 12%;
    }
  }

  @media (max-width: 1000px) {
    overflow-y: visible;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130%;

  .areaLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .logo {
    height: 36px;
    width: 209px;
    /* background-image: url(${logo}); */
    background-size: cover;
  }

  .buttonArea {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    @media (max-width: 1000px) {
      margin-top: 20px;
    }
  }

  .description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 0;

    h2 {
      font-weight: 700;
      font-size: 28px;
      color: ${middleGray};
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1%;

      @media (max-width: 1000px) {
        font-size: 23px;
      }
    }

    p {
      font-size: 14px;
      color: ${middleGray};
      margin-top: 0;
      margin-bottom: 3%;

      @media (max-width: 1000px) {
        margin-top: 10px;
      }
    }

    @media (max-width: 1000px) {
      margin-top: 10px;
      margin-bottom: 2%;
    }
  }
`;

export const BackToLogin = styled.a`
  margin-top: 5%;
  color: ${middleGray};
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10%;

  @media (max-width: 1000px) {
    margin-top: 30%;
  }
`;

export const SelectGender = styled.div`
  width: 99%;
  display: inline;

  @media (max-width: 1000px) {
    width: 104%;
  }
`;

export const Select = styled.select`
  display: flex;
  border: 1px solid ${middleGray};
  border-radius: 10px;
  align-items: center;
  color: ${middleGray};
  margin-top: 0.5%;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  font-family: 'Nunito';
  font-size: 15px;
  appearance: none;
  outline: none;

  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 9px 0px 5px 15px;
    margin-top: 1%;
    margin-bottom: 3%;
  }
`;

export const Label = styled.p`
  color: ${primary};
  font-size: 12px;
  margin-bottom: 5px;
`;

export const Option = styled.option`
  color: ${middleGray};
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
