import styled from 'styled-components';
import { black, middleGray } from 'styles/colorProvider';

export const Box = styled.div`
  width: 100%;
  margin-top: 25px;
`;

export const Text = styled.p`
  font-size: 16px;
  color: ${black};
  margin: 10px 10px 10px 20px;

  span {
    margin-top: -15px;
    color: ${middleGray};
  }
`;

export const Text1 = styled.p`
  font-size: 16px;
  color: ${middleGray};
  margin: -5px 0px 10px 25px;
`;
