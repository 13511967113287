import styled from 'styled-components';
import { primary, white, middleGray, lighterGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  height: 88vh;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-top: 50px;

  hr {
    height: 1px;
    background-color: ${lighterGray};
    width: 100%;
    border: none;
    margin-bottom: 20px;
  }

  @media (max-width: 1000px) {
    /* align-items: center; */
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const ListPolicy = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  flex-wrap: wrap;

  .no-policy {
    color: ${middleGray};
    font-size: 14px;
    margin: 20px 0px;
  }

  .add {
    margin-left: 4%;
    cursor: pointer;
  }

  div {
    margin-right: 4%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 0px;

    .add {
      margin-left: 0;
    }
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const EmptyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: ${middleGray};
  }
`;

export const ModalButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1000px) {
    justify-content: center;
  }
`