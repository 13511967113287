import styled from 'styled-components';
import { middleGray, success } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .cnhCheckboxes {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    width: 230px;
    border: 1px solid ${middleGray};
    padding: 0px 5px;
    border-radius: 10px;
    label {
      font-size: 12px;
    }
  }

  .genderRadio {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    width: 230px;
    border: 1px solid ${middleGray};
    padding: 0px 5px;
    border-radius: 10px;
    label {
      font-size: 12px;
    }
  }
`;
