import { createGlobalStyle } from 'styled-components';
import { white, black } from 'styles/colorProvider';

export const GlobalStyle = createGlobalStyle`
  html{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body {
    font-family: 'Nunito', sans-serif;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    background-color: ${white};
    color: ${black};
  }

  @media print {
    jdiv{
      display: none !important;
    }

  }

  .question-widget {
    z-index: 100;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #2B004F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .question-widget:hover {
    background-color: #2B004F;
  }
  
  .question-icon {
    font-size: 35px;
    color: #fff;
    font-weight: bold; 
  }

`;
