import styled from 'styled-components';
import { primary, black, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    margin-bottom: 10%;
    height: auto;
  }
`;

export const ContainerArea = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  font-size: 13px;

  .description {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
      padding: 4% 6%;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 5%;
    height: auto;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 85%;

  @media (max-width: 1000px) {
    width: 80%;
    margin-bottom: 10px;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  color: ${primary};
  font-weight: 700;
  margin: 0 0 15px 0;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  color: ${black};
  font-weight: 600;
  margin: 0 0 15px 0;
  line-height: 20px;

  span {
    color: ${middleGray};
  }
`;

export const HistoricArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
