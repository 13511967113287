import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';

import api from 'api';
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import { Container, Form } from './styles';
import { toast } from 'react-toastify';
import { primary } from 'styles/colorProvider';
import { saveFilesBucket } from 'utils/aws';

function ChangePhoto({ userData, onChange }) {
  const fileInputRef = useRef();

  const [loading, setLoading] = useState(false);
  const handleChangePhoto = async (e) => {
    setLoading(true);

    try {
      const ext = e?.target?.files[0]?.name.replaceAll(' ', '').toLowerCase().split('.').pop();

      saveFilesBucket({
        Bucket: process.env.AWS_USERS_FILES_BUCKET,
        Body: e?.target?.files[0],
        Key: `users/${userData?.cpf}/${new Date().getTime()}.${ext}`,
        onEnd: async (err, data) => {
          try {
            await api({
              method: 'POST',
              url: `/update-image/${userData?.id}`,
              headers: {
                'Content-Type': 'application/json',
                Authorization: Cookies.get('splitriskwl-authtoken'),
              },
              data: {
                picture: data?.file,
              },
              json: true,
            });
            onChange();
            toast.success('Foto de perfil alterada com sucesso!', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            });
            setLoading(false);
          } catch (e) {
            setLoading(false);
          }
        },
      });
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form>
        <Avatar src={userData?.signedUrl} alt={'userData?.name'} sx={{ width: 160, height: 160 }} style={{ marginRight: 9 }} />
        <input
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={(e) => handleChangePhoto(e)}
        />
        <button
          disabled={loading}
          onClick={(e) => {
            e.preventDefault();
            fileInputRef.current.click();
          }}
        >
          {loading ? <ReactLoading color={primary} height={24} width={24} type="spin" /> : 'Alterar foto de perfil'}
        </button>
      </Form>
    </Container>
  );
}

export default ChangePhoto;
