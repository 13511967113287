import styled from 'styled-components';
import { middleGray, black, primary, lightGray, white } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 90%;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-top: 5%;

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -1.6%;

  .lastItem {
    height: 9%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 80%;
  }
`;

export const ItemPolicy = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 35%;
  padding: 2.2% 3%;
  margin-top: 20px;
  margin-bottom: 0px;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
  }
`;

export const TextArea = styled.div`
  width: 37%;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 7% 5% 0% 0%;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${black};
  margin: 0;
  margin-top: 2%;

  span {
    color: ${middleGray};
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
    transition: all ease-in-out 0.3s;
    font-size: 12px;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  height: 35%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4.5%;
  margin-bottom: -4%;

  @media (max-width: 1100px) {
    width: 80%;
    margin-top: 10%;
  }
`;
