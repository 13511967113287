import { useState } from 'react';
import { Input, Select } from 'components';
import { Container } from './style';
import { cpf } from 'cpf-cnpj-validator';
import { isBirthDateValid, isDateValid } from 'utils/birthDate';
import { error, success } from 'styles/colorProvider';

const MainDriver = ({ quotationData, setQuotationData }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const availableCategories = ['A', 'B', 'C', 'D', 'E'];
  const mainDriverTypes = [
    { value: 'owner', label: 'Proprietário' },
    { value: 'spouse', label: 'Cônjuge' },
    { value: 'employee', label: 'Empregado/Auxiliar' },
    { value: 'sibling', label: 'Irmão/Irmã' },
    { value: 'child', label: 'Filho/Filha' },
    { value: 'mother', label: 'Mãe' },
    { value: 'father', label: 'Pai' },
    { value: 'other', label: 'Outros' },
  ];
  const availableCivilStatus = [
    { id: 1, label: 'Solteiro(a)' },
    { id: 2, label: 'Casado(a)' },
    { id: 3, label: 'União Marital' },
    { id: 4, label: 'Divorciado(a)' },
    { id: 5, label: 'Viúvo(a)' },
    { id: 6, label: 'Desconhecido' },
  ];

  const handleChangeCategory = (cat) => {
    const list = selectedCategories;
    let finalList = list;

    const isInList = list.findIndex((i) => i === cat) > -1;

    if (isInList) {
      const nList = list.filter((i) => i !== cat);
      finalList = nList;
    } else {
      const nList = list.filter((i) => i !== cat);
      nList.push(cat);
      finalList = nList;
    }

    setSelectedCategories(finalList);
    setQuotationData({ ...quotationData, mainDriver: { ...quotationData.mainDriver, cnh_category: finalList.join('') } });
  };

  const handleChangeMaindriverType = (val) => {
    setQuotationData({ ...quotationData, mainDriver: { ...quotationData.mainDriver, type: val } });

    if (val === 'owner') {
      setQuotationData({
        ...quotationData,
        mainDriver: { ...quotationData.client, type: val },
      });
    } else {
      setSelectedCategories([]);
      setQuotationData({
        ...quotationData,
        mainDriver: {
          type: val,
          name: '',
          document: '',
          birthdate: '',
          cnh_category: '',
          cnh_number: '',
          cnh_date: '',
          cnh_expiry: '',
          civil_status: '',
          civil_status_label: '',
        },
      });
    }
  };

  return (
    <Container>
      <div className="proposal-section" style={{ marginTop: 20 }}>
        <h3>Condutor principal</h3>

        <div className="form" style={{ marginTop: 30 }}>
          <Select
            label="Condutor Principal"
            height="30px"
            width="230px"
            value={quotationData?.mainDriver?.type}
            onChange={(e) => handleChangeMaindriverType(e.target.value)}
            success={quotationData?.mainDriver?.type}
            error={!quotationData?.mainDriver?.type}
          >
            <option value="">Selecione</option>
            {mainDriverTypes?.map((i) => (
              <option value={i.value}>{i.label}</option>
            ))}
          </Select>
        </div>

        {quotationData?.mainDriver?.type && quotationData?.mainDriver?.type !== 'owner' && (
          <>
            <div className="form">
              <Input
                height="30px"
                width="230px"
                style={{ marginRight: 50, marginTop: 20 }}
                label="Nome completo"
                value={quotationData?.mainDriver?.name}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, mainDriver: { ...quotationData?.mainDriver, name: event.target.value } })
                }
                success={quotationData?.mainDriver?.name?.length > 5}
                error={quotationData?.mainDriver?.name?.length <= 5}
              />

              <Input
                height="30px"
                width="230px"
                style={{ marginRight: 50, marginTop: 20 }}
                label="CPF"
                mask="999.999.999-999"
                value={quotationData?.mainDriver?.document}
                onChange={(event) =>
                  setQuotationData({
                    ...quotationData,
                    mainDriver: { ...quotationData?.mainDriver, document: event.target.value },
                  })
                }
                success={cpf.isValid(quotationData?.mainDriver?.document)}
                error={!quotationData?.mainDriver?.document || !cpf.isValid(quotationData?.mainDriver?.document)}
              />

              <Input
                height="30px"
                width="230px"
                label="Data de nascimento"
                style={{ marginRight: 50, marginTop: 20 }}
                mask="99/99/9999"
                value={quotationData?.mainDriver?.birthdate}
                onChange={(event) =>
                  setQuotationData({
                    ...quotationData,
                    mainDriver: { ...quotationData?.mainDriver, birthdate: event.target.value },
                  })
                }
                success={isBirthDateValid(quotationData?.mainDriver?.birthdate)}
                error={!quotationData?.mainDriver?.birthdate || !isBirthDateValid(quotationData?.mainDriver?.birthdate)}
              />

              <div name="gender-field" style={{ marginRight: 50, marginTop: 20 }}>
                <p style={{ fontSize: 12 }}>Sexo:</p>
                <div className="genderRadio" style={{ borderColor: !quotationData?.mainDriver?.gender ? error : success }}>
                  <input
                    id="mascinput"
                    type="radio"
                    checked={quotationData?.mainDriver?.gender === 1}
                    onChange={() =>
                      setQuotationData({ ...quotationData, mainDriver: { ...quotationData?.mainDriver, gender: 1 } })
                    }
                  />
                  <label htmlFor="mascinput">Masculino</label>
                  <input
                    style={{ marginLeft: 20 }}
                    id="feminput"
                    type="radio"
                    checked={quotationData?.mainDriver?.gender === 2}
                    onChange={() =>
                      setQuotationData({ ...quotationData, mainDriver: { ...quotationData?.mainDriver, gender: 2 } })
                    }
                  />
                  <label htmlFor="feminput">Feminino</label>
                </div>
              </div>
            </div>

            <div className="form" style={{ marginTop: 25 }}>
              <Select
                label="Estado Civil"
                height="30px"
                width="230px"
                placeholder="Selecione o Estado Civil"
                value={quotationData?.mainDriver.civil_status}
                style={{ marginRight: 50 }}
                onChange={(e) => {
                  setQuotationData({
                    ...quotationData,
                    mainDriver: {
                      ...quotationData.mainDriver,
                      civil_status: e.target.value,
                      civil_status_label:
                        availableCivilStatus[availableCivilStatus.findIndex((i) => i.id === Number(e.target.value))],
                    },
                  });
                }}
                error={!quotationData?.mainDriver.civil_status}
                success={quotationData?.mainDriver.civil_status}
              >
                <option value="">Selecione</option>
                {availableCivilStatus?.map((item) => (
                  <option value={item.id}>{item.label}</option>
                ))}
              </Select>

              <Input
                label="Número CNH"
                height="30px"
                width="230px"
                style={{ marginRight: 50 }}
                mask="99999999999"
                value={quotationData?.mainDriver?.cnh_number}
                onChange={(event) =>
                  setQuotationData({
                    ...quotationData,
                    mainDriver: { ...quotationData?.mainDriver, cnh_number: event.target.value },
                  })
                }
                success={quotationData?.mainDriver?.cnh_number?.length === 11}
                error={!quotationData?.mainDriver?.cnh_number || quotationData?.mainDriver?.cnh_number?.length !== 11}
              />

              <Input
                height="30px"
                width="230px"
                style={{ marginRight: 50 }}
                mask="99/99/9999"
                label="Data 1˚ Habilitação"
                value={quotationData?.mainDriver?.cnh_date}
                onChange={(event) =>
                  setQuotationData({
                    ...quotationData,
                    mainDriver: { ...quotationData?.mainDriver, cnh_date: event.target.value },
                  })
                }
                success={quotationData?.mainDriver?.cnh_date?.length > 3 && isDateValid(quotationData?.mainDriver?.cnh_date)}
                error={!quotationData?.mainDriver?.cnh_date || !isDateValid(quotationData?.mainDriver?.cnh_date)}
              />

              <Input
                height="30px"
                width="230px"
                style={{ marginRight: 50 }}
                mask="99/99/9999"
                label="Data Vencimento Habilitação"
                value={quotationData?.mainDriver?.cnh_expiry}
                onChange={(event) =>
                  setQuotationData({
                    ...quotationData,
                    mainDriver: { ...quotationData?.mainDriver, cnh_expiry: event.target.value },
                  })
                }
                success={quotationData?.mainDriver?.cnh_expiry?.length > 3}
                error={!quotationData?.mainDriver?.cnh_expiry || !isDateValid(quotationData?.mainDriver?.cnh_expiry)}
              />

              <div name="cnh-category-field" style={{ marginRight: 50, marginTop: 20 }}>
                <p style={{ fontSize: 12 }}>Categoria CNH:</p>

                <div className="cnhCheckboxes" style={{ borderColor: !quotationData.mainDriver?.cnh_category ? error : success }}>
                  {availableCategories?.map((categ) => (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        id={`c${categ}`}
                        name={`c${categ}`}
                        checked={selectedCategories?.findIndex((i) => i === categ) > -1}
                        onChange={() => handleChangeCategory(categ)}
                      />
                      <label for={`c${categ}`} style={{ cursor: 'pointer', marginRight: 10 }}>
                        <p style={{ margin: 0 }}>{categ}</p>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default MainDriver;
