import styled from 'styled-components';
import { error, primary, secondary } from 'styles/colorProvider';

export const Content = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
`;

export const ClientDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;

  .proposal-section {
    display: flex;
    flex-direction: column;

    h3 {
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
      margin: 0px;
      margin-top: 20px;
    }
  }

  .proposalInfo {
    font-size: 12px;
    margin: 3px 0px;
  }

  .form {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;

    p {
      margin: 0px;
      margin-bottom: 5px;
    }
  }

  .checkbox-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    font-size: 12px;
  }
`;

export const ClienteDataInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const VehicleDataContainer = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 6px 1px rgb(95 157 231 / 28%);

  @media (max-width: 824px) {
    flex: 1 1 auto;
  }
  p {
    font-size: 12px;
    /* padding: 0; */
    margin: 0;
  }

  select {
    border-radius: 10px;
  }
`;

export const Radio = styled.input`
  :after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }

  :checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${secondary};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }
`;

export const ContainerRadio = styled.div`
  margin: 1rem 0;
  label {
    margin: 1rem;
  }
`;

export const ResumeArea = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  overflow-y: auto;
  max-height: 500px;

  p {
    color: black;
    width: 100%;
    border-bottom: 1px solid #eee;
  }

  h4 {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
    color: ${error};
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 10px;
  }
`;
