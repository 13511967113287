/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';
import BackofficeMenu from 'components/BackofficeMenu';
import { toast } from 'react-toastify';
import { Header, ChargeItem } from 'components';
import { Container, ContentArea, Title, ListPaymentStatus, EmptyArea, LoadingArea, TitleArea } from './styles';
import { loadingColor } from 'styles/colorProvider';
import emptyAnimation from 'assets/empty.json';
import 'moment/locale/pt-br';

function Invoice({ location }) {
  const policyId = parseInt(location.pathname.split('/backoffice/faturas/')[1]);
  const [loading, setLoading] = useState(false);
  const [chargeData, setChargeData] = useState([]);
  const [clientHasCreditCard, setClientHasCreditCard] = useState(false);

  const loadData = async () => {
    try {
      setLoading(true);
      const resp = await api({
        method: 'GET',
        url: `/company-charges/${policyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      setChargeData(resp.data.findCharge);
      setLoading(false);
    } catch (e) {
      toast.error(e?.response?.data?.error || 'Não foi possivel listar cobranças da apólice', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setChargeData([]);
      setLoading(false);
    }
  };

  const getPolicyClientHasCreditCard = async () => {
    setLoading(true);
    const clientHasCreditCardResponse = await api({
      method: 'GET',
      url: `/policy-client-has-credit-card/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setClientHasCreditCard(clientHasCreditCardResponse.data);
  };

  useEffect(() => {
    loadData();
    getPolicyClientHasCreditCard();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          <BackofficeMenu policyId={policyId} />
          <TitleArea>
            <Title>FATURAS DA APÓLICE</Title>
          </TitleArea>
          <ListPaymentStatus>
            {loading && (
              <LoadingArea>
                <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
              </LoadingArea>
            )}

            {!loading &&
              chargeData?.map((charge) => (
                <ChargeItem
                  noRedirect
                  chargeData={charge}
                  id={charge?.id}
                  policyId={policyId}
                  status={!charge?.status || charge?.status?.includes('awaiting_') ? 'awaiting_payment' : charge?.status}
                  month={charge?.reference_month}
                  invoiceUrl={charge?.invoice_url}
                  canBeReprocessed={charge?.canBeReprocessed}
                  billingType={charge?.billing_type}
                  type={charge?.type}
                  value={charge?.value}
                  message={charge?.paid_at ? charge.paid_at : charge.due_at}
                  clickable={false}
                  billet={true}
                  data={charge?.TB_BILLET?.length}
                  backofficePolicy={true}
                  isHinova={charge?.TB_BILLS?.some((item) => item?.id_bill_hinova) || charge?.gateway === 'hinova'}
                  canChangeModalityToCreditCard={clientHasCreditCard}
                  loadData={loadData}
                />
              ))}

            {!loading && !chargeData.length && (
              <EmptyArea>
                <Lottie
                  autoplay
                  style={{ marginTop: 50, opacity: 0.6 }}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: emptyAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={140}
                  width={240}
                />
                <p>Nenhuma fatura encontrada.</p>
              </EmptyArea>
            )}
          </ListPaymentStatus>
        </ContentArea>
      </Container>
    </>
  );
}

export default Invoice;
