import styled from 'styled-components';
import { middleGray, primary } from 'styles/colorProvider';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .label {
    color: ${primary} !important;
    font-size: 14px;
  }

  .value {
    color: ${middleGray};
    margin-left: 5px;
    font-size: 14px;
  }
`;
