/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';
import api from 'api';
import { Header, SideMenu, Search, UserItem, Input, Dialog, Button, Select } from 'components';
import {
  Container,
  ContentArea,
  Title,
  Div,
  DataArea,
  LoadingArea,
  SelectLine,
  CountArea,
  Subtitle,
  ModalButton,
} from './styles';
import { loadingColor, primary } from 'styles/colorProvider';

function User({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [featureAcess, setFeatureAcess] = useState([]);
  const [inviteData, setInviteData] = useState();
  const [selectedAccesGroup, setSelectedAccessGroup] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [teamAcess, setTeamAcess] = useState([]);

  const inviteUser = async (e) => {
    setLoadingData(true);
    try {
      const response = await api({
        method: 'POST',
        url: '/invite',
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          email: inviteData,
          id_access_group: parseInt(selectedAccesGroup),
          id_team: parseInt(selectedTeam),
        },
        json: true,
      });
      handleClose();
      setInviteData('');
      setSelectedAccessGroup('');
      setSelectedTeam('');
      setLoadingData(false);
      toast.success('Convite enviado para o e-mail com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const loadData = async (searchData) => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/company-users`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          search: searchData,
          page: currentPage,
          take: 10,
        },
        json: true,
      });

      setUserList(resp?.data?.users);
      setTotalCount(resp?.data?.totalCount);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoading(false);
    } catch (error) {
      history.push('/nao-autorizado');
    }
  };

  const feature = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/access-groups`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });

    setFeatureAcess(resp.data.response);
    setLoading(false);
  };

  const team = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/teams`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setTeamAcess(resp.data.response);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/company-users`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          search,
          page,
          take: 10,
        },
        json: true,
      });

      const data = userList;
      const ndata = data.concat(resp?.data?.users);

      setCurrentPage(page);
      setUserList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const handleOpenModal = () => setOpen(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    loadData(search);
    feature();
    team();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <SideMenu />

        <ContentArea>
          <Div>
            <Title>USUÁRIOS</Title>

            <div className="containerTitle">
              <Button height="30px" width="240px" onClick={() => history.push('/backoffice/grupos-de-acesso')}>
                Grupos de acesso
              </Button>
              <Button height="30px" onClick={handleOpenModal}>
                Convidar
              </Button>

              <Dialog open={open} onClose={handleClose} title="Convidar usuário">
                <Subtitle id="modal-description">Insira abaixo somente o e-mail do usuário que deseja convidar.</Subtitle>
                <SelectLine>
                  <Select
                    label="Escolha o grupo de acesso"
                    height="35px"
                    value={selectedAccesGroup}
                    onChange={(e) => setSelectedAccessGroup(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    {featureAcess?.map((role) => (
                      <option value={role.id}>{role.name}</option>
                    ))}
                  </Select>
                </SelectLine>
                <SelectLine>
                  <Select
                    label="Escolha a equipe (opcional)"
                    height="35px"
                    value={selectedTeam}
                    onChange={(e) => setSelectedTeam(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    {teamAcess?.map((role) => (
                      <option value={role.id}>{role.name}</option>
                    ))}
                  </Select>
                </SelectLine>
                <Input
                  label="E-mail do usuário"
                  placeholder="E-mail"
                  height="35px"
                  width="95%"
                  value={inviteData}
                  onChange={(e) => setInviteData(e.target.value)}
                />

                <ModalButton>
                  <Button
                    onClick={inviteUser}
                    height="30px"
                    disabled={loading || !inviteData || !selectedAccesGroup || selectedAccesGroup === ''}
                  >
                    {loadingData ? <ReactLoading color="#ffff" height={20} width={20} type="spin" /> : 'Convidar'}
                  </Button>
                </ModalButton>
              </Dialog>

              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData('');
                }}
                onConfirm={() => loadData(search)}
              />
            </div>
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading && userList?.map((users) => <UserItem users={users} />)}
              </div>
            </InfiniteScroll>

            {!loading && !userList?.length && <p>Nenhum usuário encontrado</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {userList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default User;
