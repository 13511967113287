import styled from 'styled-components';
import { white, primary, middleGray, error } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    flex-direction: row;
    align-items: center;
    width: 95%;
  }
`;

export const StepArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const StepAreaMobile = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 35%;
    padding-left: 10px;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: ${primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
  }
`;
export const OptionsArea = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0;
  height: 100%;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: -25px;
  }
`;

export const ContainerUpload = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  div{
    display: flex;
    padding-right: 20px;
    cursor: pointer; 
    margin: 10px 0;
  }
`;

export const BodyArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 1000px) {
    width: 60%;
    height: 60vh;
    justify-content: flex-start;
  }

  .concluded {
    font-size: 12px;
    color: ${middleGray};
    margin: 0px;
  }

  .item {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }

  p {
    margin: 0px;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  border: 1px solid #f5f5f5;
  margin-top: 40px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ClaimOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all ease-in-out 0.3s;
  box-sizing: border-box;
  padding: 20px;
  width: 200px;
  min-height: ${(props) => props.minHeight};
  margin: 15px 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: ${primary};
  cursor: pointer;
  border: ${(props) => props.selected && `1px solid ${primary}`};
  font-size: 14px;
  text-align: center;

  img {
    height: 100px;
  }

  .selected {
    border: 1px solid ${primary};
  }

  &:hover {
    border: 1px solid ${primary};
  }

  @media (max-width: 1000px) {
    width: 260px;
    font-size: 20px;

    img {
      height: 150px;
    }
  }
`;

export const ContentFile = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`

export const EmptyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: ${error};
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: ${middleGray};
    margin: 0;
  }
`;


export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;
