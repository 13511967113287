import styled from 'styled-components';
import { black, middleGray, lightGray, success } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${lightGray};
  border-radius: 20px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  margin-top: 20px;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  &&:hover {
    box-shadow: ${(props) => props.clickable && 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'};
  }

  .description {
    display: flex;
    align-items: center;
    margin-left: 2%;
    width: 80%;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
      padding: 4% 6%;
    }
  }

  .iconArrow {
    margin-right: 5%;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  .button {
    margin-right: 5%;

    button{
      border: none;
      background: ${success};
      border-radius: 5px;
      width: 40px;
      height: 25px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all ease-in-out 0.2s;

      :hover{
        opacity: 0.8;
      }
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 10%;
    height: auto;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2%;
  width: 80%;

  @media (max-width: 1000px) {
    width: 50%;
  }
`;

export const StatusText = styled.p`
  color: ${black};
  margin-left: 5%;
`;

export const Month = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2%;
  width: 80%;

  @media (max-width: 1000px) {
    width: 40%;
    margin: 0;
  }
`;

export const MonthText = styled.p`
  color: ${black};
  margin-left: 5%;
  text-transform: capitalize;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: left;
  margin-left: 2%;
  width: 80%;

  @media (max-width: 1000px) {
    margin-top: 1%;
  }
`;

export const Value = styled.p`
  color: ${middleGray};
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const Message = styled.p`
  color: ${middleGray};
  font-size: 12px;
  text-align: left;
  width: 50%;

  @media (max-width: 1000px) {
    margin-left: 2%;
    margin-top: 1%;
    font-size: 13px;
  }
`;

export const Billet = styled.p`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    margin-left: 2%;
    margin-top: 1%;
    color: white;
    font-size: 14px;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: ${success};
    border-radius: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    :hover{
      opacity: 0.8;
    }
  }
`;