import Lottie from 'react-lottie';
import successAnimation from 'assets/success.json';
import errorAnimation from 'assets/error.json';
import { Button } from 'components';
import { Container, SuccessArea } from '../../style';
import { toast } from 'react-toastify';

const EmissionStep = ({ proposalResponse, restartQuotation }) => {
  return (
    <Container>
      {!proposalResponse?.rejected && (
        <SuccessArea>
          <Lottie
            autoplay
            options={{
              loop: false,
              autoplay: true,
              animationData: successAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={150}
            width={150}
          />
          <h1>Proposta emitida com sucesso</h1>
          {proposalResponse?.inspection_url && <p> Agora você já pode realizar a vistoria!</p>}
          <div className="buttonArea">
            {proposalResponse?.inspection_url && <Button
              onClick={() => {
                navigator.clipboard.writeText(proposalResponse?.inspection_url);
                toast.success('Link copiado com sucesso!', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                });
              }}
              buttonColor="rgb(0 182 93)"
              height="32px"
              width="250px"
            >
              COPIAR LINK DA VISTORIA
            </Button>}

            <Button onClick={restartQuotation} buttonColor="rgb(0 182 93)" height="32px" width="250px">
              NOVA COTAÇÃO
            </Button>
          </div>
        </SuccessArea>
      )}

      {proposalResponse?.rejected && (
        <SuccessArea>
          <Lottie
            autoplay
            options={{
              loop: false,
              autoplay: true,
              animationData: errorAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={150}
            width={150}
          />
          <h1>Oops!</h1>
          <p>Esta proposta não atende aos critérios necessários para avançar para a próxima etapa.</p>
          <div className="buttonArea">
            <Button onClick={restartQuotation} buttonColor="rgb(0 182 93)" height="32px" width="250px">
              NOVA COTAÇÃO
            </Button>
          </div>
        </SuccessArea>
      )}
    </Container>
  );
};

export default EmissionStep;
