import React from 'react';
import { Select, Option, Container } from './styles';

function DropDown({ ...props }) {
  return (
    <Container>
      <Select {...props}>
        <Option selected>Selecione o motivo da reclamação ---</Option>
        <Option value="1">ATRASO OU NEGATIVA DE PAGAMENTO</Option>
        <Option value="2">CONFERÊNCIA OU DIVERGÊNCIA DE VALORES DE INDENIZAÇÃO </Option>
        <Option value="3">CONFERÊNCIA OU DIVERGÊNCIA DE REAJUSTE DE PRÊMIO</Option>
        <Option value="4">DIVERGÊNCIA QUANTO A VALORES ORIUNDOS DE DEVOLUÇÃO DE PRÊMIO</Option>
        <Option value="5">PROPAGANDA ENGANOSA </Option>
        <Option value="6">INTERMEDIÁRIO NA VENDA</Option>
        <Option value="7">EVENTO NÃO COBERTO</Option>
      </Select>
    </Container>
  );
}

export default DropDown;
