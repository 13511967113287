import React from 'react';
import Avatar from '@mui/material/Avatar';
import 'moment/locale/pt-br';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { formatCPF, formatPhone } from 'react-data-formatter';

function UserList({ users }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(`/backoffice/detalhes-do-usuario/${users.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconProfile">
            <Avatar
              src={users?.picture}
              alt={users?.name}
              sx={{ width: 35, height: 35 }}
              style={{ marginRight: 9, cursor: 'pointer' }}
            />
          </div>

          <Div style={{ width: '40%' }}>
            <Title>{users?.name}</Title>
            <Subtitle>{formatCPF(users?.cpf)}</Subtitle>
          </Div>

          <Div style={{ width: '50%' }}>
            <Subtitle>{formatPhone(users?.phone)}</Subtitle>
            <Subtitle>{users?.email}</Subtitle>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>

          <IconMobile>
            <Avatar
              src={users?.picture}
              alt={users?.name}
              sx={{ width: 50, height: 50 }}
              style={{ marginRight: 9, cursor: 'pointer' }}
            />
          </IconMobile>
        </div>
      </Container>
    </>
  );
}

export default UserList;
