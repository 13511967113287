/* eslint-disable react/jsx-no-target-blank */
import moment from 'moment';
import { formatPaymentStatus } from 'utils/paymentStatus';
import { formatCurrency } from 'react-data-formatter';
import { primary } from 'styles/colorProvider';

export const dataColumns = [
  {
    name: 'Proposta/Apólice',
    selector: (row) => (
      <a
        style={{ color: primary }}
        target="_blank"
        href={
          row?.id_policy
            ? `/backoffice/detalhes-da-apolice/${row?.id_policy}`
            : `/backoffice/detalhes-da-proposta/${row?.id_proposal}`
        }
      >
        {row?.id_policy ? row?.policies?.code_ebao : `${row?.proposals?.protocol?.slice(0, 19)}...`}
      </a>
    ),
    width: '25%',
    sortable: true,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '7%',
    sortable: true,
  },
  {
    name: 'Cliente',
    selector: (row) => row?.client?.name,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Tipo',
    selector: (row) => (row?.id_policy || row?.id_subscription ? 'Resubscrição' : 'Subscrição'),
    width: '10%',
    sortable: true,
  },

  {
    name: 'Valor',
    selector: (row) => formatCurrency(row?.value || 0),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => formatPaymentStatus(row?.status)?.label || 'Aguardando pagamento',
    width: '13%',
    sortable: true,
  },
  // {
  //   name: 'Data cobrança',
  //   selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
  //   width: '10%',
  //   sortable: true,
  // },
  {
    name: 'Data vencimento',
    selector: (row) => (row?.due_date ? moment(row?.due_date).format('DD/MM/YYYY') : moment(row?.due_at).format('DD/MM/YYYY')),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Data pagamento',
    selector: (row) => (row?.paid_at ? moment(row?.paid_at).format('DD/MM/YYYY') : '-'),
    width: '10%',
    sortable: true,
  },
];
