import React, { useState } from 'react';
import { Header, PolicyMenu } from 'components';
import {
  Container,
  ContentArea,
  Description,
  Title,
  Historic,
  AddComplaint,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  Logo,
} from './styles';
import api from 'api';
import { useHistory } from 'react-router-dom';
import Questions from 'components/QuestionSelect';
import ComplaintHistoric from 'components/ComplaintHistoric';
import Cookies from 'js-cookie';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import ReactLoading from 'react-loading';
import logo from 'assets/logoQuestion.svg';

function Doubt({ location }) {
  const policyId = parseInt(location.pathname.split('/duvidas/')[1]);

  const history = useHistory();
  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);

  const policyIdData = async () => {
    const policyNumber = {
      method: 'GET',
      url: `/policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };

    setLoading(true);
    const response = await api(policyNumber);

    setPolicyData(response.data);
    setLoading(false);
  };

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color="#62ec82" height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <PolicyMenu policyId={policyId} />

            <Title>DÚVIDAS FREQUENTES</Title>

            <Questions
              question={'Minha mensalidade está atrasada, o que devo fazer?'}
              answer={'Você deve entrar em contato com o setor financeiro da empresa'}
            />
            <Questions
              question={'O cadastro do meu veículo e/ou do meus dados estão incorretos.'}
              answer={
                'Você deve entrar em contato com o seu corretor de seguros para que ele possa abrir um chamado interno para que seja solucinado o seu problema.'
              }
            />
            <Questions
              question={'A partir de qual momento meu veículo estará segurado?'}
              answer={
                'A partir do momento em que consta o pagamento no sistema e no próprio irá mostra que o seu veículo já está segurado.'
              }
            />
            <Questions
              question={
                'Coloquei meu cartão de crédito para fazer fatura automática mas agora quero desativa-lo e não estou conseguindo, o que fazer?'
              }
              answer={'Deve entrar em contato imediatamente ao nosso suporte de TI no número (34) 9 9898-5500.'}
            />
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default Doubt;
