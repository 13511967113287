/* eslint-disable react-hooks/exhaustive-deps */
import SideBar from '../CoverageStep/SideBar';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Button } from 'components';
import { Container, SectionTitle } from '../../style';
import { ConfirmDialog, InfoText } from 'components';
import { ClientDataContainer, Content, ResumeArea } from './style';
import { formatPayMethod } from 'utils/payMethod';
import ClientData from './Forms/ClientData';
import MainDriver from './Forms/MainDriver';
import VehicleData from './Forms/VehicleData';
import PaymentData from './Forms/PaymentData';
import { formatCurrency, formatPlate } from 'react-data-formatter';
import { primary } from 'styles/colorProvider';
import api from 'api/index';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const ProposalStep = ({
  quotationData,
  setQuotationData,
  quotationResponse,
  loadingProposal,
  dataCoverage,
  percentCommission,
  percentDiscount,
  sliderHandle,
  handleProposal,
  proposalResponse,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);

  const isCpf = quotationData?.client?.document?.length <= 14;
  const renderQuotationResume = () => (
    <ResumeArea>
      <p>Dados do proprietário</p>
      <InfoText label="Nome" value={quotationData?.client?.name} disabled />
      <InfoText label="CPF/CNPJ" value={quotationData?.client?.document} />
      <InfoText label="Data de Nascimento" value={quotationData?.client?.birthdate} />
      <InfoText label="E-mail" value={quotationData?.client?.email} />
      <InfoText label="Telefone" value={quotationData?.client?.phone} />
      <InfoText label="Estado Civil" value={quotationData?.client?.civil_status_label?.label} />
      <InfoText label="Pessoa Politicamente Exposta" value={quotationData?.client?.ppe} />
      <InfoText label="Sexo" value={quotationData?.client?.gender === 2 ? 'Feminino' : 'Masculino'} />
      <InfoText label="Número CNH" value={quotationData?.client?.cnh_number} />
      <InfoText label="Data 1ª Habilitação" value={quotationData?.client?.cnh_date} />
      <InfoText label="Data Vencimento CNH" value={quotationData?.client?.cnh_expiry} />
      <InfoText label="Categoria CNH" value={quotationData?.client?.cnh_category} />
      <InfoText label="Número do RG/IE" value={quotationData?.client?.rg_number} />
      <InfoText label="Órgão Expedidor" value={quotationData?.client?.rg_oe} />

      <p>Condutor principal</p>
      <InfoText label="Nome" value={quotationData?.mainDriver?.name} />
      <InfoText label="CPF/CNPJ" value={quotationData?.mainDriver?.document} />
      <InfoText label="Data de Nascimento" value={quotationData?.mainDriver?.birthdate} />
      <InfoText label="Estado Civil" value={quotationData?.mainDriver?.civil_status_label?.label} />
      <InfoText label="Sexo" value={quotationData?.mainDriver?.gender === 2 ? 'Feminino' : 'Masculino'} />
      <InfoText label="Número CNH" value={quotationData?.mainDriver?.cnh_number} />
      <InfoText label="Data 1ª Habilitação" value={quotationData?.mainDriver?.cnh_date} />
      <InfoText label="Data Vencimento CNH" value={quotationData?.mainDriver?.cnh_expiry} />
      <InfoText label="Categoria CNH" value={quotationData?.mainDriver?.cnh_category} />

      <p>Endereço</p>
      <InfoText label="CEP" value={quotationData?.address?.zip_code} />
      <InfoText label="Rua" value={quotationData?.address?.street} />
      <InfoText label="Número" value={quotationData?.address?.number} />
      <InfoText label="Complemento" value={quotationData?.address?.complement || 'Não informado'} />
      <InfoText label="Bairro" value={quotationData?.address?.district} />
      <InfoText label="Cidade" value={quotationData?.address?.city} />
      <InfoText label="Estado" value={quotationData?.address?.state} />

      <p>Dados do veículo</p>
      <InfoText label="Placa" value={formatPlate(quotationData?.vehicle?.plate)} />
      <InfoText label="Chassi" value={quotationData?.vehicle?.chassi} />
      <InfoText label="Renavam" value={quotationData?.vehicle?.renavam} />
      <InfoText label="Marca" value={quotationData?.vehicle?.brand} />
      <InfoText label="Modelo" value={quotationData?.vehicle?.model} />
      <InfoText label="Tipo do Veículo" value={quotationData?.vehicle?.vehicle_type} />
      <InfoText label="Ano Fabricação" value={quotationData?.vehicle?.build_year} />
      <InfoText label="Ano Modelo" value={quotationData?.vehicle?.year} />
      <InfoText label="Veículo de Leilão" value={quotationData?.vehicle?.auction ? 'Sim' : 'Não'} />
      <InfoText label="Veículo 0km" value={quotationData?.vehicle?.zero_km ? 'Sim' : 'Não'} />
      <InfoText label="Gás Natural Veicular (GNV)" value={quotationData?.vehicle?.gnv ? 'Sim' : 'Não'} />
      <InfoText label="Veículo Possui Seguro" value={quotationData?.vehicle?.vehicle_already_insured ? 'Sim' : 'Não'} />
      <InfoText label="Condutores Entre 18 e 25 Anos" value={quotationData?.vehicle?.under25 ? 'Sim' : 'Não'} />
      <InfoText label="Cor do Veículo" value={quotationData?.vehicle?.vehicle_color?.name} />
      <InfoText label="Uso do Veículo" value={quotationData?.vehicle?.vehicle_use?.name} />

      <p>Coberturas</p>
      {quotationResponse?.coverages?.map((coverage) => (
        <small>{coverage?.name}</small>
      ))}
      <p>Valores</p>
      <InfoText label="Valor total" value={formatCurrency(quotationResponse?.final_value_with_discount || 0)} />
      <InfoText label="Iof" value={formatCurrency(quotationResponse?.iof || 0)} />
      <InfoText label="Forma de pagamento" value={formatPayMethod(quotationData?.payment?.pay_method)} />

      <h4>Tem certeza de que deseja enviar a proposta?</h4>
    </ResumeArea>
  );

  const getAvailablePaymentMethods = async () => {
    try {
      const resp = await api({
        method: 'GET',
        url: 'get-available-payment-methods',
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      console.log(resp.data);
      setAvailablePaymentMethods(resp.data);
    } catch (error) {
      toast.error('Erro ao buscar métodos de pagamento', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    getAvailablePaymentMethods();
  }, []);

  return (
    <Container>
      <SectionTitle>Emitir proposta</SectionTitle>
      <Content style={{ width: '100%' }}>
        <ClientDataContainer>
          {/* DADOS DO PROPRIETARIO */}
          <ClientData quotationData={quotationData} setQuotationData={setQuotationData} />

          {/* CONDUTOR PRINCIPAL */}
          <MainDriver quotationData={quotationData} setQuotationData={setQuotationData} />

          {/* DADOS DO VEICULO */}
          <VehicleData quotationData={quotationData} setQuotationData={setQuotationData} />

          {/* DADOS DE PAGAMENTO */}
          <PaymentData
            quotationData={quotationData}
            setQuotationData={setQuotationData}
            availablePaymentMethods={availablePaymentMethods}
          />
        </ClientDataContainer>
        <div>
          <SideBar
            quotationData={quotationData}
            setQuotationData={setQuotationData}
            quotationResponse={quotationResponse}
            proposalResponse={proposalResponse}
            dataCoverage={dataCoverage}
            sliderHandle={sliderHandle?.handle}
            percentCommission={percentCommission}
            percentDiscount={percentDiscount}
            grievance_percent={sliderHandle.grievance_percent}
            discount_percent={sliderHandle.discount_percent}
            type="proposal"
            confirmButtonHandleClick={() => {
              setDialogIsOpen(true);
            }}
          />

          <Button
            disabled={
              loadingProposal ||
              (!!quotationData?.vehicle?.zero_km && !quotationData?.vehicleInvoice) ||
              !quotationData?.client?.name ||
              !quotationData?.client?.document ||
              !quotationData?.client?.email ||
              !quotationData?.client?.phone ||
              !quotationData?.client?.ppe ||
              (isCpf &&
                (!quotationData?.client?.birthdate ||
                  !quotationData?.client?.civil_status ||
                  !quotationData?.client?.gender ||
                  !quotationData?.client?.cnh_number ||
                  !quotationData?.client?.cnh_date ||
                  !quotationData?.client?.cnh_expiry ||
                  !quotationData?.client?.cnh_category ||
                  !quotationData?.client?.rg_number ||
                  !quotationData?.client?.rg_oe ||
                  !quotationData?.mainDriver?.birthdate ||
                  !quotationData?.mainDriver?.civil_status ||
                  !quotationData?.mainDriver?.gender ||
                  !quotationData?.mainDriver?.cnh_number ||
                  !quotationData?.mainDriver?.cnh_date ||
                  !quotationData?.mainDriver?.cnh_expiry ||
                  !quotationData?.mainDriver?.cnh_category)) ||
              !quotationData?.mainDriver?.name ||
              !quotationData?.mainDriver?.document ||
              !quotationData?.address?.zip_code ||
              !quotationData?.address?.street ||
              !quotationData?.address?.number ||
              !quotationData?.address?.district ||
              !quotationData?.address?.state ||
              (!quotationData?.vehicle?.plate &&
                (!quotationData?.vehicle?.chassi || quotationData?.vehicle?.chassi.length !== 17)) ||
              !quotationData?.vehicle?.renavam ||
              !quotationData?.vehicle?.brand ||
              !quotationData?.vehicle?.model ||
              !quotationData?.vehicle?.vehicle_type ||
              !quotationData?.vehicle?.build_year ||
              !quotationData?.vehicle?.year_model ||
              !quotationData?.vehicle?.color ||
              !quotationData?.vehicle?.vehicle_use ||
              !quotationData?.vehicle?.id_color ||
              quotationData?.vehicle?.vehicle_already_insured === null ||
              quotationData?.vehicle?.some_driver_18_25_years_old === null ||
              (quotationData?.payment?.pay_method === '1' &&
                (!quotationData?.payment?.number ||
                  !quotationData?.payment?.name ||
                  !quotationData?.payment?.expiry ||
                  !quotationData?.payment?.cvc))
            }
            height="35px"
            width="260px"
            onClick={handleProposal}
            // onClick={() => setDialogIsOpen(true)}
            // buttonColor={confirmButtonCollor}
            buttonBoxShadowColor="transparent"
            borderRadius="10px"
          >
            {!loadingProposal && 'Gerar proposta'}
            {loadingProposal && <ReactLoading color={primary} height={24} width={24} type="spin" />}
          </Button>
        </div>

        <ConfirmDialog
          open={dialogIsOpen}
          title="Resumo da proposta"
          confirmLabel="Enviar proposta"
          content={renderQuotationResume()}
          onConfirm={handleProposal}
          onCancel={() => setDialogIsOpen(false)}
          loading={loadingProposal}
        />
      </Content>
    </Container>
  );
};

export default ProposalStep;
