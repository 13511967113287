import styled from 'styled-components';
import { secondary, black, lightGray, middleGray } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  height: 70px;
  min-height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${lightGray};
  border-radius: 20px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 13px;
  position: relative;

  .description {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .iconCar {
      width: 10%;
      margin-left: 2%;
      color: ${secondary};

      @media (max-width: 1000px) {
      display: none;
      }
    }

    

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      width: 100%;
      padding: 10px;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 10%;
    height: auto;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-left: 4%;
  width: 80%;

  @media (max-width: 1000px) {
    width: 80%;
    margin-bottom: 10px;
  }
`;

export const Title = styled.h3`
  font-size: 14px;
  color: ${black};
  font-weight: 500;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  color: ${middleGray};
  margin: 0;
`;

export const IconMobile = styled.div`
  display: none;
  
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    width: 10%;
    position: absolute;
    margin-left: 80%;
    justify-content: center;
    align-items: center;
    }
`

