/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { MdChevronLeft } from 'react-icons/md';
import api from 'api';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { loadingColor, warn } from 'styles/colorProvider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import 'moment/locale/pt-br';
import moment from 'moment';
import { Header, Input, Select, VictimisItem, Button, Dialog, ConfirmDialog, ThirdPartyFiles, ThirdPartyItem } from 'components';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { error, success } from 'styles/colorProvider';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { formatClaimStatus } from 'utils/policyStatus';
import { Alert } from '@material-ui/lab';
import pdf from 'assets/pdfIcon.png';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { formatCPF, formatCNPJ } from 'react-data-formatter';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  Container,
  LoadingArea,
  ContentArea,
  ContainerHeader,
  Footer,
  Form,
  Image,
  DivImage,
  AreaButtom,
  ModalArea,
  ListProduct,
  SectionTitle,
  DivPicker,
} from './styles';

function ClaimDetails({ location }) {
  const claimId = parseInt(location.pathname.split('/backoffice/detalhes-do-sinistro/')[1]);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingRefuse, setLoadingRefuse] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingReopen, setLoadingReopen] = useState(false);
  const [loadingCreateThirdPaty, setLoadingCreateThirdPaty] = useState(false);
  const [loadingRemoveThirdPaty, setLoadingRemoveThirdPaty] = useState(false);

  const [claimData, setClaimData] = useState({});

  const [clientStatus, setClientStatus] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionAccept, setDescriptionAccept] = useState('');
  const [descriptionRefuse, setDescriptionRefuse] = useState('');
  const [value, setValue] = useState('');
  const [valueReopen, setValueReopen] = useState('');
  const [valueIndemnity, setValueIndemnity] = useState('');
  const [valueIndemnityReopen, setValueIndemnityReopen] = useState('');

  const [descriptionReopen, setDescriptionReopen] = useState('');
  const [statusReopen, setStatusReopen] = useState('');

  const [open, setOpen] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openThirdFile, setOpenThirdFile] = useState(false);
  const [reopen, setReopen] = useState(false);

  const [listThirdParty, setListThirdParty] = useState([]);
  const [nameThirtParty, setNameThirdParty] = useState('');
  const [phoneThirtParty, setPhoneThirdParty] = useState('');
  const [plateThirtParty, setPlateThirdParty] = useState('');

  const [listVictimis, setListVictimis] = useState([]);
  const [nameVictimis, setNameVictimis] = useState('');
  const [CPFVictimis, setCPFVictimis] = useState('');
  const [phoneVictimis, setPhoneVictimis] = useState('');

  const [listProduct, setListProduct] = useState([]);

  const [providerList, setProviderList] = useState([]);
  const [search, setSearch] = useState();
  const [selectProvider, setSelectProvider] = useState();
  const [selectProviderReopen, setSelectProviderReopen] = useState();
  const [thirdPartyFiles, setThirdPartyFiles] = useState([]);

  const [date, setDate] = useState();
  const [dateReopen, setDateReopen] = useState();
  const [containersOpen, setContainersOpen] = useState({
    sinisterDetails: true,
    assuredDetails: false,
    thirdDetails: false,
    victimsDetails: false,
  });

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/list-providers`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });

    setProviderList(resp?.data?.response);
    setLoading(false);
  };

  const ClaimData = async () => {
    setLoading(true);
    const policyNumber = {
      method: 'GET',
      url: `/claim/${claimId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };

    const response = await api(policyNumber);
    let allFiles = [];
    response.data?.TB_CLAIM_THIRD_PARTY?.map((i) => i.TB_CLAIM_FILES_THIRD.map((item) => allFiles.push(item)));
    setClaimData(response.data);
    setListThirdParty(response.data?.thirdParty);
    setListVictimis(response.data?.victims);
    setThirdPartyFiles(allFiles);
    setListProduct(response.data?.TB_POLICIES?.TB_PROPOSAL?.TB_PRODUCTS_PROPOSAL);
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'POST',
        url: `/submit-notice/${claimId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          description: claimData?.description,
          id_cause_loss: claimData?.TB_CLAIM_CAUSE_LOSS?.id,
          side_rear_windshield: claimData?.side_rear_windshield === 'true' ? true : false,
          rearviwe_mirror: claimData?.rearview_mirror === 'true' ? true : false,
          headlights_lanterns: claimData?.headlights_lanterns === 'true' ? true : false,
          accident_time: claimData?.accident_time,
          accident_victims: listVictimis,
          damage_third_parties: listThirdParty.length === 0 ? false : true,
          third_parties: listThirdParty,
          claim_owner: claimData?.claim_owner === null || claimData?.claim_owner === 'false' ? false : true,
          incident_report: claimData?.incident_report === 'false' ? false : true,
        },
        json: true,
      });
      setLoadingUpdate(false);
      setOpenSubmit(false);
      toast.success('Sinistro enviado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      window.location.reload();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingUpdate(false);
    }
  };

  const handleNotSubmit = async () => {
    setLoadingCancel(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/cancel-notice/${claimId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          details: description,
        },
        json: true,
      });
      setLoadingCancel(false);
      handleCloseModal();
      toast.success('Sinistro não enviado!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      window.location.reload();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingCancel(false);
    }
  };

  const handleUpdateStatus = async () => {
    setLoadingStatus(true);
    try {
      await api({
        method: 'POST',
        url: `/claim-alter-status`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: claimId,
          client_status: clientStatus,
          id_provider: selectProvider === undefined ? null : selectProvider,
          value_indemnity: parseFloat(valueIndemnity.replace('R$ ', '').replaceAll('.', '').replace(',', '.')),
          indemnity_total: clientStatus === 'indemnity_total_approved' ? true : false,
          indemnity_partial: clientStatus === 'indemnity_partial_approved' ? true : false,
        },
        json: true,
      });
      setLoadingStatus(false);
      setOpenStatus(false);
      toast.success('Status alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      window.location.reload();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingStatus(false);
    }
  };

  const handleAccept = async () => {
    setLoadingAccept(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/accept-claim`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: claimId,
          due_at: moment(date).format('YYYY/MM/DD'),
          description: descriptionAccept,
          value: parseFloat(value.replace('R$ ', '').replaceAll('.', '').replace(',', '.')),
        },
        json: true,
      });
      setLoadingAccept(false);
      setOpenAccept(false);
      toast.success('Sinistro aprovado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      window.location.reload();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleRefuse = async () => {
    setLoadingRefuse(true);
    try {
      const resp = await api({
        method: 'POST',
        url: `/refuse-claim`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: claimId,
          details: descriptionRefuse,
        },
        json: true,
      });
      setLoadingRefuse(false);
      setOpenRefuse(false);
      toast.success('Sinistro reprovado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      window.location.reload();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleAddThirtParty = async () => {
    setLoadingCreateThirdPaty(true);
    try {
      const response = await api({
        method: 'POST',
        url: `/create-third`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_claim: claimId,
          name: nameThirtParty,
          phone: phoneThirtParty,
          plate: plateThirtParty,
        },
        json: true,
      });
      setLoadingCreateThirdPaty(false);
      toast.success('Terceiro criado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setListThirdParty([response.data.claim].concat(listThirdParty));
      setNameThirdParty('');
      setPhoneThirdParty('');
      setPlateThirdParty('');
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingCreateThirdPaty(false);
    }
  };

  const handleAddVictimis = async () => {
    const data = {
      name: nameVictimis,
      cpf: CPFVictimis,
      phone: phoneVictimis,
    };
    setListVictimis((oldState) => [...oldState, data]);
    setNameVictimis('');
    setPhoneVictimis('');
    setCPFVictimis('');
  };

  const handleRemoveThirtParty = async (id) => {
    setLoadingRemoveThirdPaty(true);
    try {
      await api({
        method: 'POST',
        url: `/delete-third`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: id,
        },
        json: true,
      });
      setLoadingRemoveThirdPaty(false);
      toast.success('Terceiro removido com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setListThirdParty(listThirdParty.filter((i) => i.id !== id));
    } catch (error) {
      setLoadingRemoveThirdPaty(false);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleRemoveVictimis = (index) => {
    setListVictimis([...listVictimis.filter((thirt, i) => i !== index)]);
  };

  const handleReopenClaim = async () => {
    setLoadingReopen(true);
    try {
      await api({
        method: 'POST',
        url: `/reopen-claim`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id: claimId,
          status: statusReopen,
          description: descriptionReopen,
          id_provider: selectProviderReopen === undefined ? null : selectProviderReopen,
          value: parseFloat(valueReopen.replace('R$ ', '').replaceAll('.', '').replace(',', '.')),
          due_at: moment(dateReopen).format('YYYY/MM/DD'),
          value_indemnity: parseFloat(valueIndemnity.replace('R$ ', '').replaceAll('.', '').replace(',', '.')),
        },
        json: true,
      });
      setLoadingReopen(false);
      setReopen(false);
      toast.success('Sinistro aberto com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      window.location.reload();
      setDescriptionReopen('');
    } catch (error) {
      setLoadingReopen(false);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDescription('');
  };

  const renderSubmit = () => (
    <ConfirmDialog
      open={openSubmit}
      onCancel={() => setOpenSubmit(false)}
      content="Tem certeza que deseja  esse aviso?"
      title="Aviso de sinistro"
      onConfirm={handleSubmit}
      loading={loadingUpdate}
    />
  );

  useEffect(() => {
    loadData();
    ClaimData();
  }, []);

  const HeaderContainerHandleClick = (containerName) => {
    setContainersOpen((containersOpen) => {
      const updateContainersOpen = { ...containersOpen };
      updateContainersOpen[containerName] = !updateContainersOpen[containerName];
      return updateContainersOpen;
    });
  };

  return (
    <>
      <Header />
      <Container>
        {renderSubmit()}

        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <ContainerHeader>
              <h1>
                <IconButton size="small" style={{ marginRight: 10 }} onClick={() => history.push('/backoffice/sinistros')}>
                  <MdChevronLeft size={24} />
                </IconButton>
                DETALHES DO SINISTRO
              </h1>
            </ContainerHeader>

            {/*------------------------------- Informações do sinistro ------------------------------------*/}
            <>
              <SectionTitle>
                <h3 className="section-title" onClick={() => HeaderContainerHandleClick('sinisterDetails')}>
                  1. Informações do sinistro
                </h3>

                <div className="button-container" style={{ display: !containersOpen.sinisterDetails && 'none' }}>
                  {claimData?.client_status === 'finished' ||
                  claimData?.client_status === 'claim_disapproved' ||
                  claimData?.client_status === 'claim_canceled' ||
                  claimData?.client_status === 'indemnity_disapproved' ? null : (
                    <Button
                      outlined
                      style={{ width: '125px', height: '35px' }}
                      onClick={() => history.push(`/backoffice/enviar-arquivo/${claimId}`)}
                    >
                      Arquivos
                    </Button>
                  )}
                  <Button
                    style={{ width: '125px', height: '35px' }}
                    onClick={() => history.push(`/backoffice/historico-sinistro/${claimId}`)}
                  >
                    Ver histórico
                  </Button>
                </div>
                <ExpandMoreIcon
                  style={{ color: 'gray', cursor: 'pointer', transform: containersOpen.sinisterDetails && 'rotate(180deg)' }}
                  onClick={() => HeaderContainerHandleClick('sinisterDetails')}
                />
              </SectionTitle>
              <Divider />
              <div style={{ display: !containersOpen.sinisterDetails && 'none' }}>
                <Form>
                  <div class="grid-item">
                    <Input
                      height="35px"
                      label="Protocolo"
                      placeholder="Protocolo do sinistro"
                      value={claimData?.protocol}
                      onChange={(e) => setClaimData({ ...claimData, protocol: e.target.value })}
                      disabled
                    />
                  </div>
                  <div class="grid-item">
                    <Input
                      height="35px"
                      label="Status do sinistro"
                      value={formatClaimStatus(claimData?.client_status)?.label}
                      disabled
                      onChange={(e) => setClaimData({ ...claimData, status: e.target.value })}
                    />
                  </div>
                  <div class="grid-item">
                    <Input
                      height="35px"
                      label="N° da apólice"
                      placeholder="n° ..."
                      value={claimData?.TB_POLICIES?.policy_number}
                      onChange={(e) => setClaimData({ ...claimData, policy_number: e.target.value })}
                      disabled
                    />
                  </div>
                  <div class="grid-item">
                    <Input
                      height="35px"
                      label="Causa do aviso"
                      value={claimData?.TB_CLAIM_CAUSE_LOSS?.name}
                      onChange={(e) => setClaimData({ ...claimData, name: e.target.value })}
                      disabled
                    />
                  </div>

                  {claimData?.TB_CLAIM_CAUSE_LOSS?.id === 6 && claimData.status === 'created' && (
                    <>
                      <div class="grid-item">
                        <Select
                          label="Vidros laterais, traseiros ou parabrisa"
                          height="35px"
                          value={claimData?.side_rear_windshield}
                          onChange={(e) => setClaimData({ ...claimData, side_rear_windshield: e.target.value })}
                        >
                          <option value={true}>Sim</option>
                          <option value={false}>Não</option>
                        </Select>
                      </div>
                      <div class="grid-item">
                        <Select
                          label="Espelhos retrovisores"
                          height="35px"
                          value={claimData?.rearview_mirror}
                          onChange={(e) => setClaimData({ ...claimData, rearview_mirror: e.target.value })}
                        >
                          <option value={true}>Sim</option>
                          <option value={false}>Não</option>
                        </Select>
                      </div>
                      <div class="grid-item">
                        <Select
                          label="Faróis ou lanternas"
                          height="35px"
                          value={claimData?.headlights_lanterns}
                          onChange={(e) => setClaimData({ ...claimData, headlights_lanterns: e.target.value })}
                        >
                          <option value={true}>Sim</option>
                          <option value={false}>Não</option>
                        </Select>
                      </div>
                    </>
                  )}

                  {claimData?.TB_CLAIM_CAUSE_LOSS?.id === 6 && claimData.status !== 'created' && (
                    <>
                      <div class="grid-item">
                        <Input
                          height="35px"
                          label="Vidros laterais, traseiros ou parabrisa"
                          value={claimData?.side_rear_windshield === true ? 'Sim' : 'Não'}
                          onChange={(e) => setClaimData({ ...claimData, side_rear_windshield: e.target.value })}
                          disabled
                        />
                      </div>
                      <div class="grid-item">
                        <Input
                          height="35px"
                          label="Espelhos retrovisores"
                          value={claimData?.rearview_mirror === true ? 'Sim' : 'Não'}
                          onChange={(e) => setClaimData({ ...claimData, rearview_mirror: e.target.value })}
                          disabled
                        />
                      </div>
                      <div class="grid-item">
                        <Input
                          height="35px"
                          label="Faróis ou lanternas"
                          value={claimData?.headlights_lanterns === true ? 'Sim' : 'Não'}
                          onChange={(e) => setClaimData({ ...claimData, headlights_lanterns: e.target.value })}
                          disabled
                        />
                      </div>
                    </>
                  )}
                  <div class="grid-item">
                    <Input
                      height="35px"
                      mask="99/99/9999"
                      label="Data do acontecimento"
                      placeholder={moment(claimData?.accident_time).format('DD/MM/YYYY')}
                      onChange={(e) => setClaimData({ ...claimData, accident_time: e.target.value })}
                      disabled
                    />
                  </div>
                  <div class="grid-item">
                    <Input
                      height="35px"
                      mask="9999"
                      label="Horário do acontecimento"
                      placeholder={moment(claimData?.accident_time).format('HH:mm')}
                      onChange={(e) => setClaimData({ ...claimData, accident_time: e.target.value })}
                      disabled
                    />
                  </div>

                  <div class="grid-item">
                    {claimData?.client_status === 'created' ? (
                      <Select
                        label="Veículo do segurado"
                        height="35px"
                        value={claimData?.claim_owner}
                        onChange={(e) => setClaimData({ ...claimData, claim_owner: e.target.value })}
                      >
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                      </Select>
                    ) : (
                      <Input
                        height="35px"
                        label="Veículo do segurado"
                        value={claimData?.claim_owner === null || claimData.claim_owner === false ? 'Não' : 'Sim'}
                        onChange={(e) => setClaimData({ ...claimData, claim_owner: e.target.value })}
                        disabled
                      />
                    )}
                  </div>

                  <div class="grid-item">
                    {claimData?.client_status === 'created' ? (
                      <Select
                        label="Boletim de ocorrência"
                        height="35px"
                        value={claimData?.incident_report}
                        onChange={(e) => setClaimData({ ...claimData, incident_report: e.target.value })}
                      >
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                      </Select>
                    ) : (
                      <Input
                        height="35px"
                        label="Boletim de ocorrência"
                        value={claimData?.incident_report === null || claimData.incident_report === false ? 'Não' : 'Sim'}
                        onChange={(e) => setClaimData({ ...claimData, incident_report: e.target.value })}
                        disabled
                      />
                    )}
                  </div>
                  <div class="grid-item"></div>

                  <div class="grid-item">
                    <p style={{ fontSize: '12px' }}>Descrição do ocorrido: </p>
                    <TextareaAutosize
                      minRows={1}
                      disabled={true}
                      value={claimData?.description}
                      style={{
                        padding: 10,
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        width: '99%',
                        height: '110px',
                        borderRadius: 10,
                        borderColor: '#d1d2d4',
                      }}
                    />
                  </div>
                </Form>
                <h3 className="section-title">Produtos da apólice</h3>
                <Divider />

                <ListProduct>
                  {listProduct.map((product) => (
                    <div>
                      <p>▪️ {product?.TB_PRODUCTS?.name}</p>
                    </div>
                  ))}
                </ListProduct>

                {!!claimData?.claimFiles?.length ? (
                  <>
                    <h3 className="section-title">Arquivos do segurado</h3>
                    <DivImage>
                      {claimData?.claimFiles.map((photo) => (
                        <>
                          {photo?.url.split('.')[1] === 'pdf' ? (
                            <Image onClick={() => window.open(photo?.signedUrl, '_blank')} image={pdf} alt={photo?.description} />
                          ) : (
                            <Image
                              onClick={() => window.open(photo?.signedUrl, '_blank')}
                              image={photo?.signedUrl}
                              alt={photo?.description}
                            />
                          )}
                        </>
                      ))}
                    </DivImage>
                  </>
                ) : (
                  <>
                    <h3 className="section-title">Arquivos do segurado</h3>
                    <Alert style={{ marginBottom: 20 }} severity="warning">
                      Atenção, esse sinistro não possui fotos ou arquivos!
                    </Alert>
                  </>
                )}
                {!!thirdPartyFiles?.length ? (
                  <>
                    <h3 className="section-title">Arquivos de terceiros</h3>
                    <DivImage>
                      {thirdPartyFiles?.map((photo) => (
                        <>
                          {photo?.url.split('.')[1] === 'pdf' ? (
                            <Image onClick={() => window.open(photo?.signedUrl, '_blank')} image={pdf} alt={photo?.description} />
                          ) : (
                            <Image
                              onClick={() => window.open(photo?.signedUrl, '_blank')}
                              image={photo?.signedUrl}
                              alt={photo?.description}
                            />
                          )}
                        </>
                      ))}
                    </DivImage>
                  </>
                ) : null}
              </div>
            </>

            {/*------------------------------- Informações do reclamante< ------------------------------------*/}
            <>
              <SectionTitle>
                <h3 className="section-title" onClick={() => HeaderContainerHandleClick('assuredDetails')}>
                  2. Informações do segurado
                </h3>
                <ExpandMoreIcon
                  style={{ color: 'gray', cursor: 'pointer', transform: containersOpen.assuredDetails && 'rotate(180deg)' }}
                  onClick={() => HeaderContainerHandleClick('assuredDetails')}
                />
              </SectionTitle>
              <Divider />
              <Form style={{ display: !containersOpen.assuredDetails && 'none' }}>
                <div class="grid-item">
                  <Input
                    height="35px"
                    label="Nome"
                    placeholder="Nome"
                    disabled
                    value={claimData?.TB_USERS_TB_CLAIM_id_ownerToTB_USERS?.name}
                    onChange={(e) => setClaimData({ ...claimData, name: e.target.value })}
                  />
                </div>
                <div class="grid-item">
                  <Input
                    height="35px"
                    label="E-mail"
                    placeholder="E-mail"
                    disabled
                    value={claimData?.TB_USERS_TB_CLAIM_id_ownerToTB_USERS?.email}
                    onChange={(e) => setClaimData({ ...claimData, email: e.target.value })}
                  />
                </div>
                <div class="grid-item">
                  <Input
                    height="35px"
                    mask="(99) 99999-9999"
                    label="Telefone"
                    placeholder="Telefone"
                    disabled
                    value={claimData?.TB_USERS_TB_CLAIM_id_ownerToTB_USERS?.phone}
                    onChange={(e) => setClaimData({ ...claimData, phone: e.target.value })}
                  />
                </div>
                <div class="grid-item">
                  <Input
                    height="35px"
                    mask="999.999.999-99"
                    label="CPF"
                    placeholder="CPF"
                    disabled
                    value={claimData?.TB_USERS_TB_CLAIM_id_ownerToTB_USERS?.cpf}
                    onChange={(e) => setClaimData({ ...claimData, cpf: e.target.value })}
                  />
                </div>
                <div class="grid-item">
                  <Input
                    height="35px"
                    mask="99/99/9999"
                    label="Data de nascimento"
                    placeholder="Data de nascimento"
                    disabled
                    value={moment(claimData?.TB_USERS_TB_CLAIM_id_ownerToTB_USERS?.birthdate).format('DD/MM/YYYY')}
                    onChange={(e) => setClaimData({ ...claimData, birthdate: e.target.value })}
                  />
                </div>
              </Form>
            </>

            {/*------------------------------- Informações do terceiro< ------------------------------------*/}
            {claimData?.id_cause_loss === 3 && (
              <>
                {claimData?.client_status === 'finished' ||
                claimData?.client_status === 'claim_canceled' ||
                claimData?.client_status === 'claim_disapproved' ||
                claimData?.client_status === 'avaliation' ? (
                  <>
                    <SectionTitle onClick={() => HeaderContainerHandleClick('thirdDetails')}>
                      <h3 className="section-title">3. Informações do terceiro</h3>
                      <ExpandMoreIcon
                        style={{ color: 'gray', cursor: 'pointer', transform: containersOpen.thirdDetails && 'rotate(180deg)' }}
                        onClick={() => HeaderContainerHandleClick('thirdDetails')}
                      />
                    </SectionTitle>
                    <Divider />
                  </>
                ) : (
                  <>
                    <SectionTitle>
                      <h3 className="section-title" onClick={() => HeaderContainerHandleClick('thirdDetails')}>
                        3. Informações do terceiro
                      </h3>

                      <Button
                        outlined
                        style={{ width: '180px', height: '35px', display: !containersOpen.thirdDetails && 'none' }}
                        onClick={() => setOpenThirdFile(true)}
                      >
                        Arquivos de terceiros
                      </Button>

                      <ExpandMoreIcon
                        style={{ color: 'gray', cursor: 'pointer', transform: containersOpen.thirdDetails && 'rotate(180deg)' }}
                        onClick={() => HeaderContainerHandleClick('thirdDetails')}
                      />
                    </SectionTitle>{' '}
                    <Divider />
                    <Dialog
                      open={openThirdFile}
                      onClose={() => {
                        setOpenThirdFile(false);
                      }}
                      width="450px"
                      title="Escolha um terceiro"
                    >
                      {listThirdParty?.map((items, index) => (
                        <ThirdPartyFiles key={index} data={items} />
                      ))}
                    </Dialog>
                  </>
                )}
                <div style={{ display: !containersOpen.thirdDetails && 'none' }}>
                  {listThirdParty?.length === 0 && claimData?.client_status !== 'created' && (
                    <Alert style={{ marginBottom: 20 }} severity="warning">
                      Atenção, esse sinistro não possui terceiros!
                    </Alert>
                  )}
                  {claimData?.client_status === 'created' && (
                    <>
                      <Divider />
                      <Form>
                        <div class="grid-item">
                          <Input
                            height="35px"
                            label="Nome"
                            placeholder="Nome"
                            value={nameThirtParty}
                            onChange={(e) => setNameThirdParty(e.target.value)}
                          />
                        </div>

                        <div class="grid-item">
                          <Input
                            height="35px"
                            mask="(99) 9 9999-9999"
                            label="Telefone"
                            placeholder="Telefone"
                            value={phoneThirtParty}
                            onChange={(e) => setPhoneThirdParty(e.target.value)}
                          />
                        </div>
                        <div class="grid-item">
                          <Input
                            height="35px"
                            mask="***-9*99"
                            label="Placa"
                            placeholder="Placa"
                            value={plateThirtParty}
                            onChange={(e) => setPlateThirdParty(e.target.value.toUpperCase())}
                          />
                        </div>
                      </Form>
                      <AreaButtom>
                        <button
                          onClick={handleAddThirtParty}
                          disabled={
                            !nameThirtParty ||
                            !phoneThirtParty.length ||
                            phoneThirtParty.length < 16 ||
                            (plateThirtParty.length > 0 && plateThirtParty.length < 8)
                          }
                        >
                          {loadingCreateThirdPaty ? (
                            <ReactLoading color={success} height={20} width={20} type="spin" />
                          ) : (
                            '+ Adicionar'
                          )}
                        </button>
                      </AreaButtom>
                    </>
                  )}
                  {listThirdParty.length > 0 && (
                    <>
                      <h3 className="section-title">Lista de terceiros</h3>
                      <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                    </>
                  )}
                  {listThirdParty?.map((items, index) => (
                    <>
                      <ThirdPartyItem
                        item={items}
                        key={index}
                        claimData={claimData}
                        loading={loadingRemoveThirdPaty}
                        deleteItem={() => handleRemoveThirtParty(items.id)}
                      />
                    </>
                  ))}
                </div>
              </>
            )}

            {/*------------------------------- Informações da vítima< ------------------------------------*/}
            {claimData?.id_cause_loss === 3 && (
              <>
                <SectionTitle>
                  <h3 className="section-title" onClick={() => HeaderContainerHandleClick('victimsDetails')}>
                    4. Informações de vítimas
                  </h3>
                  <ExpandMoreIcon
                    style={{ color: 'gray', cursor: 'pointer', transform: containersOpen.victimsDetails && 'rotate(180deg)' }}
                    onClick={() => HeaderContainerHandleClick('victimsDetails')}
                  />
                </SectionTitle>
                <Divider />
                <div style={{ display: !containersOpen.victimsDetails && 'none' }}>
                  {listVictimis?.length === 0 && claimData?.client_status !== 'created' && (
                    <Alert style={{ marginBottom: 20 }} severity="warning">
                      Atenção, esse sinistro não possui vítimas!
                    </Alert>
                  )}
                  {claimData?.client_status === 'created' && (
                    <>
                      <Divider />
                      <Form>
                        <div class="grid-item">
                          <Input
                            height="35px"
                            label="Nome da vítima"
                            placeholder="Nome da vítima"
                            value={nameVictimis}
                            onChange={(e) => setNameVictimis(e.target.value)}
                          />
                        </div>
                        <div class="grid-item">
                          <Input
                            height="35px"
                            label="CPF da vítima"
                            placeholder="CPF da vítima"
                            mask="999.999.999-99"
                            value={CPFVictimis}
                            onChange={(e) => setCPFVictimis(e.target.value)}
                          />
                        </div>
                        <div class="grid-item">
                          <Input
                            height="35px"
                            label="Telefone da vítima"
                            mask="(99) 9 9999-9999"
                            placeholder="Contato"
                            value={phoneVictimis}
                            onChange={(e) => setPhoneVictimis(e.target.value)}
                          />
                        </div>
                      </Form>
                      <AreaButtom>
                        <button
                          onClick={handleAddVictimis}
                          disabled={
                            !nameVictimis ||
                            !phoneVictimis.length ||
                            phoneVictimis.length < 16 ||
                            !CPFVictimis ||
                            CPFVictimis.length < 14
                          }
                        >
                          <AiOutlinePlusCircle size={15} />
                          <p style={{ marginLeft: 5 }}>Adicionar</p>
                        </button>
                      </AreaButtom>
                    </>
                  )}

                  {listVictimis.length > 0 && (
                    <>
                      <h3 className="section-title">Lista de vítimas</h3>
                      <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                    </>
                  )}
                  {listVictimis?.map((items, index) => (
                    <>
                      <VictimisItem
                        item={items}
                        key={index}
                        claimData={claimData}
                        deleteItem={() => handleRemoveVictimis(index)}
                      />
                    </>
                  ))}
                </div>
              </>
            )}

            {/*------------------------------- Modal de reabrir sinistro< ------------------------------------*/}
            <>
              <Dialog
                open={reopen}
                onClose={() => {
                  setReopen(false);
                  setDescriptionReopen('');
                  setStatusReopen('');
                }}
                width="100%"
                title="Reabrir sinistro"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Select label="Selecione o status" value={statusReopen} onChange={(e) => setStatusReopen(e.target.value)}>
                    <option value="" disabled>
                      Selecione o status
                    </option>
                    <>
                      <option value="awaiting_payment">Aguardando pagamento</option>
                      <option value="paid_deductible">Franquia paga</option>
                      <option value="repair_approved">Reparo aprovado</option>
                      <option value="in_repair">Em reparo</option>
                      <option value="repair_finished">Reparo finalizado</option>
                      <option value="indemnity_total_approved">Indenização total aprovada</option>
                      <option value="indemnity_partial_approved">Indenização parcial aprovada</option>
                      <option value="indemnity_total_paid">Indenização total paga</option>
                      <option value="indemnity_partial_paid">Indenização parcial paga</option>
                      <option value="indemnity_disapproved">Indenização reprovada</option>
                      <option value="avaliation">Aguardando avaliação</option>
                    </>
                  </Select>

                  {statusReopen === 'indemnity_total_approved' || statusReopen === 'indemnity_partial_approved' ? (
                    <Input
                      value={valueIndemnityReopen}
                      onChange={(e) => setValueIndemnityReopen(e.target.value)}
                      numeric
                      price
                      height="30px"
                      label="Valor da indenização"
                      placeholder="R$0,00"
                    />
                  ) : null}

                  {statusReopen === 'awaiting_payment' && (
                    <>
                      <DivPicker>
                        <p>Data de vencimento:</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            emptyLabel="DD/MM/AAAA"
                            value={dateReopen}
                            onChange={setDateReopen}
                            minDate={new Date()}
                            inputVariant="filled"
                            format="dd/MM/yyyy"
                            invalidDateMessage="Data inválida"
                          />
                        </MuiPickersUtilsProvider>
                      </DivPicker>
                      <Input
                        value={valueReopen}
                        onChange={(e) => setValueReopen(e.target.value)}
                        numeric
                        price
                        height="30px"
                        label="Valor da franquia"
                        placeholder="R$0,00"
                      />
                    </>
                  )}
                  {statusReopen === 'in_repair' && (
                    <>
                      <Autocomplete
                        options={providerList}
                        sx={{ width: '100%' }}
                        style={{ margin: '20px 0 5px 0' }}
                        value={selectProviderReopen}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event, newValue) => {
                          setSelectProviderReopen(newValue.id);
                        }}
                        renderOption={(props, option) => (
                          <>
                            <p style={{ paddingLeft: 10, cursor: 'pointer' }} {...props} className="title">
                              {option?.name} -{' '}
                              {option?.document.length === 11 ? formatCPF(option.document) : formatCNPJ(option.document)}
                            </p>
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Selecione o parceiro" placeholder="Digite para buscar..." />
                        )}
                      />
                    </>
                  )}

                  <Input
                    value={descriptionReopen}
                    onChange={(e) => setDescriptionReopen(e.target.value)}
                    height="30px"
                    label="Descrição da reabertura"
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      onClick={() => {
                        setReopen(false);
                        setDescriptionReopen('');
                        setStatusReopen('');
                      }}
                      style={{
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        border: `1px solid ${error}`,
                        color: `${error}`,
                        backgroundColor: 'transparent',
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleReopenClaim}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${success}`,
                        color: 'white',
                      }}
                      disabled={
                        loadingReopen ||
                        !descriptionReopen ||
                        !statusReopen ||
                        statusReopen === '' ||
                        (statusReopen === 'awaiting_payment' && !dateReopen && !valueReopen) ||
                        (statusReopen === 'in_repair' && !selectProviderReopen) ||
                        (statusReopen === 'indemnity_total_approved' && !valueIndemnityReopen) ||
                        (statusReopen === 'indemnity_partial_approved' && !valueIndemnityReopen)
                      }
                    >
                      {loadingReopen ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Confirmar'}
                    </Button>
                  </div>
                </div>
              </Dialog>
            </>

            {/*------------------------------- Modal de cancelar envio< ------------------------------------*/}
            <>
              <Dialog open={open} onClose={handleCloseModal} width="450px" title="Cancelar sinistro">
                <ModalArea>
                  <h3>Descreva o motivo do cancelamento desse sinistro.</h3>
                  <TextareaAutosize
                    placeholder="Deixe sua observação aqui..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      padding: 10,
                      fontFamily: 'Nunito',
                      fontSize: 14,
                      width: '92%',
                      height: '60px',
                      borderRadius: 5,
                      borderColor: '#d1d2d4',
                    }}
                  />
                  <div>
                    <Button
                      onClick={() => handleCloseModal()}
                      style={{
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        border: `1px solid ${error}`,
                        color: `${error}`,
                        backgroundColor: 'transparent',
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleNotSubmit}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${success}`,
                        color: 'white',
                      }}
                      disabled={loadingCancel || !description}
                    >
                      {loadingCancel ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Confirmar'}
                    </Button>
                  </div>
                </ModalArea>
              </Dialog>
            </>

            {/*------------------------------- Modal de aprovar sinistro< ------------------------------------*/}
            <>
              <Dialog
                open={openAccept}
                onClose={() => {
                  setOpenAccept(false);
                }}
                width="450px"
                title="Aprovar sinistro"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <DivPicker>
                    <p>Data de vencimento:</p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        emptyLabel="DD/MM/AAAA"
                        value={date}
                        onChange={setDate}
                        minDate={new Date()}
                        inputVariant="filled"
                        format="dd/MM/yyyy"
                        invalidDateMessage="Data inválida"
                      />
                    </MuiPickersUtilsProvider>
                  </DivPicker>
                  <Input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    numeric
                    price
                    height="30px"
                    label="Valor da franquia"
                    placeholder="R$0,00"
                  />

                  <Input
                    value={descriptionAccept}
                    onChange={(e) => setDescriptionAccept(e.target.value)}
                    height="30px"
                    label="Descrição"
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Button
                      onClick={() => setOpenAccept(false)}
                      style={{
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        border: `1px solid ${error}`,
                        color: `${error}`,
                        backgroundColor: 'transparent',
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleAccept}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${success}`,
                        color: 'white',
                      }}
                      disabled={loadingAccept || !descriptionAccept || !date || !value}
                    >
                      {loadingAccept ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Confirmar'}
                    </Button>
                  </div>
                </div>
              </Dialog>
            </>

            {/*------------------------------- Modal de reprovar sinistro< ------------------------------------*/}
            <>
              <Dialog open={openRefuse} onClose={() => setOpenRefuse(false)} width="450px" title="Reprovar sinistro">
                <ModalArea>
                  <h3>Observação:</h3>
                  <TextareaAutosize
                    placeholder="Deixe sua observação aqui..."
                    value={descriptionRefuse}
                    onChange={(e) => setDescriptionRefuse(e.target.value)}
                    style={{
                      padding: 10,
                      fontFamily: 'Nunito',
                      fontSize: 14,
                      width: '92%',
                      height: '60px',
                      borderRadius: 5,
                      borderColor: '#d1d2d4',
                    }}
                  />
                  <div>
                    <Button
                      onClick={() => setOpenRefuse(false)}
                      style={{
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        border: `1px solid ${error}`,
                        color: `${error}`,
                        backgroundColor: 'transparent',
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleRefuse}
                      style={{
                        width: '100px',
                        borderRadius: '10px',
                        height: '35px',
                        backgroundColor: `${success}`,
                        color: 'white',
                      }}
                      disabled={loadingRefuse || !descriptionRefuse}
                    >
                      {loadingRefuse ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Confirmar'}
                    </Button>
                  </div>
                </ModalArea>
              </Dialog>
            </>

            {/*------------------------------- Modal de alterar status do sinistro< ------------------------------------*/}
            <>
              <Dialog
                open={openStatus}
                onClose={() => {
                  setOpenStatus(false);
                  setClientStatus('');
                  setSelectProvider();
                }}
                width="350px"
                title="Alterar status"
              >
                <Input label="Status atual do cliente" value={formatClaimStatus(claimData?.client_status)?.label} disabled />
                <Select label="Selecione o status" value={clientStatus} onChange={(e) => setClientStatus(e.target.value)}>
                  <option value="" disabled>
                    Selecione o status
                  </option>

                  {/* ---------Inden. Total Aprovada----  */}
                  {claimData?.client_status === 'indemnity_total_approved' && (
                    <>
                      <option value="indemnity_total_paid">Indenização total paga</option>
                    </>
                  )}

                  {/* ---------Inden. Parcial Aprovada-------- */}
                  {claimData?.client_status === 'indemnity_partial_approved' && (
                    <>
                      <option value="indemnity_partial_paid">Indenização parcial paga</option>
                    </>
                  )}

                  {/* ---------Em reparo------------------------*/}
                  {claimData?.client_status === 'in_repair' && (
                    <>
                      <option value="repair_finished">Reparo finalizado</option>
                      <option value="avaliation">Aguardando avaliação</option>
                    </>
                  )}

                  {/* ---------Reparo aprovado-------------------*/}
                  {claimData?.client_status === 'repair_approved' && (
                    <>
                      <option value="in_repair">Em reparo</option>
                      <option value="repair_finished">Reparo finalizado</option>
                      <option value="avaliation">Aguardando avaliação</option>
                    </>
                  )}

                  {/* ---------Reparo Finalizado-----------------*/}
                  {claimData?.client_status === 'repair_finished' && (
                    <>
                      <option value="in_repair">Em reparo</option>
                      <option value="avaliation">Aguardando avaliação</option>
                    </>
                  )}

                  {/* ---------Indenização reprovada-------------*/}
                  {claimData?.client_status === 'indemnity_disapproved' && (
                    <>
                      <option value="indemnity_total_approved">Indenização total aprovada</option>
                      <option value="indemnity_partial_approved">Indenização parcial aprovada</option>
                    </>
                  )}

                  {/* --------- Indenização total paga-----------*/}
                  {claimData?.client_status === 'indemnity_total_paid' && (
                    <>
                      <option value="avaliation">Aguardando avaliação</option>
                    </>
                  )}

                  {/* --------- Indenização parcial paga-------- */}
                  {claimData?.client_status === 'indemnity_partial_paid' && (
                    <>
                      <option value="avaliation">Aguardando avaliação</option>
                    </>
                  )}

                  {/* --------- Aguardando avaliação------------ */}
                  {claimData?.client_status === 'avaliation' && (
                    <>
                      <option value="finished">Sinistro finalizado</option>
                    </>
                  )}

                  {/* --------- Franquia Paga--------------------*/}
                  {claimData?.client_status === 'paid_deductible' && (
                    <>
                      <option value="indemnity_total_approved">Indenização total aprovada</option>
                      <option value="indemnity_partial_approved">Indenização parcial aprovada</option>
                      <option value="repair_approved">Reparo aprovado</option>
                    </>
                  )}
                </Select>

                {clientStatus === 'indemnity_total_approved' || clientStatus === 'indemnity_partial_approved' ? (
                  <Input
                    value={valueIndemnity}
                    onChange={(e) => setValueIndemnity(e.target.value)}
                    numeric
                    price
                    height="30px"
                    label="Valor da indenização"
                    placeholder="R$0,00"
                  />
                ) : null}

                {clientStatus === 'in_repair' && (
                  <>
                    <Autocomplete
                      options={providerList}
                      sx={{ width: '100%' }}
                      style={{ margin: '20px 0 5px 0' }}
                      value={selectProvider}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, newValue) => {
                        setSelectProvider(newValue.id);
                      }}
                      renderOption={(props, option) => (
                        <>
                          <p style={{ paddingLeft: 10, cursor: 'pointer' }} {...props} className="title">
                            {option?.name} -{' '}
                            {option?.document.length === 11 ? formatCPF(option.document) : formatCNPJ(option.document)}
                          </p>
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecione o parceiro" placeholder="Digite para buscar..." />
                      )}
                    />
                  </>
                )}

                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: '25px 0 0 0' }}
                >
                  <Button
                    onClick={handleUpdateStatus}
                    style={{ borderRadius: '20px', height: '40px' }}
                    disabled={
                      loadingStatus ||
                      claimData?.client_status === clientStatus ||
                      clientStatus === '' ||
                      !clientStatus ||
                      (clientStatus === 'in_repair' && !selectProvider) ||
                      (clientStatus === 'indemnity_total_approved' && !valueIndemnity) ||
                      (clientStatus === 'indemnity_partial_approved' && !valueIndemnity)
                    }
                  >
                    {loadingStatus ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Alterar'}
                  </Button>
                </div>
              </Dialog>
            </>
          </ContentArea>
        )}
      </Container>

      {!loading && claimData?.client_status !== 'finished' ? (
        <Footer>
          <div className="area-button">
            {claimData?.client_status !== 'created' ? (
              <Button
                onClick={() => setOpenStatus(true)}
                style={{ width: '105px', borderRadius: '10px', height: '35px', backgroundColor: `${warn}`, color: 'white' }}
              >
                Mudar status
              </Button>
            ) : null}

            {claimData?.client_status === 'noticed' ||
            claimData?.client_status === 'claim_canceled' ||
            claimData?.client_status === 'created' ? null : (
              <Button
                onClick={() => setOpen(true)}
                style={{ width: '125px', borderRadius: '10px', height: '35px', backgroundColor: `${warn}`, color: 'white' }}
              >
                Cancelar sinistro
              </Button>
            )}

            {claimData?.client_status === 'created' && (
              <>
                <Button
                  onClick={() => setOpen(true)}
                  style={{ width: '105px', borderRadius: '10px', height: '35px', backgroundColor: `${error}`, color: 'white' }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => setOpenSubmit(true)}
                  style={{ width: '105px', borderRadius: '10px', height: '35px', backgroundColor: `${success}`, color: 'white' }}
                  disabled={!claimData.accident_time}
                >
                  Enviar
                </Button>
              </>
            )}
            {claimData?.client_status === 'noticed' && (
              <>
                <Button
                  onClick={() => setOpenRefuse(true)}
                  style={{ width: '105px', borderRadius: '10px', height: '35px', backgroundColor: `${error}`, color: 'white' }}
                >
                  Reprovar
                </Button>
                <Button
                  onClick={() => setOpenAccept(true)}
                  style={{ width: '105px', borderRadius: '10px', height: '35px', backgroundColor: `${success}`, color: 'white' }}
                >
                  Aprovar
                </Button>
              </>
            )}
            {claimData?.client_status === 'claim_disapproved' || claimData?.client_status === 'claim_canceled' ? (
              <Button
                onClick={() => setReopen(true)}
                style={{ width: '105px', borderRadius: '10px', height: '35px', backgroundColor: `${error}`, color: 'white' }}
              >
                Reabrir
              </Button>
            ) : null}
          </div>
        </Footer>
      ) : null}
    </>
  );
}

export default ClaimDetails;
