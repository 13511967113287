/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Lottie from 'react-lottie';
import { Header, Button } from 'components';
import { Container } from './styles';
import animation from 'assets/unauthorized.json';

function ProposalDetails({ history }) {
  return (
    <>
      <Header />
      <Container>
        <Lottie
          style={{ marginTop: 50 }}
          autoplay
          options={{
            loop: true,
            autoplay: true,
            animationData: animation,
          }}
          height={220}
          width={300}
        />
        <h1>Não autorizado!</h1>
        <h3>Oops! Você não possui permissão para acessar este conteúdo!</h3>
        <Button onClick={() => history.push('/')}>Voltar para o início</Button>
      </Container>
    </>
  );
}

export default ProposalDetails;
