const momentBD = require('moment-business-days');

momentBD.updateLocale('br', {
  holidays: [
    '01-01', // Ano Novo
    '02-12', // Carnaval
    '02-13', // Carnaval
    '03-29', // Paixão de Cristo
    '04-21', // Tiradentes
    '05-01', // Dia do Trabalho
    '05-30', // Corpus Christi
    '09-07', // Independência do Brasil
    '10-12', // Nossa Senhora Aparecida - Padroeira do Brasil
    '11-02', // Finados
    '11-15', // Proclamação da República
    '11-20', // Dia Nacional de Zumbi e da Consciência Negra
    '12-25', // Natal
  ],
  holidayFormat: 'MM-DD', // O formato dos feriados
});

module.exports = momentBD;