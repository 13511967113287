import { ImBarcode, ImCreditCard } from 'react-icons/im';
import { Container } from './styles';
import { black } from 'styles/colorProvider';

function PaymentMethod({ type, limit, ...props }) {
  return (
    <Container {...props}>
      {type === 'boleto' && <ImBarcode size="24" color={black} />}
      {type === 'cartão de crédito' && <ImCreditCard size="24" color={black} />}
      {type === 'cartão de débito' && <ImCreditCard size="24" color={black} />}
      {type === 'pix' && <div className="pix" />}

      <div>
        <p>
          {type} {limit && <small style={{ fontSize: 10, marginLeft: 5 }}>(Até {limit})</small>}
        </p>
      </div>
    </Container>
  );
}

export default PaymentMethod;
