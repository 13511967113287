import { cpf, cnpj } from 'cpf-cnpj-validator';
import { useState, useEffect, useCallback } from 'react';
import { Container } from './style';
import { Input, Select } from 'components';
import Cookies from 'js-cookie';
import { isBirthDateValid, isDateValid } from 'utils/birthDate';
import { error, success } from 'styles/colorProvider';
import api from 'api';

const ClientData = ({ quotationData, setQuotationData }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [socialName, setSocialName] = useState('');

  const availableCategories = ['A', 'B', 'C', 'D', 'E'];
  const availableCivilStatus = [
    { id: 1, label: 'Solteiro(a)' },
    { id: 2, label: 'Casado(a)' },
    { id: 3, label: 'União Marital' },
    { id: 4, label: 'Divorciado(a)' },
    { id: 5, label: 'Viúvo(a)' },
    { id: 6, label: 'Desconhecido' },
  ];

  const handleChangeCategory = (cat) => {
    const list = selectedCategories;
    let finalList = list;

    const isInList = list.findIndex((i) => i === cat) > -1;

    if (isInList) {
      const nList = list.filter((i) => i !== cat);
      finalList = nList;
    } else {
      const nList = list.filter((i) => i !== cat);
      nList.push(cat);
      finalList = nList;
    }

    setSelectedCategories(finalList);
    setQuotationData({ ...quotationData, client: { ...quotationData.client, cnh_category: finalList.join('') } });
  };

  const loadData = async () => {
    try {
      const response = await api({
        method: 'POST',
        url: `/find-user`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken')
        },
        data: {
          cpf: quotationData?.client?.document,
        },
        json: true,
      });
      setSocialName(response.data.social_name);
    } catch (error) {
      setSocialName('');
    }
  };

  const handleSocialNameChange = (value) => {
    setSocialName(value);
    setQuotationData({
      ...quotationData,
      client: {
        ...quotationData?.client,
        social_name: value,
      },
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const isCpf = quotationData?.client?.document?.length <= 14;

  return (
    <Container>
      <div className="proposal-section">
        <h3>Dados do proprietário</h3>

        <div className="form" style={{ marginTop: 10 }}>
          <Input
            label="Nome completo (conforme documento)"
            height="30px"
            disabled
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.client?.name}
            onChange={(event) =>
              setQuotationData({ ...quotationData, client: { ...quotationData?.client, name: event.target.value } })
            }
            success={quotationData?.client?.name?.length > 5}
            error={quotationData?.client?.name?.length <= 5}
          />
          {isCpf && (
            <Input
              label="Nome social"
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              value={socialName}
              onChange={(event) => handleSocialNameChange(event.target.value)}
            />
          )}

          {quotationData?.client?.document?.length > 14 ? (
            <Input
              label="CNPJ"
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              mask="99.999.999/9999-99"
              value={quotationData?.client?.document}
              onChange={(event) =>
                setQuotationData({ ...quotationData, client: { ...quotationData?.client, document: event.target.value } })
              }
              success={cnpj.isValid(quotationData?.client?.document)}
              error={quotationData?.client?.document && !cnpj.isValid(quotationData?.client?.document)}
            />
          ) : (
            <Input
              label="CPF / CNPJ"
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              mask="999.999.999-999"
              value={quotationData?.client?.document}
              onChange={(event) =>
                setQuotationData({ ...quotationData, client: { ...quotationData?.client, document: event.target.value } })
              }
              success={quotationData?.client?.document && cpf.isValid(quotationData?.client?.document)}
              error={
                !quotationData?.client?.document ||
                (quotationData?.client?.document && !cpf.isValid(quotationData?.client?.document))
              }
            />
          )}

          {isCpf && (
            <Input
              label="Data de nascimento"
              height="30px"
              width="230px"
              style={{ marginRight: 50, marginTop: 20 }}
              mask="99/99/9999"
              value={quotationData?.client?.birthdate}
              onChange={(event) =>
                setQuotationData({ ...quotationData, client: { ...quotationData?.client, birthdate: event.target.value } })
              }
              success={isBirthDateValid(quotationData?.client?.birthdate)}
              error={!quotationData?.client?.birthdate || !isBirthDateValid(quotationData?.client?.birthdate)}
            />
          )}

          <Input
            label="E-mail"
            height="30px"
            width="230px"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.client?.email}
            onChange={(event) =>
              setQuotationData({ ...quotationData, client: { ...quotationData?.client, email: event.target.value } })
            }
            success={quotationData?.client?.email && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(quotationData?.client?.email)}
            error={
              !quotationData?.client?.email ||
              (quotationData?.client?.email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(quotationData?.client?.email))
            }
          />

          <Input
            label="Telefone"
            height="30px"
            width="230px"
            mask="(99) 99999-9999"
            style={{ marginRight: 50, marginTop: 20 }}
            value={quotationData?.client?.phone}
            onChange={(event) =>
              setQuotationData({ ...quotationData, client: { ...quotationData?.client, phone: event.target.value } })
            }
            success={quotationData?.client?.phone}
            error={!quotationData?.client?.phone}
          />

          {isCpf && (
            <Select
              label="Estado Civil"
              height="30px"
              width="230px"
              placeholder="Selecione o Estado Civil"
              value={quotationData?.client.civil_status}
              style={{ marginTop: 20, marginRight: 50 }}
              onChange={(e) => {
                setQuotationData({
                  ...quotationData,
                  client: {
                    ...quotationData.client,
                    civil_status: e.target.value,
                    civil_status_label:
                      availableCivilStatus[availableCivilStatus.findIndex((i) => i.id === Number(e.target.value))],
                  },
                });
              }}
              error={!quotationData?.client.civil_status}
              success={quotationData?.client.civil_status}
            >
              <option value="">Selecione</option>
              {availableCivilStatus?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.label}
                </option>
              ))}
            </Select>
          )}

          <Select
            label="Pessoa Politicamente Exposta"
            height="30px"
            width="230px"
            style={{ marginTop: 20, marginRight: 50 }}
            value={quotationData?.client?.ppe}
            onChange={(e) => {
              setQuotationData({ ...quotationData, client: { ...quotationData.client, ppe: e.target.value } });
            }}
            error={quotationData?.client?.ppe !== 'false' && quotationData?.client?.ppe !== 'true'}
            success={quotationData?.client?.ppe === 'false' || quotationData?.client?.ppe === 'true'}
          >
            <option value="">Selecione</option>
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </Select>

          {isCpf && (
            <div name="gender-field" style={{ marginRight: 50, marginTop: 20 }}>
              <p style={{ fontSize: 12 }}>Sexo:</p>
              <div className="genderRadio" style={{ borderColor: !quotationData?.client?.gender ? error : success }}>
                <input
                  id="minput"
                  type="radio"
                  checked={quotationData?.client?.gender === 1}
                  onChange={() => setQuotationData({ ...quotationData, client: { ...quotationData?.client, gender: 1 } })}
                />
                <label htmlFor="minput">Masculino</label>
                <input
                  style={{ marginLeft: 20 }}
                  id="finput"
                  type="radio"
                  checked={quotationData?.client?.gender === 2}
                  onChange={() => setQuotationData({ ...quotationData, client: { ...quotationData?.client, gender: 2 } })}
                />
                <label htmlFor="finput">Feminino</label>
              </div>
            </div>
          )}
        </div>

        {isCpf && (
          <>
            <div className="form" style={{ marginTop: 15 }}>
              <Input
                label="Número CNH"
                height="30px"
                width="230px"
                mask="99999999999"
                style={{ marginRight: 50 }}
                value={quotationData?.client?.cnh_number}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, cnh_number: event.target.value } })
                }
                success={quotationData?.client?.cnh_number?.length === 11}
                error={!quotationData?.client?.cnh_number || quotationData?.client?.cnh_number?.length !== 11}
              />

              <Input
                label="Data 1ª Habilitação"
                height="30px"
                width="230px"
                style={{ marginRight: 50 }}
                mask="99/99/9999"
                value={quotationData?.client?.cnh_date}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, cnh_date: event.target.value } })
                }
                success={quotationData?.client?.cnh_date?.length > 3 && isDateValid(quotationData?.client?.cnh_date)}
                error={!quotationData?.client?.cnh_date || !isDateValid(quotationData?.client?.cnh_date)}
              />

              <Input
                height="30px"
                width="230px"
                style={{ marginRight: 50 }}
                mask="99/99/9999"
                label="Data Vencimento CNH"
                value={quotationData?.client?.cnh_expiry}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, cnh_expiry: event.target.value } })
                }
                success={isDateValid(quotationData?.client?.cnh_expiry)}
                error={!isDateValid(quotationData?.client?.cnh_expiry)}
              />

              <div name="cnh-category-field" style={{ marginRight: 50 }}>
                <p style={{ fontSize: 12 }}>Categoria CNH:</p>

                <div className="cnhCheckboxes" style={{ borderColor: !quotationData.client?.cnh_category ? error : success }}>
                  {availableCategories?.map((cat, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        id={cat}
                        name={cat}
                        checked={selectedCategories?.findIndex((i) => i === cat) > -1}
                        onChange={() => handleChangeCategory(cat)}
                      />
                      <label for={cat} style={{ cursor: 'pointer', marginRight: 10 }}>
                        {cat}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="form">
              <Input
                label="Número do RG/IE"
                height="30px"
                width="230px"
                style={{ marginRight: 50, marginTop: 20 }}
                value={quotationData?.client?.rg_number}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, rg_number: event.target.value } })
                }
                success={quotationData?.client?.rg_number?.length > 3}
                error={!quotationData?.client?.rg_number}
              />

              <Input
                label="Órgão Expedidor"
                height="30px"
                width="230px"
                style={{ marginRight: 50, marginTop: 20 }}
                value={quotationData?.client?.rg_oe}
                onChange={(event) =>
                  setQuotationData({ ...quotationData, client: { ...quotationData?.client, rg_oe: event.target.value } })
                }
                success={quotationData?.client?.rg_oe?.length > 3}
                error={!quotationData?.client?.rg_oe}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default ClientData;
