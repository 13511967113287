/* eslint-disable jsx-a11y/alt-text */
import { Input, Button } from 'components';
import { Container, SectionTitle } from '../../style';
import { useEffect, useState } from 'react';
import PlateIcon from 'assets/plate-2.png';
import api from 'api/index';
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { primary } from 'styles/colorProvider';
import { isPlateFormatValid } from 'utils/plate';
import { Alert } from '@material-ui/lab';

const PlateStep = ({ quotationData, setQuotationData, nextStep }) => {
  const [noPlate, setNoPlate] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [loading, setLoading] = useState(false);
  const [plateIsValid, setPlateIsValid] = useState(false);
  const [showPlateError, setShowPlateError] = useState(false);

  const handleChangePlate = (e) => {
    setHasChange(true);
    const plate = e.target.value;

    if (plate.length <= 7) {
      setVehicle((prev) => ({ ...prev, plate }));
      setPlateIsValid(false);
    }

    if (plate.length === 7) {
      const isValid = isPlateFormatValid(plate);
      setPlateIsValid(isValid);
      setShowPlateError(!isValid);
    } else {
      setShowPlateError(false);
    }
  };

  const handleNoPlate = () => {
    setHasChange(true);
    setNoPlate((prev) => !prev);
    setVehicle((prev) => ({ ...prev, plate: undefined, chassi: undefined }));
    setPlateIsValid(true);
    setShowPlateError(false);
  };

  useEffect(() => {
    if (quotationData?.vehicle) {
      setVehicle((prev) => ({ ...prev, plate: quotationData.vehicle?.plate, chassi: quotationData.vehicle?.chassi }));
    }
  }, []);
  const handleSubmit = async () => {
    let auction = false

    try {
      setQuotationData((prev) => ({ ...prev, vehicle: { ...prev.vehicle, ...vehicle, auction,zero_km: noPlate,vehicle_already_insured:false } }));
      await api({
        method: 'put',
        url: `/quotation/proposal/${quotationData.quotationProposalData.quotationProposalId}`,

        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });
    } catch (error) {
      // Prevents loop
      return setLoading(false)
    }

    setLoading(false)
    nextStep();
  };
  return (
    <Container>
      <SectionTitle>Insira a placa do veículo</SectionTitle>
      <>
        <Input
          disabled={noPlate}
          icon={<img src={PlateIcon} style={{ width: 25 }} />}
          label="Placa"
          value={vehicle?.plate}
          disableLabel
          // mask="aaa-9*99"
          placeholder="Placa"
          onChange={handleChangePlate}
          onClick={() => setNoPlate(false)}
        />

        {showPlateError && <Alert severity="warning">Formato de placa inválido</Alert>}

        {noPlate && (
          <Input
            label="Chassi"
            value={vehicle?.chassi}
            disableLabel
            mask="*****************"
            placeholder="Chassi"
            onChange={(e) => {
              // setQuotationData({ ...quotationData, vehicle: { ...quotationData.vehicle, chassi: e.target.value } });
              setVehicle((prev) => ({ ...prev, chassi: e.target.value }));
              setHasChange(true);
            }}
          />
        )}

        <div className="checkbox-item" style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <input type="checkbox" id="noPlate" name="noPlate" checked={noPlate} onChange={handleNoPlate} />
          <label for="noPlate" style={{ cursor: 'pointer' }}>
            <p style={{ margin: 0 }}>Veículo não emplacado / 0 KM</p>
            <small style={{ marginLeft: 25, color: '#808080', margin: 0 }}>
              Marque esta opção se o veículo ainda não tiver sido emplacado ou é 0 KM.
            </small>
          </label>
        </div>

        <Button
          disabled={(!vehicle?.plate && !vehicle?.chassi) || loading || !plateIsValid}
          height="35px"
          style={{ margin: 0, marginTop: 30 }}
          onClick={() => {
            hasChange ? handleSubmit() : nextStep();
          }}
        >
          {!loading && "Continuar"}
            {loading && <ReactLoading color={primary} height={24} width={24} type="spin" />}
        </Button>
      </>
    </Container>
  );
};

export default PlateStep;
