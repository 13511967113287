/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';

import api from 'api';
import { Header, SideMenuBroker, QuotationItem, Button } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { Search } from 'components/index';

function QuotationList({ history }) {
  const scrollParent = useRef();
  const [search, setSearch] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [quotationsList, setQuotationsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();

  const loadData = async () => {
    setLoading(true);
    setCurrentPage(1);
    const resp = await api({
      method: 'GET',
      url: `/list-my-quotations`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        page: 1,
        take: 10,
        search,
      },
      json: true,
    });
    setQuotationsList(resp?.data?.response);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;
    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);
      const resp = await api({
        method: 'GET',
        url: `/list-my-quotations`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          page,
          take: 10,
          search,
        },
        json: true,
      });
      const data = quotationsList;
      const ndata = data.concat(resp?.data?.response);
      setCurrentPage(page);
      setQuotationsList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <SideMenuBroker />
        <ContentArea>
          <Div>
            <Title>MINHAS COTAÇÕES</Title>
          </Div>
          <div className="toolbar">
            <Button title="Nova cotação" height="30px" onClick={() => history.push('/cotacao')}>
              Nova cotação
            </Button>
            <Search
              placeholder="Buscar por representante"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => {
                setSearch('');
                loadData();
              }}
              onConfirm={() => loadData()}
            />
          </div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading && quotationsList?.map((quotation) => <QuotationItem quotation={quotation} />)}
              </div>
            </InfiniteScroll>

            {!loading && !quotationsList?.length && <p>Nenhuma cotação encontrada</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {quotationsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default QuotationList;
