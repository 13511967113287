/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import { Input, Button } from 'components';
import { FormBody } from './styles';
import { toast } from 'react-toastify';

function AddTeamForm({ onFinish }) {

    const [loadingCreate, setLoadingCreate] = useState(false);
    const [name, setName] = useState();


    const handleCreateKeyAccount = async () => {
        setLoadingCreate(true);
        try {
            await api({
                method: 'POST',
                url: `/create-key-account`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Cookies.get('splitriskwl-authtoken'),
                },
                data: {
                    name
                },
            });
            onFinish();
            toast.success('Cadastrado com sucesso!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
            });

            setLoadingCreate(false);
        } catch (e) {
            setLoadingCreate(false);
            toast.error(e?.response?.data?.error, {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
            });
        }
    };

    return (
        <FormBody>
            <div >
                <Input className='input-name' label="Nome" value={name} onChange={(e) => setName(e.target.value?.toUpperCase())} />
            </div>

            <Button
                loading={loadingCreate}
                disabled={loadingCreate || !name}
                height="30px"
                style={{ marginTop: 20, marginLeft: 0 }}
                onClick={handleCreateKeyAccount}
            >
                Cadastrar
            </Button>

        </FormBody>
    );
}

export default AddTeamForm;
