import styled from 'styled-components';
import { secondary, darkGray, buttonTextColor } from 'styles/colorProvider';

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.outlined ? 'transparent' : props?.backgroundColor ? props?.backgroundColor : secondary)};
  border: ${(props) => (props.outlined ? `1px solid ${secondary}` : 'none')};
  outline: none;
  cursor: pointer;
  margin: 10px;
  transition: all ease-in-out 0.3s;
  font-size: ${(props) => props.fontSize || '15px'};
  border-radius: 50px;
  height: ${(props) => props.height || '45px'};
  width: ${(props) => props.width || '180px'};
  color: ${(props) => (buttonTextColor && !props.outlined ? buttonTextColor : darkGray)};

  &&:hover {
    opacity: 0.7;
    color: ${(props) => (buttonTextColor && !props.outlined ? buttonTextColor : darkGray)};
  }
  &&:disabled {
    opacity: 0.7;
    cursor: default;
    color: ${(props) => (buttonTextColor && !props.outlined ? buttonTextColor : darkGray)};
  }

  @media (max-width: 1000px) {
    width: ${(props) => props.mobileWidth || '100%'};
  }
`;
