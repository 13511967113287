/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import api from 'api';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import emptyAnimation from 'assets/empty.json';
import settings from 'settings.json';
import { Header, PolicyMenu, Button, ClaimItem } from 'components';
import { Container, ContentArea, LoadingArea, ClaimInformationArea, Title, CountArea, DataArea } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { isCoveredPolicy } from './../../../utils/coveredPolicy';

function PolicyClaim({ location, history }) {
  const policyId = parseInt(location.pathname.split('/meus-sinistros/')[1]);
  const scrollParent = useRef();

  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [onConfirm, setOnConfirm] = useState(false);
  const [claimList, setClaimList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();

  const policyIdData = async () => {
    const policyNumber = {
      method: 'GET',
      url: `/policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyNumber);
    setPolicyData(response.data);
    setLoading(false);
  };

  const MyClaim = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/list-claim-policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        page: currentPage,
        take: 10,
      },
      json: true,
    });

    setClaimList(resp?.data?.response);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/list-claim-policy/${policyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          page,
          take: 10,
        },
        json: true,
      });

      const data = claimList;
      const ndata = data.concat(resp?.data?.response);

      setCurrentPage(page);
      setClaimList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    MyClaim();
    policyData && policyIdData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <PolicyMenu policyId={policyId} onConfirm={setOnConfirm} policyData={policyData} />

            {!loading && !claimList?.length ? (
              <ClaimInformationArea>
                <Title>MEUS SINISTROS</Title>
                <Lottie
                  autoplay
                  style={{ marginTop: 10, opacity: 0.6 }}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: emptyAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={140}
                  width={240}
                />
                <p>Nenhum sinistro encontrado!</p>
                <Button onClick={() => history.push(`/novo-sinistro/${policyId}`)}>Comunicar sinistro</Button>
              </ClaimInformationArea>
            ) : (
              <ClaimInformationArea>
                <div className="title-area">
                  <Title>MEUS SINISTROS</Title>

                  <Button heigh="30px" onClick={() => history.push(`/novo-sinistro/${policyId}`)}>
                    Comunicar sinistro
                  </Button>
                </div>
                <DataArea ref={scrollParent}>
                  <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={hasNextPage}
                    useWindow={false}
                    getScrollParent={() => scrollParent?.current}
                    initialLoad={false}
                    style={{ width: '100%', display: 'flex' }}
                    threshold={10}
                  >
                    <div style={{ width: '100%' }}>
                      {loading && (
                        <LoadingArea>
                          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                        </LoadingArea>
                      )}
                      {!loading && claimList?.map((claim) => <ClaimItem policy={policyData} claim={claim} />)}
                    </div>
                  </InfiniteScroll>
                </DataArea>
                <CountArea>
                  {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

                  {!loadingMore && !loading && (
                    <p style={{ color: primary }}>
                      Mostrando {claimList?.length} de {totalCount}
                    </p>
                  )}
                </CountArea>
              </ClaimInformationArea>
            )}
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default PolicyClaim;
