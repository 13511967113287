import { React, useState } from 'react';
import { BiDetail } from 'react-icons/bi';
import { BsPrinter } from 'react-icons/bs';
import { FiDollarSign } from 'react-icons/fi';
import { MdChevronLeft } from 'react-icons/md';
import { IoWalletOutline } from 'react-icons/io5';
import { BiMenuAltRight, BiBarcodeReader } from 'react-icons/bi';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, ItemMenu, TextItem, MobileMenu } from './styles';

function BackofficeProposalMenu({ proposalId, onCancel, proposalData }) {
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();
  const isCommercial = location?.pathname?.includes('comercial');

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <MobileMenu>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <BiMenuAltRight size={26} />
        </IconButton>

        <Menu
          style={{ marginTop: '50px', marginLeft: '-40px' }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              history.push('/backoffice/propostas');
            }}
          >
            <MdChevronLeft size="18" />
            <TextItem>Propostas</TextItem>
          </MenuItem>

          <MenuItem
            className={path.includes('/backoffice/detalhes-da-apolice') && 'select'}
            onClick={() => {
              history.push(`/backoffice/detalhes-da-proposta/${proposalId}`);
            }}
          >
            <BiDetail size="18" />
            <TextItem>Detalhes</TextItem>
          </MenuItem>

          <MenuItem
            className={path.includes('/backoffice/faturas-proposta') && 'select'}
            onClick={() => {
              history.push(`/backoffice/faturas-proposta/${proposalId}`);
            }}
          >
            <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Faturas</TextItem>
          </MenuItem>

          <MenuItem
            onClick={() => {
              window.open(`/backoffice/detalhes-da-proposta/${proposalId}/imprimir`);
            }}
          >
            <BsPrinter size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Imprimir proposta</TextItem>
          </MenuItem>
        </Menu>
      </MobileMenu>

      <Container>
        <ItemMenu
          onClick={() => {
            isCommercial ? history.push('/comercial/minhas-propostas') : history.push('/backoffice/propostas');
          }}
        >
          <MdChevronLeft size="18" />
          <TextItem>Propostas</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/detalhes-da-apolice') && 'select'}
          onClick={() => {
            isCommercial
              ? history.push(`/comercial/detalhes-da-proposta/${proposalId}`)
              : history.push(`/backoffice/detalhes-da-proposta/${proposalId}`);
          }}
        >
          <BiDetail size="18" />
          <TextItem>Detalhes</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/faturas-proposta') && 'select'}
          onClick={() => {
            isCommercial
              ? history.push(`/comercial/faturas-proposta/${proposalId}`)
              : history.push(`/backoffice/faturas-proposta/${proposalId}`);
          }}
        >
          <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Faturas</TextItem>
        </ItemMenu>
        <ItemMenu
          onClick={() => {
            window.open(`/backoffice/detalhes-da-proposta/${proposalId}/imprimir`);
          }}
        >
          <BsPrinter size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Imprimir proposta</TextItem>
        </ItemMenu>
      </Container>
    </>
  );
}

export default BackofficeProposalMenu;
