import styled from 'styled-components';
import { white, primary } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;

  h1{
    font-size: 28px;
    color: ${primary};
    margin-top: 75px;

    @media (max-width: 1000px) {
    text-align: center;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

