/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import CsvDownload from 'react-json-to-csv';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';
import { toast } from 'react-toastify';
import { Header, AccessGroupItem, Select, Button, Dialog, Input } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton } from './styles';
import { loadingColor, primary, white, secondary } from 'styles/colorProvider';
import { MdChevronLeft } from 'react-icons/md';

function AccessGroups({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [accessGroupsList, setAccessGroupsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [open, setOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [selectStatus, setSelectStatus] = useState('all');
  const [exportStatus, setExportStatus] = useState();
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [exportData, setExportData] = useState();
  const [touchFilter, setTouchFilter] = useState();

  const loadData = async () => {
    setLoading(true);
    setCurrentPage(1);
    const resp = await api({
      method: 'GET',
      url: `/access-groups`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    setAccessGroupsList(resp?.data?.response);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
    setTouchFilter(false);
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);
        const resp = await api({
          method: 'GET',
          url: `/access-groups`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitriskwl-authtoken'),
          },
          json: true,
        });
        const data = accessGroupsList;
        const ndata = data.concat(resp?.data?.response);
        setCurrentPage(page);
        setAccessGroupsList(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  const handleClear = () => {
    setSelectStatus('all');
    setStartdata('');
    setEndData('');
  };

  const handleExport = async () => {
    setLoadingExport(true);

    try {
      const resp = await api({
        method: 'GET',
        url: `/report/export-proposals`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
          feature: 'export-proposals',
        },
        params: {
          status: !exportStatus || exportStatus === '' || exportStatus === 'all' ? 'undefined' : exportStatus,
        },
        json: true,
      });
      setExportData(resp.data);
      setLoadingExport(false);
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar proposta">
      <Select height="30px" label="Filtrar por status" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
        <option value={'all'}>Todos os Status</option>
        <option value={'awaiting_inspection'}>Aguardando vistoria</option>
        <option value={'awaiting_payment'}>Aguardando pagamento</option>
        <option value={'rejected'}>Proposta rejeitada</option>
        <option value={'cancelled'}>Proposta cancelada</option>
        <option value={'awaiting_registration'}>Aguardando cadastro</option>
        <option value={'awaiting_activation'}>Aguardando ativação</option>
      </Select>

      <Input
        value={startData}
        onChange={(e) => setStartdata(e.target.value)}
        widht="30%"
        label="Data inicial"
        mask="99/99/9999"
        height="30px"
      />
      <Input
        value={endData}
        onChange={(e) => setEndData(e.target.value)}
        widht="30%"
        label="Data final"
        mask="99/99/9999"
        height="30px"
      />
      <ModalButton>
        <Button
          height="35px"
          outlined
          disabled={selectStatus === 'all' && startData === '' && endData === ''}
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          height="35px"
          onClick={() => {
            setTouchFilter(true);
            loadData();
            setOpen(false);
          }}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);
        setExportStatus();
        setExportData();
      }}
      width="50%"
      title="Exportar propostas"
    >
      {!exportData && (
        <>
          <Select height="30px" label="Status" value={exportStatus} onChange={(e) => setExportStatus(e.target.value)}>
            <option value={'all'}>Todos os Status</option>
            <option value={'awaiting_inspection'}>Aguardando vistoria</option>
            <option value={'awaiting_payment'}>Aguardando pagamento</option>
            <option value={'rejected'}>Proposta rejeitada</option>
            <option value={'cancelled'}>Proposta cancelada</option>
            <option value={'awaiting_registration'}>Aguardando cadastro</option>
            <option value={'awaiting_activation'}>Aguardando ativação</option>
          </Select>
          <Button height="35px" style={{ margin: 0, marginTop: 30 }} onClick={handleExport} disabled={loadingExport}>
            {loadingExport ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Exportar'}
          </Button>
        </>
      )}

      {exportData?.hasData ? (
        <>
          <p>Seu arquivo está pronto!</p>
          <CsvDownload
            data={exportData?.data}
            style={{
              height: 30,
              width: 200,
              margin: 0,
              cursor: 'pointer',
              borderRadius: 20,
              border: 'none',
              backgroundColor: secondary,
            }}
            filename={`split_risk_propostas_${new Date().toLocaleDateString()}.csv`}
          >
            Baixar
          </CsvDownload>
        </>
      ) : (
        <p>{exportData?.message}</p>
      )}
    </Dialog>
  );

  return (
    <>
      <Header />
      <Container>
        {renderFilterDialog()}
        {renderExportDialog()}
        <ContentArea>
          <Div>
            <Title>
              <IconButton size="small" style={{ marginRight: 10 }} onClick={() => history.push('/backoffice/usuarios')}>
                <MdChevronLeft size={24} />
              </IconButton>
              GRUPOS DE ACESSO
            </Title>
            <Button height="30px" onClick={() => history.push('/backoffice/criar-grupo-de-acesso')}>
              Criar grupo de acesso
            </Button>
          </Div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading && accessGroupsList?.map((group) => <AccessGroupItem accessGroup={group} />)}
              </div>
            </InfiniteScroll>

            {!loading && !accessGroupsList?.length && <p>Nenhum grupo de acesso encontrado.</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {accessGroupsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default AccessGroups;
