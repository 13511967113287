import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogBody } from './styles';
import { primary } from 'styles/colorProvider';

function DialogComponent({ onClose, open, title, children, width, height }) {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <DialogTitle style={{ color: primary, fontFamily: 'Nunito', fontSize: 22 }}>{title}</DialogTitle>
      <DialogBody width={width} height={height}>{children}</DialogBody>
    </Dialog>
  );
}

export default DialogComponent;
