import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import { Container } from './styles';
import { formatCPF } from 'react-data-formatter';
import { FiTrash } from 'react-icons/fi';
import { primary } from 'styles/colorProvider';

function ClaimPlateItem({ data, victim, onClick }) {
  return (
    <Container>
      <div className="info-area">
        {!victim && (
          <p>
            Placa: <span>{data?.plate}</span>
          </p>
        )}
        <p>
          {victim ? 'Nome' : 'Condutor'}: <span>{data?.name}</span>
        </p>
        {victim && (
          <p>
            CPF: <span>{formatCPF(data?.document)}</span>
          </p>
        )}
        <p>
          Telefone: <span>{data?.phone}</span>
        </p>
      </div>
      <div className="button-area">
        <IconButton onClick={onClick}>
          <FiTrash size={14} color={primary} />
        </IconButton>
      </div>
    </Container>
  );
}

export default ClaimPlateItem;
