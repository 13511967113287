import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon } from './styles';
import { useHistory } from 'react-router-dom';
import { formatProposalStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';
import { error, warn, middleGray } from '../../styles/colorProvider';
import { RiTeamLine } from 'react-icons/ri';

function AccessGroupItem({ accessGroup, commercial }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(`/backoffice/grupo-de-acesso/${accessGroup?.id}`);
  };

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconProposal">
            <RiTeamLine size={23} />
          </div>

          <Div style={{ width: '50%' }}>
            <Title>{accessGroup?.name}</Title>
            <Subtitle>{accessGroup?.description || 'Sem descrição'}</Subtitle>
          </Div>

          <Div>
            <Title>Permissões</Title>
            <Subtitle>{accessGroup?.id_features?.split(',')?.length}</Subtitle>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default AccessGroupItem;
