/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Header, ProposalMenu, Button } from 'components';
import {
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  ContractBox,
  SubscriptionChargeUrlButton,
} from './styles';
import { BsShieldFillExclamation } from 'react-icons/bs';
import { Alert } from '@material-ui/lab';
import { formatCPF, formatPlate, formatCurrency } from 'react-data-formatter';
import { formatPolicyStatus } from 'utils/policyStatus';
import moment from 'moment';
import Cookies from 'js-cookie';
import api from 'api';
import ReactLoading from 'react-loading';
import Contract from './contract';
import { loadingColor, warn } from 'styles/colorProvider';
import momentBD from 'utils/momentBusinessDays';
import { cpf } from 'cpf-cnpj-validator';

function ProposalDetails({ history, location }) {
  const proposalId = parseInt(location.pathname.split('/detalhes-da-proposta/')[1]);
  const iof = 0.0738;

  const [proposalData, setProposalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const proposalIdData = async () => {
    const proposalNumber = {
      method: 'GET',
      url: `/proposal/${proposalId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };

    setLoading(true);
    const response = await api(proposalNumber);

    setProposalData(response.data);
    setLoading(false);
  };

  useEffect(() => proposalIdData(), []);
  const isCpf = cpf.isValid(proposalData?.user?.cpf);

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && step === 1 && (
          <ContentArea>
            <ContractBox>
              <h1>Olá, seja muito bem-vindo(a)!</h1>
              <div className="contractArea">
                <Contract />
              </div>
              <Button height="35px" width="250px" onClick={() => setStep(2)}>
                Aceitar contrato e continuar
              </Button>
            </ContractBox>
          </ContentArea>
        )}
        {!loading && step === 2 && (
          <ContentArea>
            <ProposalMenu proposalId={proposalId} />
            <Title>DETALHES DA PROPOSTA</Title>

            <Alert
              style={{ marginBottom: 20 }}
              severity="warning"
              action={
                <>
                  {!!proposalData?.subscriptionChargeUrl ? (
                    <SubscriptionChargeUrlButton color="inherit" size="small">
                      <a href={proposalData.subscriptionChargeUrl} target="_blank" rel="noopener noreferrer">
                        Realizar pagamento
                      </a>
                    </SubscriptionChargeUrlButton>
                  ) : (
                    'Sua fatura está indisponível nesse momento, entre em contato com seu Representante.'
                  )}
                </>
              }
            >
              Falta pouco para você realizar o pagamento do seu seguro!
            </Alert>

            <PolicyInformationArea>
              <ItemPolicy>
                <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                  <BsShieldFillExclamation size={16} style={{ marginRight: 5 }} /> Realize o pagamento do seu seguro!
                </Subtitle>

                <Text>{formatPolicyStatus(proposalData?.status)?.label}</Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Primeiro pagamento até</Subtitle>
                <Text>{momentBD(proposalData?.accepted_at).businessAdd(3, 'days').format('DD/MM/YYYY')}</Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do segurado</Subtitle>
                <Text>
                  Nome do segurado: <span>{proposalData?.user?.name}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Nome social: <span>{proposalData?.user?.social_name}</span>
                  </Text>
                )}
                <Text>
                  CPF: <span>{formatCPF(proposalData?.user?.cpf)}</span>
                </Text>

                {isCpf && (
                  <Text>
                    Data de nascimento: <span>{moment.utc(proposalData?.user?.birthdate).format('DD/MM/YYYY')}</span>
                  </Text>
                )}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do veículo</Subtitle>
                <Text>
                  Marca e modelo:{' '}
                  <span>{`${proposalData?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.name} ${proposalData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.name}`}</span>
                </Text>
                <Text>
                  Placa:{' '}
                  <span>
                    {proposalData?.vehicle?.plate !== null || undefined ? formatPlate(proposalData?.vehicle?.plate) : 'Sem placa'}
                  </span>
                </Text>
                <Text>
                  Ano/modelo: <span>{`${proposalData?.vehicle?.year_model}/${proposalData?.vehicle?.manufacture_year}`}</span>
                </Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Coberturas</Subtitle>
                {proposalData?.products?.length
                  ? proposalData?.products
                      .filter((coverage) => coverage.isService === false)
                      .map((coverage) => <Text>• {coverage.name} </Text>)
                  : null}
                {proposalData?.coverages?.length
                  ? proposalData?.coverages
                      .filter((coverage) => coverage.isService === false)
                      .map((coverage) => <Text>• {coverage.name} </Text>)
                  : null}
                {proposalData?.arrayUnique?.length &&
                  proposalData?.arrayUnique
                    .filter((array) => array.isService === false)
                    .map((array) => <Text>• {array.name} </Text>)}
              </ItemPolicy>
              <ItemPolicy>
                <Subtitle>Serviços</Subtitle>
                {proposalData?.products?.length
                  ? proposalData?.products
                      .filter((coverage) => coverage.isService === true)
                      .map((coverage) => <Text>• {coverage.name} </Text>)
                  : null}
                {proposalData?.coverages?.length
                  ? proposalData?.coverages
                      .filter((coverage) => coverage.isService === true)
                      .map((coverage) => <Text>• {coverage.name} </Text>)
                  : null}
                {proposalData?.arrayUnique?.length &&
                  proposalData?.arrayUnique
                    .filter((array) => array.isService === true)
                    .map((array) => <Text>• {array.name} </Text>)}
              </ItemPolicy>
              <ItemPolicy>
                <Subtitle>Valor cobrado mensalmente</Subtitle>
                <Text>
                  Valor do seguro:{' '}
                  {proposalData?.value_final
                    ? formatCurrency(proposalData?.value_final)
                    : proposalData?.value
                      ? formatCurrency(proposalData?.value * (1 - (proposalData?.discount_new || 0)))
                      : 0}
                </Text>
              </ItemPolicy>
            </PolicyInformationArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default ProposalDetails;
