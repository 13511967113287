/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import api from 'api';
import AddTeam from './AddTeam';
import { Header, SideMenu, SideMenuBroker, Search, TeamItem, Button, Dialog } from 'components';
import { Container, ContentArea, Title, DataArea, LoadingArea, CountArea, ToolBar } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { BsPlusCircle } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';

function TeamsList() {
  const location = useLocation();
  const scrollParent = useRef();
  const host = location?.pathname;

  const hostOceanica = window.location.hostname;
  const isOceanica = hostOceanica.split('.')[0] === 'oceanica';

  const [loading, setLoading] = useState(false);
  const [teamsList, setTeamsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [showAddTeam, setShowAddTeam] = useState(false);

  const isCommercial = host?.includes('comercial');

  const loadData = async (searchData) => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/teams`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      params: {
        search: searchData?.replaceAll('.', '').replaceAll('-', ''),
        page: currentPage,
        take: 10,
        commercial: isCommercial,
      },
      json: true,
    });

    setTeamsList(resp?.data?.response);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/teams`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          search,
          page,
          take: 10,
          commercial: isCommercial,
        },
        json: true,
      });

      const data = teamsList;
      const ndata = data.concat(resp?.data?.response);

      setCurrentPage(page);
      setTeamsList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    loadData(search);
  }, []);

  const renderAddTeam = () => (
    <Dialog open={showAddTeam} onClose={() => setShowAddTeam(false)} title="Cadastrar equipe" width="650px">
      <AddTeam
        onFinish={() => {
          loadData();
          setShowAddTeam(false);
        }}
      />
    </Dialog>
  );

  return (
    <>
      {renderAddTeam()}
      <Header />
      <Container>
        {isCommercial ? <SideMenuBroker /> : <SideMenu />}

        <ContentArea>
          {!isOceanica ? (
            <Title>{isCommercial ? 'MINHAS EQUIPES' : 'EQUIPES'}</Title>
          ) : (
            <Title>{isCommercial ? 'CORRETORES' : 'CORRETORES'}</Title>
          )}

          <ToolBar>
            <Search
              help="Buscar pelo nome da equipe."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => {
                setSearch('');
                loadData('');
              }}
              onConfirm={() => loadData(search)}
            />
            {!isCommercial && (
              <Button height="30px" onClick={() => setShowAddTeam(true)}>
                <BsPlusCircle style={{ marginRight: 5 }} />
                Cadastrar equipe
              </Button>
            )}
          </ToolBar>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading && teamsList?.map((team) => <TeamItem isCommercial={isCommercial} team={team} client />)}
              </div>
            </InfiniteScroll>

            {!loading && !teamsList?.length && !isOceanica && <p>Nenhuma equipe encontrada</p>}
            {!loading && !teamsList?.length && isOceanica && <p>Nenhum corretor encontrado</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {teamsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default TeamsList;
